import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { reset, setDemandOrientation } from '../../../../../../app/redux/features/student/demand_orientation.slice';
import { modeEnsOption, modeEtudeOption, paysOptions, sexeOptions, slugifyFileName } from '../../../../../../app/utils';
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { links } from '../../../../../routes';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../../../layout';
import { Button, Grid, KAlert, Selectfield, Textfield } from '../../../../../components';
import { AddFileSuccess } from '../../../../../assets/illustration';

export const CreateOrientationPage = () => {
   const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()
    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.orientation_cretae}`)
      }
    }, [token, navigate]);
  
    const [data, setData] = useState<any>({
      email: "",
      niveau_etude_actuel: "",
      nom_etablissement_actuel: "",
      filiere_actuelle: "",
      moyenne_generale: "",
      motivation_de_poursuite_des_etudes: "",
      objectif_de_carriere: "",
      mode_etude: "",
      mode_enseignement: "",
      possibilite_de_demenagement: false,
      contrainte_particuliere: "",
      information_permanente: "",
      commentaire_supplementaire: ""
    })
    
    const [dataProfile, setDataProfile] = useState<any>({
      nom: "",
      prenom: "",
      telephone: "",
      ville: "",
      date_de_naissance: "",
      lieu_de_naissance: "",
      pays_de_naissance: "",
      nationalite: "",
      sexe: "",
      pays_de_residence: "",
      adresse: ""
    })
  
    const [error, setError] = useState<any>({})
    const [fichiers, setFichiers] = useState<any[]>([])
  
    const dispatch = useAppDispatch();
    const { isCreateError, isCreateLoading, isCreateSuccess, demand_orientation, message } = useAppSelector((state) => state.demand_orientation);
  
    const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
      const file = e.target.files
      if(file && file.length > 0){
        let type: number = file[0].size
        let max: number = 1024 * 1024 * 4
        if(type < max){
          setImagesBase(file[0], {
            nom_fichier: slugifyFileName(file[0].name),
            type_fichier: `Fichier-${fichiers.length + 1}`
          })
        }else{
          setError({
            field: "global",
            message: "Votre fichier ne doit pas dépasser 8Mo"
          })
          return
        }
      }
    }
  
    const setImagesBase = (file: any, info: any) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        // @ts-ignore
        setFichiers(list => [...list.filter(el => el.image !== reader.result), {...info, fichier: reader.result}])
      }
    }
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.email){
        setError({
            field: "email",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.niveau_etude_actuel){
        setError({
            field: "niveau_etude_actuel",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.nom_etablissement_actuel){
        setError({
            field: "nom_etablissement_actuel",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.filiere_actuelle){
        setError({
            field: "filiere_actuelle",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
     
      if(!data.moyenne_generale){
        setError({
            field: "moyenne_generale",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.motivation_de_poursuite_des_etudes){
        setError({
            field: "motivation_de_poursuite_des_etudes",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.objectif_de_carriere){
        setError({
            field: "objectif_de_carriere",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.mode_etude){
        setError({
            field: "mode_etude",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.mode_enseignement){
        setError({
            field: "mode_enseignement",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.information_permanente){
        setError({
            field: "information_permanente",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.contrainte_particuliere){
        setError({
            field: "contrainte_particuliere",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!dataProfile.nom){
        setError({
            field: "profile_nom",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!dataProfile.prenom){
        setError({
            field: "profile_prenom",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!dataProfile.date_de_naissance){
        setError({
            field: "profile_date_de_naissance",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!dataProfile.telephone){
        setError({
            field: "profile_telephone",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      
      if(!dataProfile.sexe){
        setError({
            field: "profile_sexe",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      
      if(!dataProfile.nationalite){
        setError({
            field: "profile_nationalite",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!dataProfile.pays_de_naissance){
        setError({
            field: "profile_pays_de_naissance",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      
      if(!dataProfile.lieu_de_naissance){
        setError({
            field: "profile_lieu_de_naissance",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      
      // setCloseM(true)
      dispatch(setDemandOrientation({
        ...data, 
        profile: dataProfile, 
        fichiers
      }))
    }
  
  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  return (
      <MainLayout title="Demande d'orientation" isMin={false} here='orientation'>
        <div className='kui-page is--min'>
          <div className='kui-arianne'>
              <Link className='kui-link' to={links.home}>
                  <i className="pi-home-6-line"></i>
                  <span>Accueil</span>
              </Link>
              <span> | </span>
              <Link className='kui-link' to={links.orientation}>
                  <span>Demandes d'orientation</span>
              </Link>
              <span> | </span>
              <p>Ajout d'une demande</p>
          </div>
          
          <div className='kui-page__header kui-page-header'>
            <h2>Demande d'orientation</h2>
          </div>
  
          <div className='kui-page-forms'>
            {
              isCreateSuccess && demand_orientation ? (
                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                  <div className={'kui-page-form'}>
                    <div className='kui-page-empty'>
                      <div className='kui-page-empty__illustration'>
                        <AddFileSuccess loop={true} autoplay={true} size={{
                          height: (120),
                          width: (120)
                        }}/>
                      </div>
                      <div className='kui-page-empty__details'>
                        <h1>La demande d'orientation a bien été ajoutée.</h1>
                      </div>
                    </div>
                  </div>
                  <div className={'kui-page-form'}>
                    <div className='kui-page-form__list kui-page-form-list'>
                      <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                        <div className='kui-page-form-action'>
                          <Button label={'Revenir à la liste'} btype='button' onClick={() => navigate(links.orientation)}/>
                        </div> 
                      </Grid>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                  {
                    error?.field === "global" ? (
                      <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
                    ) : null
                  }
                  {
                    isCreateError ? (
                      <KAlert status={'st--danger'} title={'Oupss!! Une erreur est survenue'} message={message}/>
                    ) : null
                  }
                  <div className={'kui-page-form'}>
                    <div className='kui-page-form__header kui-page-form-header'>
                      <h3>Information du demandeur</h3>
                    </div>
                    <div className='kui-page-form__list kui-page-form-list'>
                      <div className='kui-page-form-fields'>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_nom'} 
                            value={dataProfile.nom} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, nom: val})} 
                            type={"text"}
                            label='Nom de famille'
                            placeholder={'Ex: Kabore'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_prenom'} 
                            value={dataProfile.prenom} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, prenom: val})} 
                            type={"text"}
                            label='Prenom(s)'
                            placeholder={'Ex: Jean Victor'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Selectfield 
                            id={'profile_sexe'} 
                            value={dataProfile.sexe} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, sexe: val})} 
                            tabs={sexeOptions}
                            label='Sexe'
                            placeholder={'Ex: Masculin ou Feminin'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_nationalite'} 
                            value={dataProfile.nationalite} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, nationalite: val})} 
                            type={"text"}
                            label='Nationalité'
                            placeholder={'Ex: Burkinabe'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_date_de_naissance'} 
                            value={dataProfile.date_de_naissance} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, date_de_naissance: val})} 
                            type={"date"}
                            label='Date de naissance'
                            placeholder={'Ex:...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_lieu_de_naissance'} 
                            value={dataProfile.lieu_de_naissance} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, lieu_de_naissance: val})} 
                            type={"text"}
                            label='Lieu de naissance'
                            placeholder={'Ex: Bobo-Dioulasso'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Selectfield 
                            id={'profile_pays_de_naissance'} 
                            value={dataProfile.pays_de_naissance} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_naissance: val})} 
                            tabs={paysOptions}
                            label='Pays de naissance'
                            placeholder={'Ex: Burkina Faso'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_adresse'} 
                            value={dataProfile.adresse} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, adresse: val})} 
                            type={"text"}
                            label='Adresse'
                            placeholder={'Ex: Secteur 30'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_ville'} 
                            value={dataProfile.ville} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, ville: val})} 
                            type={"text"}
                            label='Ville de residence'
                            placeholder={'Ex: Bobo-Dioulasso'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Selectfield 
                            id={'profile_pays_de_residence'} 
                            value={dataProfile.pays_de_residence} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_residence: val})} 
                            tabs={paysOptions}
                            label='Pays de residence'
                            placeholder={'Ex: Burkina Faso'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profile_telephone'} 
                            value={dataProfile.telephone} 
                            require={true} 
                            changeValue={(val: any) => setDataProfile({...dataProfile, telephone: val})} 
                            type={"phone"}
                            label='Numero de telephone'
                            placeholder={'Ex: 0022675346789'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'email'} 
                            value={data.email} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, email: val})} 
                            type={"email"}
                            label='Email'
                            placeholder={'Ex: email@exemple.com'}
                            error={error}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div className={'kui-page-form'}>
                    <div className='kui-page-form__header kui-page-form-header'>
                      <h3>Information de la demande</h3>
                    </div>
                    <div className='kui-page-form__list kui-page-form-list'>
                      <div className='kui-page-form-fields'>
  
                        <div className='kui-page-form-field'>
                          <Selectfield 
                            id={'mode_etude'} 
                            value={data.mode_etude} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, mode_etude: val})} 
                            tabs={modeEtudeOption}
                            label={'Mode d\'etude'}
                            placeholder={'Choisir...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Selectfield 
                            id={'mode_enseignement'} 
                            value={data.mode_enseignement} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, mode_enseignement: val})} 
                            tabs={modeEnsOption}
                            label={'Mode d\'enseignement'}
                            placeholder={'Choisir...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'profession_actuelle'} 
                            value={data.nom_etablissement_actuel} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, nom_etablissement_actuel: val})} 
                            type={"text"}
                            label={'Nom de l\'etablissement actuel'}
                            placeholder={'Ecrire ici..'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'niveau_etude_actuel'} 
                            value={data.niveau_etude_actuel} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, niveau_etude_actuel: val})} 
                            type={"text"}
                            label={'Niveau d\'etude actuel'}
                            placeholder={'Ecrire ici..'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'filiere_actuelle'} 
                            value={data.filiere_actuelle} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, filiere_actuelle: val})} 
                            type={"text"}
                            label={'Filiere actuelle'}
                            placeholder={'Ecrire ici...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'moyenne_generale'} 
                            value={data.moyenne_generale} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, moyenne_generale: val})} 
                            type={"text"}
                            label={'Moyenne generale'}
                            placeholder={'Ecrire ici..'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'methode_collaboration_etudiant'} 
                            value={data.objectif_mentorat} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, objectif_mentorat: val})} 
                            type={"text"}
                            label={'Objectif du mentorat'}
                            placeholder={'Ecrire ici...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'motivation_de_poursuite_des_etudes'} 
                            value={data.motivation_de_poursuite_des_etudes} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, motivation_de_poursuite_des_etudes: val})} 
                            type={"text"}
                            label={'Motivation de poursuite des etudes'}
                            placeholder={'Ecrire ici...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'objectif_de_carriere'} 
                            value={data.objectif_de_carriere} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, objectif_de_carriere: val})} 
                            type={"text"}
                            label={'Objectif de carriere'}
                            placeholder={'Ecrire ici...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'contrainte_particuliere'} 
                            value={data.contrainte_particuliere} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, contrainte_particuliere: val})} 
                            type={"text"}
                            label={'Contrainte particuliere'}
                            placeholder={'Ecrire ici...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field is--full'>
                         <Grid direction={'fd-row'} spacing={'jc-between'} align={'center'} wrap={true} gap={0.8}>
                            <strong>Possibilité de demenagement</strong>
                            <button type={"button"} className={`kui-page-custom-theme-switch  ${data.possibilite_de_demenagement ?  "is--active" : ""}`} onClick={() => setData({...data, possibilite_de_demenagement: !data.possibilite_de_demenagement})}>
                              <div className={`kui-page-custom-theme-switch__content ${data.possibilite_de_demenagement ?  "is--active" : ""}`}></div>
                            </button>
                         </Grid>
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'information_permanente'} 
                            value={data.information_permanente} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, information_permanente: val})} 
                            type={"text"}
                            label={'Information permanente'}
                            placeholder={'Ecrire ici...'}
                            error={error}
                          />
                        </div>
                        <div className='kui-page-form-field'>
                          <Textfield 
                            id={'commentaire_supplementaire'} 
                            value={data.commentaire_supplementaire} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, commentaire_supplementaire: val})} 
                            type={"text"}
                            label={'Commentaire supplementaire'}
                            placeholder={'Ecrire ici...'}
                            error={error}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
  
  
                  <div className={'kui-page-form'}>
                    <div className='kui-page-form__header kui-page-form-header'>
                      <h3>Fichiers</h3>
                    </div>
                    <div className='kui-page-form__list kui-page-form-list'>
                      
                      {
                        fichiers?.length > 0 ? (
                          fichiers.map((item: any, index: number) => (
                            <div className='kui-filefield-lil' key={index}>
                              <div className='kui-filefield-lil__container'>
                                <div className='kui-filefield-lil__icon'>
                                  <i className="pi-file-Info-line"></i>
                                </div>
                                <div className='kui-filefield-lil__info'>
                                  <span>{item?.type_fichier}</span>
                                  <strong>{item?.nom_fichier}</strong>
                                </div>
                                <div className='kui-filefield-lil__action'>
                                  <button className='kui-link is--danger' type='button' onClick={() => {
                                    setFichiers(fichiers.filter((el: any) => el !== item))
                                  }}>
                                    <span>Supprimer</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <strong>Aucun fichier</strong>
                        )
                      }
                      <div className='kui-filefield-lil'>
                        <input type="file" multiple={false} id='fichier' onChange={handleFileChangeFile} />
                        
                        <label className='kui-filefield-lil__container' htmlFor="fichier">
                          <div className='kui-filefield-lil__icon'>
                            <i className="pi-file-Info-line"></i>
                          </div>
                          <div className='kui-filefield-lil__info'>
                            <strong>{`Ajouter un fichier`}</strong>
                          </div>
                        </label>
                          
                      </div>
                    </div>
                  </div>
  
                  <div className={'kui-page-form'}>
                    <form className='kui-page-form__list kui-page-form-list' onSubmit={handleSubmit}>
                      <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                        <div className='kui-page-form-action'>
                          <Button label={(isCreateLoading) ? "Creation en cours" :'Enregistrer et ajouter'} 
                            btype='submit' 
                            bstyle='is--primary' 
                            active={!(isCreateLoading)}
                            iconLeftClass={(isCreateLoading) ? "pi-spinner5" : "pi-add-circle-line"}
                          />
                        </div> 
                      </Grid>
                    </form>
                  </div>
                  
                </Grid>
              )
            }
          </div>
                
        </div>
      </MainLayout>
  )
}
