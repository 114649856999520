import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DemandOrientationState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondesdemandes/demande_orientations/"

const initialState: DemandOrientationState = {
    demand_orientations: [],
    demand_orientation: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setDemandOrientation = createAsyncThunk<any, Object>(
    'demand_orientation/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const traiterDemandOrientation = createAsyncThunk<any, Object>(
    'demand_orientation/traiter',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}traiter_demande/`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error.response.data)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getDemandOrientations = createAsyncThunk<any, Object>(
    'demand_orientation/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getDemandOrientation = createAsyncThunk<any, Object>(
    'demand_orientation/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateDemandOrientation = createAsyncThunk<any, Object>(
    'demand_orientation/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteDemandOrientation = createAsyncThunk<any, Object>(
    'demand_orientation/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            const item = await deleteProtecteItems(data)
            return {item, content}
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const demand_orientationSlice = createSlice({
    name: 'demand_orientation',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDemandOrientations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getDemandOrientations.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.demand_orientations = action.payload
            })
            .addCase(getDemandOrientations.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getDemandOrientation.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getDemandOrientation.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.demand_orientation = action.payload
            })
            .addCase(getDemandOrientation.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setDemandOrientation.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setDemandOrientation.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.demand_orientations = [action.payload, ...state.demand_orientations]
                state.demand_orientation = action.payload
            })
            .addCase(setDemandOrientation.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(traiterDemandOrientation.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(traiterDemandOrientation.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.demand_orientations = [action.payload, ...state.demand_orientation.filter((demande_inscription: any) => demande_inscription?.id !== action.payload?.id)]
                state.demand_orientation = action.payload
            })
            .addCase(traiterDemandOrientation.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(updateDemandOrientation.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateDemandOrientation.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.demand_orientations = [action.payload, ...state.demand_orientations.filter((demand_orientation: any) => demand_orientation?.id !== action.payload?.id)]
                state.demand_orientation = action.payload
            })
            .addCase(updateDemandOrientation.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteDemandOrientation.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteDemandOrientation.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.demand_orientations = state.demand_orientations.filter((demand_orientation: any) => demand_orientation?.id !== action.payload?.content)
            })
            .addCase(deleteDemandOrientation.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = demand_orientationSlice.actions
export default demand_orientationSlice.reducer