import React, { useContext, useEffect, useState } from 'react'
import { Column, Grid, Modal, ModalActions, Row, Table, TableItem, TableOptions, Toast } from '../../../../components';
import { deleteBourseEtudiant, getBourseEtudiants, reset, updateBourseEtudiant } from '../../../../../app/redux/features/bourse/bours_etudiant.slice';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getBourses, reset as breset  } from '../../../../../app/redux/features/bourse/bourse.slice';
import { getDemandInscriptions, reset as dreset  } from '../../../../../app/redux/features/inscription/demand.slice';
import { getStudents, reset as sreset  } from '../../../../../app/redux/features/student/student.slice';
import { getSchoolYears, reset as screset } from '../../../../../app/redux/features/school_year/school_year.slice';
import { MainLayout } from '../../../../layout';
import { API_URL2, capitalize, listDemandesBourses } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale/fr';

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.bourse_etudiant);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une attribution</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteBourseEtudiant(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

export const BourseEtudiantsPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.bourses_etudiant}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, isEditError, isEditLoading, isEditSuccess, bourse_etudiants, bourse_etudiant, message } = useAppSelector((state) => state.bourse_etudiant);
  const { isLoading: isBLoading, isError: isBError, isSuccess: isBSuccess, bourses, message: bMessage } = useAppSelector((state) => state.bourse);
  const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, students, message: sMessage } = useAppSelector((state) => state.student);
  const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demandes_inscription, message: dMessage } = useAppSelector((state) => state.demande_inscription);
  const { isLoading: isScLoading, isError: isScError, isSuccess: isScSuccess, school_years, message: scMessage } = useAppSelector((state) => state.school_year);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  
  const [isActiveAc, setIsActiveAc] = useState<boolean>(false)
  const [isActiveChan, setIsActiveChan] = useState<boolean>(false)

  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.bourses_etudiant+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.bourses_etudiant+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getBourseEtudiants({}))
    dispatch(getBourses({}))
    dispatch(getDemandInscriptions({}))
    dispatch(getStudents({}))
    dispatch(getSchoolYears({}))
    return () => {
      dispatch(reset())
      dispatch(sreset())
      dispatch(breset())
      dispatch(screset())
      dispatch(dreset())
    }
  }, [dispatch])

  useEffect(() => {
    if(isActiveChan && isEditSuccess && bourse_etudiant){
      setSelectItemModal({
        ...selectItemModal,
        statut: bourse_etudiant?.statut
      })
      setIsActiveChan(false)
    }
  }, [isActiveChan, isEditSuccess, bourse_etudiant, showItemModal, selectItemModal])

  return (
    <MainLayout title="Bourses attribuées" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
                <i className="pi-home-6-line"></i>
                <span>Accueil</span>
            </Link>
            <span> | </span>
            <p>Bourses attribuées</p>
        </div>
        {
          isLoading || isBLoading || isSLoading || isDLoading || isScLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Bourses attribuées</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                    <div className='kui-page-list-actions-display'>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.bourses_etudiant+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-list-check-line"></i>
                        </button>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.bourses_etudiant+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-layout-grid-line"></i>
                        </button>
                    </div>
                    <div className='kui-page-list-actions-group'>
                        <div className='kui-page-list-actions-input'>
                        <span>Nombre à afficher: </span>
                        <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                        </div>
                        <div className='kui-page-list-actions-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                        </div>
                    </div>
                </div>
              </div>
              {
                isError || isBError || isDError || isSError || isScError ? (
                  <div className='kui-page-error'>
                    <div className='kui-page-error__illustration'>
                      <NotFoundError loop={true} autoplay={true} size={{
                        height: 180,
                        width: 180
                      }}/>
                    </div>
                    <div className='kui-page-error_details'>
                      <p>Oupps! Une erreur est survenue</p>
                      <h1>{isError ? message : (isBError ? bMessage : isSError ? sMessage : isDError ? dMessage : isScError ? scMessage : "")}</h1>
                    </div>
                  </div> 
                ) : (
                  isSuccess && isSSuccess && isBSuccess && isScSuccess && isDSuccess  && bourse_etudiants  && students && bourses && school_years && demandes_inscription ? (
                    <div className='kui-page-list__container'>
                      <span className='kui-page-list__total'>
                        {
                          listDemandesBourses(bourse_etudiants, students, bourses, demandes_inscription, school_years)
                        .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                        .length > 0 ? `
                          ${listDemandesBourses(bourse_etudiants, students, bourses, demandes_inscription, school_years)
                            .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ).length > 1 ? listDemandesBourses(bourse_etudiants, students, bourses, demandes_inscription, school_years)
                              .filter((el: any) => el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ).length + " elements trouvés" : listDemandesBourses(bourse_etudiants, students, bourses, demandes_inscription, school_years)
                              .filter((el: any) => el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length +" element trouvé"} ` : "Aucun element trouvé"
                        }
                      </span>

                      {
                        listDemandesBourses(bourse_etudiants, students, bourses, demandes_inscription, school_years)
                        .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                        .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  listDemandesBourses(bourse_etudiants, students, bourses, demandes_inscription, school_years)
                                  .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-item is--md' key={index}>
                                        <div className='kui-page-list-item__min'>
                                          <div>
                                              <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                              {item.statut?.toUpperCase()}
                                              </span>
                                          </div>
                                        </div>
                                        

                                        <div className='kui-page-list-item__container'>
                                            <div className='kui-page-list-item__details'>
                                              <div style={{display: "flex", gap: ".3em"}}>
                                                <strong>
                                                  {
                                                    `${item.bourse?.intitule?.toUpperCase()} - ${item.bourse?.annee?.toUpperCase()}`
                                                  }
                                                </strong>
                                              </div>
                                              <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.photo_id ? API_URL2 + item?.student?.info?.photo_id : ""}/>
                                              
                                            </div>
                                        </div>
                                        <div className='kui-page-list-item__actions'>
                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setShowItemModal(true)
                                            }}>
                                                <i className={"pi-eye-line"}></i>
                                                <span>{"Apercu"}</span>
                                            </button>
                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setDeleteModal(true)
                                            }}>
                                                <i className={"pi-delete-2-line"}></i>
                                                <span>{"Supprimer"}</span>
                                            </button>
                                        </div>
                                    </div>
                                  ))        
                                }
                              </Grid>
                            ) : (
                            <Table>
                                <Row rtype='header'>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Etudiant</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Bourse</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Statut</strong>
                                  </Column>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                </Row>
                                {
                                  listDemandesBourses(bourse_etudiants, students, bourses, demandes_inscription, school_years)
                                  .filter((el: any) => el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                          <strong>{`#${index + 1}`}</strong>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.photo_id ? API_URL2 + item?.student?.info?.photo_id : ""}/>
                                      </Column>
                                      <Column ctype={'large'}>
                                          <div>
                                            <strong>
                                              {
                                                `${item.bourse?.intitule?.toUpperCase()} - ${item.bourse?.annee?.toUpperCase()}`
                                              }
                                            </strong>
                                          </div>
                                      </Column>
                                     
                                      <Column ctype={'mid'}>
                                          <div>
                                            <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                            {item.statut?.toUpperCase()}
                                            </span>
                                          </div>
                                      </Column>
                                        <Column ctype={'small'}>
                                            <TableOptions atype={"reduce"} tabs={[
                                              {
                                                icon: "pi-eye-line", 
                                                label: "Aperçu", 
                                                style:"is--neutral", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setShowItemModal(true)
                                                }
                                              },
                                              {
                                                icon: "pi-delete-2-line", 
                                                label: "Supprimer", 
                                                style:"is--danger", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setDeleteModal(true)
                                                }
                                              },
                                            ]}/>
                                        </Column>
                                    </Row>
                                  ))
                                }
                            </Table>
                            )
                        ) : (
                            <div className='kui-page-empty'>
                                <div className='kui-page-empty__illustration'>
                                  <EmptyList loop={true} autoplay={true} size={{
                                      height: (380 / 1.3),
                                      width: (420 / 1.3)
                                  }}/>
                                </div>
                                <div className='kui-page-empty__details'>
                                    <h1>Aucun element trouvé</h1>
                                </div>
                            </div>
                        )
                      }
                    </div>
                  ) : null
                )
              }
              
              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}
              {showItemModal && selectItemModal ? (
                <Modal size={'is--md'} withClose={true} id={'show-birth'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                  <div className='kui-modal-box__header'>
                    <h2>Aperçu</h2>
                  </div>
                  <div className="kui-modal-show">
                    <div className='kui-modal-show__illustration'>
                      <i className="pi-user-4-line"></i>
                    </div>
                    <div className='kui-modal-show__container'>
                      <p>{`Code Etudiant: ${selectItemModal?.student?.code_etudiant}`}</p>
                      <h1>{selectItemModal?.student?.info?.profile?.nom?.toUpperCase() + " " + selectItemModal?.student?.info?.profile?.prenom}</h1>
                      <h2>Bourse attribuée: {`${selectItemModal.bourse?.intitule?.toUpperCase()} - ${selectItemModal.bourse?.annee?.toUpperCase()}`}</h2>
                      <p>{selectItemModal?.student?.info?.profile?.nationalite}</p>
                      <div className='kui-modal-show__category'>
                        <span className='kui-tag'>
                          Sexe : {selectItemModal?.student?.info?.profile?.sexe?.toUpperCase()}
                        </span>
                        <span className='kui-tag is--primary'>
                          {selectItemModal?.student?.info?.cycle + " - " + selectItemModal?.student?.info?.filiere + " - " + selectItemModal?.student?.info?.niveau}
                        </span>
                      </div>
                      <p>{` Né(e) le ${selectItemModal?.student?.info?.profile?.date_de_naissance ? capitalize(format(new Date(selectItemModal?.student?.info?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${selectItemModal?.student?.info?.profile?.lieu_de_naissance} - ${selectItemModal?.student?.info?.profile?.pays_de_naissance}`}</p>
                      <p>{` Email: ${selectItemModal?.student?.info?.email} - Tel: ${selectItemModal?.student?.info?.profile?.telephone}`}</p>
                    </div>
                  </div>

                  <ModalActions tabsActions={[
                    {
                      icon: (isActiveAc && isEditLoading) ? "pi-spinner3" : "", 
                      label: (isActiveAc && isEditLoading)  ? "Traitement..." : isEditError ? "Ressayer..." :(selectItemModal?.statut?.toLocaleLowerCase() === "inactif" ? "Activer" : "Desactiver"), 
                      style: (isActiveAc && isEditLoading)  ? "Neutral..." : (selectItemModal?.statut?.toLocaleLowerCase() === "inactif" ? "is--success" : ""), 
                      active : !(isActiveAc && isEditLoading),
                      onClick: () => {
                        if(isActiveAc && isEditLoading){} else{
                          setIsActiveAc(true)
                          setIsActiveChan(true)
                          let data = {
                            id: selectItemModal.id,
                            data: {
                              statut: selectItemModal?.statut?.toLocaleLowerCase() === "inactif" ? "actif" : "inactif",
                              bourse: selectItemModal?.bourse?.id,
                              etudiant: selectItemModal.etudiant
                            }
                          }
                          dispatch(updateBourseEtudiant(data))
                        }
                      },
                      show: true
                    },
                    {
                      icon: "pi-delete-2-line", 
                      type: "button", 
                      label:"Supprimer", 
                      style:"is--danger", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setDeleteModal(true)
                      },
                      show: true
                    },
                    {
                      icon: "", 
                      type: "button", 
                      label:"Fermer", 
                      style:"is--neutral", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setSelectItemModal(null)
                      },
                      show: true
                    },
                  ]}/>
                </Modal>
              ) : null}
            </>
          )
        }
        
        {
          isActiveAc &&  isEditSuccess ? (
            <Toast id={"avatar-success"} status={'st--success'} fnc={() => setIsActiveAc(false)} >
              <p>Traitement reussie</p>
            </Toast>
          ) : null
        }
        {
          isActiveAc && isEditError ? (
            <Toast id={"avatar-danger"} status={'st--danger'} fnc={() => setIsActiveAc(false)} >
              <p>Oupps! Une erreur est survenue! Veuillez reessayer... {message}</p>
            </Toast>
          ) : null
        }
      </div>
    </MainLayout>
  )
}
