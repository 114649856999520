import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { addFiliereSchoolYear, deleteSchoolYear, getSchoolYears, removeFiliereSchoolYear, reset, setSchoolYear, updateSchoolYear } from '../../../../../app/redux/features/school_year/school_year.slice';
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions, Toast } from '../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { getCycles, reset as creset } from '../../../../../app/redux/features/cursus/cycle.slice';
import { getDepartments, reset as dreset  } from '../../../../../app/redux/features/cursus/department.slice';
import { getFilieres, reset as freset  } from '../../../../../app/redux/features/cursus/filiere.slice';
import { MainLayout } from '../../../../layout';


const AddModal = ({fnc}:{fnc: (val: boolean) => void | any}) => {
    const [data, setData] = useState<any>({
        annee_fin: 0,
        annee_debut: 0,
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess,school_year, isCreateError, message } = useAppSelector((state) => state.school_year);

    const [closeM, setCloseM] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      
      if(!data.annee_debut){
        setError({
            field: "annee_debut",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.annee_fin){
        setError({
            field: "annee_fin",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
    
      setError(null)
      setCloseM(true)
      dispatch(setSchoolYear({
        ...data, 
      }))
    }
  
    const closeModal = () => {
      setData(null)
      fnc(false)
      setCloseM(false)
    }
  
    return (
      <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
          <h2>Ajout d'une année scolaire</h2>
        </div>
        {
          closeM && isCreateSuccess && school_year ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Creation réussie avec succès</h1>
                  <p>
                    L'année scolaire <strong>{school_year?.annee_debut + " - " + school_year.annee_fin }</strong> a été créée
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              {
                closeM && isCreateError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              <ModalForm tabsField={[
                {
                  field: "textfield",
                  field_type: "number",
                  field_require: true,
                  value: data.annee_debut,
                  label: "Année de debut",
                  id: "annee_debut",
                  min: 1,
                  onChange: (val: any) => setData({...data, annee_debut: val}),
                  error: error,
                  placeholder: "Ex: 2024",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "number",
                  field_require: true,
                  value: data.annee_fin,
                  label: "Année de fin",
                  id: "annee_fin",
                  min: 1,
                  onChange: (val: any) => setData({...data, annee_fin: val}),
                  error: error,
                  placeholder: "Ex: 2025",
                  isShow: true
                },
              ]}/>
               
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
              type: "submit", 
              label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
              style:"is--primary", 
              active: !isCreateLoading,
              show: closeM && isCreateSuccess ? false : true
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isCreateLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}
  
const EditModal = ({item, setIsEdit, fnc}:{item: any, setIsEdit: (val: boolean) => void | any, fnc: (val: boolean) => void | any}) => {
    const [data, setData] = useState<any>({
        annee_debut: item.annee_debut || "",
        annee_fin: item.annee_fin || "",
        statut: item.statut || "",
    })
    const dispatch = useAppDispatch();
    const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.school_year);

    const [closeM, setCloseM] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      
      if(!data.annee_debut){
        setError({
            field: "annee_debut",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      
      if(!data.annee_fin){
        setError({
            field: "annee_debut",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
    
      setError(null)
      setCloseM(true)
      dispatch(updateSchoolYear({
        data, 
        id: item.id
      }))
    }
  
    const closeModal = () => {
      setData({
        intitule:  "",
        finalite:  "",
        description: "",
        statut: "",
      })
      fnc(false)
      setCloseM(false)
    }

    useEffect(() => {
      if(closeM && isEditSuccess){
        setIsEdit(true)
      }
    }, [setIsEdit])
  
    return (
      <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
            <h2>Modification d'une année scolaire</h2>
            <button type='button' className={`kui-chip ${data?.statut === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
              data?.statut === "actif" ? setData({...data, statut: "inactif"}) : setData({...data, statut: "actif"})
            }}>
               {data?.statut === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
            </button>
        </div>
        {
          closeM && isEditSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Modification réussie avec succès</h1>
                  <p> Les modifications ont été enregistrées.</p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              {
                closeM && isEditError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              <ModalForm tabsField={[
                {
                    field: "textfield",
                    field_type: "number",
                    field_require: true,
                    value: data.annee_debut,
                    label: "Année de debut",
                    id: "annee_debut",
                    min: 1,
                    onChange: (val: any) => setData({...data, annee_debut: val}),
                    error: error,
                    placeholder: "Ex: 2024",
                    isShow: true
                  },
                  {
                    field: "textfield",
                    field_type: "number",
                    field_require: true,
                    value: data.annee_fin,
                    label: "Année de fin",
                    id: "annee_fin",
                    min: 1,
                    onChange: (val: any) => setData({...data, annee_fin: val}),
                    error: error,
                    placeholder: "Ex: 2025",
                    isShow: true
                  },
              ]}/>
               
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
              type: "submit", 
              label: isEditLoading ? "Modification en cours" : "Enregistrer les modifications", 
              style:"is--primary", 
              active: !isEditLoading,
              show: closeM && isEditSuccess ? false : true
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isEditLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
    const dispatch = useAppDispatch();
    const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.school_year);
    
    const [closeM, setCloseM] = useState<boolean>(false)
    const closeModal = () => {
      fnc(false)
      setCloseM(false)
    }
    return (
      <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
          <h2>Suppression d'une année scolaire</h2>
        </div>
        {
          closeM && isDeleteSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Suppression réussie avec succès</h1>
                  <p>
                    { "l'element a bien été supprimé" }
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
                <div className='kui-modal-box-statut__illustration'>
                  {
                    closeM && isDeleteError ? (
                      <FailIllu loop={true} autoplay={true} size={{
                        height: 120,
                        width: 120
                      }}/>
                    ) : (
                      <i className='pi-delete-2-line'></i>
                    )
                  }
                </div>
                <div className='kui-modal-box-statut__container'>
                  {
                    closeM && isDeleteError ? (
                      <>
                        <h1>Une erreur est survenue</h1>
                        <p>{message}</p>
                      </>
                    ) : (
                      <>
                        <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                        <p>L'action est irreversible</p>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
        
        <ModalActions tabsActions={[
          {
            icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
            type: "button", 
            label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
            style:"is--primary", 
            active: !isDeleteLoading,
            onClick: () => {
                setCloseM(true)
                dispatch(deleteSchoolYear(item.id))
            },
            show: closeM && isDeleteSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
            style:"is--neutral", 
            active: !isDeleteLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </Modal>
    )
}

const AddFiliereToSchoolYearModal = ({memberIds, item, setIsEdit, fnc}:{memberIds: any[], item: any, setIsEdit: (val: boolean) => void | any, fnc: (val: boolean) => void | any}) => {
    const dispatch = useAppDispatch();
    const { isEditLoading, isEditError, isEditSuccess, message } = useAppSelector((state) => state.school_year);
    const { isSuccess, isError, isLoading, filieres, message: mMessage } = useAppSelector((state) => state.filiere);
    const { departments } = useAppSelector((state) => state.department);
    const { cycles } = useAppSelector((state) => state.cycle);
  
    const [closeM, setCloseM] = useState<boolean>(false)
    const [search, setSearch ] = useState<string>("")
    const [arrayToDelete, setArrayDeleteModal] = useState<any[]>([])

    const addOrRemoveToArrayDelete = (id: any) => {
      if(arrayToDelete?.filter((el: any) => el === id).length > 0){
        setArrayDeleteModal((list) => [...list.filter((el: any) => el !== id)])
      }else{
        setArrayDeleteModal((list) => [...list, id])
      }
    }
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setCloseM(true)
      dispatch(addFiliereSchoolYear({
        id: item.id,
        data: {
            filieres: arrayToDelete
        }
      }))
    }
  
    const closeModal = () => {
    //   if(closeM && isEditSuccess) window.location.reload();
      if(closeM && isEditSuccess) dispatch(getSchoolYears({}));
      fnc(false)
      setCloseM(false)
      setSearch("")
    }

    useEffect(() => {
      if(closeM && isEditSuccess){
        setIsEdit(true)
      }
    }, [setIsEdit])
    return (
      <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-reco'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
          <h2>Ajout de Filières à l'année school_year {item?.annee_debut + " - " + item.annee_fin}</h2>
        </div>
        {
          closeM && isEditSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Ajout de Filières à l'année school_year {item?.annee_debut + " - " + item.annee_fin}</h1>
                  <p>
                    Les informations ont été modifiées
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              {
                closeM && isEditError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              
              
              {
                (isLoading ) ? (
                  <div className='kui-loader'>
                    <LoaderAnimation loop={true} autoplay={true} size={{
                      height: 40,
                      width: 40
                    }}/>
                  </div>
                ) : (
                  isError  ? (
                    <div className='kui-page-error'>
                      <div className='kui-page-error__illustration'>
                        <NotFoundError loop={true} autoplay={true} size={{
                          height: 180,
                          width: 180
                        }}/>
                      </div>
                      <div className='kui-page-error_details'>
                        <p>Oupps! Une erreur est survenue</p>
                        <h1>{ mMessage  }</h1>
                      </div>
                    </div>
                  ) : (
                    isSuccess && filieres ? (
                      <>
                        <div className='kui-modal-box-group'>
                          <div className='kui-modal-box-group__header'><h3>Choix des filieres</h3> <span className='kui-tag'>Choix multiple</span></div>
                          <div className='kui-modal-box-list'>
                            <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                              <div className='kui-modal-box-list-action-search'>
                                <i className="pi-search-line"></i>
                                <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                              </div>
                            </div>
                            {
                              search.trim().length > 0 ? (
                                filieres
                                .filter((el: any) => memberIds?.filter((ele: any) => ele === el.id).length < 1)
                                .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                .length > 0 ? (
                                  <div className='kui-modal-box-list__container'>
                                    {
                                      filieres
                                      .filter((el: any) => memberIds?.filter((ele: any) => ele === el.id).length < 1)
                                      .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                      .slice(0, 8)
                                      .map((itemo: any, index: number) => (
                                        <button key={index} type='button' className={`kui-modal-box-list-item ${arrayToDelete?.filter((el: any) => el === itemo?.id)?.length > 0 ? "active" : ""}`} onClick={() => {
                                          addOrRemoveToArrayDelete(itemo?.id)
                                        }}>
                                          {
                                            arrayToDelete?.filter((el: any) => el === itemo?.id)?.length > 0 ? (
                                              <div className='kui-modal-box-list-item__check'>
                                                <i className="pi-minimize-line"></i>
                                              </div>
                                            ) : null
                                          }
                                            <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                <i className="pi-documents-line"></i>
                                            </div>
                                            <div className='kui-modal-box-list-item__container'>
                                              <span>Nom cour: {itemo?.nom_court}</span>
                                              <strong>{itemo?.intitule}</strong>
                                              <p>{departments?.filter((el: any) => el.id === itemo.departement)[0]?.intitule || ""} - {(cycles?.filter((el: any) => el.id === itemo.cycle)[0]?.intitule + " " +cycles?.filter((el: any) => el.id === itemo.cycle)[0]?.finalite) || ""}</p>
                                            </div>
                                        </button>
                                      ))
                                    }
                                    
                                  </div>
                                ) : (
                                  <span>Aucune filière trouvée</span>
                                )
                                
                              ) : (
                                <span>Rechercher...</span>
                              )
                            }
                            
                          </div>
                        </div>
                      </>
                    ) : null
                  )
                )
              }
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isEditLoading ? "pi-spinner5" : "pi-save-2-line", 
              type: "submit", 
              label: isEditLoading ? "Modification en cours" : "Enregistrer les modifications", 
              style:"is--primary", 
              active: !isEditLoading,
              show:  (closeM && isEditSuccess ? false : true)
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isEditLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}

const FiliereContainer = ({item, setIsEdit, fnc}:{item: any, setIsEdit: (val: boolean) => void | any, fnc: (val: boolean) => void | any}) => {
    const [search, setSearch] = useState<string>("")
    const [addFiliereModal, setAddFiliereModal] = useState<boolean>(false)
    const { isLoading, isSuccess, isError, message, filieres } = useAppSelector((state) => state.filiere);
    const { departments } = useAppSelector((state) => state.department);
    const { cycles } = useAppSelector((state) => state.cycle);
    
    const [unlinkModal, setUnLinkModal] = useState<boolean>(false)
    const [selectItemModal, setSelectItemModal] = useState<any>(null)

    const dispatch = useAppDispatch();
    const { isDeleteLoading, isDeleteSuccess, isDeleteError, message: aMessage } = useAppSelector((state) => state.school_year);
    
    useEffect(() => {
        if(selectItemModal && isDeleteSuccess && unlinkModal) {
            dispatch(getSchoolYears({}))
            // setUnLinkModal(false)
        }
    }, [selectItemModal, isDeleteSuccess , unlinkModal, dispatch])


    return (
        <div className='kui-modal-full-page__list kui-modal-full-page-list'>
            <h2>Filières</h2>
            <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                <div className='kui-page-list-actions-search'>
                <i className="pi-search-line"></i>
                <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e?.target?.value)}/>
                </div>
                <div className='kui-page-list-actions-group'>
                <Button label={'Ajouter une filière'} iconLeftClass='pi-add-circle-line'  btype='button' bstyle='is--primary' onClick={() => {
                    setAddFiliereModal(true)
                    fnc(false)
                }}/>
                </div>
            </Grid>
            <br />

            {
                isLoading ? (
                    <div className='kui-loader'>
                        <LoaderAnimation loop={true} autoplay={true} size={{
                            height: 40,
                            width: 40
                        }}/>
                    </div>
                ) : (
                    isError ? (
                        <div className='kui-page-error'>
                          <div className='kui-page-error__illustration'>
                            <NotFoundError loop={true} autoplay={true} size={{
                              height: 180,
                              width: 180
                            }}/>
                          </div>
                          <div className='kui-page-error_details'>
                            <p>Oupps! Une erreur est survenue</p>
                            <h1>{message}</h1>
                          </div>
                        </div>
                    ) : (
                        isSuccess && filieres ? (
                            filieres
                            .filter((el: any) => item.filieres?.filter((ele: any) => ele === el.id).length > 0)
                            .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                            .length > 0 ? (
                                <div className='kui-modal-full-page-list__container'>
                                    <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                                    {
                                        filieres
                                        .filter((el: any) => item.filieres?.filter((ele: any) => ele === el.id).length > 0)
                                        .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                        .map((itemo: any, index: number) => (
                                        <div className='kui-modal-full-page-list-item' key={index}>
                                            <div className='kui-modal-full-page-list-item__container'>
                                                <div className='kui-modal-full-page-list-item__illustration'>
                                                    <i className="pi-documents-line"></i>
                                                </div>
                                                <div className='kui-modal-full-page-list-item__text'>
                                                    <strong>{itemo?.intitule}</strong>
                                                    <span>{`Nom court: ${itemo?.nom_court}`}</span>
                                                    <p>{departments?.filter((el: any) => el.id === itemo.departement)[0]?.intitule || ""} - {(cycles?.filter((el: any) => el.id === itemo.cycle)[0]?.intitule + " " +cycles?.filter((el: any) => el.id === itemo.cycle)[0]?.finalite) || ""}</p>

                                                    <button type='button' className='kui-link' onClick={() => {
                                                        if((selectItemModal?.id === itemo?.id) && isDeleteLoading) return
                                                        setSelectItemModal(itemo)
                                                        setUnLinkModal(true)
                                                        dispatch(removeFiliereSchoolYear({
                                                            id: item.id,
                                                            data: {
                                                                filieres: [itemo.id]
                                                            }
                                                        }))
                                                    }}>
                                                        {
                                                            (selectItemModal?.id === itemo?.id) && isDeleteLoading ? (
                                                                <span>Retrait en cours...</span>
                                                            ) : (
                                                                <span>Retirer</span>
                                                            )
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        ))
                                    }
                                    </Grid>
                                </div>
                            ) : (
                                <div className='kui-modal-empty'>
                                    <EmptyList loop={true} autoplay={true} size={{
                                        height: 190,
                                        width: 290
                                    }}/>
                                    <span>Aucun element trouvé</span>
                                </div>
                            )
                        ) : null
                    )
                )
            }
            {
                addFiliereModal && departments && item && <AddFiliereToSchoolYearModal setIsEdit={(val: boolean) => setIsEdit(val) } memberIds={item.filieres} item={item} fnc={(val: boolean) => setAddFiliereModal(val) }/>
            }
            {
                isDeleteSuccess && unlinkModal ? (
                <Toast id={"avatar-success"} status={'st--success'} fnc={() => setUnLinkModal(false)} >
                    <p>Retrait reussie</p>
                </Toast>
                ) : null
            }
            {
                isDeleteError && unlinkModal ? (
                <Toast id={"avatar-danger"} status={'st--danger'} fnc={() => setUnLinkModal(false)} >
                    <p>{aMessage}</p>
                </Toast>
                ) : null
            }  
        </div>
    )
}

export const AnneesPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
      if(!token){
          navigate(links.login + `?url=${links.school_years}`)
      }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, school_years, message } = useAppSelector((state) => state.school_year);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  
  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  const [addModal, setAddModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.school_years+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
    
    const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
      setItemNumber(parseInt(e.target.value))
      if(parseInt(e.target.value) > 0){
          navigate(links.school_years+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
      }
    }

    useEffect(() => {
      dispatch(getSchoolYears({}))
      dispatch(getCycles({}))
      dispatch(getDepartments({}))
      dispatch(getFilieres({}))
      return () => {
        dispatch(reset())
        dispatch(creset())
        dispatch(dreset())
        dispatch(freset())
      }
    }, [dispatch])


    useEffect(() => {
        if(selectItemModal && school_years && isEdit){
          setSelectItemModal(school_years.filter((el:any) => el.id === selectItemModal.id)[0])
          setIsEdit(false)
        }
    }, [school_years, selectItemModal, isEdit])

   

    return (
        <MainLayout title="Cycle" isMin={false}>
          <div className='kui-page is--min'>
              <div className='kui-arianne'>
                  <Link className='kui-link' to={links.home}>
                      <i className="pi-home-6-line"></i>
                      <span>Accueil</span>
                  </Link>
                  <span> | </span>
                  <p>Années scolaires</p>
              </div>
              {

                  isLoading ? (
                      <div className='kui-loader'>
                          <LoaderAnimation loop={true} autoplay={true} size={{
                              height: 40,
                              width: 40
                          }}/>
                      </div>
                  ) : (
                      <>
                          <div className='kui-page__header kui-page-header'>
                              <h2>Années scolaires</h2>
                          </div>
                          <div className='kui-page-list'>
                              <div className='kui-page-list__actions kui-page-list-actions'>
                                  <div className='kui-page-list-actions-display'>
                                      <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.school_years+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                      <i className="pi-list-check-line"></i>
                                      </button>
                                      <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.school_years+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                      <i className="pi-layout-grid-line"></i>
                                      </button>
                                  </div>
                                  <div className='kui-page-list-actions-group'>
                                      <div className='kui-page-list-actions-input'>
                                      <span>Nombre à afficher: </span>
                                      <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                                      </div>
                                      <div className='kui-page-list-actions-search'>
                                      <i className="pi-search-line"></i>
                                      <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                                      </div>
                                  </div>
                                  <div className='kui-page-list-actions-group'>
                                      <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                                      
                                  </div>
                              </div>
                              {
                                   isError ? (
                                      <div className='kui-page-error'>
                                        <div className='kui-page-error__illustration'>
                                          <NotFoundError loop={true} autoplay={true} size={{
                                            height: 180,
                                            width: 180
                                          }}/>
                                        </div>
                                        <div className='kui-page-error_details'>
                                          <p>Oupps! Une erreur est survenue</p>
                                          <h1>{message}</h1>
                                        </div>
                                      </div> 
                                  ) : (
                                      isSuccess && school_years ? (
                                          <div className='kui-page-list__container'>
                                              <span className='kui-page-list__total'>
                                                  {
                                                      school_years.filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || (`Année ${el?.annee_debut + "-" +el.annee_fin}`).toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                      .length > 0 ? `
                                                      
                                                      ${
                                                          school_years.filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || (`Année ${el?.annee_debut + "-" +el.annee_fin}`).toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                          .length > 1 ? school_years.filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || (`Année ${el?.annee_debut + "-" +el.annee_fin}`).toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length + " elements trouvés" : school_years.filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || (`Année ${el?.annee_debut + "-" +el.annee_fin}`).toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length+" element trouvé"} ` : "Aucun element trouvé"
                                                  }
                                              </span>

                                              {
                                                  school_years.filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || (`Année ${el?.annee_debut + "-" +el.annee_fin}`).toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                  .length > 0 ? (
                                                      display === "grid" ? (
                                                      <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                                          {
                                                              school_years.filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || (`Année ${el?.annee_debut + "-" +el.annee_fin}`).toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                              .slice(0, itemNumber)
                                                              .map((item: any, index: number) => (
                                                                  <div className='kui-page-list-item is--md' key={index}>
                                                                      <div className='kui-page-list-item__min'>
                                                                        <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                                                              {item.statut?.toUpperCase()}
                                                                        </span>
                                                                      </div>
                                                                      

                                                                      <div className='kui-page-list-item__container'>
                                                                          <div className='kui-page-list-item__details'>
                                                                              <span>Code: {item.code_annee_scolaire}</span>
                                                                              <strong className='kui-page-list-item__title'>Année {item?.annee_debut + "-" +item.annee_fin}</strong>
                                                                          </div>
                                                                      </div>
                                                                      <div className='kui-page-list-item__actions'>
                                                                          <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                              setSelectItemModal(null)
                                                                              setSelectItemModal(item)
                                                                              setShowItemModal(true)
                                                                          }}>
                                                                              <i className={"pi-eye-line"}></i>
                                                                              <span>{"Apercu"}</span>
                                                                          </button>
                                                                          <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                              setSelectItemModal(null)
                                                                              setSelectItemModal(item)
                                                                              setEditModal(true)
                                                                          }}>
                                                                              <i className={"pi-edit-3-line"}></i>
                                                                              <span>{"Modifier"}</span>
                                                                          </button>
                                                                          <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                                              setSelectItemModal(null)
                                                                              setSelectItemModal(item)
                                                                              setDeleteModal(true)
                                                                          }}>
                                                                              <i className={"pi-delete-2-line"}></i>
                                                                              <span>{"Supprimer"}</span>
                                                                          </button>
                                                                      </div>
                                                                  </div>
                                                              ))        
                                                          }
                                                      </Grid>
                                                      ) : (
                                                      <Table>
                                                          <Row rtype='header'>
                                                              <Column ctype={'small'}>
                                                                  <strong>#</strong>
                                                              </Column>
                                                              <Column ctype={'large'}>
                                                                  <strong>Code</strong>
                                                              </Column>
                                                              <Column ctype={'mid'}>
                                                                  <strong>Années</strong>
                                                              </Column>
                                                              <Column ctype={'mid'}>
                                                                  <strong>Statut</strong>
                                                              </Column>
                                                              <Column ctype={'small'}>
                                                                  <strong>#</strong>
                                                              </Column>
                                                          </Row>
                                                          {
                                                              
                                                              school_years.filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || (`Année ${el?.annee_debut + "-" +el.annee_fin}`).toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                              .slice(0, itemNumber)
                                                              .map((item: any, index: number) => (
                                                                  <Row key={index}>
                                                                      <Column ctype={'small'}>
                                                                          <strong>{`#${index + 1}`}</strong>
                                                                      </Column>
                                                                      <Column ctype={'large'}>
                                                                          <TableItem title={item?.code_annee_scolaire} subtitle={''} withImage={false} image={null}/>
                                                                      </Column>
                                                                      <Column ctype={'mid'}>
                                                                          <div>
                                                                            <span className='kui-tag'>
                                                                              {item?.annee_debut + "-" + item.annee_fin}
                                                                            </span>
                                                                          </div>
                                                                      </Column>
                                                                      <Column ctype={'mid'}>
                                                                          <div>
                                                                              <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                                                              {item.statut?.toUpperCase()}
                                                                              </span>
                                                                          </div>
                                                                      </Column>
                                                                      <Column ctype={'small'}>
                                                                          <TableOptions atype={"reduce"} tabs={[
                                                                          {
                                                                              icon: "pi-eye-line", 
                                                                              label: "Aperçu", 
                                                                              style:"is--neutral", 
                                                                              onClick: () => {
                                                                              setSelectItemModal(null)
                                                                              setShowItemModal(true)
                                                                              setSelectItemModal(item)
                                                                              }
                                                                          },
                                                                          {
                                                                              icon: "pi-edit-3-line", 
                                                                              label: "Modifier", 
                                                                              style:"is--neutral", 
                                                                              onClick: () => {
                                                                              setSelectItemModal(null)
                                                                              setSelectItemModal(item)
                                                                              setEditModal(true)
                                                                              }
                                                                          },
                                                                          {
                                                                              icon: "pi-delete-2-line", 
                                                                              label: "Supprimer", 
                                                                              style:"is--danger", 
                                                                              onClick: () => {
                                                                              setSelectItemModal(null)
                                                                              setSelectItemModal(item)
                                                                              setDeleteModal(true)
                                                                              }
                                                                          },
                                                                          ]}/>
                                                                      </Column>
                                                                  </Row>
                                                              ))
                                                          }
                                                      </Table>
                                                      )
                                                  ) : (
                                                      <div className='kui-page-empty'>
                                                        <div className='kui-page-empty__illustration'>
                                                          <EmptyList loop={true} autoplay={true} size={{
                                                          height: (380 / 1.3),
                                                          width: (420 / 1.3)
                                                          }}/>
                                                        </div>
                                                        <div className='kui-page-empty__details'>
                                                          <h1>Aucun element trouvé</h1>
                                                        </div>
                                                      </div>
                                                  )
                                              }
                                          </div>
                                      ) : null
                                  )
                              }
                          </div>
                          {addModal ? <AddModal fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
                          {editModal && selectItemModal  ? <EditModal setIsEdit={(val: boolean) => setIsEdit(val)} item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                          
                          {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

                          {showItemModal && selectItemModal ? (
                              <Modal size={'is--full-page'} withClose={true} id={'show-category'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                                  <div className='kui-modal-box__header'>
                                      <h2>Aperçue d'une année scolaire</h2>
                                  </div>
                  
                                  <div className='kui-modal-full-page'>
                                      <div className='kui-modal-full-page__side'>
                                          <div className='kui-modal-full-page__illustration kui-modal-full-page-illustration is--left'>
                                              <div className='kui-modal-full-page-illustration__container is--square'>
                                                 <i className="pi-calendar-2-line"></i>
                                              </div>
                                          </div>
                                          <div className='kui-modal-full-page-details'>
                                                <span>{selectItemModal.code_annee_scolaire}</span>
                                              <h1>Année {selectItemModal?.annee_debut + "-" + selectItemModal.annee_fin}</h1>
                                              <p>Statut: {selectItemModal.statut}</p>
                                          </div>
                                      </div>

                                      <div className='kui-modal-full-page__container'>
                                          <div className='kui-modal-full-page__section'>
                                              <FiliereContainer  setIsEdit={(val: boolean) => setIsEdit(val)} item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/>
                                          </div>
                                      </div>
                                  </div>
                                  
                                  <ModalActions tabsActions={[
                                      {
                                          icon: "pi-edit-3-line", 
                                          type: "button", 
                                          label:"Modifier", 
                                          style:"is--primary", 
                                          active: true,
                                          onClick: () => {
                                              setShowItemModal(false)
                                              setEditModal(true)
                                          },
                                          show: true
                                      
                                      },
                                      {
                                          icon: "pi-delete-2-line", 
                                          type: "button", 
                                          label:"Supprimer", 
                                          style:"is--danger", 
                                          active: true,
                                          onClick: () => {
                                              setShowItemModal(false)
                                              setDeleteModal(true)
                                          },
                                          show: true
                                      },
                                      {
                                          icon: "pi-close-line", 
                                          type: "button", 
                                          label:"Fermer", 
                                          style:"is--neutral", 
                                          active: true,
                                          onClick: () => {
                                              setShowItemModal(false)
                                          },
                                          show: true
                                      },
                                  ]}/>
                              </Modal>
                          ) : null}  
                      </>
                  )
              }
          </div>
        </MainLayout>
    )
}
