import {differenceInCalendarYears, format, subDays, subWeeks, subYears} from "date-fns";

export const formatMyDate = (mdate: any) => {
    let now = Date.now()
    let day = new Date(mdate)
    // @ts-ignore
    var dif = now - day
    let jour = dif / (1000 * 3600 * 24)

    if(jour > 1){
        return "il y a " + Math.round(jour) + "jrs"
    }else{
        if(jour === 1){
            return "hier"
        }else{
            let heure = dif / (1000 * 3600)
            if(heure > 1){
                return "il y a " + Math.round(heure) + "h"
            }else{
                let min = dif / (1000 * 60)
                if(min > 1){
                    return "il y a " + Math.round(min)+ "min"
                }else{
                    let second = dif / 1000
                    if(second > 1){
                        return "il y a " + Math.round(second)+"s"
                    }else{
                        return "maintenant"
                    }
                }
            }
        }
    }
}

export const quelDateCest = (type: string, nombre: number) => {
    let today: any = new Date()
    let newDate: any

    switch (type){
        case "jours":
            newDate = subDays(today, nombre)
            return format(new Date(newDate), "yyyy-MM-dd")

        case "semaines":
            newDate = subWeeks(today, nombre)
            return format(new Date(newDate), "yyyy-MM-dd")

        case "annees":
            newDate = subYears(today, nombre)
            return format(newDate, "yyyy-MM-dd")

        default:
            return format(new Date(), "yyyy-MM-dd")
    }
}

export const calculateAge = (birthday: any) => {
    return differenceInCalendarYears(new Date(), new Date(birthday))
}
