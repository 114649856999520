import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { deleteSubscriptionYear, getSchoolYears, getSubscriptionYears, reset, setSubscriptionYear, updateSubscriptionYear } from '../../../../../app/redux/features/school_year/school_year.slice';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { MainLayout } from '../../../../layout';
import { capitalize } from '../../../../../app/utils';


const AddModal = ({fnc}:{fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
      date_rentrer_universitaire: format(new Date(), "yyyy-MM-dd", {locale: fr}),
  })
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess,subscription_session, school_years, isCreateError, message } = useAppSelector((state) => state.school_year);

  const [department, setDepartment] = useState<string>("")
  const [departmentI, setDepartmentI] = useState<any>("")
  const [closeM, setCloseM] = useState<boolean>(false)
  const [search, setSearch ] = useState<string>("")
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.date_rentrer_universitaire){
      setError({
          field: "date_rentrer_universitaire",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    setError(null)
    setCloseM(true)
    dispatch(setSubscriptionYear({
      ...data, 
      annee_scolaire: department
    }))
  }

  const closeModal = () => {
    setData(null)
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une session d'inscription</h2>
      </div>
      {
        closeM && isCreateSuccess && subscription_session ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  L'année scolaire <strong>{subscription_session?.code_session_inscription }</strong> a été créée
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <div className='kui-modal-box-group'>
                {
                    department ? (
                        <>
                            <div className={`kui-modal-box-list-item`}>
                                <div className='kui-modal-box-list-item__avatar is--rounded'>
                                    <i className="pi-presentation-3-line"></i>
                                </div>
                                <div className='kui-modal-box-list-item__container'>
                                    <strong>{departmentI?.code_annee_scolaire}</strong>
                                    <span>{departmentI.annee_debut + "-" + departmentI.annee_fin}</span>
                                    <button type='button' className='kui-link' onClick={() => {
                                        setDepartment("")
                                        setDepartmentI(null)
                                    }}>
                                        <span>Changer</span>
                                    </button>
                                </div>
                            </div>
                            
                            <ModalForm tabsField={[
                                {
                                    field: "textfield",
                                    field_type: "date",
                                    field_require: true,
                                    value: data.date_rentrer_universitaire,
                                    label: "Date de rentrée universitaire",
                                    id: "date_rentrer_universitaire",
                                    onChange: (val: any) => setData({...data, date_rentrer_universitaire: val}),
                                    error: error,
                                    placeholder: "Entrer la date",
                                    isShow: true
                                },
                            ]}/>
           
                        </>
                    ) : (
                        <>
                            <div className='kui-modal-box-group__header'><h3>Choix de l'année scolaire</h3></div>
                                <div className='kui-modal-box-list'>
                                <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                                    <div className='kui-modal-box-list-action-search'>
                                    <i className="pi-search-line"></i>
                                    <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                                    </div>
                                </div>
                            {
                                search.trim().length > 2 ? (
                                school_years
                                .filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length > 0 ? (
                                    <div className='kui-modal-box-list__container'>
                                    {
                                        school_years
                                        .filter((el: any) => el?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                        .slice(0, 8)
                                        .map((itemo: any, index: number) => (
                                        <button key={index} type='button' className={`kui-modal-box-list-item ${department === itemo?.id ? "active" : ""}`} onClick={() => {
                                            setDepartment(itemo?.id)
                                            setDepartmentI(itemo)
                                        }}>
                                            {
                                                department === itemo?.id ? (
                                                    <div className='kui-modal-box-list-item__check'>
                                                    <i className="pi-minimize-line"></i>
                                                    </div>
                                                ) : null
                                            }
                                            <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                <i className="pi-presentation-3-line"></i>
                                            </div>
                                            <div className='kui-modal-box-list-item__container'>
                                              <strong>{itemo?.code_annee_scolaire}</strong>
                                              <span>{itemo.annee_debut + "-" + itemo.annee_fin}</span>
                                            </div>
                                        </button>
                                        ))
                                    }
                                    
                                    </div>
                                ) : (
                                    <span>Aucune année scolaire trouvée</span>
                                )
                                
                                ) : (
                                    <span>Rechercher...</span>
                                )
                            }
                            </div>
                        </>
                    )
                }
            </div>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: closeM && isCreateSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const EditModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    date_rentrer_universitaire: item?.date_rentrer_universitaire ?  format(new Date(item?.date_rentrer_universitaire), "yyyy-MM-dd", {locale: fr}): "",
    
    statut: item.statut || "",
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.school_year);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.date_rentrer_universitaire){
      setError({
          field: "anneedate_rentrer_universitaire_debut",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    setError(null)
    setCloseM(true)
    dispatch(updateSubscriptionYear({
      data, 
      id: item.id
    }))
  }

  const closeModal = () => {
    setData({
      date_rentrer_universitaire:  "",
    })
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
          <h2>Modification d'une session d'inscription</h2>
          <button type='button' className={`kui-chip ${data?.statut === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
            data?.statut === "actif" ? setData({...data, statut: "inactif"}) : setData({...data, statut: "actif"})
          }}>
             {data?.statut === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
          </button>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                  field: "textfield",
                  field_type: "date",
                  field_require: true,
                  value: data.date_rentrer_universitaire,
                  label: "Date de rentrée universitaire",
                  id: "date_rentrer_universitaire",
                  onChange: (val: any) => setData({...data, date_rentrer_universitaire: val}),
                  error: error,
                  placeholder: "Entrer la date",
                  isShow: true
                },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modification en cours" : "Enregistrer les modifications", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.school_year);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une session d'inscription</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
              setCloseM(true)
              dispatch(deleteSubscriptionYear(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

export const SessionInscriptionsPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
      if(!token){
      navigate(links.login + `?url=${links.cycles}`)
      }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, subscription_sessions, school_years, message } = useAppSelector((state) => state.school_year);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  
  const [addModal, setAddModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.subscriptions_sessions+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.subscriptions_sessions+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

    useEffect(() => {
      dispatch(getSchoolYears({}))
      dispatch(getSubscriptionYears({}))
      return () => {
        dispatch(reset())
      }
    }, [dispatch])

    return (
      <MainLayout title="Cycle" isMin={false}>
        <div className='kui-page is--min'>
          <div className='kui-arianne'>
              <Link className='kui-link' to={links.home}>
                  <i className="pi-home-6-line"></i>
                  <span>Accueil</span>
              </Link>
              <span> | </span>
              <p>Sessions d'inscription</p>
          </div>
          {
            isLoading ? (
              <div className='kui-loader'>
                <LoaderAnimation loop={true} autoplay={true} size={{
                  height: 40,
                  width: 40
                }}/>
              </div>
            ) : (
                <>
                    <div className='kui-page__header kui-page-header'>
                        <h2>Sessions d'inscription</h2>
                    </div>
                    <div className='kui-page-list'>
                        <div className='kui-page-list__actions kui-page-list-actions'>
                            <div className='kui-page-list-actions-display'>
                                <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.subscriptions_sessions+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                <i className="pi-list-check-line"></i>
                                </button>
                                <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.subscriptions_sessions+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                <i className="pi-layout-grid-line"></i>
                                </button>
                            </div>
                            <div className='kui-page-list-actions-group'>
                                <div className='kui-page-list-actions-input'>
                                <span>Nombre à afficher: </span>
                                <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                                </div>
                                <div className='kui-page-list-actions-search'>
                                <i className="pi-search-line"></i>
                                <input type="search" value={search} placeholder='Recherche par code de session...' onChange={onChangeSearch}/>
                                </div>
                            </div>
                            <div className='kui-page-list-actions-group'>
                                <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                                
                            </div>
                        </div>
                        {
                            isError ? (
                                <div className='kui-page-error'>
                                  <div className='kui-page-error__illustration'>
                                    <NotFoundError loop={true} autoplay={true} size={{
                                      height: 180,
                                      width: 180
                                    }}/>
                                  </div>
                                  <div className='kui-page-error_details'>
                                    <p>Oupps! Une erreur est survenue</p>
                                    <h1>{message}</h1>
                                  </div>
                                </div> 
                            ) : (
                                isSuccess && school_years && subscription_sessions ? (
                                    <div className='kui-page-list__container'>
                                        <span className='kui-page-list__total'>
                                            {
                                                subscription_sessions
                                                .filter((el: any) => el?.code_session_inscription?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                .length > 0 ? `
                                                
                                                ${
                                                  subscription_sessions
                                                  .filter((el: any) => el?.code_session_inscription?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                  .length > 1 ? subscription_sessions
                                                    .filter((el: any) => el?.code_session_inscription?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                    .length + " elements trouvés" : subscription_sessions
                                                    .filter((el: any) => el?.code_session_inscription?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                    .length+" element trouvé"} ` : "Aucun element trouvé"
                                            }
                                        </span>

                                        {
                                            subscription_sessions
                                            .filter((el: any) => el?.code_session_inscription?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())  )
                                            .length > 0 ? (
                                                display === "grid" ? (
                                                <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                                    {
                                                        subscription_sessions
                                                        .filter((el: any) => el?.code_session_inscription?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                        .slice(0, itemNumber)
                                                        .map((item: any, index: number) => (
                                                            <div className='kui-page-list-item is--md' key={index}>
                                                                <div className='kui-page-list-item__min'>
                                                                    <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                                                        {item.statut?.toUpperCase()}
                                                                    </span>

                                                                    <span className='kui-tag'>{item?.code_session_inscription}</span>
                                                                </div>
                                                                

                                                                <div className='kui-page-list-item__container'>
                                                                    <div className='kui-page-list-item__details'>
                                                                        
                                                                        
                                                                        <strong className='kui-page-list-item__title'>Date: {item?.date_rentrer_universitaire ? capitalize(format(new Date(item?.date_rentrer_universitaire), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}
                                                                        </strong>
                                                                        <p>{`Année scolaire: ${school_years.filter((el: any) => el.id === item.annee_scolaire)[0]?.annee_debut + "-" + school_years.filter((el: any) => el.id === item.annee_scolaire)[0]?.annee_fin }`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='kui-page-list-item__actions'>
                                                                    
                                                                    <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                        setSelectItemModal(null)
                                                                        setSelectItemModal(item)
                                                                        setEditModal(true)
                                                                    }}>
                                                                        <i className={"pi-edit-3-line"}></i>
                                                                        <span>{"Modifier"}</span>
                                                                    </button>
                                                                    <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                                        setSelectItemModal(null)
                                                                        setSelectItemModal(item)
                                                                        setDeleteModal(true)
                                                                    }}>
                                                                        <i className={"pi-delete-2-line"}></i>
                                                                        <span>{"Supprimer"}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))        
                                                    }
                                                </Grid>
                                                ) : (
                                                <Table>
                                                    <Row rtype='header'>
                                                        <Column ctype={'small'}>
                                                            <strong>#</strong>
                                                        </Column>
                                                        <Column ctype={'large'}>
                                                            <strong>Code</strong>
                                                        </Column>
                                                        <Column ctype={'mid'}>
                                                            <strong>Date</strong>
                                                        </Column>
                                                        <Column ctype={'mid'}>
                                                            <strong>Année scolaire</strong>
                                                        </Column>
                                                        <Column ctype={'mid'}>
                                                            <strong>Statut</strong>
                                                        </Column>
                                                        <Column ctype={'small'}>
                                                            <strong>#</strong>
                                                        </Column>
                                                    </Row>
                                                    {
                                                        subscription_sessions
                                                        .filter((el: any) => el?.code_session_inscription?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                        .slice(0, itemNumber)
                                                        .map((item: any, index: number) => (
                                                            <Row key={index}>
                                                                <Column ctype={'small'}>
                                                                    <strong>{`#${index + 1}`}</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <TableItem title={item?.code_session_inscription} subtitle={''} withImage={false} image={null}/>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <div>
                                                                      <span className='kui-tag is--info'>
                                                                      {item?.date_rentrer_universitaire ? capitalize(format(new Date(item?.date_rentrer_universitaire), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}
                                                                      </span>
                                                                    </div>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <div>
                                                                      <span className='kui-tag'>
                                                                        {school_years.filter((el: any) => el.id === item.annee_scolaire)[0]?.annee_debut + "-" + school_years.filter((el: any) => el.id === item.annee_scolaire)[0]?.annee_fin }
                                                                      </span>
                                                                    </div>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <div>
                                                                        <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                                                        {item.statut?.toUpperCase()}
                                                                        </span>
                                                                    </div>
                                                                </Column>
                                                                <Column ctype={'small'}>
                                                                    <TableOptions atype={"reduce"} tabs={[
                                                                    
                                                                    {
                                                                        icon: "pi-edit-3-line", 
                                                                        label: "Modifier", 
                                                                        style:"is--neutral", 
                                                                        onClick: () => {
                                                                        setSelectItemModal(null)
                                                                        setSelectItemModal(item)
                                                                        setEditModal(true)
                                                                        }
                                                                    },
                                                                    {
                                                                        icon: "pi-delete-2-line", 
                                                                        label: "Supprimer", 
                                                                        style:"is--danger", 
                                                                        onClick: () => {
                                                                        setSelectItemModal(null)
                                                                        setSelectItemModal(item)
                                                                        setDeleteModal(true)
                                                                        }
                                                                    },
                                                                    ]}/>
                                                                </Column>
                                                            </Row>
                                                        ))
                                                    }
                                                </Table>
                                                )
                                            ) : (
                                                <div className='kui-page-empty'>
                                                  <div className='kui-page-empty__illustration'>
                                                    <EmptyList loop={true} autoplay={true} size={{
                                                    height: (380 / 1.3),
                                                    width: (420 / 1.3)
                                                    }}/>
                                                  </div>
                                                  <div className='kui-page-empty__details'>
                                                    <h1>Aucun element trouvé</h1>
                                                  </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            )
                        }
                    </div>
                    {addModal ? <AddModal fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
                    {editModal && selectItemModal  ? <EditModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                    
                    {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

                </>
            )
          }
        </div>
      </MainLayout>
    )
}
