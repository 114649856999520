import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { links } from '../../../../../routes';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { API_URL2, createInitial, listTeacher, slugifyFileName } from '../../../../../../app/utils';
import { reset, setDemandDirecteur } from '../../../../../../app/redux/features/student/demand_directeur.slice';
import { getDemandInscriptions, reset as dreset } from '../../../../../../app/redux/features/inscription/demand.slice';
import { getStudents, reset as sreset } from '../../../../../../app/redux/features/student/student.slice';
import { MainLayout } from '../../../../../layout';
import { AddFileSuccess, EmptyList, LoaderAnimation, NotFoundError } from '../../../../../assets/illustration';
import { Button, Grid, KAlert, Textfield } from '../../../../../components';

export const CreateDemandeDMPage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()
    useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.directeur_memoire_create}`)
    }
    }, [token, navigate]);

    const [data, setData] = useState<any>({
        etudiant: "",
        titre_preleminaire_memoire: "",
        titre_projet_memoire: "",
        resume_projet: "",
        nom_structure_accueil: "",
        presentation_structure_accueil: "",
        introduction: "",
        objectif_recherche: "",
        hypothese_recherche: "",
        methodologie_preleminaire: "",
        motivation: "",
    })

    const [error, setError] = useState<any>({})
    const [fichiers, setFichiers] = useState<any[]>([])

    const [question_de_recherche, setQuestion_de_recherche] = useState<any>({
        intitule_question: "",
        description_de_la_question: "",
    })
    const [questions_de_recherche, setQuestions_de_recherche] = useState<any[]>([])

    const dispatch = useAppDispatch();
    const { isCreateError, isCreateLoading, isCreateSuccess, demand_directeur, message } = useAppSelector((state) => state.demand_directeur);
    const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, students, message: sMessage } = useAppSelector((state) => state.student);
    const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demandes_inscription, message: dMessage } = useAppSelector((state) => state.demande_inscription);

    const [student, setStudent] = useState<any>(null)
    const [search, setSearch] = useState<string>("")

    const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
        const file = e.target.files
        if(file && file.length > 0){
            let type: number = file[0].size
            let max: number = 1024 * 1024 * 4
            if(type < max){
                setImagesBase(file[0], {
                    nom_fichier: slugifyFileName(file[0].name),
                    type_fichier: `Fichier-${fichiers.length + 1}`
                })
            }else{
                setError({
                    field: "global",
                    message: "Votre fichier ne doit pas dépasser 8Mo"
                })
                return
            }
        }
    }

    const setImagesBase = (file: any, info: any) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            // @ts-ignore
            setFichiers(list => [...list.filter(el => el.image !== reader.result), {...info, fichier: reader.result}])
        }
    }

    const addQR = () => { 
        if(!question_de_recherche.intitule_question){
        setError({
            field: "question_de_recherche_intitule_question",
            message: "Veuillez renseigner ce champs"
        })
        return
        }
        if(!question_de_recherche.description_de_la_question){
        setError({
            field: "question_de_recherche_description_de_la_question",
            message: "Veuillez renseigner ce champs"
        })
        return
        }
        

        setQuestions_de_recherche((list) => [...list.filter((el: any) => el !== question_de_recherche), question_de_recherche])
        setQuestion_de_recherche({
            intitule_question: "",
            description_de_la_question: "",
        })
    }

    const removeQr = (item: any) => {
        setQuestions_de_recherche((list) => [...list.filter((el: any) => el !== item)])
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.etudiant){
            setError({
                field: "global",
                message: "Veuillez choisir un etudiant"
            })
            return
        }
        if(!data.titre_preleminaire_memoire){
            setError({
                field: "titre_preleminaire_memoire",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.titre_projet_memoire){
            setError({
                field: "titre_projet_memoire",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.resume_projet){
            setError({
                field: "resume_projet",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.nom_structure_accueil){
            setError({
                field: "nom_structure_accueil",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.presentation_structure_accueil){
            setError({
                field: "presentation_structure_accueil",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.introduction){
            setError({
                field: "introduction",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.objectif_recherche){
            setError({
                field: "objectif_recherche",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.hypothese_recherche){
            setError({
                field: "hypothese_recherche",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.methodologie_preleminaire){
            setError({
                field: "methodologie_preleminaire",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.motivation){
            setError({
                field: "motivation",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        
        if(questions_de_recherche?.length < 1){
            setError({
                field: "global",
                message: "Veuillez entrer une diplome"
            })
            return
        }
    
        // setCloseM(true)
        dispatch(setDemandDirecteur({
            ...data, 
            questions_de_recherche,
            fichiers
        }))
    }

    useEffect(() => {
        dispatch(getDemandInscriptions({}))
        dispatch(getStudents({}))
        return () => {
            dispatch(reset())
            dispatch(dreset())
            dispatch(sreset())
        }
    }, [dispatch])

    return (
        <MainLayout title="Demande de directeur de memoire" isMin={false} here='directeur_memoire'>
            <div className='kui-page is--min'>
                <div className='kui-arianne'>
                    <Link className='kui-link' to={links.home}>
                        <i className="pi-home-6-line"></i>
                        <span>Accueil</span>
                    </Link>
                    <span> | </span>
                    <Link className='kui-link' to={links.directeur_memoire}>
                        <span>Demandes de directeur de memoire</span>
                    </Link>
                    <span> | </span>
                    <p>Ajout d'une demande</p>
                </div>
                
                <div className='kui-page__header kui-page-header'>
                    <h2>Demande de directeur de memoire</h2>
                </div>

                {
                    isSLoading || isDLoading  ? (
                        <div className='kui-loader'>
                            <LoaderAnimation loop={true} autoplay={true} size={{
                                height: 40,
                                width: 40
                            }}/>
                        </div>
                    ) : (
                        isDError || isSError  ? (
                            <div className='kui-page-error'>
                                <div className='kui-page-error__illustration'>
                                    <NotFoundError loop={true} autoplay={true} size={{
                                        height: 180,
                                        width: 180
                                    }}/>
                                </div>
                                <div className='kui-page-error_details'>
                                    <p>Oupps! Une erreur est survenue</p>
                                    <h1>{isSError ? sMessage : isDError ? dMessage : ""}</h1>
                                </div>
                            </div> 
                        ) : (
                            isSSuccess && isDSuccess && students && demandes_inscription ? (
                                <div className='kui-page-forms'>
                                    {
                                        isCreateSuccess && demand_directeur ? (
                                            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                                                <div className={'kui-page-form'}>
                                                    <div className='kui-page-empty'>
                                                        <div className='kui-page-empty__illustration'>
                                                            <AddFileSuccess loop={true} autoplay={true} size={{
                                                            height: (120),
                                                            width: (120)
                                                            }}/>
                                                        </div>
                                                        <div className='kui-page-empty__details'>
                                                            <h1>La demande d'enseignement a bien été ajoutée.</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'kui-page-form'}>
                                                    <div className='kui-page-form__list kui-page-form-list'>
                                                        <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                                                            <div className='kui-page-form-action'>
                                                            <Button label={'Revenir à la liste'} btype='button' onClick={() => navigate(links.demande_inscription)}/>
                                                            </div> 
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        ) : (
                                            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                                                {
                                                    error?.field === "global" ? (
                                                    <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
                                                    ) : null
                                                }
                                                {
                                                    isCreateError ? (
                                                    <KAlert status={'st--danger'} title={'Oupss!! Une erreur est survenue'} message={message}/>
                                                    ) : null
                                                }
                                                <div className={'kui-page-form'}>
                                                    <div className='kui-page-form__header kui-page-form-header'>
                                                        <h3>Choix de l'etudiant</h3>
                                                    </div>
                                                    <div className='kui-page-form__list kui-page-form-list'>
                                                        {
                                                            student ? (
                                                                <div className='kui-page-form-list-item'>
                                                                    <div className='kui-page-form-list-item__avatar'>
                                                                        {
                                                                            student?.info?.profile?.photo_id ? (
                                                                                <img src={API_URL2 + student?.info?.profile?.photo_id} alt={"avatar"} />
                                                                            ) : (
                                                                                <span>{createInitial(student?.info?.profile?.prenom + " " +student?.info?.profile?.nom)}</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className='kui-page-form-list-item__container'>
                                                                        <span>{`Code: ${student?.code_etudiant}`}</span>
                                                                        <strong>{student?.info?.profile?.prenom + " " +student?.info?.profile?.nom?.toUpperCase()}</strong>
                                                                        <span >
                                                                        {student?.info?.cycle + " - " + student?.info?.filiere + " - " + student?.info?.niveau}
                                                                        </span>
                                                                        <button type='button' className='kui-link' onClick={() => {
                                                                            setStudent(null)
                                                                            setData({...data, etudiant: ""})
                                                                        }}>
                                                                            <span>Changer</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className='kui-page-form-list__search kui-page-form-list-search'>
                                                                        <span>Rechercher un etudiant</span>
                                                                        <div className='kui-page-form-list-search__container'>
                                                                        <i className="pi-search-line"></i>
                                                                        <input type="search" value={search} placeholder='Rechercher par nom ou prenom' onChange={e => setSearch(e.target?.value)}/>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        search?.length > 2 ? (
                                                                            <div className='kui-page-form-list__container'>
                                                                                {
                                                                                    listTeacher(demandes_inscription, students)
                                                                                    .filter((el: any) => el.code_etudiant.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                                                    .length > 0 ? (
                                                                                        listTeacher(demandes_inscription, students)
                                                                                        .filter((el: any) => el.code_etudiant.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                                                        .map((item: any, index: number) => (
                                                                                            <button className='kui-page-form-list-item' type='button' key={index} onClick={() => {
                                                                                              setStudent(item)
                                                                                              setData({...data, etudiant: item.id})
                                                                                            }}>
                                                                                              <div className='kui-page-form-list-item__avatar'>
                                                                                                {
                                                                                                    item?.info?.profile?.photo_id ? (
                                                                                                        <img src={API_URL2 + item?.info?.profile?.photo_id} alt={"avatar"} />
                                                                                                    ) : (
                                                                                                        <span>{createInitial(item?.info?.profile?.prenom + " " +item?.info?.profile?.nom)}</span>
                                                                                                    )
                                                                                                }
                                                                                              </div>
                                                                                              <div className='kui-page-form-list-item__container'>
                                                                                                <span>{`Code: ${item?.code_etudiant}`}</span>
                                                                                                <strong>{item?.info?.profile?.prenom + " " +item?.info?.profile?.nom?.toUpperCase()}</strong>
                                                                                                <span>
                                                                                                    {item?.info?.cycle + " - " + item?.info?.filiere + " - " + item?.info?.niveau}
                                                                                                </span>
                                                                                              </div>
                                                                                            </button>
                                                                                          ))
                                                                                    ) : (
                                                                                        <div className='kui-page-empty'>
                                                                                            <div className='kui-page-empty__illustration'>
                                                                                                <EmptyList loop={true} autoplay={true} size={{
                                                                                                    height: (380 / 1.3),
                                                                                                    width: (420 / 1.3)
                                                                                                }}/>
                                                                                            </div>
                                                                                            <div className='kui-page-empty__details'>
                                                                                                <h1>Aucun element trouvé</h1>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                            <span>Recherche...</span>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }                                                        
                                                    </div>
                                                </div>
                            
                                                <div className={'kui-page-form'}>
                                                    <div className='kui-page-form__header kui-page-form-header'>
                                                        <h3>Information de la demande</h3>
                                                    </div>
                                                    <div className='kui-page-form__list kui-page-form-list'>
                                                        <div className='kui-page-form-fields'>
                                                            <div className='kui-page-form-field'>
                                                                <Textfield 
                                                                    id={'titre_preleminaire_memoire'} 
                                                                    value={data.titre_preleminaire_memoire} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, titre_preleminaire_memoire: val})} 
                                                                    type={"text"}
                                                                    label={'Titre preleminaire du memoire'}
                                                                    placeholder={'Ex: Ecrire ici..'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field'>
                                                                <Textfield 
                                                                    id={'titre_projet_memoire'} 
                                                                    value={data.titre_projet_memoire} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, titre_projet_memoire: val})} 
                                                                    type={"text"}
                                                                    label={'Titre du projet de memoire'}
                                                                    placeholder={'Ex: Ecrire ici..'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field is--full'>
                                                                <Textfield 
                                                                    id={'resume_projet'} 
                                                                    value={data.resume_projet} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, resume_projet: val})} 
                                                                    type={"textarea"}
                                                                    label={'Resumé du projet'}
                                                                    placeholder={'Ecrire ici..'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field is--full'>
                                                                <Textfield 
                                                                    id={'nom_structure_accueil'} 
                                                                    value={data.nom_structure_accueil} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, nom_structure_accueil: val})} 
                                                                    type={"text"}
                                                                    label={'Nom de la structure d\'accueil'}
                                                                    placeholder={'Ex: Ecrire ici...'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field is--full'>
                                                                <Textfield 
                                                                    id={'presentation_structure_accueil'} 
                                                                    value={data.presentation_structure_accueil} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, presentation_structure_accueil: val})} 
                                                                    type={"textarea"}
                                                                    label={'Presentation de la structure d\'accueil'}
                                                                    placeholder={'Ecrire ici..'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field'>
                                                                <Textfield 
                                                                    id={'introduction'} 
                                                                    value={data.introduction} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, introduction: val})} 
                                                                    type={"textarea"}
                                                                    label={'Introduction (300 caracteres max)'}
                                                                    placeholder={'Ecrire ici...'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field'>
                                                                <Textfield 
                                                                    id={'objectif_recherche'} 
                                                                    value={data.objectif_recherche} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, objectif_recherche: val})} 
                                                                    type={"textarea"}
                                                                    label='Objectif de la recherche'
                                                                    placeholder={'Ecrire ici...'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field'>
                                                                <Textfield 
                                                                    id={'hypothese_recherche'} 
                                                                    value={data.hypothese_recherche} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, hypothese_recherche: val})} 
                                                                    type={"textarea"}
                                                                    label={'Hypothese de recherche'}
                                                                    placeholder={'Ecrire ici'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field'>
                                                                <Textfield 
                                                                    id={'methodologie_preleminaire'} 
                                                                    value={data.methodologie_preleminaire} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, methodologie_preleminaire: val})} 
                                                                    type={"textarea"}
                                                                    label={'Methodologie preleminaire'}
                                                                    placeholder={'Ecrire ici'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                            <div className='kui-page-form-field' style={{
                                                                width: '100%'
                                                            }}>
                                                                <Textfield 
                                                                    id={'motivation'} 
                                                                    value={data.motivation} 
                                                                    require={true} 
                                                                    changeValue={(val: any) => setData({...data, motivation: val})} 
                                                                    type={"textarea"}
                                                                    label={'Motivation'}
                                                                    placeholder={'Ecrire ici'}
                                                                    error={error}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                
                                                <div className={'kui-page-form'}>
                                                    <div className='kui-page-form__header kui-page-form-header'>
                                                    <h3>Questions de recherche</h3>
                                                    </div>
                                                    <div className='kui-page-form__list kui-page-form-list'>
                                                    {
                                                        questions_de_recherche?.length > 0 ? (
                                                            <div className='kui-page-form-list__container'>
                                                                <br />
                                                                <h4>QR enregistrées</h4>
                                                                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={0.5}>
                                                                    {
                                                                        questions_de_recherche?.map((item: any, index: number) => (
                                                                            <div className='kui-page-form-list-item' key={index}>
                                                                                <div className='kui-page-form-list-item__container'>
                                                                                    <strong>{item?.intitule_question}</strong>
                                                                                    <span>{item.description_de_la_question}</span>
                                                                                </div>
                                                                                <div className='kui-page-form-list-item__action'>
                                                                                    <button className='kui-link is--danger' type='button' onClick={() => removeQr(item)}>
                                                                                        <span>Supprimer</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </div>
                                                        ) : null
                                                    }
                                                    <div className='kui-page-form-fields'>
                                                        <div className='kui-page-form-field is--full'>
                                                            <Textfield 
                                                                id={'question_de_recherche_intitule_question'} 
                                                                value={question_de_recherche.intitule_question} 
                                                                require={true} 
                                                                changeValue={(val: any) => setQuestion_de_recherche({...question_de_recherche, intitule_question: val})} 
                                                                type='text'
                                                                label='Intitulé'
                                                                placeholder={'Ecrire ici'}
                                                                error={error}
                                                            />
                                                        </div>
                                                        <div className='kui-page-form-field is--full'>
                                                            <Textfield 
                                                                id={'question_de_recherche_description_de_la_question'} 
                                                                value={question_de_recherche.description_de_la_question} 
                                                                require={true} 
                                                                changeValue={(val: any) => setQuestion_de_recherche({...question_de_recherche, description_de_la_question: val})} 
                                                                type='textarea'
                                                                label={'Descirption'}
                                                                placeholder={'Ex: 1'}
                                                                error={error}
                                                            />
                                                        </div>
                                                    
                                                    </div>
                                                    <div className='kui-page-form-action'>
                                                        <Button label={'Enregistrer la question'} 
                                                        btype='button' 
                                                        bstyle='is--secondary' 
                                                        active={true}
                                                        iconLeftClass={"pi-add-circle-line"}
                                                        onClick={() => addQR()}
                                                        />
                                                    </div> 
                                                    </div>
                                                </div>
                                
                                                <div className={'kui-page-form'}>
                                                    <div className='kui-page-form__header kui-page-form-header'>
                                                    <h3>Fichiers</h3>
                                                    </div>
                                                    <div className='kui-page-form__list kui-page-form-list'>
                                                    {
                                                        fichiers?.length > 0 ? (
                                                            fichiers.map((item: any, index: number) => (
                                                                <div className='kui-filefield-lil' key={index}>
                                                                <div className='kui-filefield-lil__container'>
                                                                    <div className='kui-filefield-lil__icon'>
                                                                    <i className="pi-file-Info-line"></i>
                                                                    </div>
                                                                    <div className='kui-filefield-lil__info'>
                                                                    <span>{item?.type_fichier}</span>
                                                                    <strong>{item?.nom_fichier}</strong>
                                                                    </div>
                                                                    <div className='kui-filefield-lil__action'>
                                                                    <button className='kui-link is--danger' type='button' onClick={() => {
                                                                        setFichiers(fichiers.filter((el: any) => el !== item))
                                                                    }}>
                                                                        <span>Supprimer</span>
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                        <strong>Aucun fichier</strong>
                                                        )
                                                    }
                                                    <div className='kui-filefield-lil'>
                                                        <input type="file" multiple={false} id='fichier' onChange={handleFileChangeFile} />
                                                        <label className='kui-filefield-lil__container' htmlFor="fichier">
                                                        <div className='kui-filefield-lil__icon'>
                                                            <i className="pi-file-Info-line"></i>
                                                        </div>
                                                        <div className='kui-filefield-lil__info'>
                                                            <strong>{`Ajouter un fichier`}</strong>
                                                        </div>
                                                        </label>
                                                    </div>
                                                    </div>
                                                </div>
                                
                                                <div className={'kui-page-form'}>
                                                    <form className='kui-page-form__list kui-page-form-list' onSubmit={handleSubmit}>
                                                    <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                                                        <div className='kui-page-form-action'>
                                                        <Button label={(isCreateLoading) ? "Creation en cours" :'Enregistrer et ajouter'} 
                                                            btype='submit' 
                                                            bstyle='is--primary' 
                                                            active={!(isCreateLoading)}
                                                            iconLeftClass={(isCreateLoading) ? "pi-spinner5" : "pi-add-circle-line"}
                                                        />
                                                        </div> 
                                                    </Grid>
                                                    </form>
                                                </div>
                                            
                                            </Grid>
                                        )
                                    }
                                </div>
                                
                            ) : null
                        )
                    )
                }

                    
            </div>
        </MainLayout>
    )
}
