import React, { useContext, useEffect } from 'react'
import { links } from '../../../../routes';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { MainLayout } from '../../../../layout';
import { API_URL2, capitalize, createInitial } from '../../../../../app/utils';
import { Button, Grid } from '../../../../components';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const AccountPage = () => {
  const { token, info, logOut } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.account}`)
    }
  }, [token, navigate]);
  
  return (
    <MainLayout title='Mon compte' isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <p>Mon compte</p>
        </div>

        <div className='kui-page-account'>

          <div className='kui-page-account__banner kui-page-account-banner'>

            <div className='kui-page-account-banner__avatar'>
              {
                info?.profile?.photo_id ? (
                  <img src={API_URL2 + info?.profile?.photo_id} alt={"avatar"} />
                ) : (
                  <span>{createInitial(info?.profile?.nom + " " + info?.profile?.prenom)}</span>
                )
              }
            </div>

            <div className='kui-page-account-banner__info'>
              <div className='kui-page-account-banner__name'>
                <h1>{info?.profile?.nom?.toUpperCase() + " " + info?.profile?.prenom}</h1>
                {/* <span>{`@${info?.username}`}</span> */}
              </div>
              <div className='kui-page-account-banner__actions'>
                <div className='kui-page-account-banner__action'>
                  <Button label={'Modifier mes informations'} iconLeftClass='pi-edit-3-line' active={true} btype='button' bstyle='is--neutral' onClick={() => navigate(links.info_parameter)}/>
                </div>
                <div className='kui-page-account-banner__action'>
                  <Button label={'Se deconnecter'} iconLeftClass='pi-exit-door-line' active={true} btype='button' bstyle='is--danger' onClick={() => logOut()}/>
                </div>
              </div>
            </div>
            
          </div>

          <div className='kui-page-account__grid kui-page-account-grid'>

            <div className='kui-page-account-grid-item'>
              <div className='kui-page-account-grid-item__header'>
                <div className='kui-page-account-grid-item__illu'>
                  <i className="pi-enter-door-line"></i>
                </div>
                <h2>Mes informations du compte</h2>
              </div>
              <div className='kui-page-account-grid-item__container'>
                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                  <div className='kui-page-account-grid-item__text'>
                    <span>Nom et prenom(s): </span>
                    <strong>{info?.profile?.prenom + " " + info?.profile?.nom?.toUpperCase()}</strong>
                  </div>
                  <div className='kui-page-account-grid-item__text'>
                    <span>Email: </span>
                    <strong>{`${info?.profile?.prenom}`}</strong>
                  </div>
                  <div className='kui-page-account-grid-item__text'>
                    <span>Telephone: </span>
                    <strong>{`${info?.profile?.telephone}`}</strong>
                  </div>
                </Grid>
              </div>
            </div>

            <div className='kui-page-account-grid-item'>
              <div className='kui-page-account-grid-item__header'>
                <div className='kui-page-account-grid-item__illu'>
                  <i className="pi-user-4-line"></i>
                </div>
                <h2>Mes informations personnelles</h2>
              </div>
              <div className='kui-page-account-grid-item__container'>
                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                  <div className='kui-page-account-grid-item__text'>
                    <span>Sexe: </span>
                    <strong>{info?.profile?.sexe || "ND"}</strong>
                  </div>
                  <div className='kui-page-account-grid-item__text'>
                    <span>Nationalité: </span>
                    <strong>{info?.profile?.nationalite}</strong>
                  </div>
                  <div className='kui-page-account-grid-item__text'>
                    <span>Date: </span>
                    <strong>{`${info?.profile?.date_de_naissance ? capitalize(format(new Date(info?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}`}</strong>
                  </div>
                  <div className='kui-page-account-grid-item__text'>
                    <span>Lieu de naissance: </span>
                    <strong>{(info?.profile?.pays_de_naissance?.toUpperCase() || "ND") + " - " + (info?.profile?.lieu_de_naissance || "ND")}</strong>
                  </div>
                </Grid>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
