import React, { useContext, useEffect, useState } from 'react'
import { Column, Grid, Modal, ModalActions, Row, Table, TableItem, TableOptions, Toast } from '../../../../components';
import { deleteMentor, getMentors, reset, updateMentor } from '../../../../../app/redux/features/student/mentor.slice';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { getDemandMentorats, reset as dreset } from '../../../../../app/redux/features/student/demand_mentorat.slice';
import { MainLayout } from '../../../../layout';
import { API_URL2, capitalize, createInitial, listTeacher } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale/fr';

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.mentor);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-enseignement'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'un mentor</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteMentor(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

export const MentorsPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.mentors}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, isEditError, isEditLoading, isEditSuccess, mentors, mentor, message } = useAppSelector((state) => state.mentor);
  const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demand_mentorats, message: dMessage } = useAppSelector((state) => state.demand_mentorat);
  
  const [isActiveAc, setIsActiveAc] = useState<boolean>(false)
  const [isActiveChan, setIsActiveChan] = useState<boolean>(false)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  
  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  // const [editModal, setEditModal] = useState<boolean>(false)
  // const [addModal, setAddModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.teacher+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.teacher+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getMentors({}))
    dispatch(getDemandMentorats({}))
    return () => {
      dispatch(reset())
      dispatch(dreset())
    }
  }, [dispatch])

  useEffect(() => {
    
    if(isActiveChan && isEditSuccess && mentor){
    
      setSelectItemModal({
        ...selectItemModal,
        statut: mentor?.statut
      })
      setIsActiveChan(false)
    }
  }, [isActiveChan, isEditSuccess, mentor, showItemModal, selectItemModal])

  return (
    <MainLayout title="Mentors" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
              <i className="pi-home-6-line"></i>
              <span>Accueil</span>
            </Link>
            <span> | </span>
            <p>Mentors</p>
        </div>
        {
          isLoading || isDLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Mentors</h2>
              </div>

              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                  <div className='kui-page-list-actions-display'>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.mentors+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-list-check-line"></i>
                    </button>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.mentors+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-layout-grid-line"></i>
                    </button>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <div className='kui-page-list-actions-input'>
                      <span>Nombre à afficher: </span>
                      <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                    </div>
                    <div className='kui-page-list-actions-search'>
                      <i className="pi-search-line"></i>
                      <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                    </div>
                  </div>
                </div>
                {
                  isError || isDError ? (
                    <div className='kui-page-error'>
                      <div className='kui-page-error__illustration'>
                        <NotFoundError loop={true} autoplay={true} size={{
                          height: 180,
                          width: 180
                        }}/>
                      </div>
                      <div className='kui-page-error_details'>
                        <p>Oupps! Une erreur est survenue</p>
                        <h1>{isError ? message : (isDError ? dMessage : "")}</h1>
                      </div>
                    </div> 
                  ) : (
                    isSuccess && isDSuccess && mentors && demand_mentorats ? (
                      <div className='kui-page-list__container'>
                        <span className='kui-page-list__total'>
                          {listTeacher(demand_mentorats, mentors)
                            .filter((el: any) => el.code_mentor.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                            .length  > 0 ? (

                            listTeacher(demand_mentorats, mentors)
                              .filter((el: any) => el.code_mentor.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .length  > 1 ? (

                              listTeacher(demand_mentorats, mentors)
                                .filter((el: any) => el.code_mentor.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length + " elements trouvés"

                            ) : (
                              listTeacher(demand_mentorats, mentors)
                                .filter((el: any) => el.code_mentor.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length + " element trouvé"
                            )
                          ) : (
                            `Aucun element trouvé`
                          )}
                        </span>
                        {
                          listTeacher(demand_mentorats, mentors)
                          .filter((el: any) => el.code_mentor.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                          .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  listTeacher(demand_mentorats, mentors)
                                  .filter((el: any) => el.code_mentor.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-user' key={index}>
                                  <div className='kui-page-list-user__avatar'>
                                    {
                                      item?.info?.profile?.photo_id ? (
                                        <img src={API_URL2 + item?.info?.profile?.photo_id} alt={"avatar"} />
                                      ) : (
                                        <span>{createInitial(item?.info?.profile?.prenom + " " +item?.info?.profile?.nom)}</span>
                                      )
                                    }
                                    {/* <span className='notice'></span> */}
                                  </div>
                                  <div className='kui-page-list-user__container'>
                                    <div className='kui-page-list-user__name'>
                                      <strong>{item?.info?.profile?.prenom + " " +item?.info?.profile?.nom?.toUpperCase()}</strong>
                                      <span>{`Code: ${item?.code_mentor}`}</span>
                                    </div>
                                    
                                  </div>
                                  <div className='kui-page-list-user__actions'>
                                    <button type='button' className='kui-page-list-user__action' onClick={() => {
                                      setSelectItemModal(null)
                                      setShowItemModal(true)
                                      setSelectItemModal(item)
                                    }}>
                                      <i className='pi-user-visible-line'></i>
                                      <span>Voir le profile</span>
                                    </button>
                                  </div>
                                  <div className='kui-page-list-user__min'>
                                    
                                    <TableOptions atype={"reduce"} tabs={[                        
                                      
                                      {
                                        icon: "pi-delete-2-line", 
                                        label: "Supprimer", 
                                        style:"is--danger", 
                                        onClick: () => {
                                          setSelectItemModal(null)
                                          setDeleteModal(true)
                                          setSelectItemModal(item)
                                        }
                                      },
                                    ]}/>
                                    
                                  </div>
                                </div>
                                  ))
                                }
                                
                              </Grid>
                            ) : (
                              <Table>
                                <Row rtype='header'>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Code</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Mentors</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Contacts</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Statut</strong>
                                  </Column>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                </Row>
                                {
                                  listTeacher(demand_mentorats, mentors)
                                  .filter((el: any) => el.code_mentor.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                        <strong>{`#${index+1}`}</strong>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className='kui-tag'>
                                            {item?.code_mentor}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.info?.profile?.prenom + " " +item?.info?.profile?.nom} subtitle={``} image={item?.info?.profile?.photo_id ? API_URL2 + item?.info?.profile?.photo_id : ""}/>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <p>
                                          <span>Email: </span> <strong>{item?.info.email}</strong><br />
                                          <span>Telephone: </span> <strong>{item?.info?.profile.telephone}</strong>
                                        </p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "inactif" ? "is--warning" : " is--success"}`}>
                                            {item?.statut?.toUpperCase()}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'small'}>
                                        <TableOptions atype={"reduce"} tabs={[
                                          {
                                            icon: "pi-eye-line", 
                                            label: "Aperçu", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setShowItemModal(true)
                                              setSelectItemModal(item)
                                            }
                                          },
                                          
                                          {
                                            icon: "pi-delete-2-line", 
                                            label: "Supprimer", 
                                            style:"is--danger", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setDeleteModal(true)
                                              setSelectItemModal(item)
                                            }
                                          },
                                        ]}/>
                                      </Column>
                                    </Row>
                                  ))
                                }
                                
                              </Table>
                            )
                          ) : (
                            <div className='kui-page-empty'>
                              <div className='kui-page-empty__illustration'>
                                <EmptyList loop={true} autoplay={true} size={{
                                  height: (380 / 1.3),
                                  width: (420 / 1.3)
                                }}/>
                              </div>
                              <div className='kui-page-empty__details'>
                                <h1>Aucun element trouvé</h1>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ) : null
                  )
                }
              </div>

              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}
              {showItemModal && selectItemModal ? (
                <Modal size={'is--md'} withClose={true} id={'show-birth'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                  
                  <div className='kui-modal-box__header'>
                    <h2>Aperçue d'un enseignant</h2>
                  </div>

                  <div className="kui-modal-show">
                    <div className='kui-modal-show__illustration'>
                      <i className="pi-user-4-line"></i>
                    </div>
                    <div className='kui-modal-show__container'>
                      <p>{` Numero de demande: ${selectItemModal?.info?.numero_demande} - Code Enseigant: ${selectItemModal?.code_mentor}`}</p>
                      <h1>{selectItemModal?.info?.profile?.nom?.toUpperCase() + " " + selectItemModal?.info?.profile?.prenom}</h1>
                      <p>{selectItemModal?.info?.profile?.nationalite}</p>
                      <div className='kui-modal-show__category'>
                        <span className='kui-tag'>
                          Sexe : {selectItemModal?.info?.profile?.sexe?.toUpperCase()}
                        </span>
                      </div>
                      <p>{` Né(e) le ${selectItemModal?.info?.profile?.date_de_naissance ? capitalize(format(new Date(selectItemModal?.info?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${selectItemModal?.info?.profile?.lieu_de_naissance} - ${selectItemModal?.info?.profile?.pays_de_naissance}`}</p>
                      <p>{` Email: ${selectItemModal?.info?.email} - Tel: ${selectItemModal?.info?.profile?.telephone}`}</p>
                    </div>
                  </div>

                  <ModalActions tabsActions={[
                    {
                      icon: (isActiveAc && isEditLoading) ? "pi-spinner3" : "", 
                      label: (isActiveAc && isEditLoading)  ? "Traitement..." : isEditError ? "Ressayer..." :(selectItemModal?.statut?.toLocaleLowerCase() === "inactif" ? "Activer" : "Desactiver"), 
                      style: (isActiveAc && isEditLoading)  ? "is--neutral" : (selectItemModal?.statut?.toLocaleLowerCase() === "inactif" ? "is--success" : "is--warning"), 
                      active : !(isActiveAc && isEditLoading),
                      onClick: () => {
                        if(isActiveAc && isEditLoading){} else{
                          setIsActiveAc(true)
                          setIsActiveChan(true)
                          let data = {
                            id: selectItemModal.id,
                            data: {
                              statut: selectItemModal?.statut?.toLocaleLowerCase() === "inactif" ? "Actif" : "Inactif",
                              demande: selectItemModal?.demande
                            }
                          }
                          dispatch(updateMentor(data))
                        }
                      },
                      show: true
                    },
                    {
                      icon: "pi-delete-2-line", 
                      type: "button", 
                      label:"Supprimer", 
                      style:"is--danger", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setDeleteModal(true)
                      },
                      show: true
                    },
                    {
                      icon: "", 
                      type: "button", 
                      label:"Fermer", 
                      style:"is--neutral", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setSelectItemModal(null)
                      },
                      show: true
                    },
                  ]}/>
                </Modal>
              ) : null}
              {
                isActiveAc &&  isEditSuccess ? (
                  <Toast id={"avatar-success"} status={'st--success'} fnc={() => setIsActiveAc(false)} >
                    <p>Traitement reussie</p>
                  </Toast>
                ) : null
              }
              {
                isActiveAc && isEditError ? (
                  <Toast id={"avatar-danger"} status={'st--danger'} fnc={() => setIsActiveAc(false)} >
                    <p>Oupps! Une erreur est survenue! Veuillez reessayer... {message}</p>
                  </Toast>
                ) : null
              }
            </>
          )
        }
      </div>
    </MainLayout>
  )
}
