import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { deleteTypeEnseignement, getTypeEnseignements, reset, setTypeEnseignement, updateTypeEnseignement } from '../../../../../../app/redux/features/teacher/type_enseignement.slice';
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../../assets/illustration';
import { links } from '../../../../../routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { MainLayout } from '../../../../../layout';
import { SettingSubmenu } from '../../module/SettingSubmenu';

const AddModal = ({fnc}:{fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
      intitule_type_enseignement: "",
  })
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.type_enseignement);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule_type_enseignement){
      setError({
          field: "intitule_type_enseignement",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    setError(null)
    setCloseM(true)
    dispatch(setTypeEnseignement({
      ...data, 
    }))
  }

  const closeModal = () => {
    setData(null)
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-type-frais'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'un type d'enseignement</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Le type de enseignement a été créé
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule_type_enseignement,
                label: "Intitulé",
                id: "intitule_type_enseignement",
                onChange: (val: any) => setData({...data, intitule_type_enseignement: val}),
                error: error,
                placeholder: "Ex: ...",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: closeM && isCreateSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const EditModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
      intitule_type_enseignement: item.intitule_type_enseignement || "",
      statut_type_enseignement: item.statut_type_enseignement || "",
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.type_frais);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule_type_enseignement){
      setError({
          field: "intitule_type_enseignement",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
  
    setError(null)
    setCloseM(true)
    dispatch(updateTypeEnseignement({
      data, 
      id: item.id
    }))
  }

  const closeModal = () => {
    setData({
      intitule_type_enseignement:  "",
      statut: "",
    })
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-type_frais'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
          <h2>Modification d'un type d'enseignement</h2>
          <button type='button' className={`kui-chip ${data?.statut_type_enseignement === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
            data?.statut_type_enseignement === "actif" ? setData({...data, statut_type_enseignement: "inactif"}) : setData({...data, statut_type_enseignement: "actif"})
          }}>
             {data?.statut_type_enseignement === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
          </button>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule_type_enseignement,
                label: "Intitulé",
                id: "intitule_type_enseignement",
                onChange: (val: any) => setData({...data, intitule_type_enseignement: val}),
                error: error,
                placeholder: "Ex: ...",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modification en cours" : "Enregistrer les modification", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.type_frais);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-type_frais'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'un type d'enseigement</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
              setCloseM(true)
              dispatch(deleteTypeEnseignement(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

export const TypeEnseignementPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.type_enseignement}`)
      }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, type_enseignements, message } = useAppSelector((state) => state.type_enseignement);
    
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
    
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)

  const [addModal, setAddModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.type_enseignement+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.type_enseignement+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getTypeEnseignements({}))
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  return (
    <MainLayout title="Types d'enseignement" here='setting' isMin={false}>
      <div className='kui-arianne'>
        <Link className='kui-link' to={links.home}>
          <i className="pi-home-6-line-2"></i>
          <span>Accueil</span>
        </Link>
        <span> | </span>
        <p>Types d'enseignement</p>
      </div>
      <div className='kui-page with--submenu'>
        <SettingSubmenu />
        <div className='kui-page__container'>
        {
          isLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                  <h2>Type d'enseignement</h2>
              </div>
              <div className='kui-page-list'>
                  <div className='kui-page-list__actions kui-page-list-actions'>
                      <div className='kui-page-list-actions-display'>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.type_enseignement+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                          <i className="pi-list-check-line"></i>
                        </button>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.type_enseignement+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                          <i className="pi-layout-grid-line"></i>
                        </button>
                      </div>
                      <div className='kui-page-list-actions-group'>
                          <div className='kui-page-list-actions-input'>
                          <span>Nombre à afficher: </span>
                          <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                          </div>
                          <div className='kui-page-list-actions-search'>
                          <i className="pi-search-line"></i>
                          <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                          </div>
                      </div>
                      <div className='kui-page-list-actions-group'>
                        <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                      </div>
                  </div>
                  {
                      isError ? (
                        <div className='kui-page-error'>
                          <div className='kui-page-error__illustration'>
                            <NotFoundError loop={true} autoplay={true} size={{
                              height: 180,
                              width: 180
                            }}/>
                          </div>
                          <div className='kui-page-error_details'>
                            <p>Oupps! Une erreur est survenue</p>
                            <h1>{message}</h1>
                          </div>
                        </div> 
                      ) : (
                          isSuccess &&  type_enseignements ? (
                              <div className='kui-page-list__container'>
                                  <span className='kui-page-list__total'>
                                      {
                                        type_enseignements.filter((el: any) => el?.intitule_type_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                        .length > 0 ? `
                                        ${
                                          type_enseignements.filter((el: any) => el?.intitule_type_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                            .length > 1 ? type_enseignements.filter((el: any) => el?.intitule_type_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())  )
                                          .length + " elements trouvés" : type_enseignements.filter((el: any) => el?.intitule_type_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                          .length+" element trouvé"} ` : "Aucun element trouvé"
                                      }
                                  </span>

                                  {
                                      type_enseignements
                                      .filter((el: any) => el?.intitule_type_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                      .length > 0 ? (
                                          display === "grid" ? (
                                          <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                              {
                                                  type_enseignements
                                                  .filter((el: any) => el?.intitule_type_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                  .slice(0, itemNumber)
                                                  .map((item: any, index: number) => (
                                                      <div className='kui-page-list-item is--md' key={index}>
                                                          <div className='kui-page-list-item__min'>
                                                              <span className={`kui-chip ${item?.statut_type_enseignement ===  "actif" ? "is--success" : "is--warning"}`}>
                                                                  {item.statut_type_enseignement?.toUpperCase()}
                                                              </span>
                                                          </div>
                                                          

                                                          <div className='kui-page-list-item__container'>
                                                              <div className='kui-page-list-item__details'>
                                                                  <strong className='kui-page-list-item__title'>{item?.intitule_type_enseignement}</strong>
                                                                  {/* <p>{`Finalité: ${item.finalite}`}</p> */}
                                                              </div>
                                                          </div>
                                                          <div className='kui-page-list-item__actions'>
                                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                              setSelectItemModal(null)
                                                              setSelectItemModal(item)
                                                              setEditModal(true)
                                                            }}>
                                                                <i className={"pi-edit-3-line"}></i>
                                                                <span>{"Modifier"}</span>
                                                            </button>
                                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                              setSelectItemModal(null)
                                                              setSelectItemModal(item)
                                                              setDeleteModal(true)
                                                            }}>
                                                                <i className={"pi-delete-2-line"}></i>
                                                                <span>{"Supprimer"}</span>
                                                            </button>
                                                          </div>
                                                      </div>
                                                  ))        
                                              }
                                          </Grid>
                                          ) : (
                                          <Table>
                                              <Row rtype='header'>
                                                  <Column ctype={'small'}>
                                                      <strong>#</strong>
                                                  </Column>
                                                  <Column ctype={'large'}>
                                                      <strong>Intitulé</strong>
                                                  </Column>
                                                  <Column ctype={'mid'}>
                                                      <strong>Statut</strong>
                                                  </Column>
                                                  <Column ctype={'small'}>
                                                      <strong>#</strong>
                                                  </Column>
                                              </Row>
                                              {
                                                  type_enseignements
                                                  .filter((el: any) => el?.intitule_type_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                  .slice(0, itemNumber)
                                                  .map((item: any, index: number) => (
                                                      <Row key={index}>
                                                          <Column ctype={'small'}>
                                                              <strong>{`#${index + 1}`}</strong>
                                                          </Column>
                                                          <Column ctype={'large'}>
                                                              <TableItem title={item?.intitule_type_enseignement} subtitle={''} withImage={false} image={null}/>
                                                          </Column>
                                                          
                                                          <Column ctype={'mid'}>
                                                              <div>
                                                                  <span className={`kui-chip ${item?.statut_type_enseignement === "actif" ? "is--success" : "is--warning"}`}>
                                                                  {item.statut_type_enseignement?.toUpperCase()}
                                                                  </span>
                                                              </div>
                                                          </Column>
                                                          <Column ctype={'small'}>
                                                              <TableOptions atype={"reduce"} tabs={[
                                                              
                                                              {
                                                                  icon: "pi-edit-3-line", 
                                                                  label: "Modifier", 
                                                                  style:"is--neutral", 
                                                                  onClick: () => {
                                                                  setSelectItemModal(null)
                                                                  setSelectItemModal(item)
                                                                  setEditModal(true)
                                                                  }
                                                              },
                                                              {
                                                                  icon: "pi-delete-2-line", 
                                                                  label: "Supprimer", 
                                                                  style:"is--danger", 
                                                                  onClick: () => {
                                                                  setSelectItemModal(null)
                                                                  setSelectItemModal(item)
                                                                  setDeleteModal(true)
                                                                  }
                                                              },
                                                              ]}/>
                                                          </Column>
                                                      </Row>
                                                  ))
                                              }
                                          </Table>
                                          )
                                      ) : (
                                          <div className='kui-page-empty'>
                                            <div className='kui-page-empty__illustration'>
                                              <EmptyList loop={true} autoplay={true} size={{
                                                height: (380 / 1.3),
                                                width: (420 / 1.3)
                                              }}/>
                                            </div>
                                            <div className='kui-page-empty__details'>
                                              <h1>Aucun element trouvé</h1>
                                            </div>
                                          </div>
                                      )
                                  }
                              </div>
                          ) : null
                      )
                  }
              </div>
              {addModal ? <AddModal fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
              {editModal && selectItemModal  ? <EditModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}
            </>
          )
        }
        </div>
      </div>
    </MainLayout>
  )
}
