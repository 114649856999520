import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import auth from "../features/auth/auth.slice"
import user from "../features/user/user.slice"
import school_year from "../features/school_year/school_year.slice"
import cycle from "../features/cursus/cycle.slice"
import filiere from "../features/cursus/filiere.slice"
import department from "../features/cursus/department.slice"
import unite_enseignement from "../features/cursus/unite_enseignement.slice"
import semestre from "../features/cursus/semestre.slice"
import matiere from "../features/cursus/matiere.slice"
import niveau from "../features/cursus/niveau.slice"
import filiere_niveau from "../features/cursus/filiere_niveau.slice"
import inscription from "../features/inscription/inscription.slice";
import frais from "../features/inscription/frais.slice";
import zone from "../features/inscription/zone.slice";
import demande_inscription from "../features/inscription/demand.slice";
import type_frais from "../features/inscription/type_frais.slice";
import promotion from "../features/cursus/promotion.slice";
import student from "../features/student/student.slice";
import type_enseignement from "../features/teacher/type_enseignement.slice";
import ressource_enseignement from "../features/teacher/ressource_enseignement.slice";
import teacher from "../features/teacher/teacher.slice";
import demande_enseignement from "../features/teacher/demande_enseignement.slice";
import seance from "../features/teacher/seance.slice";
import enseignement from "../features/teacher/enseignement.slice";
import demand_bourse from "../features/bourse/demand_bourse.slice";
import bourse from "../features/bourse/bourse.slice";
import bourse_etudiant from "../features/bourse/bours_etudiant.slice";
import demand_cite from "../features/student/demand_cite.slice";
import demand_directeur from "../features/student/demand_directeur.slice";
import demand_mentorat from "../features/student/demand_mentorat.slice";
import demand_orientation from "../features/student/demand_orientation.slice";
import mentor from "../features/student/mentor.slice";
import mentorat from "../features/student/mentorat.slice";
import stage from "../features/student/stage.slice";
import chambre from "../features/student/type_chambre.slice";


export const store = configureStore({
    reducer: {
        auth,
        user,
        school_year,
        department,
        filiere,
        cycle,
        unite_enseignement,
        semestre,
        matiere,
        niveau,
        filiere_niveau,
        inscription,
        frais,
        zone,
        demande_inscription,
        type_frais,
        promotion,
        student,
        chambre,  
        stage, 
        mentor, 
        mentorat, 
        demand_bourse, 
        bourse_etudiant,
        bourse,
        demand_cite, 
        demand_directeur, 
        demand_mentorat, 
        demand_orientation,
        type_enseignement,
        ressource_enseignement,
        teacher,
        demande_enseignement,
        seance,
        enseignement
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;