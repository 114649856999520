import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DemandDirecteurState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondesdemandes/demande_de_directeur_dememoires/"

const initialState: DemandDirecteurState = {
    demand_directeurs: [],
    demand_directeur: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setDemandDirecteur = createAsyncThunk<any, Object>(
    'demand_directeur/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const traiterDemandDirecteur = createAsyncThunk<any, Object>(
    'demand_directeur/traiter',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}traiter_demande/`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error.response.data)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getDemandDirecteurs = createAsyncThunk<any, Object>(
    'demand_directeur/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getDemandDirecteur = createAsyncThunk<any, Object>(
    'demand_directeur/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateDemandDirecteur = createAsyncThunk<any, Object>(
    'demand_directeur/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteDemandDirecteur = createAsyncThunk<any, Object>(
    'demand_directeur/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            const item = await deleteProtecteItems(data)
            return {item, content}
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const demand_directeurSlice = createSlice({
    name: 'demand_directeur',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDemandDirecteurs.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getDemandDirecteurs.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.demand_directeurs = action.payload
            })
            .addCase(getDemandDirecteurs.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getDemandDirecteur.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getDemandDirecteur.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.demand_directeur = action.payload
            })
            .addCase(getDemandDirecteur.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setDemandDirecteur.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setDemandDirecteur.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.demand_directeurs = [action.payload, ...state.demand_directeurs]
                state.demand_directeur = action.payload
            })
            .addCase(setDemandDirecteur.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })


            .addCase(traiterDemandDirecteur.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(traiterDemandDirecteur.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.demand_directeurs = [action.payload, ...state.demand_directeurs.filter((demande_inscription: any) => demande_inscription?.id !== action.payload?.id)]
                state.demand_directeur = action.payload
            })
            .addCase(traiterDemandDirecteur.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(updateDemandDirecteur.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateDemandDirecteur.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.demand_directeurs = [action.payload, ...state.demand_directeurs.filter((demand_directeur: any) => demand_directeur?.id !== action.payload?.id)]
                state.demand_directeur = action.payload
            })
            .addCase(updateDemandDirecteur.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteDemandDirecteur.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteDemandDirecteur.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.demand_directeurs = state.demand_directeurs.filter((demand_directeur: any) => demand_directeur?.id !== action.payload?.content)
            })
            .addCase(deleteDemandDirecteur.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = demand_directeurSlice.actions
export default demand_directeurSlice.reducer