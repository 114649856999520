import React, { FC, useContext } from 'react'
import { MenuLayoutParams } from '../type/menu'
import { API_URL2, createInitial, titlePage, truncate } from '../../../../app/utils'
import { links } from '../../../routes'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { LogoIcon } from '../../../assets/illustration'
import { UserAuthContext } from '../../../../context/AuthContext'

export const AcconutBar = ({here}:{here?: any}) => {
  let navigate = useNavigate()
  const { info } = useContext(UserAuthContext);
  return (
    <div className='kui-account'>
      <div className='kui-account__actions kui-account-actions'>
        <div className='kui-account-action'>
          <button className={here === "notification" ? "active" : ""} type='button' onClick={() =>navigate(links.notif)}>
              <i className="pi-bell-ringing-line"></i>
              <span>Notification</span>
              <div className='notice'></div>
          </button>
        </div>
      </div>
      <div className='kui-account__me kui-account-me'>
        <button  type='button' onClick={() => navigate(links.account)}>
          <div className='kui-account-me__avatar'>
            {
              info?.profile?.photo_id ? (
                <img src={API_URL2 + info?.profile?.photo_id} alt={"avatar"} />
              ) : (
                <span>{createInitial(info?.profile?.nom + " " + info?.profile?.prenom)}</span>
              )
            }
          </div>
          <span className='tooltip'>{`Mon compte)`}</span>
        </button>
      </div>
    </div>
  )
}

export const MainLayout:FC<MenuLayoutParams>  = ({title, here, isMin = false, children}) => {
  titlePage(title)
  const { info } = useContext(UserAuthContext);
  const navItems = [
    {
      label: "Cursus",
      items: [
        {
          label: "Cycles",
          icon: "pi-school-line",
          path: links.cycles,
          here: here === "cycle" ? "active" : "",
          show: true

        },
        {
          label: "Departements",
          icon: "pi-presentation-3-line",
          path: links.departments,
          here: here === "department" ? "active" : "",
          show: true
        },
        {
          label: "Filières",
          icon: "pi-documents-line",
          path: links.filieres,
          here: here === "filiere" ? "active" : "",
          show: true
        },
      ]
    },
 
    {
      label: "Années scolaires",
      items: [
        {
          label: "Années scolaires",
          icon: "pi-calendar-2-line",
          path: links.school_years,
          here: here === "annnee" ? "active" : "",
          show: true
        },
        {
          label: "Sessions d'inscription",
          icon: "pi-calendar-3-line",
          path: links.subscriptions_sessions,
          here: here === "session" ? "active" : "",
          show: true
        },
        {
          label: "Promotions",
          icon: "pi-rocket-line",
          path: links.promotions,
          here: here === "promotion" ? "active" : "",
          show: true
        },
      ]
    },
 
    {
      label: "Inscription",
      items: [
        {
          label: "Demandes",
          icon: "pi-document-3-line",
          path: links.demande_inscription,
          here: here === "demande_inscription" ? "active" : "",
          show: true
        },
        {
          label: "Liste",
          icon: "pi-file-check-line",
          path: links.inscription,
          here: here === "inscription" ? "active" : "",
          show: true
        },
        {
          label: "Frais",
          icon: "pi-card-pay-line",
          path: links.frais,
          here: here === "frais" ? "active" : "",
          show: true
        },
      ]
    },

    {
      label: "Etudiant",
      items: [
        {
          label: "Liste",
          icon: "pi-school-line",
          path: links.students,
          here: here === "student" ? "active" : "",
          show: true
        },
        {
          label: "Stages",
          icon: "pi-presentation-3-line",
          path: links.stage,
          here: here === "stage" ? "active" : "",
          show: true
        },
        {
          label: "Demandes de chambre",
          icon: "pi-bed-line",
          path: links.cites,
          here: here === "cite" ? "active" : "",
          show: true
        },
        {
          label: truncate("Demande de directeur de memoire", 20),
          icon: "pi-document-2-line",
          path: links.directeur_memoire,
          here: here === "directeur_memoire" ? "active" : "",
          show: true
        },
        {
          label: truncate("Demande d'orientation", 20),
          icon: "pi-document-2-line",
          path: links.orientation,
          here: here === "orientation" ? "active" : "",
          show: true
        },
      ]
    },

    {
      label: "Enseigments",
      items: [
        {
          label: "Demandes",
          icon: "pi-clipboard-line",
          path: links.demandes_enseignants,
          here: here === "demandes_enseignants" ? "active" : "",
          show: true
        },
        {
          label: "Liste",
          icon: "pi-school-line",
          path: links.enseignements,
          here: here === "enseignements" ? "active" : "",
          show: true
        },
        {
          label: "Enseignant",
          icon: "pi-group-2-line",
          path: links.teacher,
          here: here === "teacher" ? "active" : "",
          show: true
        },
        {
          label: "Seances",
          icon: "pi-presentation-1-line",
          path: links.seance,
          here: here === "seance" ? "active" : "",
          show: true
        },
        {
          label: "Ressources",
          icon: "pi-folder-3-line",
          path: links.ressource_enseignements,
          here: here === "ressource_enseignements" ? "active" : "",
          show: true
        },
      ]
    },
 
    {
      label: "Mentorat",
      items: [
        {
          label: "Demandes de mentorat",
          icon: "pi-group-2-line",
          path: links.demand_mentorats,
          here: here === "demand_mentorat" ? "active" : "",
          show: true
        },
        {
          label: "Mentorat",
          icon: "pi-group-line",
          path: links.mentorats,
          here: here === "mentorat" ? "active" : "",
          show: true
        },
        {
          label: "Mentor",
          icon: "pi-group-3-line",
          path: links.mentors,
          here: here === "mentor" ? "active" : "",
          show: true
        },
      ]
    },
    {
      label: "Bourse",
      items: [
        {
          label: "Liste",
          icon: "pi-coin-2-line",
          path: links.bourses,
          here: here === "bourses" ? "active" : "",
          show: true
        },
        {
          label: "Demande",
          icon: "pi-document-3-line",
          path: links.demande_bourses,
          here: here === "demande_bourses" ? "active" : "",
          show: true
        },
        {
          label: "Bourses attribuées",
          icon: "pi-heart-hand-line",
          path: links.bourses_etudiant,
          here: here === "bourses_etudiant" ? "active" : "",
          show: true
        },
        
      ]
    },

    {
      label: "Administration",
      items: [
        {
          label: "Administrateurs",
          icon: "pi-group-3-line",
          path: links.admins,
          here: here === "admins" ? "active" : "",
          show: true
        },
        {
          label: "Paramètres",
          icon: "pi-settings-3-line",
          path: links.setting,
          here: here === "setting" ? "active" : "",
          show: true
        },
      ]
    },
  ]
  return (
    <>
      <div className={`kui-menu ${isMin ? "is--min" : ""}`}>
        <header className='kui-menu__header'>
          <Link to={links.home}>
            <LogoIcon/>
          </Link>
        </header>
        <nav className='kui-menu__navigation kui-menu-navigation'>
          <div className='kui-menu-navigation__item kui-menu-navigation-item'>
            <NavLink to={links.home} className={here === "home" ? "active" : ""} end>
              <i className="pi-home-6-line"></i>
              <span>Accueil</span>
            </NavLink>
          </div>
          <div className='kui-menu-navigation__container'>
            {
              navItems?.filter((el: any) => el).map((item: any, index: number) => (
                <div className='kui-menu-navigation__group kui-menu-navigation-group' key={index}>
                  <strong>{item.label}</strong>
                  <div className='kui-menu-navigation-group__container'>
                    {
                      item.items?.map((nav: any, idx: number) => (
                        <div className='kui-menu-navigation__item kui-menu-navigation-item' key={idx}>
                          <NavLink to={nav.path} className={nav.here} end>
                            <i className={nav.icon}></i>
                            <span>{nav.label}</span>
                          </NavLink>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
          {/* <div className='kui-menu-navigation__item kui-menu-navigation-item'>
            <NavLink to={links.setting} className={here === "setting" ? "active" : ""} end>
              <i className="pi-settings-3-line"></i>
              <span>Paramètres</span>
            </NavLink>
          </div> */}
        </nav>
      </div>

      <AcconutBar here={here}/>

      <main className={`kui-main ${isMin ? "is--min" : ""}`} role='main'>
        {children}
      </main>
      <footer className={`kui-footer ${isMin ? "is--min" : ""}`}>
        <p>©2024 CFA Université.</p>
      </footer>
    </>
  )
}
