export const links = {
    home: "/",
    error: "/*",

    // admin
    admins: "/administrateurs",
    admin: "/administrateur",

    // auth
    account: "/mon-compte",
    login: "/connexion",
    notif: "/notification",
    info_parameter: "/parametres-du-compte",
    password_parameter: "/securite-et-confidentialite",

    // setting
    custom: "/customisation",
    setting: "/parametre",
    type_frais: "/type-de-frais",
    type_enseignement: "/type-enseignement",
    zone: "/zone",

    school_years: "/annees-scolaires",

    subscriptions_sessions: "/sessions-d-inscriptions",
    promotions: "/promotions",

    departments: "/departements",

    cycles: "/cycles",

    filieres: "/filieres",
    filiere: "/filiere",

    demande_inscription: "/demande-inscription",
    create_demande_inscription: "/ajout-demande-inscription",
    edit_demande_inscription: "/modification-demande-inscription",
    
    inscription: "/inscription",
    frais: "/frais-inscription",

    demandes_enseignants: "/demande-enseignant",
    create_demande_enseignant: "/ajout-demande-enseignant",
    edit_demande_enseignant: "/edit-demande-enseignant",

    teacher: "/enseignants",
    enseignements: "/enseignements",
    ressource_enseignements: "/ressources-enseignements",
    seance: "/seances",

    chambre: "/type-chambres",
    cites: "/demande-cites",

    directeur_memoire: "/demande-de-directeur-de-memoire",
    directeur_memoire_create: "/create-demande-de-directeur-de-memoire",

    students: "/etudiants",

    orientation: "/demande-d-orientation",
    orientation_cretae: "/ajout-demande-d-orientation",
    
    stage: "/stage",

    bourses: "/bourses",
    demande_bourses: "/demandes-bourses",
    bourses_etudiant: "/bourses-etudiants",

    mentors: "/mentors",
    mentorats: "/mentorats",
    demand_mentorats: "/demande-mentorat",
    create_demand_mentorats: "/ajout-demande-mentorat",
}