import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { links } from '../../../../../routes';
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { getDemandInscription, reset, updateDemandInscription } from '../../../../../../app/redux/features/inscription/demand.slice';
import { paysOptions, sexeOptions, slugifyFileName } from '../../../../../../app/utils';
import { Button, Grid, KAlert, Selectfield, Textfield } from '../../../../../components';
import { AddFileSuccess, LoaderAnimation, NotFoundError } from '../../../../../assets/illustration';
import { MainLayout } from '../../../../../layout';


const DemandeInscriptionEditForm = ({item}:{item: any}) => {
    let navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { isEditError, isEditLoading, isEditSuccess, demande_inscription, message } = useAppSelector((state) => state.demande_inscription);

    const [data, setData] = useState<any>({
        email: item.email || "",
        information_financiere: item.information_financiere || "",
        information_supplementaire: item.information_supplementaire || "",
        information_pertinente: item.information_pertinente || "",
        serie_du_bac: item.serie_du_bac || "",
        annee_du_bac: item.annee_du_bac ||  "",
        session_inscription: item.session_inscription || ""
    })
    
    const [dataProfile, setDataProfile] = useState<any>({
        nom: item?.profile?.nom || "",
        prenom: item?.profile?.prenom || "",
        telephone: item?.profile?.telephone || "",
        ville: item?.profile?.ville || "",
        date_de_naissance: item?.profile?.date_de_naissance || "",
        lieu_de_naissance: item?.profile?.lieu_de_naissance || "",
        pays_de_naissance: item?.profile?.pays_de_naissance || "",
        nationalite: item?.profile?.nationalite || "",
        sexe: item?.profile?.sexe || "",
        pays_de_residence: item?.profile?.pays_de_residence || "",
        adresse: item?.profile?.adresse ||  ""
    })
    
    const [error, setError] = useState<any>({})

    const [formation_anterieure, setFormationAnterieure] = useState<any>({
        intitule_diplome: "",
        etablissement: "",
        annee_obtention: ""
    })

    const [formations_anterieures, setFormationsAnterieures] = useState<any[]>(item.formations_anterieures || [])
  
    const addFormation = () => {
        if(!formation_anterieure.intitule_diplome){
            setError({
            field: "formation_anterieure_intitule_diplome",
            message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!formation_anterieure.annee_obtention){
            setError({
            field: "formation_anterieure_annee_obtention",
            message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!formation_anterieure.etablissement){
            setError({
            field: "formation_anterieure_etablissement",
            message: "Veuillez renseigner ce champs"
            })
            return
        }

        setFormationsAnterieures((list) => [...list.filter((el: any) => el != formation_anterieure), formation_anterieure])
        setFormationAnterieure({
            intitule_diplome: "",
            etablissement: "",
            annee_obtention: ""
        })
    }

    const removeFormation = (item: any) => {
        setFormationsAnterieures((list) => [...list.filter((el: any) => el != item)])
    }
    
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.email){
        setError({
            field: "email",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!dataProfile.nom){
        setError({
            field: "profile_nom",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!dataProfile.prenom){
        setError({
            field: "profile_prenom",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!dataProfile.date_de_naissance){
        setError({
            field: "profile_date_de_naissance",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!dataProfile.telephone){
        setError({
            field: "profile_telephone",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    
    if(!dataProfile.sexe){
        setError({
            field: "profile_sexe",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    
    if(!dataProfile.nationalite){
        setError({
            field: "profile_nationalite",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!dataProfile.pays_de_naissance){
        setError({
            field: "profile_pays_de_naissance",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    
    if(!dataProfile.lieu_de_naissance){
        setError({
            field: "profile_lieu_de_naissance",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    
    if(formations_anterieures?.length < 1){
        setError({
            field: "global",
            message: "Veuillez entrer une formation anterieure"
        })
        return
    }
    // setCloseM(true)
    dispatch(updateDemandInscription({
        data: {
            ...data, 
            profile: dataProfile, 
            formations_anterieures, 
        },
        id: item.numero_demande
    }))
    }

    return (
        
        <div className='kui-page-forms'>
        {
            isEditSuccess && demande_inscription ? (
            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                <div className={'kui-page-form'}>
                    <div className='kui-page-empty'>
                        <div className='kui-page-empty__illustration'>
                            <AddFileSuccess loop={true} autoplay={true} size={{
                                height: (120),
                                width: (120)
                            }}/>
                        </div>
                        <div className='kui-page-empty__details'>
                            <h1>Les informations ont bien été modifiées.</h1>
                        </div>
                    </div>
                    </div>
                    <div className={'kui-page-form'}>
                    <div className='kui-page-form__list kui-page-form-list'>
                        <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                            <div className='kui-page-form-action'>
                                <Button label={'Revenir à la liste'} btype='button' onClick={() => navigate(links.demande_inscription)}/>
                            </div> 
                        </Grid>
                    </div>
                </div>
            </Grid>
            ) : (
            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                {
                    error?.field === "global" ? (
                        <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
                    ) : null
                }
                {
                    isEditError ? (
                        <KAlert status={'st--danger'} title={'Oupss!! Une erreur est survenue'} message={message}/>
                    ) : null
                }
                <div className={'kui-page-form'}>
                    <div className='kui-page-form__header kui-page-form-header'>
                        <h3>Information de l'etudiant</h3>
                    </div>
                <div className='kui-page-form__list kui-page-form-list'>
                    <div className='kui-page-form-fields'>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_nom'} 
                        value={dataProfile.nom} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, nom: val})} 
                        type={"text"}
                        label='Nom de famille'
                        placeholder={'Ex: Kabore'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_prenom'} 
                        value={dataProfile.prenom} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, prenom: val})} 
                        type={"text"}
                        label='Prenom(s)'
                        placeholder={'Ex: Jean Victor'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Selectfield 
                        id={'profile_sexe'} 
                        value={dataProfile.sexe} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, sexe: val})} 
                        tabs={sexeOptions}
                        label='Sexe'
                        placeholder={'Ex: Masculin ou Feminin'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_nationalite'} 
                        value={dataProfile.nationalite} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, nationalite: val})} 
                        type={"text"}
                        label='Nationalité'
                        placeholder={'Ex: Burkinabe'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_date_de_naissance'} 
                        value={dataProfile.date_de_naissance} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, date_de_naissance: val})} 
                        type={"date"}
                        label='Date de naissance'
                        placeholder={'Ex:...'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_lieu_de_naissance'} 
                        value={dataProfile.lieu_de_naissance} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, lieu_de_naissance: val})} 
                        type={"text"}
                        label='Lieu de naissance'
                        placeholder={'Ex: Bobo-Dioulasso'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Selectfield 
                        id={'profile_pays_de_naissance'} 
                        value={dataProfile.pays_de_naissance} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_naissance: val})} 
                        tabs={paysOptions}
                        label='Pays de naissance'
                        placeholder={'Ex: Burkina Faso'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_adresse'} 
                        value={dataProfile.adresse} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, adresse: val})} 
                        type={"text"}
                        label='Adresse'
                        placeholder={'Ex: Secteur 30'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_ville'} 
                        value={dataProfile.ville} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, ville: val})} 
                        type={"text"}
                        label='Ville de residence'
                        placeholder={'Ex: Bobo-Dioulasso'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Selectfield 
                        id={'profile_pays_de_residence'} 
                        value={dataProfile.pays_de_residence} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_residence: val})} 
                        tabs={paysOptions}
                        label='Pays de residence'
                        placeholder={'Ex: Burkina Faso'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'profile_telephone'} 
                        value={dataProfile.telephone} 
                        require={true} 
                        changeValue={(val: any) => setDataProfile({...dataProfile, telephone: val})} 
                        type={"phone"}
                        label='Numero de telephone'
                        placeholder={'Ex: 0022675346789'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'email'} 
                        value={data.email} 
                        require={true} 
                        changeValue={(val: any) => setData({...data, email: val})} 
                        type={"email"}
                        label='Email'
                        placeholder={'Ex: email@exemple.com'}
                        error={error}
                        />
                    </div>
                    </div>
                </div>
                </div>

                <div className={'kui-page-form'}>
                <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Information de la demande</h3>
                </div>
                <div className='kui-page-form__list kui-page-form-list'>
                    <div className='kui-page-form-fields'>
                    
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'annee_du_bac'} 
                        value={data.annee_du_bac} 
                        require={true} 
                        changeValue={(val: any) => setData({...data, annee_du_bac: val})} 
                        type={"text"}
                        label='Année du BAC'
                        placeholder={'Ex: 2017'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'serie_du_bac'} 
                        value={data.serie_du_bac} 
                        require={true} 
                        changeValue={(val: any) => setData({...data, serie_du_bac: val})} 
                        type={"text"}
                        label='Serie du BAC'
                        placeholder={'Ex: D'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                            id={'information_financiere'} 
                            value={data.information_financiere} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, information_financiere: val})} 
                            type={"text"}
                            label='Information financiere'
                            placeholder={'Ecrire ici...'}
                            error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                            id={'information_pertinente'} 
                            value={data.information_pertinente} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, information_pertinente: val})} 
                            type={"text"}
                            label='Information pertinente'
                            placeholder={'Ecrire ici...'}
                            error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                            id={'information_supplementaire'} 
                            value={data.information_supplementaire} 
                            require={true} 
                            changeValue={(val: any) => setData({...data, information_supplementaire: val})} 
                            type={"text"}
                            label='Information supplimentaire'
                            placeholder={'Ecrire ici...'}
                            error={error}
                        />
                    </div>
                    </div>
                </div>
                </div>

                <div className={'kui-page-form'}>
                <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Formations anterieures</h3>
                </div>
                <div className='kui-page-form__list kui-page-form-list'>
                {
                    formations_anterieures?.length > 0 ? (
                    <div className='kui-page-form-list__container'>
                        <br />
                        <h4>Formations enregistrées</h4>
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={0.5}>
                        {
                            formations_anterieures?.map((item: any, index: number) => (
                            <div className='kui-page-form-list-item' key={index}>
                                <div className='kui-page-form-list-item__container'>
                                    <strong>{item?.intitule_diplome}</strong>
                                    <span>{item.etablissement + " - Obtenu en" +item.annee_obtention}</span>
                                </div>
                                <div className='kui-page-form-list-item__action'>
                                    <button className='kui-link is--danger' type='button' onClick={() => removeFormation(item)}>
                                        <span>Supprimer</span>
                                    </button>
                                </div>
                            </div>
                            ))
                        }
                        </Grid>
                    </div>
                    ) : null
                }
                    <div className='kui-page-form-fields'>
                    <div className='kui-page-form-field is--full'>
                        <Textfield 
                        id={'formation_anterieure_intitule_diplome'} 
                        value={formation_anterieure.intitule_diplome} 
                        require={true} 
                        changeValue={(val: any) => setFormationAnterieure({...formation_anterieure, intitule_diplome: val})} 
                        type='text'
                        label='Intitulé du diplôme'
                        placeholder={'Ex: Formation en...'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'formation_anterieure_etablissement'} 
                        value={formation_anterieure.etablissement} 
                        require={true} 
                        changeValue={(val: any) => setFormationAnterieure({...formation_anterieure, etablissement: val})} 
                        type='text'
                        label='Etablissement'
                        placeholder={'Ex: Etablissement'}
                        error={error}
                        />
                    </div>
                    <div className='kui-page-form-field'>
                        <Textfield 
                        id={'formation_anterieure_annee_obtention'} 
                        value={formation_anterieure.annee_obtention} 
                        require={true} 
                        changeValue={(val: any) => setFormationAnterieure({...formation_anterieure, annee_obtention: val})} 
                        type='date'
                        label={'Année d\'obtention'}
                        placeholder={'Ex: 2023'}
                        error={error}
                        />
                    </div>
                    </div>
                    <div className='kui-page-form-action'>
                    <Button label={'Enregistrer la formation'} 
                        btype='button' 
                        bstyle='is--secondary' 
                        active={true}
                        iconLeftClass={"pi-add-circle-line"}
                        onClick={() => addFormation()}
                    />
                    </div> 
                </div>
                </div>


                <div className={'kui-page-form'}>
                    <form className='kui-page-form__list kui-page-form-list' onSubmit={handleSubmit}>
                        <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                        <div className='kui-page-form-action'>
                            <Button label={(isEditLoading) ? "Creation en cours" :'Enregistrer et ajouter'} 
                            btype='submit' 
                            bstyle='is--primary' 
                            active={!(isEditLoading)}
                            iconLeftClass={(isEditLoading) ? "pi-spinner5" : "pi-add-circle-line"}
                            />
                        </div> 
                        </Grid>
                    </form>
                </div>
                
            </Grid>
            )
        }
        </div>
    )
}
 
export const DemandeInscriptionEditPage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate();
    const {slug} = useParams()

    useEffect(() => {
        if(!token){
            navigate(links.login + `?url=${links.edit_demande_inscription + "/"+slug}`)
        }
    }, [token, navigate]);

    const dispatch = useAppDispatch();
    const { isLoading, isError, isSuccess, demande_inscription, message } = useAppSelector((state) => state.demande_inscription);

    useEffect(() => {
        if(slug){
            dispatch(getDemandInscription(slug))
        }

        return () => {
            dispatch(reset())
        }
    }, [dispatch])
    
    return (
        <MainLayout title="Modification d'une Demande d'inscription" isMin={false} here='demande_inscription'>
            <div className='kui-page is--min'>
                <div className='kui-arianne'>
                    <Link className='kui-link' to={links.home}>
                        <i className="pi-home-6-line"></i>
                        <span>Accueil</span>
                    </Link>
                    <span> | </span>
                    <Link className='kui-link' to={links.demande_inscription}>
                        <span>Demande d'inscription</span>
                    </Link>
                    <span> | </span>
                    <p>Modification d'une demande</p>
                </div>
                {
                    isLoading ? (
                        <div className='kui-loader'>
                            <LoaderAnimation loop={true} autoplay={true} size={{
                                height: 40,
                                width: 40
                            }}/>
                        </div>
                    ) : (
                        isError ? (
                            <div className='kui-page-error'>
                                <div className='kui-page-error__illustration'>
                                    <NotFoundError loop={true} autoplay={true} size={{
                                        height: 180,
                                        width: 180
                                    }}/>
                                </div>
                                <div className='kui-page-error_details'>
                                    <p>Oupps! Une erreur est survenue</p>
                                    <h1>{message}</h1>
                                </div>
                            </div> 
                        ) : (
                            isSuccess && demande_inscription ? (
                                <>
                                    <div className='kui-page__header kui-page-header'>
                                        <h2>Modification d'une demande d'inscription</h2>
                                    </div>
                                    <DemandeInscriptionEditForm item={demande_inscription}/>
                                </>
                            ) : null
                           
                        )
                    )
                }
            </div>
        </MainLayout>
    )
}
