
import { AccountPage, AdminPage, AdminsPage, CustomPage, CyclePage, DepartmentPage, ErrorPage, FilierePage, FilieresPage, HomePage, LoginPage, NotifPage, SettingPage, TypeFraisPage, ZonePage, DemandInscriptionPage, AnneesPage, SessionInscriptionsPage, FraisPage, InscriptionPage, DemandInscriptionCreate, PromotionsPage, DemandeInscriptionEditPage, TypeEnseignementPage, DemandesEnseignementsPage, DemandeEnseignementPage, EditDemandeEnseignementPage, TeachersPage, RessourcesEnseignementsPage, SeancePage, EnseignementsPage, ChambresPage, StudentsPage, StagesPage, OrientationPage, MentoratsPage, MentorsPage, DemandeMentoratPage, DirecteurMemoirePage, CitePage, BoursePage, DemandeBourse, BourseEtudiantsPage, CreateDemandeMentoratPage, CreateOrientationPage, CreateDemandeDMPage   } from "../../../pages";
import { links } from "./links";


export const routes = [
    {
        path: links.home,
        Component: HomePage
    },
    {
        path: links.login,
        Component: LoginPage
    },
    {
        path: links.notif,
        Component: NotifPage
    },
    {
        path: links.setting,
        Component: SettingPage
    },
    {
        path: links.custom,
        Component: CustomPage
    },
    {
        path: links.zone,
        Component: ZonePage
    },
    {
        path: links.type_frais,
        Component: TypeFraisPage
    },
    {
        path: links.type_enseignement,
        Component: TypeEnseignementPage
    },
    {
        path: links.account,
        Component: AccountPage
    },
    {
        path: links.admins,
        Component: AdminsPage
    },
    {
        path: links.admin + "/:slug",
        Component: AdminPage
    },
    {
        path: links.cycles,
        Component: CyclePage
    },
    {
        path: links.departments,
        Component: DepartmentPage
    },
    {
        path: links.filieres,
        Component: FilieresPage
    },
    {
        path: links.filiere + "/:slug",
        Component: FilierePage
    },
    {
        path: links.school_years,
        Component: AnneesPage
    },
    {
        path: links.subscriptions_sessions,
        Component: SessionInscriptionsPage
    },
    {
        path: links.promotions,
        Component: PromotionsPage
    },
    {
        path: links.inscription,
        Component: InscriptionPage
    },
    {
        path: links.frais,
        Component: FraisPage
    },
    {
        path: links.demande_inscription,
        Component: DemandInscriptionPage
    },
    {
        path: links.create_demande_inscription,
        Component: DemandInscriptionCreate
    },
    {
        path: links.edit_demande_inscription + "/:slug",
        Component: DemandeInscriptionEditPage
    },
    {
        path: links.demandes_enseignants,
        Component: DemandesEnseignementsPage
    },
    {
        path: links.create_demande_enseignant,
        Component: DemandeEnseignementPage
    },
    {
        path: links.edit_demande_enseignant + "/:slug",
        Component: EditDemandeEnseignementPage
    },
    {
        path: links.enseignements,
        Component: EnseignementsPage
    },
    {
        path: links.teacher,
        Component: TeachersPage
    },
    {
        path: links.seance,
        Component: SeancePage
    },
    {
        path: links.ressource_enseignements,
        Component: RessourcesEnseignementsPage
    },
    {
        path: links.chambre,
        Component: ChambresPage
    },
    {
        path: links.students,
        Component: StudentsPage
    },
    {
        path: links.stage,
        Component: StagesPage
    },
    {
        path: links.orientation,
        Component: OrientationPage
    },
    {
        path: links.orientation_cretae,
        Component: CreateOrientationPage
    },
    {
        path: links.directeur_memoire,
        Component: DirecteurMemoirePage
    },
    {
        path: links.directeur_memoire_create,
        Component: CreateDemandeDMPage
    },
    {
        path: links.cites,
        Component: CitePage
    },
    {
        path: links.bourses,
        Component: BoursePage
    },
    {
        path: links.demande_bourses,
        Component: DemandeBourse
    },
    {
        path: links.bourses_etudiant,
        Component: BourseEtudiantsPage
    },
    {
        path: links.mentorats,
        Component: MentoratsPage
    },
    {
        path: links.mentors,
        Component: MentorsPage
    },
    {
        path: links.demand_mentorats,
        Component: DemandeMentoratPage
    },
    {
        path: links.create_demand_mentorats,
        Component: CreateDemandeMentoratPage
    },
    {
        path: links.error,
        Component: ErrorPage
    },
]