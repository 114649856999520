import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { deleteFrais, getFraisList, reset, setFrais, updateFrais } from '../../../../../app/redux/features/inscription/frais.slice';
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { getZones, reset as zreset } from '../../../../../app/redux/features/inscription/zone.slice';
import { getTypesFrais, reset as treset } from '../../../../../app/redux/features/inscription/type_frais.slice';
import { getSchoolYears, reset as sreset } from '../../../../../app/redux/features/school_year/school_year.slice';
import { getNiveauFilieres, reset as nfreset } from '../../../../../app/redux/features/cursus/filiere_niveau.slice';
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../../layout';
import { getFilieres, reset as freset  } from '../../../../../app/redux/features/cursus/filiere.slice';
import { optionifyAnnesScolaire, optionifyNiveauFilieres, optionifyTypeFrais, optionifyZones } from '../../../../../app/utils';

const AddModal = ({zones, annees, niveaux_f, type_frais, fnc}:{zones: any[], annees: any[], niveaux_f: any[], type_frais: any[], fnc: (val: boolean) => void | any}) => {
    
    const [data, setData] = useState<any>({
        montant: 0,
        type_frais: "",
        annee_universitaire: "",
        zones: [],
        niveaux_filieres: []
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess, isCreateError, frais, message } = useAppSelector((state) => state.frais);
    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
  
    const [closeM, setCloseM] = useState<boolean>(false)
    
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.montant){
            setError({
                field: "montant",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.type_frais){
            setError({
                field: "type_frais",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
      
        if(!data.annee_universitaire){
            setError({
                field: "annee_universitaire",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
      
        if(!data.zones){
            setError({
                field: "zones",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
    
        if(!data.niveaux_filieres){
            setError({
                field: "niveaux_filieres",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
    
      setCloseM(true)
      dispatch(setFrais({...data}))
    }
  
    const closeModal = () => {
      fnc(false)
      setCloseM(false)
      setData({
        montant: 0,
        type_frais: "",
        annee_universitaire: "",
        zones: [],
        niveaux_filieres: []
      })
    }
  
    return (
      <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-frais'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
          <h2>Ajout d'un frais </h2>
        </div>
        {
          closeM && isCreateSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Ajout d'une filière</h1>
                  <p>
                    Le frais d'inscription a été créé
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
                {
                    closeM && isCreateError ? (
                      <KAlert status={"st--danger"} title={"Ouppss!!! Une erreur est survenue"} message={message}/>
                    ) : null
                }
                {
                    error?.field === "global" ? (
                        <KAlert status={"st--danger"} title={"Ouppss!!  Une erreur est survenue"} message={error?.message}/>
                    ) : null
                }
                        
                <div className='kui-modal-box-group'>
                    <ModalForm tabsField={[
                        {
                            field: "textfield",
                            field_type: "number",
                            min: 0,
                            field_require: true,
                            value: data.montant,
                            label: "Montant",
                            id: "montant",
                            onChange: (val: any) => setData({...data, montant: val}),
                            error: error,
                            placeholder: "Ex: Science de la santé",
                            isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "no-multiple",
                          field_require: true,
                          value: data.type_frais,
                          label: "Type de frais",
                          id: "type_frais",
                          onChange: (value: any) => setData({...data, type_frais: value}),
                          error: error,
                          tabs: type_frais,
                          placeholder: "Ex: professionnel",
                          isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "no-multiple",
                          field_require: true,
                          value: data.annee_universitaire,
                          label: "Année universitaire",
                          id: "annee_universitaire",
                          onChange: (value: any) => setData({...data, annee_universitaire: value}),
                          error: error,
                          tabs: annees,
                          placeholder: "Ex: Année 20...",
                          isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "multiple",
                          field_require: true,
                          value: data.zones,
                          label: "Zones",
                          id: "zones",
                          onChange: (value: any) => setData({...data, zones: value}),
                          error: error,
                          tabs: zones,
                          placeholder: "Ex: zone de ..",
                          isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "multiple",
                          field_require: true,
                          value: data.niveaux_filieres,
                          label: "Niveau de filiere",
                          id: "zones",
                          onChange: (value: any) => setData({...data, niveaux_filieres: value}),
                          error: error,
                          tabs: niveaux_f,
                          placeholder: "Ex: licence 1 ...",
                          isShow: true
                        },
                    ]}/>
                </div>
                    
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isCreateLoading ? "pi-spinner5" : "pi-save-2-line", 
              type: "submit", 
              label: isCreateLoading ? "Création en cours" : "Enregistrer et ajouter", 
              style:"is--primary", 
              active: !isCreateLoading,
              show:  (closeM && isCreateSuccess ? false : true)
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isCreateLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}

const EditModal = ({item, zones, annees, niveaux_f, type_frais, fnc}:{zones: any[], annees: any[], niveaux_f: any[], type_frais: any[],item: any, fnc: (val: boolean) => void | any}) => {
    const [data, setData] = useState<any>({
        montant: item.montant || 0,
        type_frais: item.type_frais || "",
        annee_universitaire: item.annee_universitaire || "",
        zones: item.zones || [],
        niveaux_filieres: item.niveaux_filieres || [],
        statut: item.statut || "",
    })
    const dispatch = useAppDispatch();
    const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.frais);

    const [closeM, setCloseM] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.montant){
        setError({
            field: "montant",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.type_frais){
        setError({
            field: "type_frais",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    if(!data.annee_universitaire){
        setError({
            field: "annee_universitaire",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    if(!data.zones){
        setError({
            field: "zones",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    if(!data.niveaux_filieres){
        setError({
            field: "niveaux_filieres",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
      setError(null)
      setCloseM(true)
      dispatch(updateFrais({
        data, 
        id: item.id
      }))
    }
  
    const closeModal = () => {
      setData({
        montant: 0,
        type_frais: "",
        annee_universitaire: "",
        zones: [],
        niveaux_filieres: [],
        statut: "",
      })
      fnc(false)
      setCloseM(false)
    }
  
    return (
      <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
            <h2>Modification d'un frais</h2>
            <button type='button' className={`kui-chip ${data?.statut === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
              data?.statut === "actif" ? setData({...data, statut: "inactif"}) : setData({...data, statut: "actif"})
            }}>
               {data?.statut === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
            </button>
        </div>
        {
          closeM && isEditSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Modification réussie avec succès</h1>
                  <p> Les modifications ont été enregistrées.</p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              {
                closeM && isEditError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
                  
                  <ModalForm tabsField={[
                        {
                            field: "textfield",
                            field_type: "number",
                            min: 0,
                            field_require: true,
                            value: data.montant,
                            label: "Montant",
                            id: "montant",
                            onChange: (val: any) => setData({...data, montant: val}),
                            error: error,
                            placeholder: "Ex: Science de la santé",
                            isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "no-multiple",
                          field_require: true,
                          value: data.type_frais,
                          label: "Type de frais",
                          id: "type_frais",
                          onChange: (value: any) => setData({...data, type_frais: value}),
                          error: error,
                          tabs: type_frais,
                          placeholder: "Ex: professionnel",
                          isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "no-multiple",
                          field_require: true,
                          value: data.annee_universitaire,
                          label: "Année universitaire",
                          id: "annee_universitaire",
                          onChange: (value: any) => setData({...data, annee_universitaire: value}),
                          error: error,
                          tabs: annees,
                          placeholder: "Ex: Année 20...",
                          isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "multiple",
                          field_require: true,
                          value: data.zones,
                          label: "Zones",
                          id: "zones",
                          onChange: (value: any) => setData({...data, zones: value}),
                          error: error,
                          tabs: zones,
                          placeholder: "Ex: zone de ..",
                          isShow: true
                        },
                        {
                          field: "selectfield",
                          field_type: "multiple",
                          field_require: true,
                          value: data.niveaux_filieres,
                          label: "Niveau de filiere",
                          id: "zones",
                          onChange: (value: any) => setData({...data, niveaux_filieres: value}),
                          error: error,
                          tabs: niveaux_f,
                          placeholder: "Ex: licence 1 ...",
                          isShow: true
                        },
                    ]}/>
               
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
              type: "submit", 
              label: isEditLoading ? "Modification en cours" : "Enregistrer les modification", 
              style:"is--primary", 
              active: !isEditLoading,
              show: closeM && isEditSuccess ? false : true
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isEditLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
    const dispatch = useAppDispatch();
    const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.frais);
    console.log(item)
    const [closeM, setCloseM] = useState<boolean>(false)
    const closeModal = () => {
      fnc(false)
      setCloseM(false)
    }
    return (
      <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
          <h2>Suppression d'un cycle</h2>
        </div>
        {
          closeM && isDeleteSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Suppression réussie avec succès</h1>
                  <p>
                    { "l'element a bien été supprimé" }
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
                <div className='kui-modal-box-statut__illustration'>
                  {
                    closeM && isDeleteError ? (
                      <FailIllu loop={true} autoplay={true} size={{
                        height: 120,
                        width: 120
                      }}/>
                    ) : (
                      <i className='pi-delete-2-line'></i>
                    )
                  }
                </div>
                <div className='kui-modal-box-statut__container'>
                  {
                    closeM && isDeleteError ? (
                      <>
                        <h1>Une erreur est survenue</h1>
                        <p>{message}</p>
                      </>
                    ) : (
                      <>
                        <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                        <p>L'action est irreversible</p>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
        
        <ModalActions tabsActions={[
          {
            icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
            type: "button", 
            label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
            style:"is--primary", 
            active: !isDeleteLoading,
            onClick: () => {
                setCloseM(true)
                dispatch(deleteFrais(item.slug))
            },
            show: closeM && isDeleteSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
            style:"is--neutral", 
            active: !isDeleteLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </Modal>
    )
}


export const FraisPage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
        if(!token){
        navigate(links.login + `?url=${links.frais}`)
        }
    }, [token, navigate]);

    const dispatch = useAppDispatch();
    const { isLoading, isError, isSuccess, frais_list, message } = useAppSelector((state) => state.frais);
    const { isLoading: isZLoading, isSuccess: isZSuccess, isError: isZError, message: zMessage, zones } = useAppSelector((state) => state.zone);
    const { isLoading: isTLoading, isSuccess: isTSuccess, isError: isTError, message: tMessage, types_frais } = useAppSelector((state) => state.type_frais);
    const { isLoading: isSLoading, isSuccess: isSSuccess, isError: isSError, message: sMessage, school_years } = useAppSelector((state) => state.school_year);
    const { isLoading: isFLoading, isSuccess: isFSuccess, isError: isFError, message: fMessage, filieres } = useAppSelector((state) => state.filiere);
    const { isLoading: isNFLoading, isSuccess: isNFSuccess, isError: isNFError, message: nfMessage, niveau_filieres } = useAppSelector((state) => state.filiere_niveau);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const display = searchParams.get('display')
    const n = searchParams.get('number')
    const q = searchParams.get('q')
    
    const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
    const [search, setSearch] = useState<string>(q  || "")
    const [selectItemModal, setSelectItemModal] = useState<any>(null)
    
    const [addModal, setAddModal] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
    const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        navigate(links.frais+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
    }
    
    const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemNumber(parseInt(e.target.value))
        if(parseInt(e.target.value) > 0){
            navigate(links.frais+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
        }
    }

    useEffect(() => {
      dispatch(getFraisList({}))
      dispatch(getZones({}))
      dispatch(getTypesFrais({}))
      dispatch(getSchoolYears({}))
      dispatch(getNiveauFilieres({}))
      dispatch(getFilieres({}))
      
      return () => {
        dispatch(reset())
        dispatch(zreset())
        dispatch(treset())
        dispatch(sreset())
        dispatch(nfreset())
        dispatch(freset())
      }
    }, [dispatch])

    return (
        <MainLayout title="Frais" isMin={false}>
            <div className='kui-page is--min'>
                <div className='kui-arianne'>
                    <Link className='kui-link' to={links.home}>
                        <i className="pi-home-6-line"></i>
                        <span>Accueil</span>
                    </Link>
                    <span> | </span>
                    <p>Frais</p>
                </div>
                {

                    isLoading || isZLoading || isNFLoading || isSLoading || isTLoading || isFLoading ? (
                        <div className='kui-loader'>
                            <LoaderAnimation loop={true} autoplay={true} size={{
                                height: 40,
                                width: 40
                            }}/>
                        </div>
                    ) : (
                        <>
                            <div className='kui-page__header kui-page-header'>
                                <h2>Frais</h2>
                            </div>
                            <div className='kui-page-list'>
                                <div className='kui-page-list__actions kui-page-list-actions'>
                                    <div className='kui-page-list-actions-display'>
                                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.frais+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                        <i className="pi-list-check-line"></i>
                                        </button>
                                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.frais+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                        <i className="pi-layout-grid-line"></i>
                                        </button>
                                    </div>
                                    <div className='kui-page-list-actions-group'>
                                        <div className='kui-page-list-actions-input'>
                                        <span>Nombre à afficher: </span>
                                        <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                                        </div>
                                        <div className='kui-page-list-actions-search'>
                                        <i className="pi-search-line"></i>
                                        <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                                        </div>
                                    </div>
                                    <div className='kui-page-list-actions-group'>
                                        <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                                        
                                    </div>
                                </div>
                                {
                                    isZError || isSError || isTError || isNFError || isFError || isError ? (
                                        <div className='kui-page-error'>
                                            <div className='kui-page-error__illustration'>
                                                <NotFoundError loop={true} autoplay={true} size={{
                                                height: 180,
                                                width: 180
                                                }}/>
                                            </div>
                                            <div className='kui-page-error_details'>
                                                <p>Oupps! Une erreur est survenue</p>
                                                <h1>{isError ? message : (isZError ? zMessage : (isTError ? tMessage : (isNFError ? nfMessage : (isSError ? sMessage : (isFError ? fMessage : "")))))}</h1>
                                            </div>
                                        </div> 
                                    ) : (
                                        isSuccess && isZSuccess && isTSuccess && isNFSuccess && isSSuccess && isFSuccess && niveau_filieres && zones && types_frais && school_years && frais_list && filieres ? (
                                            <div className='kui-page-list__container'>
                                                <span className='kui-page-list__total'>
                                                    {
                                                        frais_list.filter((el: any) => school_years.filter((ele: any) => ele.id === el.annee_universitaire)[0]?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || types_frais.filter((ele: any) => ele.id === el.type_frais)[0]?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                        .length > 0 ? `
                                                        
                                                        ${
                                                            frais_list.filter((el: any) => school_years.filter((ele: any) => ele.id === el.annee_universitaire)[0]?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || types_frais.filter((ele: any) => ele.id === el.type_frais)[0]?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length > 1 ? frais_list.filter((el: any) => school_years.filter((ele: any) => ele.id === el.annee_universitaire)[0]?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || types_frais.filter((ele: any) => ele.id === el.type_frais)[0]?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                        .length + " elements trouvés" : frais_list.filter((el: any) => school_years.filter((ele: any) => ele.id === el.annee_universitaire)[0]?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || types_frais.filter((ele: any) => ele.id === el.type_frais)[0]?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length+" element trouvé"} ` : "Aucun element trouvé"
                                                    }
                                                </span>

                                                {
                                                    frais_list.filter((el: any) => school_years.filter((ele: any) => ele.id === el.annee_universitaire)[0]?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || types_frais.filter((ele: any) => ele.id === el.type_frais)[0]?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                    .length > 0 ? (
                                                        display === "grid" ? (
                                                        <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                                            {
                                                                frais_list.filter((el: any) => school_years.filter((ele: any) => ele.id === el.annee_universitaire)[0]?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || types_frais.filter((ele: any) => ele.id === el.type_frais)[0]?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                                .slice(0, itemNumber)
                                                                .map((item: any, index: number) => (
                                                                    <div className='kui-page-list-item is--md' key={index}>
                                                                        <div className='kui-page-list-item__min'>
                                                                            <span className={`kui-chip ${item?.statut ? "is--success" : "is--warning"}`}>
                                                                                {item.statut}
                                                                            </span>
                                                                        </div>
                                                                        

                                                                        <div className='kui-page-list-item__container'>
                                                                            <div className='kui-page-list-item__details'>

                                                                                <p>Type de frais: {types_frais.filter((ele: any) => ele.id === item.type_frais)[0]?.intitule}</p>
                                                                                <strong className='kui-page-list-item__title'>Somme: {item?.montant}</strong>
                                                                                <p>{`Annee universitaire: ${school_years.filter((ele: any) => ele.id === item.annee_universitaire)[0]?.annee_debut} - ${school_years.filter((ele: any) => ele.id === item.annee_universitaire)[0]?.annee_fin} . Code: ${school_years.filter((ele: any) => ele.id === item.annee_universitaire)[0]?.code_annee_scolaire} `}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='kui-page-list-item__actions'>
                                                                           
                                                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                                setSelectItemModal(null)
                                                                                setSelectItemModal(item)
                                                                                setEditModal(true)
                                                                            }}>
                                                                                <i className={"pi-edit-3-line"}></i>
                                                                                <span>{"Modifier"}</span>
                                                                            </button>
                                                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                                                setSelectItemModal(null)
                                                                                setSelectItemModal(item)
                                                                                setDeleteModal(true)
                                                                            }}>
                                                                                <i className={"pi-delete-2-line"}></i>
                                                                                <span>{"Supprimer"}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))        
                                                            }
                                                        </Grid>
                                                        ) : (
                                                        <Table>
                                                            <Row rtype='header'>
                                                                <Column ctype={'small'}>
                                                                    <strong>#</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <strong>Frais</strong>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <strong>Annee universitaire</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <strong>Zones</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <strong>Filières et niveaux</strong>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <strong>Statut</strong>
                                                                </Column>
                                                                <Column ctype={'small'}>
                                                                    <strong>#</strong>
                                                                </Column>
                                                            </Row>
                                                            {
                                                                frais_list.filter((el: any) => school_years.filter((ele: any) => ele.id === el.annee_universitaire)[0]?.code_annee_scolaire?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || types_frais.filter((ele: any) => ele.id === el.type_frais)[0]?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                                .slice(0, itemNumber)
                                                                .map((item: any, index: number) => (
                                                                    <Row key={index}>
                                                                        <Column ctype={'small'}>
                                                                            <strong>{`#${index + 1}`}</strong>
                                                                        </Column>
                                                                        <Column ctype={'large'}>
                                                                            <TableItem title={item?.montant} subtitle={`Type de frais: ${types_frais.filter((ele: any) => ele.id === item.type_frais)[0]?.intitule}`} withImage={false} image={null}/>
                                                                        </Column>

                                                                        <Column ctype={'mid'}>
                                                                            <div>
                                                                                <span className='kui-tag'>
                                                                                {`${school_years.filter((ele: any) => ele.id === item.annee_universitaire)[0]?.annee_debut} - ${school_years.filter((ele: any) => ele.id === item.annee_universitaire)[0]?.annee_fin} . Code: ${school_years.filter((ele: any) => ele.id === item.annee_universitaire)[0]?.code_annee_scolaire} `}
                                                                                </span>
                                                                            </div>
                                                                        </Column>
                                                                        <Column ctype={'large'}>
                                                                            <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={0.3} >
                                                                                {
                                                                                    item.zones.slice(0, 3).map((zone: any, idx: number) => (
                                                                                        <span className='kui-tag' key={idx}>
                                                                                            {zones.filter((ele: any) => ele.id === zone)[0]?.intitule}
                                                                                        </span>
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    item.zones.length > 3 ? (
                                                                                        <span className='kui-tag'>
                                                                                            {`+ ${item.zones.length - 3}`}
                                                                                        </span>
                                                                                    ) : null
                                                                                }
                                                                            </Grid>
                                                                        </Column>
                                                                        <Column ctype={'large'}>
                                                                            <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={0.3} >
                                                                                {
                                                                                    item.niveaux_filieres.slice(0, 3).map((niveau_filiere: any, idx: number) => (
                                                                                        <span className='kui-tag' key={idx}>
                                                                                            {`${niveau_filieres.filter((ele: any) => ele.id === niveau_filiere)[0]?.niveau?.intitule} - ${filieres?.filter((ele: any) => (ele.id === niveau_filieres.filter((ele: any) => ele.id === niveau_filiere)[0]?.filiere))[0]?.intitule}`}
                                                                                        </span>
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    item.niveaux_filieres.length > 3 ? (
                                                                                        <span className='kui-tag'>
                                                                                            {`+ ${item.niveaux_filieres.length - 3}`}
                                                                                        </span>
                                                                                    ) : null
                                                                                }
                                                                            </Grid>
                                                                        </Column>
                                                                        <Column ctype={'mid'}>
                                                                            <div>
                                                                                <span className={`kui-chip ${item?.statut ? "is--success" : "is--warning"}`}>
                                                                                {item.statut}
                                                                                </span>
                                                                            </div>
                                                                        </Column>
                                                                        <Column ctype={'small'}>
                                                                            <TableOptions atype={"reduce"} tabs={[
                                                                            
                                                                            {
                                                                                icon: "pi-edit-3-line", 
                                                                                label: "Modifier", 
                                                                                style:"is--neutral", 
                                                                                onClick: () => {
                                                                                    setSelectItemModal(null)
                                                                                    setSelectItemModal(item)
                                                                                    setEditModal(true)
                                                                                }
                                                                            },
                                                                            {
                                                                                icon: "pi-delete-2-line", 
                                                                                label: "Supprimer", 
                                                                                style:"is--danger", 
                                                                                onClick: () => {
                                                                                    setSelectItemModal(null)
                                                                                    setSelectItemModal(item)
                                                                                    setDeleteModal(true)
                                                                                }
                                                                            },
                                                                            ]}/>
                                                                        </Column>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </Table>
                                                        )
                                                    ) : (
                                                        <div className='kui-page-empty'>
                                                            <div className='kui-page-empty__illustration'>
                                                                <EmptyList loop={true} autoplay={true} size={{
                                                                height: (380 / 1.3),
                                                                width: (420 / 1.3)
                                                                }}/>
                                                            </div>
                                                            <div className='kui-page-empty__details'>
                                                                <h1>Aucun element trouvé</h1>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : null
                                    )
                                }
                            </div>
                            {addModal ? <AddModal fnc={(val?: boolean) => setAddModal(val || false)} zones={optionifyZones(zones)} annees={optionifyAnnesScolaire(school_years)} niveaux_f={optionifyNiveauFilieres(niveau_filieres, filieres)} type_frais={optionifyTypeFrais(types_frais)}/> : null}
                            {editModal && selectItemModal  ? <EditModal zones={optionifyZones(zones)} annees={optionifyAnnesScolaire(school_years)} niveaux_f={optionifyNiveauFilieres(niveau_filieres, filieres)} type_frais={optionifyTypeFrais(types_frais)} item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                            
                            {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

                            
                        </>
                    )
                }
            </div>
        </MainLayout>
    )
}
