import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FraisState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondesfrais/frais/"

const initialState: FraisState = {
    frais_list: [],
    frais: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setFrais = createAsyncThunk<any, Object>(
    'frais/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getFraisList = createAsyncThunk<any, Object>(
    'frais/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getFrais = createAsyncThunk<any, Object>(
    'frais/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateFrais = createAsyncThunk<any, Object>(
    'frais/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteFrais = createAsyncThunk<any, Object>(
    'frais/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            const item = await deleteProtecteItems(data)
            return {item, content}
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const fraisSlice = createSlice({
    name: 'frais',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFraisList.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFraisList.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.frais_list = action.payload
            })
            .addCase(getFraisList.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getFrais.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFrais.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.frais = action.payload
            })
            .addCase(getFrais.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setFrais.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setFrais.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.frais_list = [action.payload, ...state.frais_list]
            })
            .addCase(setFrais.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateFrais.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateFrais.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.frais_list = [action.payload, ...state.frais_list.filter((frais: any) => frais?.id !== action.payload?.id)]
                state.frais = action.payload
            })
            .addCase(updateFrais.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteFrais.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteFrais.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.frais_list = state.frais_list.filter((frais: any) => frais?.slug !== action.payload?.content)
            })
            .addCase(deleteFrais.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = fraisSlice.actions
export default fraisSlice.reducer