import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { deletePromotion, getPromotions, setPromotion, updatePromotion, reset } from '../../../../../app/redux/features/cursus/promotion.slice';
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { getSchoolYears, getSubscriptionYears, reset as sreset } from '../../../../../app/redux/features/school_year/school_year.slice';
import { getFilieres, reset as freset  } from '../../../../../app/redux/features/cursus/filiere.slice';
import { getNiveauFilieres, reset as nfreset  } from '../../../../../app/redux/features/cursus/filiere_niveau.slice';
import { MainLayout } from '../../../../layout';
import { capitalize, optionifyNiveauFilieres, optionifySession } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale/fr';

const AddModal = ({sessions, fn_list, fnc}:{sessions: any[], fn_list: any[], fnc: (val: boolean) => void | any}) => {
    const [data, setData] = useState<any>({
        niveau_filiere: "",
        session_inscription: ""
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess, promotion, isCreateError, message } = useAppSelector((state) => state.promotion);

   
    const [closeM, setCloseM] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(!data.niveau_filiere){
            setError({
                field: "niveau_filiere",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.session_inscription){
            setError({
                field: "session_inscription",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError(null)
        setCloseM(true)
        dispatch(setPromotion({
            ...data, 
        }))
    }

    const closeModal = () => {
        setData(null)
        fnc(false)
        setCloseM(false)
    }

    return (
        <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
            <div className='kui-modal-box__header'>
                <h2>Ajout d'une promotion</h2>
            </div>
            {
                closeM && isCreateSuccess && promotion ? (
                    <div className='kui-modal-box__content'>
                        <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                        <div className='kui-modal-box-statut__illustration'>
                            <AddSuccessIllu loop={true} autoplay={true} size={{
                            height: 120,
                            width: 120
                            }}/>
                        </div>
                        <div className='kui-modal-box-statut__container'>
                            <h1>Creation réussie avec succès</h1>
                            <p>
                                La promotions  a été créée
                            </p>
                        </div>
                        </div>
                    </div>
                ) : (
                    <div className='kui-modal-box__content'>
                        {
                            closeM && isCreateError ? (
                                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                            ) : null
                        }
                        <div className='kui-modal-box-group'>
                            <>
                                <ModalForm tabsField={[
                                    {
                                        field: "selectfield",
                                        field_type: "no-multiple",
                                        field_require: true,
                                        value: data.niveau_filiere,
                                        label: "Niveau de la filiere",
                                        id: "niveau_filiere",
                                        onChange: (val: any) => setData({...data, niveau_filiere: val}),
                                        error: error,
                                        tabs: fn_list,
                                        placeholder: "Ex: niveau",
                                        isShow: true
                                    },
                                    {
                                        field: "selectfield",
                                        field_type: "no-multiple",
                                        field_require: true,
                                        value: data.session_inscription,
                                        label: "Session d'inscription",
                                        id: "session_inscription",
                                        onChange: (val: any) => setData({...data, session_inscription: val}),
                                        error: error,
                                        tabs: sessions,
                                        placeholder: "Ex: session",
                                        isShow: true
                                    },
                                ]}/>
                            </>
                        </div>
                    </div>
                )
            }
            <form onSubmit={handleSubmit}>
                <ModalActions tabsActions={[
                    {
                        icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
                        type: "submit", 
                        label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
                        style:"is--primary", 
                        active: !isCreateLoading,
                        show: closeM && isCreateSuccess ? false : true
                    },
                    {
                        icon: "pi-close-line", 
                        type: "button", 
                        label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
                        style:"is--danger", 
                        active: !isCreateLoading,
                        onClick: () => closeModal(),
                        show: true
                    },
                ]}/>
            </form>
        </Modal>
    )
}

const EditModal = ({sessions, fn_list, item, fnc}:{sessions: any[], fn_list: any[],item: any, fnc: (val: boolean) => void | any}) => {
    const [data, setData] = useState<any>({
      niveau_filiere: item.niveau_filiere || "",
        session_inscription: item.session_inscription ||"",
      statut: item.statut || "",
    })
    const dispatch = useAppDispatch();
    const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.promotion);
  
    const [closeM, setCloseM] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      
      
      if(!data.niveau_filiere){
        setError({
            field: "niveau_filiere",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.session_inscription){
        setError({
            field: "session_inscription",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
      setError(null)
      setCloseM(true)
      dispatch(updatePromotion({
        data, 
        id: item.id
      }))
    }
  
    const closeModal = () => {
      setData({
        date_rentrer_universitaire:  "",
      })
      fnc(false)
      setCloseM(false)
    }
  
    return (
      <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
            <h2>Modification d'une session d'inscription</h2>
            <button type='button' className={`kui-chip ${data?.statut === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
              data?.statut === "actif" ? setData({...data, statut: "inactif"}) : setData({...data, statut: "actif"})
            }}>
               {data?.statut === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
            </button>
        </div>
        {
          closeM && isEditSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Modification réussie avec succès</h1>
                  <p> Les modifications ont été enregistrées.</p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              {
                closeM && isEditError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              <ModalForm tabsField={[
                  {
                      field: "selectfield",
                      field_type: "no-multiple",
                      field_require: true,
                      value: data.niveau_filiere,
                      label: "Niveau de la filiere",
                      id: "niveau_filiere",
                      onChange: (val: any) => setData({...data, niveau_filiere: val}),
                      error: error,
                      tabs: fn_list,
                      placeholder: "Ex: niveau",
                      isShow: true
                  },
                  {
                      field: "selectfield",
                      field_type: "no-multiple",
                      field_require: true,
                      value: data.session_inscription,
                      label: "Session d'inscription",
                      id: "session_inscription",
                      onChange: (val: any) => setData({...data, session_inscription: val}),
                      error: error,
                      tabs: sessions,
                      placeholder: "Ex: session",
                      isShow: true
                  },
              ]}/>
               
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
              type: "submit", 
              label: isEditLoading ? "Modification en cours" : "Enregistrer les modifications", 
              style:"is--primary", 
              active: !isEditLoading,
              show: closeM && isEditSuccess ? false : true
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isEditLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}
  
const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
    const dispatch = useAppDispatch();
    const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.promotion);

    const [closeM, setCloseM] = useState<boolean>(false)
    const closeModal = () => {
        fnc(false)
        setCloseM(false)
    }
    return (
        <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
            <div className='kui-modal-box__header'>
                <h2>Suppression d'une promotion</h2>
            </div>
            {
                closeM && isDeleteSuccess ? (
                <div className='kui-modal-box__content'>
                    <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                    <div className='kui-modal-box-statut__illustration'>
                        <AddSuccessIllu loop={true} autoplay={true} size={{
                        height: 120,
                        width: 120
                        }}/>
                    </div>
                    <div className='kui-modal-box-statut__container'>
                        <h1>Suppression réussie avec succès</h1>
                        <p>
                        { "l'element a bien été supprimé" }
                        </p>
                    </div>
                    </div>
                </div>
                ) : (
                <div className='kui-modal-box__content'>
                    <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
                    <div className='kui-modal-box-statut__illustration'>
                        {
                        closeM && isDeleteError ? (
                            <FailIllu loop={true} autoplay={true} size={{
                            height: 120,
                            width: 120
                            }}/>
                        ) : (
                            <i className='pi-delete-2-line'></i>
                        )
                        }
                    </div>
                    <div className='kui-modal-box-statut__container'>
                        {
                        closeM && isDeleteError ? (
                            <>
                            <h1>Une erreur est survenue</h1>
                            <p>{message}</p>
                            </>
                        ) : (
                            <>
                            <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                            <p>L'action est irreversible</p>
                            </>
                        )
                        }
                    </div>
                    </div>
                </div>
                )
            }
            
            <ModalActions tabsActions={[
                {
                icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
                type: "button", 
                label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
                style:"is--primary", 
                active: !isDeleteLoading,
                onClick: () => {
                    setCloseM(true)
                    dispatch(deletePromotion(item.id))
                },
                show: closeM && isDeleteSuccess ? false : true
                },
                {
                icon: "pi-close-line", 
                type: "button", 
                label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
                style:"is--neutral", 
                active: !isDeleteLoading,
                onClick: () => closeModal(),
                show: true
                },
            ]}/>
        </Modal>
    )
}

  
export const PromotionsPage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
        if(!token){
        navigate(links.login + `?url=${links.promotions}`)
        }
    }, [token, navigate]);

    const dispatch = useAppDispatch();
    const { isLoading, isError, isSuccess, promotions, message } = useAppSelector((state) => state.promotion);
    const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, subscription_sessions, school_years, message: sMessage } = useAppSelector((state) => state.school_year);
    
    const { isLoading: isFLoading, isError: isFError, isSuccess: isFSuccess, filieres, message: fMessage } = useAppSelector((state) => state.filiere);
    const { isLoading: isNFLoading, isError: isNFError, isSuccess: isNFSuccess, niveau_filieres, message: nfMessage } = useAppSelector((state) => state.filiere_niveau);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const display = searchParams.get('display')
    const n = searchParams.get('number')
    const q = searchParams.get('q')
    
    const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
    const [search, setSearch] = useState<string>(q  || "")
    const [selectItemModal, setSelectItemModal] = useState<any>(null)
    
    const [addModal, setAddModal] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
    const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
      navigate(links.promotions+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
    }
    
    const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
      setItemNumber(parseInt(e.target.value))
      if(parseInt(e.target.value) > 0){
          navigate(links.promotions+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
      }
    }

    useEffect(() => {
      dispatch(getSchoolYears({}))
      dispatch(getSubscriptionYears({}))
      dispatch(getPromotions({}))
      dispatch(getFilieres({}))
      dispatch(getNiveauFilieres({}))

      return () => {
        dispatch(reset())
        dispatch(sreset())
        dispatch(freset())
        dispatch(nfreset())
      }
    }, [dispatch])
    
    return (
        <MainLayout title="Promotions" isMin={false}>
            <div className='kui-page is--min'>
                <div className='kui-arianne'>
                    <Link className='kui-link' to={links.home}>
                        <i className="pi-home-6-line"></i>
                        <span>Accueil</span>
                    </Link>
                    <span> | </span>
                    <p>Promotions</p>
                </div>
                {
                    isLoading ? (
                        <div className='kui-loader'>
                        <LoaderAnimation loop={true} autoplay={true} size={{
                            height: 40,
                            width: 40
                        }}/>
                        </div>
                    ) : (
                        <>
                            <div className='kui-page__header kui-page-header'>
                                <h2>Promotions</h2>
                            </div>
                            <div className='kui-page-list'>
                                <div className='kui-page-list__actions kui-page-list-actions'>
                                    <div className='kui-page-list-actions-display'>
                                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.promotions+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                            <i className="pi-list-check-line"></i>
                                        </button>
                                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.promotions+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                            <i className="pi-layout-grid-line"></i>
                                        </button>
                                    </div>
                                    <div className='kui-page-list-actions-group'>
                                        <div className='kui-page-list-actions-input'>
                                        <span>Nombre à afficher: </span>
                                        <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                                        </div>
                                        <div className='kui-page-list-actions-search'>
                                        <i className="pi-search-line"></i>
                                        <input type="search" value={search} placeholder='Recherche par code...' onChange={onChangeSearch}/>
                                        </div>
                                    </div>
                                    <div className='kui-page-list-actions-group'>
                                        <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                                    </div>
                                </div>
                                {
                                    isError || isSError || isFError || isNFError ? (
                                        <div className='kui-page-error'>
                                          <div className='kui-page-error__illustration'>
                                            <NotFoundError loop={true} autoplay={true} size={{
                                              height: 180,
                                              width: 180
                                            }}/>
                                          </div>
                                          <div className='kui-page-error_details'>
                                            <p>Oupps! Une erreur est survenue</p>
                                            <h1>{isError ? message : (isSError ? sMessage : (isNFError ? nfMessage : (isFError ? fMessage : "")))}</h1>
                                          </div>
                                        </div> 
                                    ) : (
                                        isSuccess && isSSuccess && isNFSuccess && isFSuccess && filieres && niveau_filieres && school_years && subscription_sessions && promotions ? (
                                            <div className='kui-page-list__container'>
                                                <span className='kui-page-list__total'>
                                                    {
                                                        promotions
                                                        .filter((el: any) => el?.code_promotion?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                        .length > 0 ? `
                                                        
                                                        ${
                                                            promotions
                                                        .filter((el: any) => el?.code_promotion?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                        .length > 1 ? promotions
                                                            .filter((el: any) => el?.code_promotion?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                            .length + " elements trouvés" : promotions
                                                            .filter((el: any) => el?.code_promotion?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                            .length+" element trouvé"} ` : "Aucun element trouvé"
                                                    }
                                                </span>

                                                {
                                                    promotions
                                                    .filter((el: any) => el?.code_promotion?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                    .length > 0 ? (
                                                        display === "grid" ? (
                                                        <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                                            {
                                                                promotions
                                                                .filter((el: any) => el?.code_promotion?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                                .slice(0, itemNumber)
                                                                .map((item: any, index: number) => (
                                                                    <div className='kui-page-list-item is--md' key={index}>
                                                                        <div className='kui-page-list-item__min'>
                                                                            <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                                                                {item.statut?.toUpperCase()}
                                                                            </span>

                                                                            {/* <span className='kui-tag'>{item?.code_session_inscription}</span> */}
                                                                        </div>
                                                                        

                                                                        <div className='kui-page-list-item__container'>
                                                                            <div className='kui-page-list-item__details'>
                                                                                
                                                                                <strong>{item.code_promotion}</strong>
                                                                                <p className='kui-page-list-item__title'>
                                                                                    Session d'inscription : {subscription_sessions?.filter((el: any) => el.id === item.session_inscription)[0]?.code_session_inscription} - {subscription_sessions?.filter((el: any) => el.id === item.session_inscription)[0]?.date_rentrer_universitaire ? capitalize(format(new Date(subscription_sessions?.filter((el: any) => el.id === item.session_inscription)[0]?.date_rentrer_universitaire), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}
                                                                                </p>
                                                                                <p>{`${niveau_filieres?.filter((el: any) => el.id === item.niveau_filiere)[0]?.niveau?.intitule} - ${filieres?.filter((ele: any) => (ele.id === niveau_filieres?.filter((el: any) => el.id === item.niveau_filiere)[0]?.filiere))[0]?.intitule}`}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='kui-page-list-item__actions'>
                                                                            
                                                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                                setSelectItemModal(null)
                                                                                setSelectItemModal(item)
                                                                                setEditModal(true)
                                                                            }}>
                                                                                <i className={"pi-edit-3-line"}></i>
                                                                                <span>{"Modifier"}</span>
                                                                            </button>
                                                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                                                setSelectItemModal(null)
                                                                                setSelectItemModal(item)
                                                                                setDeleteModal(true)
                                                                            }}>
                                                                                <i className={"pi-delete-2-line"}></i>
                                                                                <span>{"Supprimer"}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))        
                                                            }
                                                        </Grid>
                                                        ) : (
                                                        <Table>
                                                            <Row rtype='header'>
                                                                <Column ctype={'small'}>
                                                                    <strong>#</strong>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <strong>Code</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <strong>Session d'inscription</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <strong>Niveau Filiere</strong>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <strong>Statut</strong>
                                                                </Column>
                                                                <Column ctype={'small'}>
                                                                    <strong>#</strong>
                                                                </Column>
                                                            </Row>
                                                            {
                                                                promotions
                                                                .filter((el: any) => el?.code_promotion?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                                .slice(0, itemNumber)
                                                                .map((item: any, index: number) => (
                                                                    <Row key={index}>
                                                                        <Column ctype={'small'}>
                                                                            <strong>{`#${index + 1}`}</strong>
                                                                        </Column>
                                                                        <Column ctype={'mid'}>
                                                                            <TableItem title={item?.code_promotion} subtitle={''} withImage={false} image={null}/>
                                                                        </Column>
                                                                        <Column ctype={'large'}>
                                                                            <div>
                                                                            <span className='kui-tag is--info'>
                                                                            {subscription_sessions?.filter((el: any) => el.id === item.session_inscription)[0]?.code_session_inscription} - {subscription_sessions?.filter((el: any) => el.id === item.session_inscription)[0]?.date_rentrer_universitaire ? capitalize(format(new Date(subscription_sessions?.filter((el: any) => el.id === item.session_inscription)[0]?.date_rentrer_universitaire), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}
                                                                            </span>
                                                                            </div>
                                                                        </Column>
                                                                        <Column ctype={'large'}>
                                                                            <div>
                                                                            <span className='kui-tag'>
                                                                                {`${niveau_filieres?.filter((el: any) => el.id === item.niveau_filiere)[0]?.niveau?.intitule} - ${filieres?.filter((ele: any) => (ele.id === niveau_filieres?.filter((el: any) => el.id === item.niveau_filiere)[0]?.filiere))[0]?.intitule}`}
                                                                            </span>
                                                                            </div>
                                                                        </Column>
                                                                        <Column ctype={'mid'}>
                                                                            <div>
                                                                                <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                                                                    {item.statut?.toUpperCase()}
                                                                                </span>
                                                                            </div>
                                                                        </Column>
                                                                        <Column ctype={'small'}>
                                                                            <TableOptions atype={"reduce"} tabs={[
                                                                                {
                                                                                    icon: "pi-edit-3-line", 
                                                                                    label: "Modifier", 
                                                                                    style:"is--neutral", 
                                                                                    onClick: () => {
                                                                                    setSelectItemModal(null)
                                                                                    setSelectItemModal(item)
                                                                                    setEditModal(true)
                                                                                    }
                                                                                },
                                                                                {
                                                                                    icon: "pi-delete-2-line", 
                                                                                    label: "Supprimer", 
                                                                                    style:"is--danger", 
                                                                                    onClick: () => {
                                                                                    setSelectItemModal(null)
                                                                                    setSelectItemModal(item)
                                                                                    setDeleteModal(true)
                                                                                    }
                                                                                },
                                                                            ]}/>
                                                                        </Column>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </Table>
                                                        )
                                                    ) : (
                                                        <div className='kui-page-empty'>
                                                            <div className='kui-page-empty__illustration'>
                                                                <EmptyList loop={true} autoplay={true} size={{
                                                                height: (380 / 1.3),
                                                                width: (420 / 1.3)
                                                                }}/>
                                                            </div>
                                                            <div className='kui-page-empty__details'>
                                                                <h1>Aucun element trouvé</h1>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : null
                                    )
                                }
                                {filieres && niveau_filieres && school_years && subscription_sessions && addModal ? <AddModal fnc={(val?: boolean) => setAddModal(val || false)} sessions={optionifySession(subscription_sessions, school_years)} fn_list={optionifyNiveauFilieres(niveau_filieres, filieres)}/> : null}
                                {filieres && niveau_filieres && school_years && subscription_sessions && editModal && selectItemModal  ? <EditModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}  sessions={optionifySession(subscription_sessions, school_years)} fn_list={optionifyNiveauFilieres(niveau_filieres, filieres)}/> : null}
                                
                                {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

                            </div>
                        </>
                    )
                }
                
            </div>
        </MainLayout>
    )
}
