import { APP_NAME, APP_NAME_SHORT } from "./constant";
import strSlug from "slug"
import { randomIntFromInterval } from "./number";
import { format } from "date-fns";
import { fr } from "date-fns/locale/fr";

export const capitalize = (word: string) => {
    const lower = word.toLowerCase()
    return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const titlePage = (title?: string) => {
    if(title){
        document.title = title + " | " + APP_NAME;
    }else {
        document.title =" Bienvenue sur "+APP_NAME_SHORT;
    }
}

export const truncate = (str: string | string[], maxlength: number) => {
    return (str.length > maxlength) ? str.slice(0, maxlength - 1) + '…' : str;
}

export  const slugifyFileName = (name: string) => {
    let tabs: string[] = name.split('.')
    let nom: string = tabs[0]
    let ext: string = name.split('.').pop() || ""
  
    if(nom && ext){
        return strSlug(nom) + "."+ext
    }else{
        return name
    }
}

export const generateUsername = (nom: string, prenom: string) => {
    return strSlug(nom + " " + prenom+" "+randomIntFromInterval(0,999)).toLowerCase()
}

export const createInitial = (name: string) => {
    const words = name.split(' ');
    let initial = '';

    for (let i = 0; i < words.length; i++) {
        initial += words[i][0];
    }
    return initial.slice(0,3).toUpperCase();
}

export const optionifyAnnesScolaire = (annees: any[]) => {
    let tabs: any[] = []
    annees?.forEach((el: any) => {
        tabs = [...tabs, {
            name: `Année ${el?.annee_debut} - ${el?.annee_fin}`,
            value: el?.id,
        }]
    })

    return tabs
}

export const optionifySession = (session_in: any[], annees: any[]) => {
    let tabs: any[] = []
    session_in?.forEach((item: any) => {
        tabs = [...tabs, {
            name: `Code: ${item.code_session_inscription} - Année ${annees.filter((el: any) => el.id === item.annee_scolaire)[0]?.annee_debut + "-" + annees.filter((el: any) => el.id === item.annee_scolaire)[0]?.annee_fin } - Date de rentrée: ${item?.date_rentrer_universitaire ? capitalize(format(new Date(item?.date_rentrer_universitaire), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}`,
            value: item?.id,
            annee: item.annee_scolaire
        }]
    })

    return tabs
}



export const optionifyZones = (zones: any[]) => {
    let tabs: any[] = []
    zones?.forEach((el: any) => {
        tabs = [...tabs, {
            name: el?.intitule,
            value: el?.id,
        }]
    })

    return tabs
}
export const optionifyCycle = (cycles: any[]) => {
    let tabs: any[] = []
    cycles?.forEach((el: any) => {
        tabs = [...tabs, {
            name: el?.intitule + " " + el?.finalite,
            value: el?.id,
        }]
    })

    return tabs
}

export const optionifyNiveau = (cycles: any[]) => {
    let tabs: any[] = []
    cycles?.forEach((el: any) => {
        tabs = [...tabs, {
            name: el?.intitule,
            value: el?.id,
        }]
    })
    return tabs
}

export const optionifyFiliere = (filieres: any[], cycles: any[]) => {
    let tabs: any[] = []
    filieres?.forEach((el: any) => {
        tabs = [...tabs, {
            name: `${el?.intitule} - ${(cycles.filter((ele: any) => ele.id === el.cycle)[0]?.intitule + " " +cycles.filter((ele: any) => ele.id === el.cycle)[0]?.finalite) || ""}`,
            value: el?.id,
            cycle: el?.cycle
        }]
    })

    return tabs
}

export const optionifyTypeFrais = (types_frais: any[]) => {
    let tabs: any[] = []
    types_frais?.forEach((el: any) => {
        tabs = [...tabs, {
            name: el?.intitule,
            value: el?.id,
        }]
    })

    return tabs
}

export const optionifyNiveauFilieres = (niveaux_f: any[], filieres: any[]) => {
    let tabs: any[] = []
    niveaux_f?.forEach((el: any) => {
        tabs = [...tabs, {
            name: `${el?.niveau?.intitule} - ${filieres?.filter((ele: any) => (ele.id === el.filiere))[0]?.intitule}`,
            value: el?.id,
        }]
    })
    return tabs
}

export const optionifyPromotion = (items: any[]) => {
    let tabs: any[] = []
    items?.forEach((el: any) => {
        tabs = [...tabs, {
            name: `Code ${el?.code_promotion}`,
            value: el?.id,
        }]
    })
    return tabs
}

export const optionifyTypeEnseignement = (items: any[]) => {
    let tabs: any[] = []
    items?.forEach((el: any) => {
        tabs = [...tabs, {
            name: `${el?.intitule_type_enseignement}`,
            value: el?.id,
        }]
    })
    return tabs
}

export const optionifyTypeMatiere = (items: any[]) => {
    let tabs: any[] = []
    items?.forEach((el: any) => {
        tabs = [...tabs, {
            name: `${el?.intitule} (${el.code_matiere})`,
            value: el?.id,
        }]
    })
    return tabs
}

export const optionifyTypeChambre = (items: any[]) => {
    let tabs: any[] = []
    items?.forEach((el: any) => {
        tabs = [...tabs, {
            name: `${el?.libelle_type}`,
            value: el?.id,
        }]
    })
    return tabs
}

export const onlyIdsOnTabs = (items: any[]) => {
    let tabs: any[] = []
    items?.forEach((el: any) => {
        tabs = [...tabs, el?.id]
    })
    return tabs
}

export const onlyIdsOnBureauTabs = (items: any[]) => {
    let tabs: any[] = []
    items?.forEach((el: any) => {
        tabs = [...tabs, {
            member: el?.member?._id,
            poste: el?.poste
        }]
    })
    return tabs
}

export function creerInitiales(texte: string) {
    let initiales = '';
    for (let i = 0; i < texte.length; i++) {
        if (texte[i] === texte[i].toUpperCase() && texte[i].match(/[A-Z]/)) {
            initiales += texte[i];
        }
    }
    return initiales;
}

export const fetchFile = async (url: string) => {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const filename = url.substring(url.lastIndexOf('/') + 1);
        const link = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      })
      .catch(error => console.error('Download failed', error));
}

export const listTeacher = (demands: any[], teachers: any[]) => {
    let tabs: any[] = []

    teachers?.filter((el: any) => el.demande).forEach((el: any) => {
        tabs = [...tabs, {
            ...el,
            info: demands.filter((ele: any) => ele.id  === el.demande)[0]
        }]
    })
    console.log(tabs)
    return tabs
}

export const listEnseignement = (enseignements: any[],types: any[], matieres: any[], promotions:  any[], demands: any[], teachers: any[]) => {
    let tabs: any[] = []

    enseignements?.forEach((el: any) => {
        tabs = [...tabs, {
            ...el,
            teacher: { 
                ...teachers.filter((ele: any) => ele.id === el.enseignant)[0],
                info: demands.filter((elem: any) => elem.id === teachers.filter((ele: any) => ele.id === el.enseignant)[0].demande)[0] 
            },
            promotion_info: promotions.filter((ele: any) => ele.id === el.promotion)[0],
            type: types.filter((ele: any) => ele.id === el.type_enseignement)[0],
            matiere_info: {
                ...matieres.filter((ele: any) => ele.id === el.matiere)[0],
            }
        }]
    })
    return tabs
}

export const listDemandesBourses = (demandes_bourse: any[], students: any[], bourses: any[], demandes_insc: any[], annees: any[]) => {
    let tabs: any[] = []
    demandes_bourse?.forEach((item: any) => {
       
        tabs = [...tabs, {
            ...item,
            student: students.filter((el: any) => el.id === item.etudiant).length > 0 ? {
                ...students.filter((el: any) => el.id === item.etudiant)[0],
                info: demandes_insc.filter((el: any) => el.id === students.filter((el: any) => el.id === item.etudiant)[0]?.demande)[0]
            } : null,
            bourse: {
                ...bourses.filter((el: any) => el.id === item.bourse)[0],
                annee: optionifyAnnesScolaire(annees)?.filter((el: any) => el.value === bourses.filter((el: any) => el.id === item.bourse)[0].annee_universitaire)[0]?.name
            }
        }]
    })

    return tabs
}

export const listDemandesDirecteurs = (demandes_dm: any[], students: any[], demandes_insc: any[]) => {
    let tabs: any[] = []
    demandes_dm?.forEach((item: any) => {
       
        tabs = [...tabs, {
            ...item,
            student: students.filter((el: any) => el.id === item.etudiant).length > 0 ? {
                ...students.filter((el: any) => el.id === item.etudiant)[0],
                info: demandes_insc.filter((el: any) => el.id === students.filter((el: any) => el.id === item.etudiant)[0]?.demande)[0]
            } : null,
            
        }]
    })

    return tabs
}

export const listMentorats = (mentorats: any[], students: any[], mentors: any[], demandes_insc: any[], demandes: any[]) => {
    let tabs: any[] = []
    mentorats?.forEach((item: any) => {
        tabs = [...tabs, {
            ...item,
            student: students.filter((el: any) => el.id === item.etudiant).length > 0 ? {
                ...students.filter((el: any) => el.id === item.etudiant)[0],
                info: demandes_insc.filter((el: any) => students.filter((el: any) => el.id === item.etudiant)[0]?.demande ? el.id === students.filter((el: any) => el.id === item.etudiant)[0]?.demande : true)[0]
            } : null,
            mentor: mentors.filter((el: any) => el.id === item.mentor).length > 0 ? {
                ...mentors.filter((el: any) => el.id === item.mentor)[0],
                info: demandes.filter((el: any) => el.id === mentors.filter((el: any) => el.id === item.mentor)[0]?.demande)[0]
            } : null,
        }]
    })

    return tabs
}

export const listStages = (stages: any[], students: any[], teachers: any[], demandes_insc: any[], demandes: any[]) => {
    let tabs: any[] = []
    stages?.forEach((item: any) => {
        tabs = [...tabs, {
            ...item,
            student: students.filter((el: any) => el.id === item.etudiant).length > 0 ? {
                ...students.filter((el: any) => el.id === item.etudiant)[0],
                info: demandes_insc.filter((el: any) => students.filter((el: any) => el.id === item.etudiant)[0]?.demande ? el.id === students.filter((el: any) => el.id === item.etudiant)[0]?.demande : true)[0]
            } : null,
            teacher: teachers.filter((el: any) => el.id === item.enseignant).length > 0 ? {
                ...teachers.filter((el: any) => el.id === item.enseignant)[0],
                info: demandes.filter((el: any) => el.id === teachers.filter((el: any) => el.id === item.enseignant)[0]?.demande)[0]
            } : null,
        }]
    })

    return tabs
}


export const listSeance = (seances: any[], enseignements: any[]) => {
    let tabs: any[] = []

    seances?.forEach((el: any) => {
        tabs = [...tabs, {
            ...el,
            enseignement_info: enseignements.filter((ele: any) => ele.id === el.enseignement)[0],
            
        }]
    })
    
    return tabs
}

export const listDemandeCites = (chambres: any[], students: any[], demandes_insc: any[], demandes: any[]) => {
    let tabs: any[] = []
    demandes?.forEach((item: any) => {
        tabs = [...tabs, {
            ...item,
            student: students.filter((el: any) => el.id === item.etudiant).length > 0 ? {
                ...students.filter((el: any) => el.id === item.etudiant)[0],
                info: demandes_insc.filter((el: any) => students.filter((el: any) => el.id === item.etudiant)[0]?.demande ? el.id === students.filter((el: any) => el.id === item.etudiant)[0]?.demande : true)[0]
            } : null,
            chambre: chambres.filter((el: any) => el.id === item.type_chambre)[0]
            
        }]
    })
    console.log(tabs)
    return tabs
}
