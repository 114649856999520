import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { deleteDemandCite, getDemandCites, reset, setDemandCite, traiterDemandCite } from '../../../../../app/redux/features/student/demand_cite.slice';
import { API_URL2, calculateAge, capitalize, createInitial, creerInitiales, fetchFile, listDemandeCites, optionifyTypeChambre, slugifyFileName } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { getDemandInscriptions, reset as dreset } from '../../../../../app/redux/features/inscription/demand.slice';
import { getStudents, reset as sreset } from '../../../../../app/redux/features/student/student.slice';
import { getChambres, reset as creset } from '../../../../../app/redux/features/student/type_chambre.slice';
import { MainLayout } from '../../../../layout';


const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.demand_cite);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une demande de chambre</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteDemandCite(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const AddModal = ({students, demandes, typeChambres, fnc}:{demandes: any[], students: any[], typeChambres: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    type_chambre: "",
    dure_de_residence: "",
    etudiant: "",
    besoin_speciaux: "",
    exigence_alimentaire:""
  })

  const [fichiers, setFichiers] = useState<any[]>([])

  const [student, setStudent] = useState<any>(null)
  const [search, setSearch] = useState<string>("")

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 4
      if(type < max){
        setImagesBase(file[0], {
          nom_fichier: slugifyFileName(file[0].name),
          type_fichier: `Fichier-${fichiers.length + 1}`
        })
      }else{
        setError({
          field: "global",
          message: "Votre fichier ne doit pas dépasser 8Mo"
        })
        return
      }
    }
  }

  const setImagesBase = (file: any, info: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setFichiers(list => [...list.filter(el => el.image !== reader.result), {...info, fichier: reader.result}])
    }
  }

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.demand_cite);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.type_chambre){
      setError({
        field: "type_chambre",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.dure_de_residence){
      setError({
        field: "dure_de_residence",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.besoin_speciaux){
      setError({
        field: "besoin_speciaux",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.exigence_alimentaire){
      setError({
        field: "exigence_alimentaire",
        message: "Veuillez renseigner ce champs"
      })
      return
    }

    if(!data.etudiant){
      setError({
        field: "global",
        message: "Veuillez choisir un etudiant"
      })
      return
    }
    setCloseM(true)
    dispatch(setDemandCite({...data, fichiers}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setData({
      type_chambre: "",
      dure_de_residence: "",
      etudiant: "",
      besoin_speciaux: "",
      exigence_alimentaire:""
    })
    setFichiers([])
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Demande de chambre</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Les informations ont été enregistrées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }

              <div className='kui-modal-box-group'>
                <div className='kui-modal-box-group__header'> <h3>Etudiant</h3></div>
                {
                  student ? (
                    <div className={`kui-modal-box-list-item`}>
                      <div className='kui-modal-box-list-item__avatar is--rounded'>
                        <span>{createInitial(student?.profile?.prenom + " " + student?.profile?.nom)}</span>
                      </div>
                      <div className='kui-modal-box-list-item__container'>
                        <strong>{student?.profile?.prenom + " " + student?.profile?.nom?.toUpperCase()}</strong>
                        <span>{`Sexe: ${student?.profile?.sexe} - Né(e) le ${student?.profile?.date_de_naissance ? capitalize(format(new Date(student?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${student?.profile?.lieu_de_naissance} - ${student?.profile?.pays_de_naissance}`}</span>
                        <button type='button' className='kui-link' onClick={() => {setStudent(null); setData({...data, etudiant: ""})}}>
                          <span>Changer</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className='kui-modal-box-list'>
                      <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                        <div className='kui-modal-box-list-action-search'>
                          <i className="pi-search-line"></i>
                          <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                      </div>
                      {
                        search?.length > 2 ? (
                          students
                          .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                          .length > 0 ? (
                            <div className='kui-modal-box-list__container'>
                              {
                                students
                                .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .slice(0, 8)
                                .map((item: any, index: number) => (
                                  <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                    setStudent( demandes.filter((el: any) => el.id ===  item.demande)[0])
                                    setData({...data, etudiant: item?.id})
                                  }}>
                                      {
                                        data?.etudiant === item?.id ? (
                                          <div className='kui-modal-box-list-item__check'>
                                            <i className="pi-minimize-line"></i>
                                          </div>
                                        ) : null
                                      }
                                      <div className='kui-modal-box-list-item__avatar is--rounded'>
                                        
                                        <span>{creerInitiales(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.nom)}</span>
                                      </div>
                                      <div className='kui-modal-box-list-item__container'>
                                        <span>Code: {item?.code_etudiant}</span>
                                        <strong>{demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0].profile?.nom?.toUpperCase()}</strong>
                                        <span>{`Sexe: ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.sexe} - Né(e) le ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance ? capitalize(format(new Date(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.lieu_de_naissance} - ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.pays_de_naissance}`}</span>
                                      </div>
                                  </button>
                                ))      
                              }
                            
                            </div>
                          ) : (
                            <span>Aucun element trouvé</span>
                          )
                        ) : (
                          <span>Rechercher</span>
                        )
                        
                      }
                    </div>
                  )
                }
              </div>
            
              <ModalForm tabsField={[
                {
                  field: "textfield",
                  field_type: "number",
                  field_require: true,
                  value: data.dure_de_residence,
                  label: "Durée de residence (en mois)",
                  id: "dure_de_residence",
                  onChange: (val: any) => setData({...data, dure_de_residence: val}),
                  error: error,
                  placeholder: "Entrer la date de residence",
                  isShow: true
                },
                {
                  field: "selectfield",
                  field_type: "no-multiple",
                  field_require: true,
                  value: data.type_chambre,
                  label: "Type de chambre",
                  id: "type_chambre",
                  onChange: (val: any) => setData({...data, type_chambre: val}),
                  error: error,
                  tabs: typeChambres,
                  placeholder: "Choisir le type de chambre",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "textarea",
                  field_require: true,
                  value: data.besoin_speciaux,
                  label: "Besoins speciaux",
                  id: "besoin_speciaux",
                  onChange: (val: any) => setData({...data, besoin_speciaux: val}),
                  error: error,
                  placeholder: "Decrire ici...",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "textarea",
                  field_require: true,
                  value: data.exigence_alimentaire,
                  label: "Exigence alimentaire",
                  id: "exigence_alimentaire",
                  onChange: (val: any) => setData({...data, exigence_alimentaire: val}),
                  error: error,
                  placeholder: "Decrire ici...",
                  isShow: true
                },
              ]}/>

              <div className='kui-modal-fields'>
                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                  {
                    fichiers?.length > 0 ? (
                      fichiers.map((item: any, index: number) => (
                        <div className='kui-filefield-lil' key={index}>
                          <div className='kui-filefield-lil__container'>
                            <div className='kui-filefield-lil__icon'>
                              <i className="pi-file-Info-line"></i>
                            </div>
                            <div className='kui-filefield-lil__info'>
                              <span>{item?.type_fichier}</span>
                              <strong>{item?.nom_fichier}</strong>
                            </div>
                            <div className='kui-filefield-lil__action'>
                              <button className='kui-link is--danger' type='button' onClick={() => {
                                setFichiers(fichiers.filter((el: any) => el !== item))
                              }}>
                                <span>Supprimer</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <strong>Aucun fichier</strong>
                    )
                  }
                  <div className='kui-filefield-lil'>
                    <input type="file" multiple={false} id='fichier' onChange={handleFileChangeFile} />
                    
                    <label className='kui-filefield-lil__container' htmlFor="fichier">
                      <div className='kui-filefield-lil__icon'>
                        <i className="pi-file-Info-line"></i>
                      </div>
                      <div className='kui-filefield-lil__info'>
                        <strong>{`Ajouter un fichier`}</strong>
                      </div>
                    </label>
                      
                  </div>
                </Grid>
              </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}

const DecisionModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    statut: item.statut,
    numero_demande: item.numero_demande,
    decision: item.decision
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isEditLoading, isEditError, isEditSuccess, message } = useAppSelector((state) => state.demand_cite);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data?.statut){
      setError({
        field: "global",
        message: "Veuillez choisir une decision"
      })
      return
    }else{
      if(data.statut === "en_attente"){
        setError({
          field: "global",
          message: "Veuillez choisir une decision"
        })
        return
      }
    }
    if(data.statut === "rejetee"){
      if(!data?.decision){
        setError({
          field: "decision",
          message: "Veuillez renseigner ce champs"
        })
        return
      }
    }
    
    setError(null)
    setCloseM(true)
    dispatch(traiterDemandCite({
      ...data,
    }))
  }

  const closeModal = () => {
    setData(null)
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-decision'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Traitement de la demande</h2>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Traitement réussi avec succès</h1>
                <p>
                  La demande a été traitée.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }

            <div className='kui-modal-box__grid kui-modal-box-grid'>
              <button type='button' className={`kui-modal-box-grid-item ${data?.statut === "acceptee" ? "is--active as--success" : ""}`} onClick={() => {
                setData({...data, statut: "acceptee"})
              }}>
                <i className="pi-check-circle-line"></i>
                <span>Accepter la demande</span>
              </button>
              <button type='button' className={`kui-modal-box-grid-item ${data?.statut === "rejetee" ? "is--active as--danger" : ""}`} onClick={() => {
                setData({...data, statut: "rejetee"})
              }}>
                <i className="pi-forbid-circle-line"></i>
                <span>Rejeter la demande</span>
              </button>
            </div>

            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "textarea",
                field_require: true,
                value: data.decision,
                label: "Observation",
                id: "decision",
                onChange: (val: any) => setData({...data, decision: val}),
                error: error,
                placeholder: "Ecrire ici",
                isShow: data.statut === "rejetee"
              },
            ]}/>
                 
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-save-2-line", 
            type: "submit", 
            label: isEditLoading ? "Traitement en cours" : "Enregistrer la decision", 
            style:"is--primary", 
            active: !isEditLoading,
            show:  (closeM && isEditSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

export const CitePage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.cites}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, demand_cites, message } = useAppSelector((state) => state.demand_cite);
  const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, students, message: sMessage } = useAppSelector((state) => state.student);
  const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demandes_inscription, message: dMessage } = useAppSelector((state) => state.demande_inscription);
  const { isLoading: isScLoading, isError: isScError, isSuccess: isScSuccess, chambres, message: scMessage } = useAppSelector((state) => state.chambre);
    
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  const [showItemModal, setShowItemModal] = useState<boolean>(false)

  const [addModal, setAddModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.cites+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
    
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.cites+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }
  
  useEffect(() => {
    dispatch(getDemandCites({}))
    dispatch(getDemandInscriptions({}))
    dispatch(getStudents({}))
    dispatch(getChambres({}))
    return () => {
      dispatch(reset())
      dispatch(dreset())
      dispatch(sreset())
      dispatch(creset())
    }
  }, [dispatch])

  return (
    <MainLayout title="Demandes de cités" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
              <i className="pi-home-6-line"></i>
              <span>Accueil</span>
            </Link>
            <span> | </span>
            <p>Demandes de chambres</p>
        </div>
        {
          isLoading || isSLoading || isDLoading || isScLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Demandes de chambres</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                    <div className='kui-page-list-actions-display'>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.cites+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-list-check-line"></i>
                        </button>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.cites+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-layout-grid-line"></i>
                        </button>
                    </div>
                    <div className='kui-page-list-actions-group'>
                        <div className='kui-page-list-actions-input'>
                        <span>Nombre à afficher: </span>
                        <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                        </div>
                        <div className='kui-page-list-actions-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                        </div>
                    </div>
                    <div className='kui-page-list-actions-group'>
                      <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                    </div>
                </div>
              </div>
              {
                isError || isDError || isSError  || isScError ? (
                  <div className='kui-page-error'>
                    <div className='kui-page-error__illustration'>
                      <NotFoundError loop={true} autoplay={true} size={{
                        height: 180,
                        width: 180
                      }}/>
                    </div>
                    <div className='kui-page-error_details'>
                      <p>Oupps! Une erreur est survenue</p>
                      <h1>{isError ? message : (isDError ? dMessage : (isSError ? sMessage : (isScError ? scMessage : (""))))}</h1>
                    </div>
                  </div> 
                ) : (
                  isSuccess && isDSuccess && isSSuccess && isScSuccess && chambres && demand_cites && students  && demandes_inscription ? (
                    <div className='kui-page-list__container'>
                      <span className='kui-page-list__total'>
                        {
                          listDemandeCites(chambres, students, demandes_inscription, demand_cites).filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                          .length > 0 ? `
                          ${listDemandeCites(chambres, students, demandes_inscription, demand_cites).filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                              .length > 1 ? listDemandeCites(chambres, students, demandes_inscription, demand_cites).filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.etudiant?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                              .length + " elements trouvés" : listDemandeCites(chambres, students, demandes_inscription, demand_cites).filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .length +" element trouvé"} ` : "Aucun element trouvé"
                        }
                      </span>

                      {
                       listDemandeCites(chambres, students, demandes_inscription, demand_cites)
                       .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                        .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  listDemandeCites(chambres, students, demandes_inscription, demand_cites).filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-item is--md' key={index}>
                                        <div className='kui-page-list-item__min'>
                                          <div>
                                              <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "acceptee" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                              {item.statut?.toUpperCase()}
                                              </span>
                                          </div>
                                        </div>
                                        

                                        <div className='kui-page-list-item__container'>
                                            <div className='kui-page-list-item__details'>
                                              <strong>{item.numero_demande}</strong>
                                              <div style={{display: "flex", gap: ".3em"}}>
                                                <span className='kui-tag'>
                                                  {
                                                    `Chambre: ${item.chambre?.libelle_type}`
                                                  }
                                                </span>
                                                <span className='kui-tag'>
                                                  {
                                                    `Durée de sejour: ${item.dure_de_residence} mois`
                                                  }
                                                </span>
                                              </div>
                                              <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.profile?.photo_id ? API_URL2 + item?.student?.info?.profile?.photo_id : ""}/>
                                              
                                            </div>
                                        </div>
                                        <div className='kui-page-list-item__actions'>
                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setShowItemModal(true)
                                            }}>
                                                <i className={"pi-eye-line"}></i>
                                                <span>{"Apercu"}</span>
                                            </button>
                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setDeleteModal(true)
                                            }}>
                                                <i className={"pi-delete-2-line"}></i>
                                                <span>{"Supprimer"}</span>
                                            </button>
                                        </div>
                                    </div>
                                  ))        
                                }
                              </Grid>
                            ) : (
                            <Table>
                                <Row rtype='header'>
                                    <Column ctype={'small'}>
                                        <strong>#</strong>
                                    </Column>
                                    <Column ctype={'mid'}>
                                        <strong>Numero demande</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                        <strong>Etudiant</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                      <strong>Type Chambres</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                      <strong>Durée de sejour</strong>
                                    </Column>
                                    <Column ctype={'mid'}>
                                      <strong>Statut</strong>
                                    </Column>
                                    <Column ctype={'small'}>
                                      <strong>#</strong>
                                    </Column>
                                </Row>
                                {
                                  listDemandeCites(chambres, students, demandes_inscription, demand_cites).filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                          <strong>{`#${index + 1}`}</strong>
                                      </Column>
                                      <Column ctype={'mid'}>
                                          <div>
                                          <span className='kui-tag'>{item.numero_demande}</span>
                                          </div>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.profile?.photo_id ? API_URL2 + item?.student?.info?.profile?.photo_id : ""}/>
                                      </Column>
                                      <Column ctype={'large'}>
                                          <div>
                                            <span className='kui-tag'>
                                              {
                                                `${item.chambre?.libelle_type}`
                                              }
                                            </span>
                                          </div>
                                      </Column>
                                      <Column ctype={'large'}>
                                          <div>
                                            <span className='kui-tag'>
                                              {
                                                `${item.dure_de_residence} mois`
                                              }
                                            </span>
                                          </div>
                                      </Column>
                                      <Column ctype={'mid'}>
                                          <div>
                                            <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "acceptee" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                            {item.statut?.toUpperCase()}
                                            </span>
                                          </div>
                                      </Column>
                                        <Column ctype={'small'}>
                                            <TableOptions atype={"reduce"} tabs={[
                                              {
                                                icon: "pi-eye-line", 
                                                label: "Aperçu", 
                                                style:"is--neutral", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setShowItemModal(true)
                                                }
                                              },
                                              {
                                                icon: "pi-delete-2-line", 
                                                label: "Supprimer", 
                                                style:"is--danger", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setDeleteModal(true)
                                                }
                                              },
                                            ]}/>
                                        </Column>
                                    </Row>
                                  ))
                                }
                            </Table>
                            )
                        ) : (
                            <div className='kui-page-empty'>
                                <div className='kui-page-empty__illustration'>
                                  <EmptyList loop={true} autoplay={true} size={{
                                      height: (380 / 1.3),
                                      width: (420 / 1.3)
                                  }}/>
                                </div>
                                <div className='kui-page-empty__details'>
                                    <h1>Aucun element trouvé</h1>
                                </div>
                            </div>
                        )
                      }
                    </div>
                  ) : null
                )
              }
              {editModal && selectItemModal ? <DecisionModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)} /> : null}

              {addModal && chambres && students && demandes_inscription ? (
                <AddModal 
                  demandes={demandes_inscription} 
                  students={students} 
                  typeChambres={optionifyTypeChambre(chambres)} 
                  fnc={(val?: boolean) => setAddModal(val || false)}/>
              ) : null}

              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

              {showItemModal && selectItemModal ? (
                <Modal size={'is--full-page'} withClose={true} id={'show-category'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                    <div className='kui-modal-box__header'>
                        <h2>Aperçue d'une demande</h2>
                    </div>
    
                    <div className='kui-modal-full-page'>
                      <div className='kui-modal-full-page__container'>
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__illustration kui-modal-full-page-illustration is--left'>
                              <div className='kui-modal-full-page-illustration__container is--square'>
                                {
                                  selectItemModal?.student?.info?.profile?.photo_id ? (
                                    ""
                                  ) : (
                                    <span>{creerInitiales(selectItemModal?.student?.info?.profile?.nom + " "+selectItemModal?.student?.info?.profile?.prenom)}</span>
                                  )
                                }
                              </div>
                            </div>
                            
                            <div className='kui-modal-full-page-details'>
                              <h1>{selectItemModal?.student?.info?.profile?.nom?.toUpperCase() + " "+selectItemModal?.student?.info?.profile?.prenom}</h1>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Sexe: <strong>{selectItemModal?.student?.info?.profile?.sexe?.toUpperCase()}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Email: <strong>{selectItemModal?.student?.info?.email}</strong> </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Telephone: <strong>{selectItemModal?.student?.info?.profile?.telephone || "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Nationalité: <strong>{selectItemModal?.student?.info?.profile?.nationalite || "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Date de naissance: <strong>{selectItemModal?.student?.info?.profile?.date_de_naissance ? `${capitalize(format(new Date(selectItemModal?.student?.info?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr}))} (${calculateAge(selectItemModal?.student?.info?.profile?.date_de_naissance)} ans)` : "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Lieu et Pays de naissance: <strong>{(selectItemModal?.student?.info?.profile?.lieu_de_naissance || "ND") + " - " + (selectItemModal?.student?.info?.profile?.pays_de_naissance || "ND")}</strong> </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Adresse et Pays de residence: <strong>{(selectItemModal?.student?.info?.profile?.adresse || "ND")+ " - " + (selectItemModal?.student?.info?.profile?.ville || "ND")+ " - " + (selectItemModal?.student?.info?.profile?.pays_de_residence || "ND")}</strong> </Grid>
                            </div>
                          </div>
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__list kui-modal-full-page-list'>
                              <h2>Informations de la demande</h2>

                              <div className='kui-modal-full-page-list__container'>
                                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>

                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Numero de la demande: <strong>{selectItemModal?.numero_demande}</strong> 
                                  </Grid>
                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Date de la demande: <strong>{selectItemModal?.date_demande ? `${capitalize(format(new Date(selectItemModal?.date_demande), "EEEE d MMMM yyyy", {locale: fr}))}` : "ND"}</strong> 
                                  </Grid>
                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Type de chambre: <strong>{selectItemModal?.chambre?.libelle_type}</strong> 
                                  </Grid>
                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Durée de sejour: <strong>{selectItemModal?.dure_de_residence + " mois"}</strong> 
                                  </Grid>
                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Besoins speciaux: <strong>{selectItemModal?.besoin_speciaux}</strong> 
                                  </Grid>
                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Exigence alimentaire: <strong>{selectItemModal?.exigence_alimentaire}</strong> 
                                  </Grid>
                                </Grid>
                              </div>
                             
                            </div>
                          </div>
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__list kui-modal-full-page-list'>
                              <h2>Documents</h2>
                              {
                                selectItemModal?.fichiers?.length > 0 ? (
                                  <div className='kui-modal-full-page-list__container'>
                                    <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                                      {
                                        selectItemModal?.fichiers?.map((item: any, index: number) => (
                                          <div className='kui-modal-full-page-list-item' key={index}>
                                            <div className='kui-modal-full-page-list-item__container'>
                                              <div className='kui-modal-full-page-list-item__illustration'>
                                                  <i className="pi-documents-line"></i>
                                              </div>
                                              <div className='kui-modal-full-page-list-item__text'>
                                                  <strong>{item?.nom_fichier}</strong>
                                                  <span>{`Type: ${item?.type_fichier?.toUpperCase()}`}</span>
                                              </div>
                                              <div>
                                                <Button iconLeftClass='pi-download-2-line' bstyle='is--primary' btype='button' btypo='is--icon-btn' onClick={() => {fetchFile(API_URL2 + item?.fichier)}} label={'Telecharger'}/>
                                              </div>
                                            </div>
                                        </div>
                                        ))
                                      }
                                    </Grid>
                                  </div>
                                ) : (
                                  <div className='kui-modal-empty'>
                                    <EmptyList loop={true} autoplay={true} size={{
                                        height: 190,
                                        width: 290
                                    }}/>
                                    <span>Aucune fichier</span>
                                </div>
                                )
                              }
                            </div>
                          </div>
                      </div>
                    </div>
                    
                    <ModalActions tabsActions={[
                        
                        {
                          icon: "pi-check-circle-line", 
                          type: "button", 
                          label:"Decision", 
                          style:"is--success", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setEditModal(true)
                          },
                          show: selectItemModal.statut === "en_attente"
                        },
                        
                        {
                          icon: "pi-delete-2-line", 
                          type: "button", 
                          label:"Supprimer", 
                          style:"is--danger", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setDeleteModal(true)
                          },
                          show: true
                        },
                        {
                          icon: "pi-close-line", 
                          type: "button", 
                          label:"Fermer", 
                          style:"is--neutral", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                          },
                          show: true
                        },
                    ]}/>
                </Modal>
              ) : null}  
            </>
          )
        }
        
      </div>
    </MainLayout>
  )
}
