import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { deleteSeance, getSeances, reset, setSeance, updateSeance } from '../../../../../app/redux/features/teacher/seance.slice';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { getEnseignements, reset as ereset } from '../../../../../app/redux/features/teacher/enseignement.slice';
import { getDemandeEnseignements, reset as dreset} from '../../../../../app/redux/features/teacher/demande_enseignement.slice';
import { getTeachers, reset as treset } from '../../../../../app/redux/features/teacher/teacher.slice';
import { getTypeEnseignements, reset as tereset } from '../../../../../app/redux/features/teacher/type_enseignement.slice';
import { getPromotions, reset as preset } from '../../../../../app/redux/features/cursus/promotion.slice';
import { getMatieres, reset as mreset } from '../../../../../app/redux/features/cursus/matiere.slice';
import { MainLayout } from '../../../../layout';
import { API_URL2, capitalize, listEnseignement, listSeance } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.seance);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une seance</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteSeance(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const AddModal = ({ enseignements, fnc}:{enseignements: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    date_sceance: "",
    heure_de_debut_sceance: "",
    heure_de_fin_sceance: "",
    description_sceance: "",
    enseignement: ""
  })

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.seance);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [enseignantInfo, setEnseignantInfo] = useState<any>(null)
  const [search, setSearch] = useState<string>("")
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.date_sceance){
      setError({
          field: "date_sceance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    if(!data.heure_de_debut_sceance){
      setError({
          field: "heure_de_debut_sceance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    if(!data.enseignement){
        setError({
            field: "global",
            message: "Veuillez choisir un enseignement"
        })
        return
    }
  
    if(!data.heure_de_fin_sceance){
      setError({
          field: "heure_de_fin_sceance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    setCloseM(true)
    dispatch(setSeance({...data}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setSearch("")
    setEnseignantInfo(null)
    setData({
      date_sceance: "",
      heure_de_debut_sceance: "",
      heure_de_fin_sceance: "",
      description_sceance: "",
      enseignement: ""
    })
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une séance</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Les informations ont été enregistrées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
            <ModalForm tabsField={[
              
              {
                field: "textfield",
                field_type: "date",
                field_require: true,
                value: data.date_sceance,
                label: "Date",
                id: "date_sceance",
                onChange: (val: any) => setData({...data, date_sceance: val}),
                error: error,
                placeholder: "Entrer la date",
                isShow: true
              },

              {
                field: "textfield",
                field_type: "time",
                field_require: true,
                value: data.heure_de_debut_sceance,
                label: "Heure de debut",
                id: "heure_de_debut_sceance",
                onChange: (val: any) => setData({...data, heure_de_debut_sceance: val}),
                error: error,
                placeholder: "Entrer l'heure de debut",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "time",
                field_require: true,
                value: data.heure_de_fin_sceance,
                label: "Heure de fin",
                id: "heure_de_fin_sceance",
                onChange: (val: any) => setData({...data, heure_de_fin_sceance: val}),
                error: error,
                placeholder: "Entrer l'heure de fin",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "textarea",
                field_require: false,
                value: data.description_sceance,
                label: "Description",
                id: "description_sceance",
                onChange: (val: any) => setData({...data, description_sceance: val}),
                error: error,
                placeholder: "Entrer l'heure de fin",
                isShow: true
              },
            ]}/>

            <div className='kui-modal-box-group'>
              <div className='kui-modal-box-group__header'> <h3>Enseignement</h3></div>
              {
                enseignantInfo ? (
                  <div className={`kui-modal-box-list-item`}>
                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                      <i className="pi-school-line"></i>
                    </div>
                    <div className='kui-modal-box-list-item__container'>
                      <span>{enseignantInfo.code_enseignement}</span>
                      <strong>{enseignantInfo?.matiere_info?.intitule + ` (${enseignantInfo?.matiere_info?.code_matiere})`}</strong>
                      <span>Enseignant: {enseignantInfo?.teacher?.info?.profile?.prenom + " " +enseignantInfo?.teacher?.info?.profile?.nom + ` (${enseignantInfo.teacher?.code_enseignant})`}</span>
                     
                      <button type='button' className='kui-link' onClick={() => {setEnseignantInfo(null); setData({...data, enseignement: ""})}}>
                        <span>Changer</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='kui-modal-box-list'>
                    <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                      <div className='kui-modal-box-list-action-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                      </div>
                    </div>
                    {
                      search?.length > 2 ? (
                        enseignements
                        .filter((el: any) => el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                        .length > 0 ? (
                          <div className='kui-modal-box-list__container'>
                            {
                              enseignements
                              .filter((el: any) => el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .slice(0, 8)
                              .map((item: any, index: number) => (
                                <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                  setEnseignantInfo(item)
                                  setData({...data, enseignement: item?.id})
                                }}>
                                    {
                                      data?.enseignant === item?.id ? (
                                        <div className='kui-modal-box-list-item__check'>
                                          <i className="pi-minimize-line"></i>
                                        </div>
                                      ) : null
                                    }
                                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      <i className="pi-school-line"></i>
                                    </div>
                                    <div className='kui-modal-box-list-item__container'>
                                      <span>{item.code_enseignement}</span>
                                      <strong>{item?.matiere_info?.intitule + ` (${item?.matiere_info?.code_matiere})`}</strong>
                                      <span>Enseignant: {item?.teacher?.info?.profile?.prenom + " " +item?.teacher?.info?.profile?.nom + ` (${item.teacher?.code_enseignant})`}</span>
                                    </div>
                                </button>
                              ))      
                            }
                          </div>
                        ) : (
                          <span>Aucun element trouvé</span>
                        )
                      ) : (
                        <span>Rechercher</span>
                      )
                      
                    }
                  </div>
                )
              }
            </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}

const EditModal = ({item, enseignements, fnc}:{item: any, enseignements: any[], fnc: (val: boolean) => void | any}) => {
 
  const [data, setData] = useState<any>({
    date_sceance: item.date_sceance,
    heure_de_debut_sceance: item.heure_de_debut_sceance,
    heure_de_fin_sceance: item.heure_de_fin_sceance,
    description_sceance: item.description_sceance,
    enseignement: item.enseignement,
    statut_sceance: item.statut_sceance
  })

  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditError, isEditLoading, message } = useAppSelector((state) => state.seance);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [enseignantInfo, setEnseignantInfo] = useState<any>(enseignements.filter((el:any) => el.id === item.enseignement)[0])
  const [search, setSearch] = useState<string>("")
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.date_sceance){
      setError({
          field: "date_sceance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    if(!data.heure_de_debut_sceance){
      setError({
          field: "heure_de_debut_sceance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    if(!data.enseignement){
        setError({
            field: "global",
            message: "Veuillez choisir un enseignement"
        })
        return
    }
  
    if(!data.heure_de_fin_sceance){
      setError({
          field: "heure_de_fin_sceance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    setCloseM(true)
    dispatch(updateSeance({id:item.id,data}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setSearch("")
    setEnseignantInfo(null)
    setData({
      date_sceance: "",
      heure_de_debut_sceance: "",
      heure_de_fin_sceance: "",
      description_sceance: "",
      enseignement: ""
    })
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Modification d'une séance</h2>
        <button type='button' className={`kui-chip ${data?.statut_sceance === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
            data?.statut_sceance === "actif" ? setData({...data, statut_sceance: "inactif"}) : setData({...data, statut_sceance: "actif"})
          }}>
             {data?.statut_sceance === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
        </button>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
            <ModalForm tabsField={[
              
              {
                field: "textfield",
                field_type: "date",
                field_require: true,
                value: data.date_sceance,
                label: "Date",
                id: "date_sceance",
                onChange: (val: any) => setData({...data, date_sceance: val}),
                error: error,
                placeholder: "Entrer la date",
                isShow: true
              },

              {
                field: "textfield",
                field_type: "time",
                field_require: true,
                value: data.heure_de_debut_sceance,
                label: "Heure de debut",
                id: "heure_de_debut_sceance",
                onChange: (val: any) => setData({...data, heure_de_debut_sceance: val}),
                error: error,
                placeholder: "Entrer l'heure de debut",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "time",
                field_require: true,
                value: data.heure_de_fin_sceance,
                label: "Heure de fin",
                id: "heure_de_fin_sceance",
                onChange: (val: any) => setData({...data, heure_de_fin_sceance: val}),
                error: error,
                placeholder: "Entrer l'heure de fin",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "textarea",
                field_require: false,
                value: data.description_sceance,
                label: "Description",
                id: "description_sceance",
                onChange: (val: any) => setData({...data, description_sceance: val}),
                error: error,
                placeholder: "Entrer l'heure de fin",
                isShow: true
              },
            ]}/>

            <div className='kui-modal-box-group'>
              <div className='kui-modal-box-group__header'> <h3>Enseignement</h3></div>
              {
                enseignantInfo ? (
                  <div className={`kui-modal-box-list-item`}>
                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                      <i className="pi-school-line"></i>
                    </div>
                    <div className='kui-modal-box-list-item__container'>
                      <span>{enseignantInfo.code_enseignement}</span>
                      <strong>{enseignantInfo?.matiere_info?.intitule + ` (${enseignantInfo?.matiere_info?.code_matiere})`}</strong>
                      <span>Enseignant: {enseignantInfo?.teacher?.info?.profile?.prenom + " " +enseignantInfo?.teacher?.info?.profile?.nom + ` (${enseignantInfo.teacher?.code_enseignant})`}</span>
                     
                      <button type='button' className='kui-link' onClick={() => {setEnseignantInfo(null); setData({...data, enseignement: ""})}}>
                        <span>Changer</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='kui-modal-box-list'>
                    <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                      <div className='kui-modal-box-list-action-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                      </div>
                    </div>
                    {
                      search?.length > 2 ? (
                        enseignements
                        .filter((el: any) => el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                        .length > 0 ? (
                          <div className='kui-modal-box-list__container'>
                            {
                              enseignements
                              .filter((el: any) => el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .slice(0, 8)
                              .map((item: any, index: number) => (
                                <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                  setEnseignantInfo(item)
                                  setData({...data, enseignement: item?.id})
                                }}>
                                    {
                                      data?.enseignant === item?.id ? (
                                        <div className='kui-modal-box-list-item__check'>
                                          <i className="pi-minimize-line"></i>
                                        </div>
                                      ) : null
                                    }
                                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      <i className="pi-school-line"></i>
                                    </div>
                                    <div className='kui-modal-box-list-item__container'>
                                      <span>{item.code_enseignement}</span>
                                      <strong>{item?.matiere_info?.intitule + ` (${item?.matiere_info?.code_matiere})`}</strong>
                                      <span>Enseignant: {item?.teacher?.info?.profile?.prenom + " " +item?.teacher?.info?.profile?.nom + ` (${item.teacher?.code_enseignant})`}</span>
                                    </div>
                                </button>
                              ))      
                            }
                          </div>
                        ) : (
                          <span>Aucun element trouvé</span>
                        )
                      ) : (
                        <span>Rechercher</span>
                      )
                      
                    }
                  </div>
                )
              }
            </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modification en cours" : "Enregistrer les modification", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}


export const SeancePage = () => {

  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.seance}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, seances, message } = useAppSelector((state) => state.seance);
  const { isLoading: isELoading, isError: isEError, isSuccess: isESuccess, enseignements, message: eMessage } = useAppSelector((state) => state.enseignement);
  const { isLoading: isTeLoading, isError:isTeError, isSuccess: isTeSuccess, type_enseignements, message: teMessage } = useAppSelector((state) => state.type_enseignement);
  const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demande_enseignements, message: dMessage } = useAppSelector((state) => state.demande_enseignement);
  const { isLoading: isTLoading, isError: isTError, isSuccess: isTSuccess, teachers, message: tMessage } = useAppSelector((state) => state.teacher);
  const { isLoading: isPLoading, isError: isPError, isSuccess: isPSuccess, promotions, message: pMessage } = useAppSelector((state) => state.promotion);
  const { isLoading: isMLoading, isError: isMError, isSuccess: isMSuccess, matieres, message: mMessage } = useAppSelector((state) => state.matiere);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  const [showItemModal, setShowItemModal] = useState<boolean>(false)

  const [addModal, setAddModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.seance+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.seance+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getSeances({}))
    dispatch(getEnseignements({}))
    dispatch(getDemandeEnseignements({}))
    dispatch(getTeachers({}))
    dispatch(getTypeEnseignements({}))
    dispatch(getPromotions({}))
    dispatch(getMatieres({}))
    return () => {
      dispatch(reset())
      dispatch(ereset())
      dispatch(dreset())
      dispatch(treset())
      dispatch(tereset())
      dispatch(preset())
      dispatch(mreset())
    }
  }, [dispatch])
  

  return (
    <MainLayout title="Seances" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <p>Seances</p>
        </div>
        {
          isLoading || isDLoading || isELoading || isTLoading || isTeLoading || isPLoading || isMLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Seances</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                  <div className='kui-page-list-actions-display'>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.seance+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-list-check-line"></i>
                    </button>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.seance+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-layout-grid-line"></i>
                    </button>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <div className='kui-page-list-actions-input'>
                      <span>Nombre à afficher: </span>
                      <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                    </div>
                    <div className='kui-page-list-actions-search'>
                      <i className="pi-search-line"></i>
                      <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                    </div>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                    
                  </div>
                </div>
                {
                  isError || isEError || isDError || isTError || isTeError || isPError || isMError  ? (
                    <div className='kui-page-error'>
                      <div className='kui-page-error__illustration'>
                        <NotFoundError loop={true} autoplay={true} size={{
                          height: 180,
                          width: 180
                        }}/>
                      </div>
                      <div className='kui-page-error_details'>
                        <p>Oupps! Une erreur est survenue</p>
                        <h1>{isError ? message : (isDError ? dMessage : (isTError ? tMessage : (isTeError ? teMessage : (isMError ? mMessage : (isEError ? eMessage : (isMError ? mMessage : (isPError ? pMessage : "")))))))}</h1>
                      </div>
                    </div> 
                  ) : (
                    isSuccess && isESuccess && isTeSuccess && isTSuccess && isDSuccess && isMSuccess && isPSuccess && enseignements && promotions && type_enseignements && matieres && teachers && demande_enseignements && seances ? (
                      <div className='kui-page-list__container'>
                        <span className='kui-page-list__total'>
                          {
                            listSeance(seances, listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers))
                            .filter((el: any) => el?.enseignement_info?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                            .length > 0 ? (
                            
                              listSeance(seances, listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers))
                              .filter((el: any) => el?.enseignement_info?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                            .length > 1 ? (
                              
                              listSeance(seances, listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers))
                              .filter((el: any) => el?.enseignement_info?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length + " elements trouvés"
                              ) : (
                              
                                listSeance(seances, listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers))
                                .filter((el: any) => el?.enseignement_info?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length + " element trouvé"
                            )
                          ) : (
                            `Aucun element trouvé`
                          )}
                        </span>
                        {
                          
                          listSeance(seances, listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers))
                            .filter((el: any) => el?.enseignement_info?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                          .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  
                                  listSeance(seances, listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers))
                                  .filter((el: any) => el?.enseignement_info?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-item is--md' key={index}>
                                      <div className='kui-page-list-item__min'>
                                        
                                        <div>
                                          <span className='kui-tag'>
                                          {item?.type?.intitule_type_enseignement}
                                          </span>
                                        </div>
                                      </div>
                                      <div className='kui-page-list-item__container'>
                                        <div className='kui-page-list-item__details'>
                                          <strong className='kui-page-list-item__title'>
                                          {item?.enseignement_info.matiere_info?.intitule + ` (${item?.enseignement_info?.matiere_info?.code_matiere})`}
                                          </strong>
                                          <div style={{
                                            display: "flex"
                                          }}>
                                            <span className='kui-tag'>
                                              {`Date: `+(item?.date_sceance ? capitalize(format(new Date(item.date_sceance), "EEEE d MMMM yyyy", {locale: fr})) : "ND")}
                                            </span>
                                          </div>
                                          <p>{`De ${item.heure_de_debut_sceance} à ${item.heure_de_fin_sceance}`}</p>

                                          <p>Enseignant:</p>
                                          <TableItem title={item?.enseignement_info?.teacher?.info?.profile?.prenom + " " +item?.enseignement_info?.teacher?.info?.profile?.nom} subtitle={`${item.enseignement_info?.teacher?.code_enseignant}`} image={item?.enseignement_info?.teacher?.info?.profile?.photo_id ? API_URL2 + item?.enseignement_info?.teacher?.info?.profile?.photo_id : ""}/>
                                          
                                        </div>
                                      </div>
                                      <div className='kui-page-list-item__actions'>
                                        <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setShowItemModal(true)
                                        }}>
                                          <i className={"pi-eye-line"}></i>
                                          <span>{"Apercu"}</span>
                                        </button>
                                        <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setEditModal(true)
                                        }}>
                                          <i className={"pi-edit-3-line"}></i>
                                          <span>{"Modifier"}</span>
                                        </button>
                                        <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setDeleteModal(true)
                                        }}>
                                          <i className={"pi-delete-2-line"}></i>
                                          <span>{"Supprimer"}</span>
                                        </button>
                                      </div>
                                    </div>
                                  ))        
                                }
                              </Grid>
                            ) : (
                              <Table>
                                <Row rtype='header'>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Enseignant</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Matiere</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Date</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Heure</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Statut</strong>
                                  </Column>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                </Row>
                                {
                                  listSeance(seances, listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers))
                                  .filter((el: any) => el?.enseignement_info?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.teacher?.code_enseignant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.matiere_info?.code_matiere?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.enseignement_info?.code_enseignement?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                        <strong>{index+1}</strong>
                                      </Column>
                                      <Column ctype={'large'}>
                                      <TableItem title={item?.enseignement_info?.teacher?.info?.profile?.prenom + " " +item?.enseignement_info?.teacher?.info?.profile?.nom} subtitle={`${item.enseignement_info?.teacher?.code_enseignant}`} image={item?.enseignement_info?.teacher?.info?.profile?.photo_id ? API_URL2 + item?.enseignement_info?.teacher?.info?.profile?.photo_id : ""}/>

                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className='kui-tag'>
                                            {item?.enseignement_info?.matiere_info?.intitule + ` (${item?.enseignement_info?.matiere_info?.code_matiere})`}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <p>{(item?.date_sceance ? capitalize(format(new Date(item.date_sceance), "EEEE d MMMM yyyy", {locale: fr})) : "ND")}</p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <p>{`De ${item.heure_de_debut_sceance} à ${item.heure_de_fin_sceance}`}</p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className={`kui-chip ${item?.statut_sceance === "actif" ? "is--success" : " is--warning"}`}>
                                            {item?.statut_sceance?.toUpperCase()}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'small'}>
                                        <TableOptions atype={"reduce"} tabs={[
                                          {
                                            icon: "pi-eye-line", 
                                            label: "Aperçu", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setShowItemModal(true)
                                              setSelectItemModal(item)
                                            }
                                          },
                                          {
                                            icon: "pi-edit-3-line", 
                                            label: "Modifier", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setSelectItemModal(item)
                                              setEditModal(true)
                                            }
                                          },
                                          {
                                            icon: "pi-delete-2-line", 
                                            label: "Supprimer", 
                                            style:"is--danger", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setSelectItemModal(item)
                                              setDeleteModal(true)
                                            }
                                          },
                                        ]}/>
                                      </Column>
                                    </Row>
                                  ))
                                }
                              </Table>
                            )
                          ) : (
                            <div className='kui-page-empty'>
                              <div className='kui-page-empty__illustration'>
                                <EmptyList loop={true} autoplay={true} size={{
                                  height: (380 / 1.3),
                                  width: (420 / 1.3)
                                }}/>
                              </div>
                              <div className='kui-page-empty__details'>
                                <h1>Aucun element trouvé</h1>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ) : null
                  )
                }
              </div>
              {addModal && matieres && teachers && type_enseignements && promotions && demande_enseignements && enseignements ? (
                <AddModal fnc={(val?: boolean) => setAddModal(val || false)} 
                  enseignements={listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers)} 
                />
              ) : null}

              {editModal && matieres && teachers && type_enseignements && promotions && demande_enseignements  && selectItemModal  && enseignements  ? (
                <EditModal 
                  item={selectItemModal} 
                  fnc={(val?: boolean) => setEditModal(val || false)} 
                  enseignements={ listEnseignement(enseignements, type_enseignements, matieres, promotions, demande_enseignements, teachers)} 
                />
              ) : null}
              
              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}
              
              {showItemModal && selectItemModal ? (
                <Modal size={'is--md'} withClose={true} id={'show-birth'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                  
                  <div className='kui-modal-box__header'>
                    <h2>Aperçue d'une seance</h2>
                  </div>

                  <div className="kui-modal-show">
                    <div className='kui-modal-show__illustration'>
                      <i className="pi-school-line"></i>
                    </div>
                    <div className='kui-modal-show__container'>
                      <h1>{selectItemModal?.enseignement_info?.matiere_info?.intitule + ` (${selectItemModal?.enseignement_info?.matiere_info?.code_matiere})`}</h1>
                      <div className='kui-modal-show__category'>
                        <span className='kui-tag'>
                          {(selectItemModal?.date_sceance ? capitalize(format(new Date(selectItemModal.date_sceance), "EEEE d MMMM yyyy", {locale: fr})) : "ND")}
                        </span>
                      </div>
                      <p>{`De ${selectItemModal.heure_de_debut_sceance} à ${selectItemModal.heure_de_fin_sceance}`}</p>
                      <div className='kui-modal-show__user'>
                        <p>Enseignant:</p>
                        <TableItem title={selectItemModal?.enseignement_info?.teacher?.info?.profile?.prenom + " " +selectItemModal?.enseignement_info?.teacher?.info?.profile?.nom} subtitle={`${selectItemModal?.enseignement_info?.teacher?.code_enseignant}`} image={selectItemModal?.enseignement_info?.teacher?.info?.profile?.photo_id ? API_URL2 + selectItemModal?.enseignement_info?.teacher?.info?.profile?.photo_id : ""}/>
                      </div>
                      
                    </div>
                  </div>

                  <ModalActions tabsActions={[
                    {
                      icon: "pi-edit-3-line", 
                      type: "button", 
                      label:"Modifier", 
                      style:"is--primary", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setEditModal(true)
                      },
                      show: true
                    
                    },
                    {
                      icon: "pi-delete-2-line", 
                      type: "button", 
                      label:"Supprimer", 
                      style:"is--danger", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setDeleteModal(true)
                      },
                      show: true
                    },
                  ]}/>
                </Modal>
              ) : null}

            </>
          )
        }
      </div>
    </MainLayout>
  )
}
