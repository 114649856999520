import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableOptions } from '../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { deleteBourse, getBourses, reset, setBourse, updateBourse } from '../../../../../app/redux/features/bourse/bourse.slice';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { API_URL2, fetchFile, optionifyAnnesScolaire, slugifyFileName } from '../../../../../app/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { reset as ereset, getSchoolYears } from '../../../../../app/redux/features/school_year/school_year.slice';
import { MainLayout } from '../../../../layout';

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.ressource_enseignement);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une bourse</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteBourse(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const AddModal = ({annees, fnc}:{annees: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    intitule: "",
    description: "",
    montant: "",
    brochure: "",
    annee_universitaire: ""
  })

  const [brochure, setBrochure] = useState<any>(null)

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 4
      if(type < max){
        setImagesBase(file[0], {
          nom_fichier: slugifyFileName(file[0].name),
          type_fichier: e.target?.id
        })
      }else{
        setError({
          field: "global",
          message: "Votre fichier ne doit pas dépasser 8Mo"
        })
        return
      }
    }
  }

  const setImagesBase = (file: any, info: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setBrochure({...info, fichier: reader.result})
      setData({...data, brochure: reader.result})
    }
  }

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.bourse);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
        field: "intitule",
        message: "Veuillez renseigner ce champs"
      })
      return
    }


    if(!data.description){
      setError({
        field: "description",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.montant){
      setError({
        field: "montant",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.annee_universitaire){
      setError({
        field: "annee_universitaire",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    setCloseM(true)
    dispatch(setBourse({...data}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setData({
      intitule: "",
      description: "",
      montant: "",
      annee_universitaire: ""
    })
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une bourse</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Les informations ont été enregistrées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
              <ModalForm tabsField={[
                
                {
                  field: "textfield",
                  field_type: "text",
                  field_require: true,
                  value: data.intitule,
                  label: "Intitule",
                  id: "intitule",
                  onChange: (val: any) => setData({...data, intitule: val}),
                  error: error,
                  placeholder: "Entrer l'intitulé",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "number",
                  field_require: true,
                  value: data.montant,
                  label: "Montant",
                  id: "montant",

                  onChange: (val: any) => setData({...data, montant: val}),
                  error: error,
                  placeholder: "Entrer le montant",
                  isShow: true
                },
                {
                  field: "selectfield",
                  field_type: "no-multiple",
                  field_require: true,
                  value: data.annee_universitaire,
                  label: "Année universitaire",
                  id: "annee_universitaire",
                  onChange: (val: any) => setData({...data, annee_universitaire: val}),
                  error: error,
                  tabs: optionifyAnnesScolaire(annees),
                  placeholder: "Entrer l'année université",
                  isShow: true
                },

                
                {
                  field: "textfield",
                  field_type: "textarea",
                  field_require: true,
                  value: data.description,
                  label: "Description",
                  id: "description",
                  onChange: (val: any) => setData({...data, description: val}),
                  error: error,
                  placeholder: "Decrire ...",
                  isShow: true
                },

              ]}/>
              <div className='kui-modal-fields'>
                <div className='kui-filefield-lil'>
                  <input type="file" multiple={false} id='brochure' onChange={handleFileChangeFile} />
                  {
                    brochure ? (
                      <div className='kui-filefield-lil__container'>
                        <div className='kui-filefield-lil__icon'>
                          <i className="pi-file-Info-line"></i>
                        </div>
                        <div className='kui-filefield-lil__info'>
                          <span>{brochure?.type_fichier}</span>
                          <strong>{brochure?.nom_fichier}</strong>
                        </div>
                        <div className='kui-filefield-lil__action'>
                          <button className='kui-link is--danger' type='button' onClick={() => {
                            setBrochure(null)
                            setData({...data, brochure: ""})
                          }}>
                            <span>Supprimer</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <label className='kui-filefield-lil__container' htmlFor="brochure">
                        <div className='kui-filefield-lil__icon'>
                          <i className="pi-file-Info-line"></i>
                        </div>
                        <div className='kui-filefield-lil__info'>
                          <strong>{`Ajouter une brochure`}</strong>
                        </div>
                      </label>
                    )
                  }
                </div>
              </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}

const EditModal = ({item, annees, fnc}:{item: any, annees: any[], fnc: (val: boolean) => void | any}) => {
 
  const [data, setData] = useState<any>({
    intitule: item?.libelle || "",
    description: item?.description ||  "",
    montant: item?.montant ||  "",
    annee_universitaire: item?.annee_universitaire || "",
    statut: item.statut
  })

 

  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditError, isEditLoading, message } = useAppSelector((state) => state.bourse);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    if(!data.description){
      setError({
        field: "description",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.montant){
      setError({
        field: "montant",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.annee_universitaire){
      setError({
        field: "annee_universitaire",
        message: "Veuillez renseigner ce champs"
      })
      return
    }

    setCloseM(true)
    dispatch(updateBourse({id:item.id,data}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setData({
      intitule: "",
      description: "",
      montant: "",
      annee_universitaire: ""
    })
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Modification d'une bourse</h2>
        <button type='button' className={`kui-chip ${data?.statut_sceance === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
            data?.statut_sceance === "actif" ? setData({...data, statut_sceance: "inactif"}) : setData({...data, statut_sceance: "actif"})
          }}>
             {data?.statut_sceance === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
        </button>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule,
                label: "Intitule",
                id: "intitule",
                onChange: (val: any) => setData({...data, intitule: val}),
                error: error,
                placeholder: "Entrer l'intitulé",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "number",
                field_require: true,
                value: data.montant,
                label: "Montant",
                id: "montant",

                onChange: (val: any) => setData({...data, montant: val}),
                error: error,
                placeholder: "Entrer le montant",
                isShow: true
              },
              {
                field: "selectfield",
                field_type: "no-multiple",
                field_require: true,
                value: data.annee_universitaire,
                label: "Année universitaire",
                id: "annee_universitaire",
                onChange: (val: any) => setData({...data, annee_universitaire: val}),
                error: error,
                tabs: optionifyAnnesScolaire(annees),
                placeholder: "Entrer l'année université",
                isShow: true
              },

              
              {
                field: "textfield",
                field_type: "textarea",
                field_require: true,
                value: data.description,
                label: "Description",
                id: "description",
                onChange: (val: any) => setData({...data, description: val}),
                error: error,
                placeholder: "Decrire ...",
                isShow: true
              },
            ]}/>
              
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modification en cours" : "Enregistrer les modification", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}

export const BoursePage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.bourses}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, bourses, message } = useAppSelector((state) => state.bourse);
  const { isLoading: isELoading, isError: isEError, isSuccess: isESuccess, school_years, message: eMessage } = useAppSelector((state) => state.school_year);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')

  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  const [showItemModal, setShowItemModal] = useState<boolean>(false)

  const [addModal, setAddModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.bourses+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.bourses+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getBourses({}))
    dispatch(getSchoolYears({}))
    return () => {
      dispatch(reset())
      dispatch(ereset())
    }
  }, [dispatch])
  

  return (
    <MainLayout title="Bourses" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <p>Bourses</p>
        </div>
        {
          isLoading ||  isELoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Bourses</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                  <div className='kui-page-list-actions-display'>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.bourses+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-list-check-line"></i>
                    </button>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.bourses+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-layout-grid-line"></i>
                    </button>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <div className='kui-page-list-actions-input'>
                      <span>Nombre à afficher: </span>
                      <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                    </div>
                    <div className='kui-page-list-actions-search'>
                      <i className="pi-search-line"></i>
                      <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                    </div>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                    
                  </div>
                </div>
                {
                  isError || isEError   ? (
                    <div className='kui-page-error'>
                      <div className='kui-page-error__illustration'>
                        <NotFoundError loop={true} autoplay={true} size={{
                          height: 180,
                          width: 180
                        }}/>
                      </div>
                      <div className='kui-page-error_details'>
                        <p>Oupps! Une erreur est survenue</p>
                        <h1>{isError ? message : (isEError ? eMessage : "")}</h1>
                      </div>
                    </div> 
                  ) : (
                    isSuccess && isESuccess &&  school_years && bourses ? (
                      <div className='kui-page-list__container'>
                        <span className='kui-page-list__total'>
                          {
                            bourses
                            .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                            .length > 0 ? (
                            
                              bourses
                              .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                            .length > 1 ? (
                              
                              bourses
                              .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length + " elements trouvés"
                              ) : (
                              
                                bourses
                                .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length + " element trouvé"
                            )
                          ) : (
                            `Aucun element trouvé`
                          )}
                        </span>
                        {
                          
                          bourses
                          .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                          .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  
                                  bourses
                                  .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-item is--md' key={index}>
                                      
                                      <div className='kui-page-list-item__container'>
                                        <div className='kui-page-list-item__details'>
                                          <strong className='kui-page-list-item__title'>
                                          {item?.intitule}
                                          </strong>
                                          <div style={{
                                            display: "flex"
                                          }}>
                                            <span className='kui-tag'>
                                              {`Montant: ${item.montant}`}
                                            </span>
                                          </div>
                                          <p>{`Année universitaire: ${optionifyAnnesScolaire(school_years)?.filter((el: any)=> el.value === item.annee_universitaire)[0]?.name}`}</p>
                                        </div>
                                      </div>
                                      <div className='kui-page-list-item__actions'>
                                        <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setShowItemModal(true)
                                        }}>
                                          <i className={"pi-eye-line"}></i>
                                          <span>{"Apercu"}</span>
                                        </button>
                                        <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setEditModal(true)
                                        }}>
                                          <i className={"pi-edit-3-line"}></i>
                                          <span>{"Modifier"}</span>
                                        </button>
                                        <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setDeleteModal(true)
                                        }}>
                                          <i className={"pi-delete-2-line"}></i>
                                          <span>{"Supprimer"}</span>
                                        </button>
                                      </div>
                                    </div>
                                  ))        
                                }
                              </Grid>
                            ) : (
                              <Table>
                                <Row rtype='header'>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Intitulé</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Montant</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Année universitaire</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Statut</strong>
                                  </Column>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                </Row>
                                {
                                  bourses
                                  .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                        <strong>{index+1}</strong>
                                      </Column>
                                      <Column ctype={'large'}>
                                      <p>
                                        <strong>{item.intitule}</strong>
                                      </p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className='kui-tag'>
                                            {item?.montant}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <p>{`${optionifyAnnesScolaire(school_years)?.filter((el: any)=> el.value === item.annee_universitaire)[0]?.name}`}</p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "actif" ? "is--success" : " is--warning"}`}>
                                            {item?.statut?.toUpperCase()}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'small'}>
                                        <TableOptions atype={"reduce"} tabs={[
                                          {
                                            icon: "pi-eye-line", 
                                            label: "Aperçu", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setShowItemModal(true)
                                              setSelectItemModal(item)
                                            }
                                          },
                                          {
                                            icon: "pi-download-3-line", 
                                            label: "Telecharger la brochure", 
                                            style:"is--secondary", 
                                            onClick: () => {
                                              fetchFile(API_URL2 + item?.brochure)
                                            }
                                          },
                                          {
                                            icon: "pi-edit-3-line", 
                                            label: "Modifier", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setSelectItemModal(item)
                                              setEditModal(true)
                                            }
                                          },
                                          {
                                            icon: "pi-delete-2-line", 
                                            label: "Supprimer", 
                                            style:"is--danger", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setSelectItemModal(item)
                                              setDeleteModal(true)
                                            }
                                          },
                                        ]}/>
                                      </Column>
                                    </Row>
                                  ))
                                }
                              </Table>
                            )
                          ) : (
                            <div className='kui-page-empty'>
                              <div className='kui-page-empty__illustration'>
                                <EmptyList loop={true} autoplay={true} size={{
                                  height: (380 / 1.3),
                                  width: (420 / 1.3)
                                }}/>
                              </div>
                              <div className='kui-page-empty__details'>
                                <h1>Aucun element trouvé</h1>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ) : null
                  )
                }
              </div>
              {addModal && school_years ? (
                <AddModal fnc={(val?: boolean) => setAddModal(val || false)} 
                  annees={school_years} 
                />
              ) : null}

              {editModal && selectItemModal  && school_years  ? (
                <EditModal 
                  item={selectItemModal} 
                  fnc={(val?: boolean) => setEditModal(val || false)} 
                  annees={school_years} 
                />
              ) : null}
              
              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}
              
              {showItemModal && selectItemModal ? (
                <Modal size={'is--md'} withClose={true} id={'show-birth'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                  
                  <div className='kui-modal-box__header'>
                    <h2>Aperçue d'une bourse</h2>
                  </div>

                  <div className="kui-modal-show">
                    <div className='kui-modal-show__illustration'>
                      <i className="pi-school-line"></i>
                    </div>
                    <div className='kui-modal-show__container'>
                      <h1>{selectItemModal?.intitule }</h1>
                      <div className='kui-modal-show__category'>
                        <span className='kui-tag'>
                          {"Montant: "+selectItemModal?.montant}
                        </span>
                      </div>
                      <p>{`${optionifyAnnesScolaire(school_years)?.filter((el: any)=> el.value === selectItemModal.annee_universitaire)[0]?.name}`}</p>
                     
                      <div className='kui-modal-show__description'>
                        <strong>Description:</strong>
                        <p>{selectItemModal.description}</p>
                      </div>
                      
                    </div>
                  </div>

                  <ModalActions tabsActions={[
                    {
                      icon: "pi-download-3-line", 
                      type: "button", 
                      label:"Telecharger la brochure", 
                      style:"is--neutral", 
                      active: true,
                      onClick: () => {
                        fetchFile(API_URL2 + selectItemModal?.brochure)
                      },
                      show: true
                    },
                    {
                      icon: "pi-edit-3-line", 
                      type: "button", 
                      label:"Modifier", 
                      style:"is--primary", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setEditModal(true)
                      },
                      show: true
                    },
                    {
                      icon: "pi-delete-2-line", 
                      type: "button", 
                      label:"Supprimer", 
                      style:"is--danger", 
                      active: true,
                      onClick: () => {
                        setShowItemModal(false)
                        setDeleteModal(true)
                      },
                      show: true
                    },
                  ]}/>
                </Modal>
              ) : null}

            </>
          )
        }
      </div>
    </MainLayout>
  )
}
