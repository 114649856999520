import React, { useContext, useEffect } from 'react'
import { UserAuthContext } from '../../../../context/AuthContext';
import { links } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../layout';
import { LogoIcon } from '../../../assets/illustration';

export const HomePage = () => {
    const { token, info } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
        if(!token){
            navigate(links.login + `?url=${links.home}`)
        }
    }, [token, navigate]);

    return (
        <MainLayout>
            <div className='kui-page is--min'>
                <div className='kui-home'>
                    <div className='kui-home__header kui-home-header'>
                        <div className='kui-home-header__logo'>
                            <LogoIcon/>
                        </div>
                        <div className='kui-home-header__title'>
                            <strong>Salut!</strong>
                            <h1>{info?.profile?.nom?.toUpperCase() + " " + info?.profile?.prenom}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}
