import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { deleteInscription, getInscriptions, reset, setInscription } from '../../../../../app/redux/features/inscription/inscription.slice';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { getStudents } from '../../../../../app/redux/features/student/student.slice';
import { getDemandInscriptions } from '../../../../../app/redux/features/inscription/demand.slice';
import { getPromotions } from '../../../../../app/redux/features/cursus/promotion.slice';
import { capitalize, createInitial, creerInitiales, optionifyPromotion } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { MainLayout } from '../../../../layout';

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.inscription);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une inscription</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteInscription(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const AddModal = ({promotions, students, demandes, fnc}:{demandes: any[], students: any[], promotions: any[], fnc: (val: boolean) => void | any}) => {
  
  const [data, setData] = useState<any>({
    etudiant: "",
    promotion: "",
    date_inscription: "",
  })

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.inscription);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [studentInfo, setStudentInfo] = useState<any>(null)
  const [search, setSearch] = useState<string>("")
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.promotion){
        setError({
            field: "promotion",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    if(!data.etudiant){
        setError({
            field: "global",
            message: "Veuillez choisir un etudiant"
        })
        return
    }
  
  
    if(!data.date_inscription){
      setError({
          field: "date_inscription",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    setCloseM(true)
    dispatch(setInscription({...data}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setData({
      etudiant: "",
      promotion: "",
    })
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une inscription</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Les informations du décès ont été enregistrées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "selectfield",
                field_type: "no-multiple",
                field_require: true,
                value: data.promotion,
                label: "Promotion",
                id: "promotion",
                onChange: (val: any) => setData({...data, promotion: val}),
                error: error,
                tabs: promotions,
                placeholder: "Choisir la promotion (Rechercher à le code de la promotion)",
                isShow: true
              },

              {
                field: "textfield",
                field_type: "date",
                field_require: true,
                value: data.date_inscription,
                label: "Date d'inscription",
                id: "date_inscription",
                onChange: (val: any) => setData({...data, date_inscription: val}),
                error: error,
                placeholder: "Entrer la date d'inscription",
                isShow: true
              },
            ]}/>
            <div className='kui-modal-box-group'>
              <div className='kui-modal-box-group__header'> <h3>Etudiant</h3></div>
              {
                studentInfo ? (
                  <div className={`kui-modal-box-list-item`}>
                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                      <span>{createInitial(studentInfo?.profile?.prenom + " " + studentInfo?.profile?.nom)}</span>
                    </div>
                    <div className='kui-modal-box-list-item__container'>
                      <strong>{studentInfo?.profile?.prenom + " " + studentInfo?.profile?.nom?.toUpperCase()}</strong>
                      <span>{`Sexe: ${studentInfo?.profile?.sexe} - Né(e) le ${studentInfo?.profile?.date_de_naissance ? capitalize(format(new Date(studentInfo?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${studentInfo?.profile?.lieu_de_naissance} - ${studentInfo?.profile?.pays_de_naissance}`}</span>
                      <button type='button' className='kui-link' onClick={() => {setStudentInfo(null); setData({...data, etudiant: ""})}}>
                        <span>Changer</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='kui-modal-box-list'>
                    <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                      <div className='kui-modal-box-list-action-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                      </div>
                    </div>
                    {
                      search?.length > 2 ? (
                        students
                        .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                        .length > 0 ? (
                          <div className='kui-modal-box-list__container'>
                            {
                              students
                              .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .slice(0, 8)
                              .map((item: any, index: number) => (
                                <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                  setStudentInfo( demandes.filter((el: any) => el.id ===  item.demande)[0])
                                  setData({...data, etudiant: item?.id})
                                }}>
                                    {
                                      data?.etudiant === item?.id ? (
                                        <div className='kui-modal-box-list-item__check'>
                                          <i className="pi-minimize-line"></i>
                                        </div>
                                      ) : null
                                    }
                                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      
                                      <span>{creerInitiales(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.nom)}</span>
                                    </div>
                                    <div className='kui-modal-box-list-item__container'>
                                      <span>Code: {item?.code_etudiant}</span>
                                      <strong>{demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0].profile?.nom?.toUpperCase()}</strong>
                                      <span>{`Sexe: ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.sexe} - Né(e) le ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance ? capitalize(format(new Date(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.lieu_de_naissance} - ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.pays_de_naissance}`}</span>
                                    </div>
                                </button>
                              ))      
                            }
                          
                          </div>
                        ) : (
                          <span>Aucun element trouvé</span>
                        )
                      ) : (
                        <span>Rechercher</span>
                      )
                      
                    }
                  </div>
                )
              }
            </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}

const EditModal = ({item, promotions, students, demandes, fnc}:{item: any, demandes: any[], students: any[], promotions: any[], fnc: (val: boolean) => void | any}) => {
  
  const [data, setData] = useState<any>({
    etudiant: "",
    promotion: "",
  })

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.inscription);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [studentInfo, setStudentInfo] = useState<any>(null)
  const [search, setSearch] = useState<string>("")
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      
      if(!data.promotion){
          setError({
              field: "promotion",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
  
      if(!data.etudiant){
          setError({
              field: "global",
              message: "Veuillez choisir un etudiant"
          })
          return
      }
  
    setCloseM(true)
    dispatch(setInscription({...data}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setData({
      etudiant: "",
      promotion: "",
    })
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une inscription</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Les informations du décès ont été enregistrées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "selectfield",
                field_type: "no-multiple",
                field_require: true,
                value: data.promotion,
                label: "Promotion",
                id: "promotion",
                onChange: (val: any) => setData({...data, promotion: val}),
                error: error,
                tabs: promotions,
                placeholder: "Choisir la promotion (Rechercher à le code de la promotion)",
                isShow: true
              },
            ]}/>
            <div className='kui-modal-box-group'>
              <div className='kui-modal-box-group__header'> <h3>Etudiant</h3></div>
              {
                studentInfo ? (
                  <div className={`kui-modal-box-list-item`}>
                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                      <span>{createInitial(studentInfo?.profile?.prenom + " " + studentInfo?.profile?.nom)}</span>
                    </div>
                    <div className='kui-modal-box-list-item__container'>
                      <strong>{studentInfo?.profile?.prenom + " " + studentInfo?.profile?.nom?.toUpperCase()}</strong>
                      <span>{`Sexe: ${studentInfo?.profile?.sexe} - Né(e) le ${studentInfo?.profile?.date_de_naissance ? capitalize(format(new Date(studentInfo?.person?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${studentInfo?.profile?.lieu_de_naissance} ${studentInfo?.profile?.pays_de_naissance}`}</span>
                      <button type='button' className='kui-link' onClick={() => {setStudentInfo(null); setData({...data, etudiant: ""})}}>
                        <span>Changer</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='kui-modal-box-list'>
                    <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                      <div className='kui-modal-box-list-action-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                      </div>
                    </div>
                    {
                      search?.length > 2 ? (
                        students
                        .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                        .length > 0 ? (
                          <div className='kui-modal-box-list__container'>
                            {
                              students
                              .filter((el: any) => (el?.person?.sexe === "masculin"))
                              .filter((el: any) =>  el?.slug?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.person?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.person?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .slice(0, 8)
                              .map((item: any, index: number) => (
                                <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                  setStudentInfo( demandes.filter((el: any) => el.id ===  item.demande)[0])
                                  setData({...data, etudiant: item?.id})
                                }}>
                                    {
                                      data?.etudiant === item?.id ? (
                                        <div className='kui-modal-box-list-item__check'>
                                          <i className="pi-minimize-line"></i>
                                        </div>
                                      ) : null
                                    }
                                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      
                                      <span>{creerInitiales(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.nom)}</span>
                                    </div>
                                    <div className='kui-modal-box-list-item__container'>
                                      <span>Code: {item?.code_etudiant}</span>
                                      <strong>{demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0].profile?.nom?.toUpperCase()}</strong>
                                      <span>{`Sexe: ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.sexe}`}</span>
                                    </div>
                                </button>
                              ))      
                            }
                          
                          </div>
                        ) : (
                          <span>Aucun element trouvé</span>
                        )
                      ) : (
                        <span>Rechercher</span>
                      )
                      
                    }
                  </div>
                )
              }
            </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}


export const InscriptionPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.inscription}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, inscriptions, message } = useAppSelector((state) => state.inscription);
  const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, students, message: sMessage } = useAppSelector((state) => state.student);
  const { isLoading: isPLoading, isError: isPError, isSuccess: isPSuccess, promotions, message: pMessage } = useAppSelector((state) => state.promotion);
  const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demandes_inscription, message: dMessage } = useAppSelector((state) => state.demande_inscription);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  
  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [addModal, setAddModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.demande_inscription+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.demande_inscription+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getInscriptions({}))
    dispatch(getStudents({}))
    dispatch(getDemandInscriptions({}))
    dispatch(getPromotions({}))
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  return (
    <MainLayout title="Inscription" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <p>Inscription</p>
        </div>
        {
          isLoading || isSLoading || isPLoading || isDLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Inscriptions</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                  <div className='kui-page-list-actions-display'>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.inscription+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-list-check-line"></i>
                    </button>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.inscription+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-layout-grid-line"></i>
                    </button>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <div className='kui-page-list-actions-input'>
                      <span>Nombre à afficher: </span>
                      <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                    </div>
                    <div className='kui-page-list-actions-search'>
                      <i className="pi-search-line"></i>
                      <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                    </div>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                    
                  </div>
                </div>
                {
                  isError || isSError || isPError || isDError  ? (
                    <div className='kui-page-error'>
                      <div className='kui-page-error__illustration'>
                        <NotFoundError loop={true} autoplay={true} size={{
                          height: 180,
                          width: 180
                        }}/>
                      </div>
                      <div className='kui-page-error_details'>
                        <p>Oupps! Une erreur est survenue</p>
                        <h1>{isError ? message : (isDError ? dMessage : (isSError ? sMessage : (isPError ? pMessage : (isSError ? sMessage : "" ))))}</h1>
                      </div>
                    </div> 
                  ) : (
                    isSuccess && isSSuccess && isPSuccess && isDSuccess && promotions && inscriptions && demandes_inscription && students  ? (
                      <div className='kui-page-list__container'>
                        <span className='kui-page-list__total'>
                          {inscriptions
                              // .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .length  > 0 ? (
                            inscriptions
                            // .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                            .length  > 1 ? (
                              inscriptions
                              // .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .length + " elements trouvés"
                            ) : (
                              inscriptions
                              // .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .length + " element trouvé"
                            )
                          ) : (
                            `Aucun element trouvé`
                          )}
                        </span>
                        {
                          inscriptions
                          // .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                          .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  inscriptions
                                  // .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-item is--md' key={index}>
                                      
                                      <div className='kui-page-list-item__container'>
                                        <div className='kui-page-list-item__details'>
                                          <strong className='kui-page-list-item__title'>Code promotion {item.id}</strong>
                                        </div>
                                      </div>
                                      <div className='kui-page-list-item__actions'>
                                        <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setShowItemModal(true)
                                        }}>
                                          <i className={"pi-eye-line"}></i>
                                          <span>{"Apercu"}</span>
                                        </button>
                                        <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setEditModal(true)
                                        }}>
                                          <i className={"pi-edit-3-line"}></i>
                                          <span>{"Modifier"}</span>
                                        </button>
                                        <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                          setSelectItemModal(null)
                                          setSelectItemModal(item)
                                          setDeleteModal(true)
                                        }}>
                                          <i className={"pi-delete-2-line"}></i>
                                          <span>{"Supprimer"}</span>
                                        </button>
                                      </div>
                                    </div>
                                  ))        
                                }
                              </Grid>
                            ) : (
                              <Table>
                                <Row rtype='header'>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Code inscription</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Promotion</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Etudiant</strong>
                                  </Column>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                </Row>
                                {
                                  inscriptions
                                  // .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                        <strong>{index + 1}</strong>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.nom + " " + item?.prenom} subtitle={``} withImage={true} image={null}/>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className='kui-tag'>
                                            {item?.promotion?.code_promotion?.toUpperCase()}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.mere?.person?.prenom + " " + item?.mere?.person?.nom} subtitle={`Sexe: ${item?.mere?.person?.sexe} - Né(e) le ${item?.mere?.person?.dateNaissance ? capitalize(format(new Date(item?.mere?.person?.dateNaissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${item?.mere?.person?.lieuNaissance}`} withImage={true} image={null}/>
                                      </Column>
                                      <Column ctype={'small'}>
                                        <TableOptions atype={"reduce"} tabs={[
                                          {
                                            icon: "pi-eye-line", 
                                            label: "Aperçu", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setShowItemModal(true)
                                              setSelectItemModal(item)
                                            }
                                          },
                                          {
                                            icon: "pi-edit-3-line", 
                                            label: "Modifier", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setSelectItemModal(item)
                                              setEditModal(true)
                                            }
                                          },
                                          {
                                            icon: "pi-delete-2-line", 
                                            label: "Supprimer", 
                                            style:"is--danger", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setSelectItemModal(item)
                                              setDeleteModal(true)
                                            }
                                          },
                                        ]}/>
                                      </Column>
                                    </Row>
                                  ))
                                }
                              </Table>
                            )
                          ) : (
                            <div className='kui-page-empty'>
                              <div className='kui-page-empty__illustration'>
                                <EmptyList loop={true} autoplay={true} size={{
                                  height: (380 / 1.3),
                                  width: (420 / 1.3)
                                }}/>
                              </div>
                              <div className='kui-page-empty__details'>
                                <h1>Aucun element trouvé</h1>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ) : null
                  )
                }
              </div>
              {addModal ? <AddModal promotions={optionifyPromotion(promotions)} students={students} demandes={demandes_inscription} fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
              {/* {editModal && selectItemModal  ? <EditModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null} */}
              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}
              
            </>
          )
        }
      </div>
    </MainLayout>
  )
}
