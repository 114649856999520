import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { deleteDemandOrientation, getDemandOrientations, reset, traiterDemandOrientation } from '../../../../../../app/redux/features/student/demand_orientation.slice';
import { links } from '../../../../../routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { MainLayout } from '../../../../../layout';
import { API_URL2, calculateAge, capitalize, creerInitiales, fetchFile } from '../../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale/fr';


const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.demand_orientation);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-enseignement'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une demande de mentorat</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteDemandOrientation(item.numero_demande))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const DecisionModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    statut: item.statut,
    numero_demande: item.numero_demande,
    decision: item.decision
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isEditLoading, isEditError, isEditSuccess, message } = useAppSelector((state) => state.demand_orientation);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data?.statut){
      setError({
        field: "global",
        message: "Veuillez choisir une decision"
      })
      return
    }else{
      if(data.statut === "en_attente"){
        setError({
          field: "global",
          message: "Veuillez choisir une decision"
        })
        return
      }
    }
    if(data.statut === "rejetee"){
      if(!data?.decision){
        setError({
          field: "decision",
          message: "Veuillez renseigner ce champs"
        })
        return
      }
    }
    
    setError(null)
    setCloseM(true)
    dispatch(traiterDemandOrientation({
      ...data,
    }))
  }

  const closeModal = () => {
    setData(null)
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-decision'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Traitement de la demande</h2>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Traitement réussi avec succès</h1>
                <p>
                  La demande a été traitée.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }

            <div className='kui-modal-box__grid kui-modal-box-grid'>
              <button type='button' className={`kui-modal-box-grid-item ${data?.statut === "acceptee" ? "is--active as--success" : ""}`} onClick={() => {
                setData({...data, statut: "acceptee"})
              }}>
                <i className="pi-check-circle-line"></i>
                <span>Accepter la demande</span>
              </button>
              <button type='button' className={`kui-modal-box-grid-item ${data?.statut === "rejetee" ? "is--active as--danger" : ""}`} onClick={() => {
                setData({...data, statut: "rejetee"})
              }}>
                <i className="pi-forbid-circle-line"></i>
                <span>Rejeter la demande</span>
              </button>
            </div>

            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "textarea",
                field_require: true,
                value: data.decision,
                label: "Observation",
                id: "decision",
                onChange: (val: any) => setData({...data, decision: val}),
                error: error,
                placeholder: "Ecrire ici",
                isShow: true
              },
            ]}/>
                 
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-save-2-line", 
            type: "submit", 
            label: isEditLoading ? "Traitement en cours" : "Enregistrer la decision", 
            style:"is--primary", 
            active: !isEditLoading,
            show:  (closeM && isEditSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}


export const OrientationPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.orientation}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, demand_orientations, message } = useAppSelector((state) => state.demand_orientation);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  
  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [addModal, setAddModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.orientation+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
    
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.orientation+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getDemandOrientations({}))
    return () => {
      dispatch(reset())
    }
  }, [dispatch])
  
  return (
    <MainLayout title="Demandes d'orientation" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
                <i className="pi-home-6-line"></i>
                <span>Accueil</span>
            </Link>
            <span> | </span>
            <p>Demandes d'orientation</p>
        </div>
        {
          isLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Demandes d'orientation</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                    <div className='kui-page-list-actions-display'>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.orientation+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-list-check-line"></i>
                        </button>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.orientation+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-layout-grid-line"></i>
                        </button>
                    </div>
                    <div className='kui-page-list-actions-group'>
                        <div className='kui-page-list-actions-input'>
                        <span>Nombre à afficher: </span>
                        <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                        </div>
                        <div className='kui-page-list-actions-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                        </div>
                    </div>
                    <div className='kui-page-list-actions-group'>
                      <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => navigate(links.orientation_cretae)}/>
                    </div>
                </div>
                {
                  isError ? (
                    <div className='kui-page-error'>
                      <div className='kui-page-error__illustration'>
                        <NotFoundError loop={true} autoplay={true} size={{
                          height: 180,
                          width: 180
                        }}/>
                      </div>
                      <div className='kui-page-error_details'>
                        <p>Oupps! Une erreur est survenue</p>
                        <h1>{message}</h1>
                        <button type='button' className='kui-link' onClick={() => window.location.reload()}>
                          <span>Reactualiser</span>
                        </button>
                      </div>
                    </div> 
                  ) : (
                    isSuccess && demand_orientations ? (
                      <div className='kui-page-list__container'>
                        <span className='kui-page-list__total'>
                          {
                            demand_orientations.filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                            .length > 0 ? `
                            ${demand_orientations.filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                .length > 1 ? demand_orientations.filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                .length + " elements trouvés" : demand_orientations.filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .length +" element trouvé"} ` : "Aucun element trouvé"
                          }
                        </span>

                        {
                          demand_orientations
                          .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                          .length > 0 ? (
                              display === "grid" ? (
                                <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                  {
                                    demand_orientations
                                    .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                    .slice(0, itemNumber)
                                    .map((item: any, index: number) => (
                                      <div className='kui-page-list-item is--md' key={index}>
                                          <div className='kui-page-list-item__min'>
                                            <div>
                                                <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "acceptee" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                                {item.statut?.toUpperCase()}
                                                </span>
                                            </div>
                                          </div>
                                          

                                          <div className='kui-page-list-item__container'>
                                              <div className='kui-page-list-item__details'>
                                                <strong>{item.numero_demande}</strong>
                                                <TableItem title={item?.profile?.nom + " "+item?.profile?.prenom} subtitle={item?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.profile?.telephone} withImage={true} image={item?.profile?.photo_id ? API_URL2 + item?.profile?.photo_id : ""}/>
                                                
                                              </div>
                                          </div>
                                          <div className='kui-page-list-item__actions'>
                                              <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setSelectItemModal(true)
                                              }}>
                                                  <i className={"pi-eye-line"}></i>
                                                  <span>{"Apercu"}</span>
                                              </button>
                                              <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setDeleteModal(true)
                                              }}>
                                                  <i className={"pi-delete-2-line"}></i>
                                                  <span>{"Supprimer"}</span>
                                              </button>
                                          </div>
                                      </div>
                                    ))        
                                  }
                                </Grid>
                              ) : (
                              <Table>
                                  <Row rtype='header'>
                                      <Column ctype={'small'}>
                                          <strong>#</strong>
                                      </Column>
                                      <Column ctype={'mid'}>
                                          <strong>Numero demande</strong>
                                      </Column>
                                      <Column ctype={'large'}>
                                          <strong>Demandeur</strong>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <strong>Statut</strong>
                                      </Column>
                                      <Column ctype={'small'}>
                                        <strong>#</strong>
                                      </Column>
                                  </Row>
                                  {
                                    demand_orientations
                                    .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                    .slice(0, itemNumber)
                                    .map((item: any, index: number) => (
                                      <Row key={index}>
                                        <Column ctype={'small'}>
                                          <strong>{`#${index + 1}`}</strong>
                                        </Column>
                                        <Column ctype={'mid'}>
                                          <div>
                                            <span className='kui-tag'>{item.numero_demande}</span>
                                          </div>
                                        </Column>
                                        <Column ctype={'large'}>
                                            <TableItem title={item?.profile?.nom + " "+item?.profile?.prenom} subtitle={item?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.profile?.telephone} withImage={true} image={item?.profile?.photo_id ? API_URL2 + item?.profile?.photo_id : ""}/>
                                        </Column>
                                        <Column ctype={'mid'}>
                                          <div>
                                            <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "acceptee" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                            {item.statut?.toUpperCase()}
                                            </span>
                                          </div>
                                        </Column>
                                          <Column ctype={'small'}>
                                            <TableOptions atype={"reduce"} tabs={[
                                              {
                                                icon: "pi-eye-line", 
                                                label: "Aperçu", 
                                                style:"is--neutral", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setShowItemModal(true)
                                                }
                                              },
                                              {
                                                icon: "pi-delete-2-line", 
                                                label: "Supprimer", 
                                                style:"is--danger", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setDeleteModal(true)
                                                }
                                              },
                                            ]}/>
                                          </Column>
                                      </Row>
                                    ))
                                  }
                              </Table>
                              )
                          ) : (
                              <div className='kui-page-empty'>
                                  <div className='kui-page-empty__illustration'>
                                    <EmptyList loop={true} autoplay={true} size={{
                                        height: (380 / 1.3),
                                        width: (420 / 1.3)
                                    }}/>
                                  </div>
                                  <div className='kui-page-empty__details'>
                                      <h1>Aucun element trouvé</h1>
                                  </div>
                              </div>
                          )
                        }
                      </div>
                    ) : null
                  )
                }
              </div>
              
              {editModal && selectItemModal ? <DecisionModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)} /> : null}

              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

              {showItemModal && selectItemModal ? (
                <Modal size={'is--xl'} withClose={true} id={'show-category'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                    <div className='kui-modal-box__header'>
                        <h2>Aperçue d'une demande</h2>
                    </div>
    
                    <div className='kui-modal-full-page'>
                      <div className='kui-modal-full-page__container'>
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__illustration kui-modal-full-page-illustration is--left'>
                              <div className='kui-modal-full-page-illustration__container is--square'>
                                {
                                  selectItemModal?.profile.photo_id ? (
                                   <img src={selectItemModal?.profile.photo_id} alt="avatar" />
                                  ) : (
                                    <span>{creerInitiales(selectItemModal?.profile?.nom + " "+selectItemModal?.profile?.prenom)}</span>
                                  )
                                }
                              </div>
                            </div>
                            
                            <div className='kui-modal-full-page-details'>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Numero de la demande: <strong>{selectItemModal?.numero_demande}</strong> 
                              </Grid>
                              <h1>{selectItemModal?.profile?.nom?.toUpperCase() + " "+selectItemModal?.profile?.prenom}</h1>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Sexe: <strong>{selectItemModal?.profile?.sexe?.toUpperCase()}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Email: <strong>{selectItemModal?.email}</strong> </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Telephone: <strong>{selectItemModal?.profile?.telephone || "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Nationalité: <strong>{selectItemModal?.profile?.nationalite || "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Date de naissance: <strong>{selectItemModal?.profile?.date_de_naissance ? `${capitalize(format(new Date(selectItemModal?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr}))} (${calculateAge(selectItemModal?.profile?.date_de_naissance)} ans)` : "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Lieu et Pays de naissance: <strong>{(selectItemModal?.profile?.lieu_de_naissance || "ND") + " - " + (selectItemModal?.profile?.pays_de_naissance || "ND")}</strong> </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Adresse et Pays de residence: <strong>{(selectItemModal?.profile?.adresse || "ND")+ " - " + (selectItemModal?.profile?.ville || "ND")+ " - " + (selectItemModal?.profile?.pays_de_residence || "ND")}</strong> </Grid>
                              
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Date de la demande: <strong>{selectItemModal?.date_demande ? `${capitalize(format(new Date(selectItemModal?.date_demande), "EEEE d MMMM yyyy", {locale: fr}))}` : "ND"}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Nom de l'etablissement actuel: <strong>{selectItemModal?.nom_etablissement_actuel}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Niveau d'etude actuel: <strong>{selectItemModal?.niveau_etude_actuel }</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Filiere actuelle: <strong>{selectItemModal?.filiere_actuelle }</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Moyenne generale: <strong>{selectItemModal?.moyenne_generale}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Motivation de poursuite des etudes: <strong>{selectItemModal?.motivation_de_poursuite_des_etudes}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Objectif de carriere: <strong>{selectItemModal?.objectif_de_carriere || "ND"}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Mode d'etude: <strong>{selectItemModal?.mode_etude?.toUpperCase() || "ND"}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Mode d'enseignement: <strong>{selectItemModal?.mode_enseignement?.toUpperCase() || "ND"}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Possibilite de demenagement: <strong>{selectItemModal?.possibilite_de_demenagement ? "OUI" : "NON"}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Contrainte particulière: <strong>{selectItemModal?.contrainte_particuliere || "ND"}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Information pertinente: <strong>{selectItemModal?.information_permanente || "ND"}</strong> 
                              </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                Commentaire supplementaire: <strong>{selectItemModal?.commentaire_supplementaire || "ND"}</strong> 
                              </Grid>
                                
                            </div>
                          </div>
                          
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__list kui-modal-full-page-list'>
                              <h2>Documents</h2>
                              {
                                selectItemModal?.fichiers?.length > 0 ? (
                                  <div className='kui-modal-full-page-list__container'>
                                    <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                                      {
                                        selectItemModal?.fichiers?.map((item: any, index: number) => (
                                          <div className='kui-modal-full-page-list-item' key={index}>
                                            <div className='kui-modal-full-page-list-item__container'>
                                              <div className='kui-modal-full-page-list-item__illustration'>
                                                  <i className="pi-documents-line"></i>
                                              </div>
                                              <div className='kui-modal-full-page-list-item__text'>
                                                  <strong>{item?.nom_fichier}</strong>
                                                  <span>{`Type: ${item?.type_fichier?.toUpperCase()}`}</span>
                                              </div>
                                              <div>
                                                <Button iconLeftClass='pi-download-2-line' bstyle='is--primary' btype='button' btypo='is--icon-btn' onClick={() => {fetchFile(API_URL2 + item?.fichier)}} label={'Telecharger'}/>
                                              </div>
                                            </div>
                                        </div>
                                        ))
                                      }
                                    </Grid>
                                  </div>
                                ) : (
                                  <div className='kui-modal-empty'>
                                    <EmptyList loop={true} autoplay={true} size={{
                                        height: 190,
                                        width: 290
                                    }}/>
                                    <span>Aucune formation anterieure</span>
                                </div>
                                )
                              }
                            </div>
                          </div>
                      </div>
                    </div>
                    
                    <ModalActions tabsActions={[
                        // {
                        //   icon: "pi-edit-3-line", 
                        //   type: "button", 
                        //   label:"Modifier", 
                        //   style:"is--primary", 
                        //   active: true,
                        //   onClick: () => {
                        //     navigate(links.edit_demande_inscription + "/" + selectItemModal.numero_demande)
                        //   },
                        //   show: true
                        // },
                        {
                          icon: "pi-check-circle-line", 
                          type: "button", 
                          label:"Decision", 
                          style:"is--success", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setEditModal(true)
                          },
                          show: selectItemModal.statut === "en_attente"
                        },
                        {
                          icon: "pi-user-4-line", 
                          type: "button", 
                          label:"Creer le compte Mentor", 
                          style:"is--neutral", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setAddModal(true)
                          },
                          show: selectItemModal.statut === "acceptee"
                        },
                        {
                          icon: "pi-delete-2-line", 
                          type: "button", 
                          label:"Supprimer", 
                          style:"is--danger", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setDeleteModal(true)
                          },
                          show: true
                        },
                        {
                          icon: "pi-close-line", 
                          type: "button", 
                          label:"Fermer", 
                          style:"is--neutral", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                          },
                          show: true
                        },
                    ]}/>
                </Modal>
              ) : null}  
            </>
          )
        }
        
      </div>
    </MainLayout>
  )
}
