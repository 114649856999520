import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { links } from '../../../../../routes';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { reset, setDemandeEnseignement } from '../../../../../../app/redux/features/teacher/demande_enseignement.slice';
import { paysOptions, sexeOptions, slugifyFileName } from '../../../../../../app/utils';
import { MainLayout } from '../../../../../layout';
import { Button, Grid, KAlert, Selectfield, Textfield } from '../../../../../components';
import { AddFileSuccess } from '../../../../../assets/illustration';

export const DemandeEnseignementPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()
  useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.create_demande_enseignant}`)
    }
  }, [token, navigate]);

  const [data, setData] = useState<any>({
    email: "",
    description_approche_pedagogique: "",
    methode_planification_cours: "",
    methode_evaluation_etudiant: "",
    methode_etablissement_rapport_etudiant: "",
    methode_collaboration_etudiant: "",
    qualite_personnelle: "",
    nombre_annee_experience: "",
  })
  
  const [dataProfile, setDataProfile] = useState<any>({
    nom: "",
    prenom: "",
    telephone: "",
    ville: "",
    date_de_naissance: "",
    lieu_de_naissance: "",
    pays_de_naissance: "",
    nationalite: "",
    sexe: "",
    pays_de_residence: "",
    adresse: ""
  })

  const [error, setError] = useState<any>({})
  const [fichiers, setFichiers] = useState<any[]>([])

  const [diplome_obtenue, setDiplomeAnterieure] = useState<any>({
    intitule_diplome: "",
    institution: "",
    annee_obtention: ""
  })
  const [diplomes_obtenues, setDiplomesAnterieures] = useState<any[]>([])

  const [experience_enseignement, setExperienceEnseignement] = useState<any>({
    etablissement: "",
    nombre_annee_experience: "",
    niveau_enseignement: "",
    matiere_enseigne: "",
  })
  const [experiences_enseignement, setExperiencesEnseignements] = useState<any[]>([])

  const [reference, setReference] = useState<any>({
    nom_reference: "",
    prenom_reference: "",
    poste_reference: "",
    email_reference: "",
    telephone_reference: "",
  })
  const [references, setRefernces] = useState<any[]>([])

  const dispatch = useAppDispatch();
  const { isCreateError, isCreateLoading, isCreateSuccess, demande_enseignement, message } = useAppSelector((state) => state.demande_enseignement);

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 4
      if(type < max){
        setImagesBase(file[0], {
          nom_fichier: slugifyFileName(file[0].name),
          type_fichier: `Fichier-${fichiers.length + 1}`
        })
      }else{
        setError({
          field: "global",
          message: "Votre fichier ne doit pas dépasser 8Mo"
        })
        return
      }
    }
  }

  const setImagesBase = (file: any, info: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setFichiers(list => [...list.filter(el => el.image !== reader.result), {...info, fichier: reader.result}])
    }
  }

  const addExperience = () => {
    if(!experience_enseignement.niveau_enseignement){
      setError({
        field: "experience_enseignement_niveau_enseignement",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!experience_enseignement.nombre_annee_experience){
      setError({
        field: "experience_enseignement_nombre_annee_experience",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!experience_enseignement.etablissement){
      setError({
        field: "experience_enseignement_etablissement",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!experience_enseignement.matiere_enseigne){
      setError({
        field: "experience_enseignement_matiere_enseigne",
        message: "Veuillez renseigner ce champs"
      })
      return
    }

    setExperiencesEnseignements((list) => [...list.filter((el: any) => el !== experience_enseignement), experience_enseignement])
    setExperienceEnseignement({
      etablissement: "",
      nombre_annee_experience: "",
      niveau_enseignement: "",
      matiere_enseigne: "",
    })
  }

  const addDiplomeObtenue = () => { 
    if(!diplome_obtenue.institution){
      setError({
        field: "diplome_obtenue_institution",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!diplome_obtenue.intitule_diplome){
      setError({
        field: "diplome_obtenue_intitule_diplome",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!diplome_obtenue.annee_obtention){
      setError({
        field: "diplome_obtenue_annee_obtention",
        message: "Veuillez renseigner ce champs"
      })
      return
    }

    setDiplomesAnterieures((list) => [...list.filter((el: any) => el !== diplome_obtenue), diplome_obtenue])
    setDiplomeAnterieure({
      institution: "",
      intitule_diplome: "",
      annee_obtention: "",
    })
  }

  const addReference = () => { 
    if(!reference.poste_reference){
      setError({
        field: "reference_poste_reference",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!reference.email_reference){
      setError({
        field: "reference_email_reference",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!reference.telephone_reference){
      setError({
        field: "reference_telephone_reference",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!reference.prenom_reference){
      setError({
        field: "reference_prenom_reference",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!reference.nom_reference){
      setError({
        field: "reference_nom_reference",
        message: "Veuillez renseigner ce champs"
      })
      return
    }

    setRefernces((list) => [...list.filter((el: any) => el !== reference), reference])
    setReference({
      nom_reference: "",
      prenom_reference: "",
      poste_reference: "",
      email_reference: "",
      telephone_reference: "",
    })
  }

  const removeExperience = (item: any) => {
    setExperiencesEnseignements((list) => [...list.filter((el: any) => el !== item)])
  }

  const removeDiplome = (item: any) => {
    setDiplomesAnterieures((list) => [...list.filter((el: any) => el !== item)])
  }


  const removeReference = (item: any) => {
    setRefernces((list) => [...list.filter((el: any) => el !== item)])
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.email){
      setError({
          field: "email",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.description_approche_pedagogique){
      setError({
          field: "description_approche_pedagogique",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.methode_planification_cours){
      setError({
          field: "methode_planification_cours",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.methode_evaluation_etudiant){
      setError({
          field: "methode_evaluation_etudiant",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.methode_etablissement_rapport_etudiant){
      setError({
          field: "methode_etablissement_rapport_etudiant",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.methode_collaboration_etudiant){
      setError({
          field: "methode_collaboration_etudiant",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.qualite_personnelle){
      setError({
          field: "qualite_personnelle",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nombre_annee_experience){
      setError({
          field: "nombre_annee_experience",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.nom){
      setError({
          field: "profile_nom",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.prenom){
      setError({
          field: "profile_prenom",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.date_de_naissance){
      setError({
          field: "profile_date_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.telephone){
      setError({
          field: "profile_telephone",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.sexe){
      setError({
          field: "profile_sexe",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.nationalite){
      setError({
          field: "profile_nationalite",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.pays_de_naissance){
      setError({
          field: "profile_pays_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.lieu_de_naissance){
      setError({
          field: "profile_lieu_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(diplomes_obtenues?.length < 1){
      setError({
          field: "global",
          message: "Veuillez entrer une diplome"
      })
      return
    }
    if(references?.length < 1){
      setError({
          field: "global",
          message: "Veuillez entrer une reference"
      })
      return
    }
    if(experiences_enseignement?.length < 1){
      setError({
          field: "global",
          message: "Veuillez entrer une experience"
      })
      return
    }
    // setCloseM(true)
    dispatch(setDemandeEnseignement({
      ...data, 
      profile: dataProfile, 
      references,
      diplomes_obtenues, 
      experiences_enseignement,
      fichiers
    }))
  }

  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  return (
    <MainLayout title="Demande d'enseignements" isMin={false} here='demandes_enseignants'>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
                <i className="pi-home-6-line"></i>
                <span>Accueil</span>
            </Link>
            <span> | </span>
            <Link className='kui-link' to={links.demandes_enseignants}>
                <span>Demandes d'enseignement</span>
            </Link>
            <span> | </span>
            <p>Ajout d'une demande</p>
        </div>
        
        <div className='kui-page__header kui-page-header'>
          <h2>Demande d'Enseignant</h2>
        </div>

        <div className='kui-page-forms'>
          {
            isCreateSuccess && demande_enseignement ? (
              <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                <div className={'kui-page-form'}>
                  <div className='kui-page-empty'>
                    <div className='kui-page-empty__illustration'>
                      <AddFileSuccess loop={true} autoplay={true} size={{
                        height: (120),
                        width: (120)
                      }}/>
                    </div>
                    <div className='kui-page-empty__details'>
                      <h1>La demande d'enseignement a bien été ajoutée.</h1>
                    </div>
                  </div>
                </div>
                <div className={'kui-page-form'}>
                  <div className='kui-page-form__list kui-page-form-list'>
                    <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                      <div className='kui-page-form-action'>
                        <Button label={'Revenir à la liste'} btype='button' onClick={() => navigate(links.demande_inscription)}/>
                      </div> 
                    </Grid>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                {
                  error?.field === "global" ? (
                    <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
                  ) : null
                }
                {
                  isCreateError ? (
                    <KAlert status={'st--danger'} title={'Oupss!! Une erreur est survenue'} message={message}/>
                  ) : null
                }
                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Information de l'enseigant</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                    <div className='kui-page-form-fields'>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_nom'} 
                          value={dataProfile.nom} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, nom: val})} 
                          type={"text"}
                          label='Nom de famille'
                          placeholder={'Ex: Kabore'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_prenom'} 
                          value={dataProfile.prenom} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, prenom: val})} 
                          type={"text"}
                          label='Prenom(s)'
                          placeholder={'Ex: Jean Victor'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Selectfield 
                          id={'profile_sexe'} 
                          value={dataProfile.sexe} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, sexe: val})} 
                          tabs={sexeOptions}
                          label='Sexe'
                          placeholder={'Ex: Masculin ou Feminin'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_nationalite'} 
                          value={dataProfile.nationalite} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, nationalite: val})} 
                          type={"text"}
                          label='Nationalité'
                          placeholder={'Ex: Burkinabe'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_date_de_naissance'} 
                          value={dataProfile.date_de_naissance} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, date_de_naissance: val})} 
                          type={"date"}
                          label='Date de naissance'
                          placeholder={'Ex:...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_lieu_de_naissance'} 
                          value={dataProfile.lieu_de_naissance} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, lieu_de_naissance: val})} 
                          type={"text"}
                          label='Lieu de naissance'
                          placeholder={'Ex: Bobo-Dioulasso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Selectfield 
                          id={'profile_pays_de_naissance'} 
                          value={dataProfile.pays_de_naissance} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_naissance: val})} 
                          tabs={paysOptions}
                          label='Pays de naissance'
                          placeholder={'Ex: Burkina Faso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_adresse'} 
                          value={dataProfile.adresse} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, adresse: val})} 
                          type={"text"}
                          label='Adresse'
                          placeholder={'Ex: Secteur 30'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_ville'} 
                          value={dataProfile.ville} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, ville: val})} 
                          type={"text"}
                          label='Ville de residence'
                          placeholder={'Ex: Bobo-Dioulasso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Selectfield 
                          id={'profile_pays_de_residence'} 
                          value={dataProfile.pays_de_residence} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_residence: val})} 
                          tabs={paysOptions}
                          label='Pays de residence'
                          placeholder={'Ex: Burkina Faso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_telephone'} 
                          value={dataProfile.telephone} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, telephone: val})} 
                          type={"phone"}
                          label='Numero de telephone'
                          placeholder={'Ex: 0022675346789'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'email'} 
                          value={data.email} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, email: val})} 
                          type={"email"}
                          label='Email'
                          placeholder={'Ex: email@exemple.com'}
                          error={error}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Information de la demande</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                    <div className='kui-page-form-fields'>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'methode_evaluation_etudiant'} 
                          value={data.description_approche_pedagogique} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, description_approche_pedagogique: val})} 
                          type={"text"}
                          label={'Approche pedagogique'}
                          placeholder={'Ex: Ecrire ici..'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'methode_evaluation_etudiant'} 
                          value={data.methode_planification_cours} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, methode_planification_cours: val})} 
                          type={"text"}
                          label={'Methode de planification du cours'}
                          placeholder={'Ex: Ecrire ici..'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'methode_evaluation_etudiant'} 
                          value={data.methode_evaluation_etudiant} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, methode_evaluation_etudiant: val})} 
                          type={"text"}
                          label={'Methode d\'evaluation etudiant'}
                          placeholder={'Ex: Ecrire ici...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'methode_etablissement_rapport_etudiant'} 
                          value={data.methode_etablissement_rapport_etudiant} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, methode_etablissement_rapport_etudiant: val})} 
                          type={"text"}
                          label={'Methode d\'etablissement du rapport etudiant'}
                          placeholder={'Ecrire ici..'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'methode_collaboration_etudiant'} 
                          value={data.methode_collaboration_etudiant} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, methode_collaboration_etudiant: val})} 
                          type={"text"}
                          label={'Methode de collaboration avec l\'etudiant'}
                          placeholder={'Ecrire ici...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'qualite_personnelle'} 
                          value={data.qualite_personnelle} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, qualite_personnelle: val})} 
                          type={"text"}
                          label='Qualité personnelle'
                          placeholder={'Ecrire ici...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'nombre_annee_experience'} 
                          value={data.nombre_annee_experience} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, nombre_annee_experience: val})} 
                          type={"number"}
                          min={0}
                          label={'Nombre d\'année d\'experince'}
                          placeholder={'Ex: 1'}
                          error={error}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Experiences anterieures dans l'enseignement</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                  {
                    experiences_enseignement?.length > 0 ? (
                      <div className='kui-page-form-list__container'>
                        <br />
                        <h4>Experiences enregistrées</h4>
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={0.5}>
                          {
                            experiences_enseignement?.map((item: any, index: number) => (
                              <div className='kui-page-form-list-item' key={index}>
                                <div className='kui-page-form-list-item__container'>
                                  <strong>{item?.etablissement}</strong>
                                  <span>{"Année d'experience: "+item.nombre_annee_experience + " - Niveau:" +item.niveau_enseignement}</span>
                                  <span>Matiere: {item.matiere_enseigne}</span>
                                </div>
                                <div className='kui-page-form-list-item__action'>
                                  <button className='kui-link is--danger' type='button' onClick={() => removeExperience(item)}>
                                    <span>Supprimer</span>
                                  </button>
                                </div>
                              </div>
                            ))
                          }
                        </Grid>
                      </div>
                    ) : null
                  }
                    <div className='kui-page-form-fields'>
                      <div className='kui-page-form-field is--full'>
                        <Textfield 
                          id={'experience_enseignement_etablissement'} 
                          value={experience_enseignement.etablissement} 
                          require={true} 
                          changeValue={(val: any) => setExperienceEnseignement({...experience_enseignement, etablissement: val})} 
                          type='text'
                          label='Etablissement'
                          placeholder={'Ex: Etablissement'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'experience_enseignement_nombre_annee_experience'} 
                          value={experience_enseignement.nombre_annee_experience} 
                          require={true} 
                          changeValue={(val: any) => setExperienceEnseignement({...experience_enseignement, nombre_annee_experience: val})} 
                          type='number'
                          min={0}
                          label={'Année d\'experience'}
                          placeholder={'Ex: 1'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'experience_enseignement_niveau_enseignement'} 
                          value={experience_enseignement.niveau_enseignement} 
                          require={true} 
                          changeValue={(val: any) => setExperienceEnseignement({...experience_enseignement, niveau_enseignement: val})} 
                          type='text'
                          label={'Niveau'}
                          placeholder={'Ex: Licence'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'experience_enseignement_matiere_enseigne'} 
                          value={experience_enseignement.matiere_enseigne} 
                          require={true} 
                          changeValue={(val: any) => setExperienceEnseignement({...experience_enseignement, matiere_enseigne: val})} 
                          type='text'
                          label={'Matière enseignée'}
                          placeholder={'Ex: Maths'}
                          error={error}
                        />
                      </div>
                    </div>
                    <div className='kui-page-form-action'>
                      <Button label={'Enregistrer la formation'} 
                        btype='button' 
                        bstyle='is--secondary' 
                        active={true}
                        iconLeftClass={"pi-add-circle-line"}
                        onClick={() => addExperience()}
                      />
                    </div> 
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Diplomes obtenues</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                  {
                    diplomes_obtenues?.length > 0 ? (
                      <div className='kui-page-form-list__container'>
                        <br />
                        <h4>Diplomes obtenues enregistrées</h4>
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={0.5}>
                          {
                            diplomes_obtenues?.map((item: any, index: number) => (
                              <div className='kui-page-form-list-item' key={index}>
                                <div className='kui-page-form-list-item__container'>
                                  <strong>{item?.intitule_diplome}</strong>
                                  <span>{item.institution + " - Obtenu en" +item.annee_obtention}</span>
                                </div>
                                <div className='kui-page-form-list-item__action'>
                                  <button className='kui-link is--danger' type='button' onClick={() => removeDiplome(item)}>
                                    <span>Supprimer</span>
                                  </button>
                                </div>
                              </div>
                            ))
                          }
                        </Grid>
                      </div>
                    ) : null
                  }
                    <div className='kui-page-form-fields'>
                      <div className='kui-page-form-field is--full'>
                        <Textfield 
                          id={'diplome_obtenue_intitule_diplome'} 
                          value={diplome_obtenue.intitule_diplome} 
                          require={true} 
                          changeValue={(val: any) => setDiplomeAnterieure({...diplome_obtenue, intitule_diplome: val})} 
                          type='text'
                          label='Intitulé du diplôme'
                          placeholder={'Ex: Formation en...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'diplome_obtenue_institution'} 
                          value={diplome_obtenue.institution} 
                          require={true} 
                          changeValue={(val: any) => setDiplomeAnterieure({...diplome_obtenue, institution: val})} 
                          type='text'
                          label='Institution'
                          placeholder={'Ex: Institution'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'diplome_obtenue_annee_obtention'} 
                          value={diplome_obtenue.annee_obtention} 
                          require={true} 
                          changeValue={(val: any) => setDiplomeAnterieure({...diplome_obtenue, annee_obtention: val})} 
                          type='date'
                          label={'Année d\'obtention'}
                          placeholder={'Ex: 2023'}
                          error={error}
                        />
                      </div>
                    </div>
                    <div className='kui-page-form-action'>
                      <Button label={'Enregistrer la formation'} 
                        btype='button' 
                        bstyle='is--secondary' 
                        active={true}
                        iconLeftClass={"pi-add-circle-line"}
                        onClick={() => addDiplomeObtenue()}
                      />
                    </div> 
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>References</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                  {
                    references?.length > 0 ? (
                      <div className='kui-page-form-list__container'>
                        <br />
                        <h4>Refences enregistrées</h4>
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={0.5}>
                          {
                            references?.map((item: any, index: number) => (
                              <div className='kui-page-form-list-item' key={index}>
                                <div className='kui-page-form-list-item__container'>
                                  <strong>{item?.nom_reference?.toUpperCase() + " " + item?.prenom_reference}</strong>
                                  <span>{"Email: "+item.email_reference + " - Tel:" +item.telephone_reference}</span>
                                  <span>{"Poste: "+item.poste_reference}</span>
                                </div>
                                <div className='kui-page-form-list-item__action'>
                                  <button className='kui-link is--danger' type='button' onClick={() => removeReference(item)}>
                                    <span>Supprimer</span>
                                  </button>
                                </div>
                              </div>
                            ))
                          }
                        </Grid>
                      </div>
                    ) : null
                  }
                    <div className='kui-page-form-fields'>
                      <div className='kui-page-form-field is--full'>
                        <Textfield 
                          id={'reference_nom_reference'} 
                          value={reference.nom_reference} 
                          require={true} 
                          changeValue={(val: any) => setReference({...reference, nom_reference: val})} 
                          type='text'
                          label='Nom du referant'
                          placeholder={'Ex: Sanon'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'reference_prenom_reference'} 
                          value={reference.prenom_reference} 
                          require={true} 
                          changeValue={(val: any) => setReference({...reference, prenom_reference: val})} 
                          type='text'
                          label='Prenom du referant'
                          placeholder={'Ex: Jean'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'reference_email_reference'} 
                          value={reference.email_reference} 
                          require={true} 
                          changeValue={(val: any) => setReference({...reference, email_reference: val})} 
                          type='email'
                          label={'Email'}
                          placeholder={'Ex: example@email.com'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'reference_telephone_reference'} 
                          value={reference.telephone_reference} 
                          require={true} 
                          changeValue={(val: any) => setReference({...reference, telephone_reference: val})} 
                          type='phone'
                          label={'Telephone'}
                          placeholder={'Ex: 00226734569'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'reference_poste_reference'} 
                          value={reference.poste_reference} 
                          require={true} 
                          changeValue={(val: any) => setReference({...reference, poste_reference: val})} 
                          type='text'
                          label={'Poste'}
                          placeholder={'Ex: DRH de ...'}
                          error={error}
                        />
                      </div>
                    </div>
                    <div className='kui-page-form-action'>
                      <Button label={'Enregistrer la formation'} 
                        btype='button' 
                        bstyle='is--secondary' 
                        active={true}
                        iconLeftClass={"pi-add-circle-line"}
                        onClick={() => addReference()}
                      />
                    </div> 
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Fichiers</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                   
                    {
                      fichiers?.length > 0 ? (
                        fichiers.map((item: any, index: number) => (
                          <div className='kui-filefield-lil' key={index}>
                            <div className='kui-filefield-lil__container'>
                              <div className='kui-filefield-lil__icon'>
                                <i className="pi-file-Info-line"></i>
                              </div>
                              <div className='kui-filefield-lil__info'>
                                <span>{item?.type_fichier}</span>
                                <strong>{item?.nom_fichier}</strong>
                              </div>
                              <div className='kui-filefield-lil__action'>
                                <button className='kui-link is--danger' type='button' onClick={() => {
                                  setFichiers(fichiers.filter((el: any) => el !== item))
                                }}>
                                  <span>Supprimer</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <strong>Aucun fichier</strong>
                      )
                    }
                    <div className='kui-filefield-lil'>
                      <input type="file" multiple={false} id='fichier' onChange={handleFileChangeFile} />
                      <label className='kui-filefield-lil__container' htmlFor="fichier">
                        <div className='kui-filefield-lil__icon'>
                          <i className="pi-file-Info-line"></i>
                        </div>
                        <div className='kui-filefield-lil__info'>
                          <strong>{`Ajouter un fichier`}</strong>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <form className='kui-page-form__list kui-page-form-list' onSubmit={handleSubmit}>
                    <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                      <div className='kui-page-form-action'>
                        <Button label={(isCreateLoading) ? "Creation en cours" :'Enregistrer et ajouter'} 
                          btype='submit' 
                          bstyle='is--primary' 
                          active={!(isCreateLoading)}
                          iconLeftClass={(isCreateLoading) ? "pi-spinner5" : "pi-add-circle-line"}
                        />
                      </div> 
                    </Grid>
                  </form>
                </div>
                
              </Grid>
            )
          }
        </div>
              
      </div>
    </MainLayout>
  )
}
