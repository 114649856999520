import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { links } from '../../../../../routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { getNiveaux, setNiveau, updateNiveau, reset as nreset } from '../../../../../../app/redux/features/cursus/niveau.slice';
import { deleteNiveauFiliere, getNiveauFilieres, setNiveauFiliere , reset as nfreset } from '../../../../../../app/redux/features/cursus/filiere_niveau.slice';
import { Button, Grid, KAlert, Modal, ModalActions, ModalForm, Toast } from '../../../../../components';
import { AddSuccessIllu, EmptyList, LoaderAnimation, NotFoundError } from '../../../../../assets/illustration';
import { getFiliere, getLinkBetweenFiliereSemester, linkFilierToSemestre, reset, unlinkFiliereToSemestre } from '../../../../../../app/redux/features/cursus/filiere.slice';
import { getSemestres, setSemestre, updateSemestre, reset as sreset } from '../../../../../../app/redux/features/cursus/semestre.slice';
import { getLinkBetweenUniteEnseignementsSemester, getUniteEnseignements, linkUniteEnseignementToSemestre, setUniteEnseignement, unlinkUniteEnseignementToSemestre, updateUniteEnseignement, reset as ureset } from '../../../../../../app/redux/features/cursus/unite_enseignement.slice';
import { getMatieres, removeMatiereToFiliere, setMatiereToFiliere, reset as mreset } from '../../../../../../app/redux/features/cursus/matiere.slice';
import { MainLayout } from '../../../../../layout';
import { getCycles, reset as creset } from '../../../../../../app/redux/features/cursus/cycle.slice';
import { getDepartments, reset as dreset } from '../../../../../../app/redux/features/cursus/department.slice';
import { EditModal } from '../list';

const LinkFiliereToNiveau = ({item, niveaux, fnc}:{item: any, niveaux: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    intitule:  "",
    nom_court:  "",
  })

  const { isCreateLoading, isCreateSuccess, isCreateError, niveau, message } = useAppSelector((state) => state.niveau);
  const { isCreateLoading: isCNFLoading, isCreateSuccess: isCNFSuccess, isCreateError: isCNFError, message: nfMessage } = useAppSelector((state) => state.filiere_niveau);
  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const dispatch = useAppDispatch();
  
  const [niveauId, setNiveauId] = useState<string>("")
  const [niveauI, setNiveauI] = useState<any>(null)

  const [closeM, setCloseM] = useState<boolean>(false)
  const [newM, setNewM] = useState<boolean>(false)
  const [newMF, setNewMF] = useState<boolean>(false)
  const [search, setSearch ] = useState<string>("")

  const addNewNiveau = () => {
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    setNewM(true)
    dispatch(setNiveau(data))
  }

  const linkFiliereNiveau = () => {
    
    if(!niveauId){
        setError({
            field: "global",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    setCloseM(true)

    let datas = {
      filiere: item.id,
      niveau: niveauId
    }
    dispatch(setNiveauFiliere(datas))
  }

  useEffect(() => {
    if(newM && isCreateSuccess && niveau && item){
      let datas = {
        filiere: item.id,
        niveau: niveau.id
      }
      dispatch(setNiveauFiliere(datas))
    }
  }, [newM, isCreateSuccess, niveau, item, dispatch])

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setNewMF(false)
    setNewM(false)
    setSearch("")
    setNiveauI(null)
    setNiveauId("")
    setData({
      intitule:  "",
      nom_court:  "",
    })
  }

  useEffect(() => {
    console.log(niveaux)
  }, [niveaux, search])
  
  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-niveau-filiere'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'un niveau à une filière </h2>
      </div>
      {
        (closeM && isCNFSuccess ) || (newM && isCNFSuccess && isCreateSuccess) ? (
          <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Ajout d'un niveau à une filière</h1>
                  <p>
                    La liaison a été créée
                  </p>
                </div>
              </div>
            </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              (newM) && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              (closeM || newM) && isCNFError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={nfMessage}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message}/>
              ) : null
            }
            <div className='kui-modal-box-group'>
              {
                newMF ? ( 
                  <>
                    <Button label={'Revenir au choix'} btype='button'  onClick={() => setNewMF(false)} />
                    <ModalForm tabsField={[
                      {
                          field: "textfield",
                          field_type: "text",
                          field_require: true,
                          value: data.intitule,
                          label: "Intitulé",
                          id: "intitule",
                          onChange: (val: any) => setData({...data, intitule: val}),
                          error: error,
                          placeholder: "Ex: Science de la santé",
                          isShow: true
                      },
                      {
                          field: "textfield",
                          field_type: "text",
                          field_require: true,
                          value: data.nom_court,
                          label: "Nom court",
                          id: "nom_court",
                          onChange: (val: any) => setData({...data, nom_court: val}),
                          error: error,
                          placeholder: "Ex: SDS",
                          isShow: true
                      },
                    ]}/>
                  </>
                ) : (
                  niveauId ? (
                    <>
                      <div className={`kui-modal-box-list-item`}>
                          <div className='kui-modal-box-list-item__avatar is--rounded'>
                              <i className="pi-presentation-3-line"></i>
                          </div>
                          <div className='kui-modal-box-list-item__container'>
                              <strong>{niveauI?.intitule}</strong>
                              <span>{niveauI.nom_court}</span>
                              <button type='button' className='kui-link' onClick={() => {
                                  setNiveauId("")
                                  setNiveauI(null)
                              }}>
                                  <span>Changer</span>
                              </button>
                          </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='kui-modal-box-group__header'><h3>Choix du niveau</h3></div>
                      <div className='kui-modal-box-list'>
                        <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                          <div className='kui-modal-box-list-action-search'>
                            <i className="pi-search-line"></i>
                            <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                          </div>
                        </div>
                        {
                          search.trim().length > 2 ? (
                            niveaux
                            .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                            .length > 0 ? (
                                <div className='kui-modal-box-list__container'>
                                {
                                    niveaux
                                    .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                    .slice(0, 8)
                                    .map((itemo: any, index: number) => (
                                    <button key={index} type='button' className={`kui-modal-box-list-item ${niveauId === itemo?.id ? "active" : ""}`} onClick={() => {
                                        setNiveauId(itemo?.id)
                                        setNiveauI(itemo)
                                    }}>
                                        {
                                            niveauId === itemo?.id ? (
                                              <div className='kui-modal-box-list-item__check'>
                                                <i className="pi-minimize-line"></i>
                                              </div>
                                            ) : null
                                        }
                                        <div className='kui-modal-box-list-item__avatar is--rounded'>
                                            <i className="pi-presentation-3-line"></i>
                                        </div>
                                        <div className='kui-modal-box-list-item__container'>
              
                                            <strong>{itemo?.intitule}</strong>
                                            <span>{itemo?.nom_court}</span>
                                        </div>
                                    </button>
                                    ))
                                }
                                
                                </div>
                            ) : (
                              <>
                                <span>Aucun niveau trouvé</span>
                                <Button label={'Ajouter un nouveau niveau'} btype='button' bstyle='is--secondary' onClick={() => setNewMF(true)} />
                              </>
                            )
                          ) : (
                            <span>Rechercher...</span>
                          )
                        }
                      </div>
                    </>
                  )
                )
              }
            </div>

          </div>
        )
      }
      <ModalActions tabsActions={[
        {
          icon: newMF ? (isCreateLoading && isCNFLoading ? "pi-spinner5" : "pi-save-2-line" ): (isCNFLoading ? "pi-spinner5" : "pi-save-2-line") , 
          type: "button", 
          label: isCreateLoading ? "Création en cours" : "Enregistrer et ajouter", 
          style:"is--primary", 
          active: newMF ? !(isCreateLoading || isCNFLoading) : !(isCNFLoading),
          onClick: () => {
            newMF ? addNewNiveau() : linkFiliereNiveau() 
          },
          show: newMF ? (newM && isCNFSuccess && isCreateSuccess? false : true) : (closeM && isCNFSuccess ? false : true)
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
          style:"is--danger", 
          active: !isCreateLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const LinkFiliereToNiveauList = ({fn_list, niveaux, item}:{fn_list: any[], item: any, niveaux: any[]}) => {
  
  const [addModal, setAddModal] = useState<boolean>(false)
  const [unlinkModal, setUnLinkModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [selectItemModal, setSelectItemModal] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isDeleteLoading, isDeleteSuccess, isDeleteError, message } = useAppSelector((state) => state.filiere_niveau);
  
  useEffect(() => {
    if(selectItemModal && isDeleteSuccess && unlinkModal) window.location.reload();
  }, [selectItemModal, isDeleteSuccess , unlinkModal])

  return (
    <div className='kui-page-account-grid-item'>
      <div className='kui-page-account-grid-item__header'>
        <div className='kui-page-account-grid-item__illu'>
          <i className="pi-user-security-line"></i>
        </div>
        <h2>Niveaux</h2>
      </div>
      <div className='kui-page-account-grid-item__text'>
        <div>
          <Button label='Ajouter'  btype='button' bstyle='is--primary' onClick={() => setAddModal(true)} />
        </div>
      </div>
      <div className='kui-page-account-grid-item__container'>
        {
          fn_list?.filter((el: any) => el.filiere === item.id).length > 0 ? (
            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={1}>
              {
                fn_list?.filter((el: any) => el.filiere === item.id).map((itemo: any, idx: number) => (
                  <div className='kui-page-account-grid-item__text' key={idx}>
                    <span>{itemo.niveau?.nom_court} </span>
                    <strong>{itemo.niveau?.intitule}</strong>
                    <Grid direction='fd-row' spacing='jc-start' align='start' wrap={true} gap={.4}>

                      <button type='button' className='kui-link' onClick={() => {
                        setSelectItemModal(itemo)
                        setEditModal(true)
                      }}>
                          <span>Modifier</span>
                      </button>

                      <button type='button' className='kui-link' onClick={() => {
                        if((selectItemModal?.id === itemo?.id) && isDeleteLoading) return
                        setSelectItemModal(itemo)
                        setUnLinkModal(true)
                        dispatch(deleteNiveauFiliere(itemo.id))
                      }}>
                        {
                          (selectItemModal?.id === itemo?.id) && isDeleteLoading ? (
                            <span>Dissociation en cours...</span>
                          ) : (
                            <span>Dissocier</span>
                          )
                        }
                      </button>
                    </Grid>
                  </div>
                ))
              }
            </Grid>
          ) : (
            <div className='kui-page-account-grid-item__error kui-page-account-grid-item-error'>
              <div className='kui-page-account-grid-item-error__illustration'>
                <EmptyList loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-page-account-grid-item-error_details'>
                <strong>Aucun niveau associé à cette filière</strong>
              </div>
            </div>
          )
        }
        
      </div>

      {addModal ? <LinkFiliereToNiveau niveaux={niveaux} item={item} fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
      {editModal && selectItemModal  ? <EditNiveauModal item={selectItemModal.niveau} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                            
      {
        isDeleteSuccess && unlinkModal ? (
          <Toast id={"avatar-success"} status={'st--success'} fnc={() => setUnLinkModal(false)} >
            <p>Dissociation reussie</p>
          </Toast>
        ) : null
      }
      {
        isDeleteError && unlinkModal ? (
          <Toast id={"avatar-danger"} status={'st--danger'} fnc={() => setUnLinkModal(false)} >
            <p>{message}</p>
          </Toast>
        ) : null
      }                
    </div>
  )
}

const EditNiveauModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
      intitule: item.intitule || "",
      nom_court: item.nom_court || "",
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.niveau);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    setError(null)
    setCloseM(true)
    dispatch(updateNiveau({
      data, 
      id: item.id
    }))
  }

  const closeModal = () => {
    setData({
      intitule:  "",
      finalite:  "",
    })
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-filier'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Modification d'un niveau</h2>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule,
                label: "Intitulé",
                id: "intitule",
                onChange: (val: any) => setData({...data, intitule: val}),
                error: error,
                placeholder: "Ex: Science des Données",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.nom_court,
                label: "Nom court",
                id: "nom_court",
                onChange: (val: any) => setData({...data, nom_court: val}),
                error: error,
                placeholder: "Ex: SDD",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modifiaction en cours" : "Enregistrer les modifications", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const EditSemestreModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
      intitule: item.intitule || "",
      nom_court: item.nom_court || "",
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.semestre);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    setError(null)
    setCloseM(true)
    dispatch(updateSemestre({
      data, 
      id: item.id
    }))
  }

  const closeModal = () => {
    setData({
      intitule:  "",
      finalite:  "",
    })
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-filier'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Modification d'un semestre</h2>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule,
                label: "Intitulé",
                id: "intitule",
                onChange: (val: any) => setData({...data, intitule: val}),
                error: error,
                placeholder: "Ex: Science des Données",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.nom_court,
                label: "Nom court",
                id: "nom_court",
                onChange: (val: any) => setData({...data, nom_court: val}),
                error: error,
                placeholder: "Ex: SDD",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modifiaction en cours" : "Enregistrer les modifications", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const EditMatiereModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
      intitule: item.intitule || "",
      nom_court: item.nom_court || "",
      description: item.description || "",
      nombre_credit: item.nombre_credit ||  1,
      volume_horaire: item.volume_horaire || 1,
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.matiere);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    setError(null)
    setCloseM(true)
    dispatch(updateSemestre({
      data, 
      id: item.id
    }))
  }

  const closeModal = () => {
    setData({
      intitule:  "",
      finalite: "",
      description: "",
      nombre_credit: 1,
      volume_horaire: 1,
    })
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-filier'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Modification d'une matière</h2>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule,
                label: "Intitulé",
                id: "intitule",
                onChange: (val: any) => setData({...data, intitule: val}),
                error: error,
                placeholder: "Ex: Science des Données",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.nom_court,
                label: "Nom court",
                id: "nom_court",
                onChange: (val: any) => setData({...data, nom_court: val}),
                error: error,
                placeholder: "Ex: SDD",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "number",
                field_require: true,
                value: data.nombre_credit,
                label: "Credit",
                id: "nombre_credit",
                onChange: (val: any) => setData({...data, nombre_credit: val}),
                error: error,
                placeholder: "Ex: 4",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "number",
                field_require: true,
                value: data.volume_horaire,
                label: "Volume horaire",
                id: "volume_horaire",
                onChange: (val: any) => setData({...data, volume_horaire: val}),
                error: error,
                placeholder: "Ex: 72",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "textarea",
                field_require: false,
                value: data.description,
                label: "Description",
                id: "description",
                onChange: (val: any) => setData({...data, description: val}),
                error: error,
                placeholder: "Décrire un peu la matière",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modifiaction en cours" : "Enregistrer les modifications", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const LinkSemestreToFiliere = ({fn_list, itemo, semestres, fnc}:{fn_list: any[], itemo: any, semestres: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    intitule:  "",
    nom_court:  "",
  })

  const { isCreateLoading, isCreateSuccess, isCreateError, semestre, message } = useAppSelector((state) => state.semestre);
  const { isEditError, isEditLoading, isEditSuccess, message: fMessage } = useAppSelector((state) => state.filiere);
  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const dispatch = useAppDispatch();
  
  const [niveauFId, setNiveauFId] = useState<string>("")
  const [niveauFI, setNiveauFI] = useState<any>(null)

  const [niveauId, setNiveauId] = useState<string>("")
  const [niveauI, setNiveauI] = useState<any>(null)

  const [closeM, setCloseM] = useState<boolean>(false)
  const [newM, setNewM] = useState<boolean>(false)
  const [newMF, setNewMF] = useState<boolean>(false)
  const [search, setSearch ] = useState<string>("")
  const [searchD, setSearchD ] = useState<string>("")

  const addNewSemestre = () => {
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    setNewM(true)
    dispatch(setSemestre(data))
    console.log("ok")
  }

  const linkFiliereSemestre = () => {
    
    if(!niveauId){
      setError({
          field: "global",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!niveauFId){
        setError({
            field: "global",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    setCloseM(true)

    let datas = {
      niveau_filiere: niveauFId,
      semestre: niveauId
    }
    dispatch(linkFilierToSemestre(datas))
  }

  useEffect(() => {
    if(newM && isCreateSuccess && semestre && niveauFId){
      
      let datas = {
        niveau_filiere: niveauFId,
        semestre: semestre.id
      }
      dispatch(linkFilierToSemestre(datas))
    }
  }, [newM, isCreateSuccess, semestre, niveauFId, dispatch])

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setNewMF(false)
    setNewM(false)
    setSearch("")
    setSearchD("")
    setNiveauI(null)
    setNiveauId("")
    setData({
      intitule:  "",
      nom_court:  "",
    })
  }
  
  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-niveau-filiere'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'un semestre à une filière </h2>
      </div>
      {
        (closeM && isEditSuccess ) || (newM && isEditSuccess && isCreateSuccess) ? (
          <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Ajout d'un semestre à une filière</h1>
                  <p>
                    La liaison a été créée
                  </p>
                </div>
              </div>
            </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              (newM) && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              (closeM || newM) && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={fMessage}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message}/>
              ) : null
            }
            <div className='kui-modal-box-group'>
              {
                niveauFId ? (
                  <>
                    <div className={`kui-modal-box-list-item`}>
                        <div className='kui-modal-box-list-item__avatar is--rounded'>
                            <i className="pi-presentation-3-line"></i>
                        </div>
                        <div className='kui-modal-box-list-item__container'>
                            <strong>Niveau: {niveauFI?.niveau?.intitule}</strong>
                            <span>Filiere: {itemo.intitule}</span>
                            <button type='button' className='kui-link' onClick={() => {
                                setNiveauFId("")
                                setNiveauFI(null)
                            }}>
                                <span>Changer</span>
                            </button>
                        </div>
                    </div>
                    {
                      newMF ? ( 
                        <>
                          <Button label={'Revenir au choix'} btype='button'  onClick={() => setNewMF(false)} />
                          <ModalForm tabsField={[
                            {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.intitule,
                                label: "Intitulé",
                                id: "intitule",
                                onChange: (val: any) => setData({...data, intitule: val}),
                                error: error,
                                placeholder: "Ex: Science de la santé",
                                isShow: true
                            },
                            {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.nom_court,
                                label: "Nom court",
                                id: "nom_court",
                                onChange: (val: any) => setData({...data, nom_court: val}),
                                error: error,
                                placeholder: "Ex: SDS",
                                isShow: true
                            },
                          ]}/>
                        </>
                      ) : (
                        niveauId ? (
                          <>
                            <div className={`kui-modal-box-list-item`}>
                                <div className='kui-modal-box-list-item__avatar is--rounded'>
                                    <i className="pi-presentation-3-line"></i>
                                </div>
                                <div className='kui-modal-box-list-item__container'>
                                    <strong>{niveauI?.intitule}</strong>
                                    <span>{niveauI.nom_court}</span>
                                    <button type='button' className='kui-link' onClick={() => {
                                        setNiveauId("")
                                        setNiveauI(null)
                                    }}>
                                        <span>Changer</span>
                                    </button>
                                </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='kui-modal-box-group__header'><h3>Choix du semestre</h3></div>
                            <div className='kui-modal-box-list'>
                              <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                                <div className='kui-modal-box-list-action-search'>
                                  <i className="pi-search-line"></i>
                                  <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                                </div>
                              </div>
                              {
                                search.trim().length > 2 ? (
                                  semestres
                                  .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                  .length > 0 ? (
                                      <div className='kui-modal-box-list__container'>
                                      {
                                          semestres
                                          .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                          .slice(0, 8)
                                          .map((itemo: any, index: number) => (
                                          <button key={index} type='button' className={`kui-modal-box-list-item ${niveauId === itemo?.id ? "active" : ""}`} onClick={() => {
                                              setNiveauId(itemo?.id)
                                              setNiveauI(itemo)
                                          }}>
                                              {
                                                  niveauId === itemo?.id ? (
                                                    <div className='kui-modal-box-list-item__check'>
                                                      <i className="pi-minimize-line"></i>
                                                    </div>
                                                  ) : null
                                              }
                                              <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                  <i className="pi-presentation-3-line"></i>
                                              </div>
                                              <div className='kui-modal-box-list-item__container'>
                    
                                                  <strong>{itemo?.intitule}</strong>
                                                  <span>{itemo?.nom_court}</span>
                                              </div>
                                          </button>
                                          ))
                                      }
                                      
                                      </div>
                                  ) : (
                                    <>
                                      <span>Aucun semestre trouvé</span>
                                      <Button label={'Ajouter un nouveau semestre'} btype='button' bstyle='is--secondary' onClick={() => setNewMF(true)} />
                                    </>
                                  )
                                ) : (
                                  <span>Rechercher...</span>
                                )
                              }
                            </div>
                          </>
                        )
                      )
                    }
                  </>
                ) : (
                  
                  <>
                  <div className='kui-modal-box-group__header'><h3>Choix du niveau de la filiere</h3></div>
                  <div className='kui-modal-box-list'>
                    <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                      <div className='kui-modal-box-list-action-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={searchD} placeholder='Recherche...' onChange={(e) => setSearchD(e.target.value)}/>
                      </div>
                    </div>
                    {
                      searchD.trim().length > 2 ? (
                        fn_list
                        .filter((el: any) => el?.niveau?.intitule?.toLocaleLowerCase()?.includes(searchD?.toLocaleLowerCase()) )
                        .length > 0 ? (
                            <div className='kui-modal-box-list__container'>
                            {
                                fn_list
                                .filter((el: any) => el?.niveau?.intitule?.toLocaleLowerCase()?.includes(searchD?.toLocaleLowerCase()) )
                                .slice(0, 8)
                                .map((itemor: any, index: number) => (
                                <button key={index} type='button' className={`kui-modal-box-list-item ${niveauFId === itemor?.id ? "active" : ""}`} onClick={() => {
                                    setNiveauFId(itemor?.id)
                                    setNiveauFI(itemor)
                                }}>
                                    {
                                        niveauFId === itemor?.id ? (
                                          <div className='kui-modal-box-list-item__check'>
                                            <i className="pi-minimize-line"></i>
                                          </div>
                                        ) : null
                                    }
                                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                                        <i className="pi-presentation-3-line"></i>
                                    </div>
                                    <div className='kui-modal-box-list-item__container'>
          
                                        <strong>Niveau: {itemor?.niveau?.intitule}</strong>
                                        <span>Filiere: {itemo?.intitule}</span>
                                    </div>
                                </button>
                                ))
                            }
                            
                            </div>
                        ) : (
                          <span>Aucun niveau trouvé</span>
                        )
                      ) : (
                        <span>Rechercher...</span>
                      )
                    }
                  </div>
                </>
                )
              }
            </div>

          </div>
        )
      }
      <ModalActions tabsActions={[
        {
          icon: newMF ? (isCreateLoading && isEditLoading ? "pi-spinner5" : "pi-save-2-line" ): (isEditLoading ? "pi-spinner5" : "pi-save-2-line") , 
          type: "button", 
          label: isCreateLoading ? "Création en cours" : "Enregistrer et ajouter", 
          style:"is--primary", 
          active: newMF ? !(isCreateLoading || isEditLoading) : !(isEditLoading),
          onClick: () => {
            newMF ? addNewSemestre() : linkFiliereSemestre() 
          },
          show: newMF ? (newM && isEditSuccess && isCreateSuccess? false : true) : (closeM && isEditSuccess ? false : true)
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
          style:"is--danger", 
          active: !isCreateLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const LinkSemestreToFiliereList = ({sf_list, fn_list, semestres, item}:{sf_list: any[],fn_list: any[], item: any, semestres: any[]}) => {
   
  const [addModal, setAddModal] = useState<boolean>(false)
  const [unlinkModal, setUnLinkModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [selectItemModal, setSelectItemModal] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isDeleteLoading, isDeleteSuccess, isDeleteError, message } = useAppSelector((state) => state.filiere);
  
  useEffect(() => {
    if(selectItemModal && isDeleteSuccess && unlinkModal) window.location.reload();
  }, [selectItemModal, isDeleteSuccess , unlinkModal])

  return (
    <div className='kui-page-account-grid-item'>
      <div className='kui-page-account-grid-item__header'>
        <div className='kui-page-account-grid-item__illu'>
          <i className="pi-user-security-line"></i>
        </div>
        <h2>Semestres</h2>
      </div>
      <div className='kui-page-account-grid-item__text'>
        <div>
          <Button label='Ajouter'  btype='button' bstyle='is--primary' onClick={() => setAddModal(true)} />
        </div>
      </div>
      <div className='kui-page-account-grid-item__container'>
        {
          sf_list?.filter((el: any) => fn_list?.filter((ele: any) => ele.filiere === item?.id).filter((ele: any) => ele.id === el?.niveau_filiere).length > 0).length > 0 ? (
            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={1}>
              {
                sf_list?.filter((el: any) => fn_list?.filter((ele: any) => ele.filiere === item?.id).filter((ele: any) => ele.id === el?.niveau_filiere).length > 0).map((itemo: any, idx: number) => (
                  <div className='kui-page-account-grid-item__text' key={idx}>
                    <span>{itemo.semestre?.nom_court} </span>
                    <strong>{itemo.semestre?.intitule}</strong>
                    <p>Niveau: {fn_list.filter((el: any) => el.id === itemo?.niveau_filiere)[0]?.niveau?.intitule}</p>
                    <Grid direction='fd-row' spacing='jc-start' align='start' wrap={true} gap={.4}>
                      <button type='button' className='kui-link' onClick={() => {
                        setSelectItemModal(itemo)
                        setEditModal(true)
                      }}>
                          <span>Modifier</span>
                      </button>

                      <button type='button' className='kui-link' onClick={() => {
                        if((selectItemModal?.id === itemo?.id) && isDeleteLoading) return
                        setSelectItemModal(itemo)
                        setUnLinkModal(true)
                        dispatch(unlinkFiliereToSemestre(itemo.id))
                      }}>
                        {
                          (selectItemModal?.id === itemo?.id) && isDeleteLoading ? (
                            <span>Dissociation en cours...</span>
                          ) : (
                            <span>Dissocier</span>
                          )
                        }
                      </button>
                    </Grid>
                  </div>
                ))
              }
            </Grid>
          ) : (
            <div className='kui-page-account-grid-item__error kui-page-account-grid-item-error'>
              <div className='kui-page-account-grid-item-error__illustration'>
                <EmptyList loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-page-account-grid-item-error_details'>
                <strong>Aucun semestre associé à cette filière</strong>
              </div>
            </div>
          )
        }
        
      </div>
      
      {addModal ? <LinkSemestreToFiliere semestres={semestres} fn_list={fn_list.filter((el: any) => el.filiere === item.id)} itemo={item} fnc={(val?: boolean) => setAddModal(val || false)}/> : null}

      {editModal && selectItemModal  ? <EditSemestreModal item={selectItemModal.semestre} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                            
      {
        isDeleteSuccess && unlinkModal ? (
          <Toast id={"avatar-success"} status={'st--success'} fnc={() => setUnLinkModal(false)} >
            <p>Dissociation reussie</p>
          </Toast>
        ) : null
      }
      {
        isDeleteError && unlinkModal ? (
          <Toast id={"avatar-danger"} status={'st--danger'} fnc={() => setUnLinkModal(false)} >
            <p>{message}</p>
          </Toast>
        ) : null
      }                
    </div>
  )
}

const EditUEModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {

  const [data, setData] = useState<any>({
      intitule: item.intitule || "",
      nom_court: item.nom_court || "",
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.unite_enseignement);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    setError(null)
    setCloseM(true)
    dispatch(updateUniteEnseignement({
      data, 
      id: item.id
    }))
  }

  const closeModal = () => {
    setData({
      intitule:  "",
      finalite:  "",
    })
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-filier'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Modification d'une unité d'enseignements</h2>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule,
                label: "Intitulé",
                id: "intitule",
                onChange: (val: any) => setData({...data, intitule: val}),
                error: error,
                placeholder: "Ex: Science des Données",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.nom_court,
                label: "Nom court",
                id: "nom_court",
                onChange: (val: any) => setData({...data, nom_court: val}),
                error: error,
                placeholder: "Ex: SDD",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
            type: "submit", 
            label: isEditLoading ? "Modifiaction en cours" : "Enregistrer les modifications", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const LinkUniteEnseignementFiliere = ({sfn_list, fn_list, itemo, ues, fnc}:{sfn_list: any[], fn_list: any[], itemo: any, ues: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    intitule:  "",
    nom_court:  "",
  })

  const { isCreateLoading, isCreateSuccess, isCreateError, unite_enseignement, isEditError, isEditLoading, isEditSuccess, message } = useAppSelector((state) => state.unite_enseignement);

  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const dispatch = useAppDispatch();
  
  const [niveauFId, setNiveauFId] = useState<string>("")
  const [niveauFI, setNiveauFI] = useState<any>(null)

  const [niveauId, setNiveauId] = useState<string>("")
  const [niveauI, setNiveauI] = useState<any>(null)

  const [closeM, setCloseM] = useState<boolean>(false)
  const [newM, setNewM] = useState<boolean>(false)
  const [newMF, setNewMF] = useState<boolean>(false)
  const [search, setSearch ] = useState<string>("")
  const [searchD, setSearchD ] = useState<string>("")

  const addNewSemestre = () => {
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    setNewM(true)
    dispatch(setUniteEnseignement(data))
  }

  const linkFiliereSemestre = () => {
    
    if(!niveauId){
      setError({
          field: "global",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!niveauFId){
        setError({
            field: "global",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    setCloseM(true)

    let datas = {
      semestre_niveau_filiere: niveauFId,
      unite_enseignement: niveauId
    }
    dispatch(linkUniteEnseignementToSemestre(datas))
  }

  useEffect(() => {


    if(newM && isCreateSuccess && unite_enseignement && niveauFId){
      let datas = {
        semestre_niveau_filiere: niveauFId,
        unite_enseignement: unite_enseignement.id
      }
      dispatch(linkUniteEnseignementToSemestre(datas))
    }
  }, [newM, isCreateSuccess, unite_enseignement, niveauFId, dispatch])

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setNewMF(false)
    setNewM(false)
    setSearch("")
    setSearchD("")
    setNiveauI(null)
    setNiveauId("")
    setData({
      intitule:  "",
      nom_court:  "",
    })
  }
  
  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-niveau-filiere'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une unité d'enseignement à une filière </h2>
      </div>
      {
        (closeM && isEditSuccess ) || (newM && isEditSuccess && isCreateSuccess) ? (
          <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Ajout d'une unité d'enseignement à une filière</h1>
                  <p>
                    La liaison a été créée
                  </p>
                </div>
              </div>
            </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              (newM) && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              (closeM || newM) && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message}/>
              ) : null
            }
            <div className='kui-modal-box-group'>
              {
                niveauFId ? (
                  <>
                    <div className={`kui-modal-box-list-item`}>
                        <div className='kui-modal-box-list-item__avatar is--rounded'>
                            <i className="pi-presentation-3-line"></i>
                        </div>
                        <div className='kui-modal-box-list-item__container'>
                            <strong>Semestre: {niveauFI?.semestre?.intitule}</strong>
                            <span>{fn_list.filter((el: any) => el.id === niveauFI?.niveau_filiere)[0]?.niveau?.intitule} - {itemo.intitule}</span>
                            <button type='button' className='kui-link' onClick={() => {
                                setNiveauFId("")
                                setNiveauFI(null)
                            }}>
                                <span>Changer</span>
                            </button>
                        </div>
                    </div>
                    {
                      newMF ? ( 
                        <>
                          <Button label={'Revenir au choix'} btype='button'  onClick={() => setNewMF(false)} />
                          <ModalForm tabsField={[
                            {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.intitule,
                                label: "Intitulé",
                                id: "intitule",
                                onChange: (val: any) => setData({...data, intitule: val}),
                                error: error,
                                placeholder: "Ex: Science de la santé",
                                isShow: true
                            },
                            {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.nom_court,
                                label: "Nom court",
                                id: "nom_court",
                                onChange: (val: any) => setData({...data, nom_court: val}),
                                error: error,
                                placeholder: "Ex: SDS",
                                isShow: true
                            },
                          ]}/>
                        </>
                      ) : (
                        niveauId ? (
                          <>
                            <div className={`kui-modal-box-list-item`}>
                                <div className='kui-modal-box-list-item__avatar is--rounded'>
                                    <i className="pi-presentation-3-line"></i>
                                </div>
                                <div className='kui-modal-box-list-item__container'>
                                    <strong>{niveauI?.intitule}</strong>
                                    <span>{niveauI.nom_court}</span>
                                    <button type='button' className='kui-link' onClick={() => {
                                        setNiveauId("")
                                        setNiveauI(null)
                                    }}>
                                        <span>Changer</span>
                                    </button>
                                </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='kui-modal-box-group__header'><h3>Choix de l'unité d'enseignements</h3></div>
                            <div className='kui-modal-box-list'>
                              <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                                <div className='kui-modal-box-list-action-search'>
                                  <i className="pi-search-line"></i>
                                  <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                                </div>
                              </div>
                              {
                                search.trim().length > 2 ? (
                                  ues
                                  .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                  .length > 0 ? (
                                      <div className='kui-modal-box-list__container'>
                                      {
                                          ues
                                          .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                          .slice(0, 8)
                                          .map((itemo: any, index: number) => (
                                          <button key={index} type='button' className={`kui-modal-box-list-item ${niveauId === itemo?.id ? "active" : ""}`} onClick={() => {
                                              setNiveauId(itemo?.id)
                                              setNiveauI(itemo)
                                          }}>
                                              {
                                                  niveauId === itemo?.id ? (
                                                    <div className='kui-modal-box-list-item__check'>
                                                      <i className="pi-minimize-line"></i>
                                                    </div>
                                                  ) : null
                                              }
                                              <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                  <i className="pi-presentation-3-line"></i>
                                              </div>
                                              <div className='kui-modal-box-list-item__container'>
                                                  <strong>{itemo?.intitule}</strong>
                                                  <span>{itemo?.nom_court}</span>
                                              </div>
                                          </button>
                                          ))
                                      }
                                      
                                      </div>
                                  ) : (
                                    <>
                                      <span>Aucune UE trouvée</span>
                                      <Button label={'Ajouter une nouvelle UE'} btype='button' bstyle='is--secondary' onClick={() => setNewMF(true)} />
                                    </>
                                  )
                                ) : (
                                  <span>Rechercher...</span>
                                )
                              }
                            </div>
                          </>
                        )
                      )
                    }
                  </>
                ) : (
                  <>
                    <div className='kui-modal-box-group__header'><h3>Choix du semestre de la filiere</h3></div>
                    <div className='kui-modal-box-list'>
                      <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                        <div className='kui-modal-box-list-action-search'>
                          <i className="pi-search-line"></i>
                          <input type="search" value={searchD} placeholder='Recherche...' onChange={(e) => setSearchD(e.target.value)}/>
                        </div>
                      </div>
                      {
                        searchD.trim().length > 2 ? (
                          sfn_list
                          .filter((el: any) => fn_list.filter((ele: any) => ele.id === el.niveau_filiere).length > 0)
                          .filter((el: any) => el?.semestre?.intitule?.toLocaleLowerCase()?.includes(searchD?.toLocaleLowerCase()) )
                          .length > 0 ? (
                              <div className='kui-modal-box-list__container'>
                              {
                                  sfn_list
                                  .filter((el: any) => fn_list.filter((ele: any) => ele.id === el.niveau_filiere).length > 0)
                                  .filter((el: any) => el?.semestre?.intitule?.toLocaleLowerCase()?.includes(searchD?.toLocaleLowerCase()) )
                                  .slice(0, 8)
                                  .map((itemor: any, index: number) => (
                                  <button key={index} type='button' className={`kui-modal-box-list-item ${niveauFId === itemor?.id ? "active" : ""}`} onClick={() => {
                                      setNiveauFId(itemor?.id)
                                      setNiveauFI(itemor)
                                  }}>
                                      {
                                          niveauFId === itemor?.id ? (
                                            <div className='kui-modal-box-list-item__check'>
                                              <i className="pi-minimize-line"></i>
                                            </div>
                                          ) : null
                                      }
                                      <div className='kui-modal-box-list-item__avatar is--rounded'>
                                          <i className="pi-presentation-3-line"></i>
                                      </div>
                                      <div className='kui-modal-box-list-item__container'>
            
                                          <strong>{itemor?.semestre?.intitule}</strong>
                                          <span>Niveau: {fn_list.filter((el: any) => el.id === itemor?.niveau_filiere)[0]?.niveau?.intitule}</span>
                                      </div>
                                  </button>
                                  ))
                              }
                              
                              </div>
                          ) : (
                            <span>Aucun semestre trouvé</span>
                          )
                        ) : (
                          <span>Rechercher...</span>
                        )
                      }
                    </div>
                  </>
                )
              }
            </div>

          </div>
        )
      }
      <ModalActions tabsActions={[
        {
          icon: newMF ? (isCreateLoading && isEditLoading ? "pi-spinner5" : "pi-save-2-line" ): (isEditLoading ? "pi-spinner5" : "pi-save-2-line") , 
          type: "button", 
          label: isCreateLoading ? "Création en cours" : "Enregistrer et ajouter", 
          style:"is--primary", 
          active: newMF ? !(isCreateLoading || isEditLoading) : !(isEditLoading),
          onClick: () => {
            newMF ? addNewSemestre() : linkFiliereSemestre() 
          },
          show: newMF ? (newM && isEditSuccess && isCreateSuccess? false : true) : (closeM && isEditSuccess ? false : true)
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
          style:"is--danger", 
          active: !isCreateLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const LinkUniteEnseignementFiliereList = ({ues, ef_list, fn_list, snf_list, item}:{ues: any[], ef_list: any[],fn_list: any[], item: any, snf_list: any[]}) => {

  const [addModal, setAddModal] = useState<boolean>(false)
  const [unlinkModal, setUnLinkModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [selectItemModal, setSelectItemModal] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isDeleteLoading, isDeleteSuccess, isDeleteError, message } = useAppSelector((state) => state.unite_enseignement);
  
  useEffect(() => {
    if(selectItemModal && isDeleteSuccess && unlinkModal) window.location.reload();
  }, [selectItemModal, isDeleteSuccess , unlinkModal])

  return (
    <div className='kui-page-account-grid-item'>
      <div className='kui-page-account-grid-item__header'>
        <div className='kui-page-account-grid-item__illu'>
          <i className="pi-user-security-line"></i>
        </div>
        <h2>Unite d'enseignement</h2>
      </div>
      <div className='kui-page-account-grid-item__text'>
        <div>
          <Button label='Ajouter'  btype='button' bstyle='is--primary' onClick={() => setAddModal(true)} />
        </div>
      </div>
      <div className='kui-page-account-grid-item__container'>
        {
          ef_list
            .filter((el: any) => snf_list
                                .filter((ele: any) => fn_list
                                                      .filter((elem: any) => elem.id === ele.niveau_filiere)
                                                      .length > 0
                                )
                                .filter((elem: any) => elem.id === el.semestre_niveau_filiere)
                                .length > 0
            ).length > 0 ? (
            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={1}>
              {
                ef_list
                .filter((el: any) => snf_list
                                    .filter((ele: any) => fn_list
                                                          .filter((elem: any) => elem.id === ele.niveau_filiere)
                                                          .length > 0
                                    )
                                    .filter((elem: any) => elem.id === el.semestre_niveau_filiere)
                                    .length > 0
                ).map((itemo: any, idx: number) => (
                  <div className='kui-page-account-grid-item__text' key={idx}>
                    <span>{itemo.unite_enseignement?.nom_court} </span>
                    <strong>{itemo.unite_enseignement?.intitule}</strong>
                    <Grid direction='fd-row' spacing='jc-start' align='start' wrap={true} gap={.4}>
                      <button type='button' className='kui-link' onClick={() => {
                        setSelectItemModal(itemo)
                        setEditModal(true)
                      }}>
                          <span>Modifier</span>
                      </button>

                      <button type='button' className='kui-link' onClick={() => {
                        if((selectItemModal?.id === itemo?.id) && isDeleteLoading) return
                        setSelectItemModal(itemo)
                        setUnLinkModal(true)
                        dispatch(unlinkUniteEnseignementToSemestre(itemo.id))
                      }}>
                        {
                          (selectItemModal?.id === itemo?.id) && isDeleteLoading ? (
                            <span>Dissociation en cours...</span>
                          ) : (
                            <span>Dissocier</span>
                          )
                        }
                      </button>
                    </Grid>
                  </div>
                ))
              }
            </Grid>
          ) : (
            <div className='kui-page-account-grid-item__error kui-page-account-grid-item-error'>
              <div className='kui-page-account-grid-item-error__illustration'>
                <EmptyList loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-page-account-grid-item-error_details'>
                <strong>Aucun unite d'enseignement associé à cette filière</strong>
              </div>
            </div>
          )
        }
        
      </div>
      
      {addModal ? <LinkUniteEnseignementFiliere ues={ues} sfn_list={snf_list} fn_list={fn_list.filter((el: any) => el.filiere === item.id)} itemo={item} fnc={(val?: boolean) => setAddModal(val || false)}/> : null}

      {editModal && selectItemModal  ? <EditUEModal item={selectItemModal.unite_enseignement} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                            
      {
        isDeleteSuccess && unlinkModal ? (
          <Toast id={"avatar-success"} status={'st--success'} fnc={() => setUnLinkModal(false)} >
            <p>Dissociation reussie</p>
          </Toast>
        ) : null
      }
      {
        isDeleteError && unlinkModal ? (
          <Toast id={"avatar-danger"} status={'st--danger'} fnc={() => setUnLinkModal(false)} >
            <p>{message}</p>
          </Toast>
        ) : null
      }                
    </div>
  )
}

const AddMatiereToFiliere = ({ef_list, fn_list, snf_list, item, fnc }:{ef_list: any[], fn_list: any[], item: any, snf_list: any[], fnc: (val: boolean) => void | any }) => {

  const [data, setData] = useState<any>({
    intitule: "",
    nom_court: "",
    description: "",
    nombre_credit: 1,
    volume_horaire: 1,
  })
  const dispatch = useAppDispatch();

  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.matiere);

  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const [ue, setUe] = useState<string>("")
  const [ueI, setUeI] = useState<any>(null)
  const [closeM, setCloseM] = useState<boolean>(false)
  const [search, setSearch ] = useState<string>("")
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.nombre_credit){
        setError({
          field: "nombre_credit",
          message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.volume_horaire){
        setError({
          field: "volume_horaire",
          message: "Veuillez renseigner ce champs"
        })
        return
    }
    setCloseM(true)
    
    dispatch(setMatiereToFiliere({
      data: {
        matieres: [
          {
            ...data,
            unite_enseignement_semestre_niveau: ue
          }
        ]
      },
      id: item?.id
    }))
  }
  const closeModal = () => {
    if(closeM && isCreateSuccess) window.location.reload();

    fnc(false)
    setCloseM(false)
    setSearch("")
    setData({
      intitule: "",
      nom_court: "",
      description: "",
      nombre_credit: 1,
      volume_horaire: 1,
    })
    setUe("")
    setUeI(null)
  }

  useEffect(() => {
    console.log(ef_list)
    console.log(snf_list)
    console.log(fn_list)
  }, [ef_list, snf_list, fn_list])

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'edit-reco'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une matière à une filière</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Ajout d'une matière à une filiere {item?.intitule}</h1>
                <p>
                  Les informations ont été modifiées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
              {
                closeM && isCreateError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
                      
              <div className='kui-modal-box-group'>
                  {
                      ue ? (
                          <>
                            <div className={`kui-modal-box-list-item`}>
                                <div className='kui-modal-box-list-item__avatar is--rounded'>
                                    <i className="pi-school-line"></i>
                                </div>
                                <div className='kui-modal-box-list-item__container'>
                                    <strong>{ueI?.unite_enseignement?.intitule}</strong>
                                    <span>{ueI.unite_enseignement?.nom_court}</span>
                                    <button type='button' className='kui-link' onClick={() => {
                                        setUe("")
                                        setUeI(null)
                                    }}>
                                        <span>Changer</span>
                                    </button>
                                </div>
                            </div>
                            
                            <ModalForm tabsField={[
                              {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.intitule,
                                label: "Intitulé",
                                id: "intitule",
                                onChange: (val: any) => setData({...data, intitule: val}),
                                error: error,
                                placeholder: "Ex: Science de la santé",
                                isShow: true
                              },
                              {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.nom_court,
                                label: "Nom court",
                                id: "nom_court",
                                onChange: (val: any) => setData({...data, nom_court: val}),
                                error: error,
                                placeholder: "Ex: SDS",
                                isShow: true
                              },
                              {
                                field: "textfield",
                                field_type: "number",
                                field_require: true,
                                value: data.nombre_credit,
                                label: "Credit",
                                id: "nombre_credit",
                                onChange: (val: any) => setData({...data, nombre_credit: val}),
                                error: error,
                                placeholder: "Ex: 4",
                                isShow: true
                              },
                              {
                                field: "textfield",
                                field_type: "number",
                                field_require: true,
                                value: data.volume_horaire,
                                label: "Volume horaire",
                                id: "volume_horaire",
                                onChange: (val: any) => setData({...data, volume_horaire: val}),
                                error: error,
                                placeholder: "Ex: 72",
                                isShow: true
                              },
                              {
                                field: "textfield",
                                field_type: "textarea",
                                field_require: false,
                                value: data.description,
                                label: "Description",
                                id: "description",
                                onChange: (val: any) => setData({...data, description: val}),
                                error: error,
                                placeholder: "Décrire un peu la matière",
                                isShow: true
                              },
                            ]}/>
                          </>
                      ) : (
                        <>
                          <div className='kui-modal-box-group__header'><h3>Choix de l'unité d'enseignement</h3></div>
                          <div className='kui-modal-box-list'>
                              <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                                  <div className='kui-modal-box-list-action-search'>
                                    <i className="pi-search-line"></i>
                                    <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                                  </div>
                              </div>
                            {
                              search.trim().length > 2 ? (
                                ef_list
                                .filter((el: any) => snf_list
                                                    .filter((ele: any) => fn_list
                                                                          .filter((elem: any) => elem.id === ele.niveau_filiere)
                                                                          .length > 0
                                                    )
                                                    .filter((elem: any) => elem.id === el.semestre_niveau_filiere)
                                                    .length > 0
                                )
                                .filter((el: any) => el?.unite_enseignement.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                .length > 0 ? (
                                    <div className='kui-modal-box-list__container'>
                                    {
                                        ef_list
                                        .filter((el: any) => snf_list
                                                            .filter((ele: any) => fn_list
                                                                                  .filter((elem: any) => elem.id === ele.niveau_filiere)
                                                                                  .length > 0
                                                            )
                                                            .filter((elem: any) => elem.id === el.semestre_niveau_filiere)
                                                            .length > 0
                                        )
                                        .filter((el: any) => el?.unite_enseignement.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                        .slice(0, 8)
                                        .map((itemo: any, index: number) => (
                                        <button key={index} type='button' className={`kui-modal-box-list-item ${ue === itemo?.id ? "active" : ""}`} onClick={() => {
                                            setUe(itemo?.id)
                                            setUeI(itemo)
                                        }}>
                                            {
                                                ue === itemo?.id ? (
                                                  <div className='kui-modal-box-list-item__check'>
                                                    <i className="pi-minimize-line"></i>
                                                  </div>
                                                ) : null
                                            }
                                            <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                <i className="pi-school-line"></i>
                                            </div>
                                            <div className='kui-modal-box-list-item__container'>
                                            <span>{itemo.unite_enseignement?.nom_court} </span>
                                            <strong>{itemo.unite_enseignement?.intitule}</strong>
                                            </div>
                                        </button>
                                        ))
                                    }
                                    
                                    </div>
                                ) : (
                                  <span>Aucune unité d'ensigenement trouvé</span>
                                )
                              ) : (
                                <span>Rechercher...</span>
                              )
                            }
                          </div>
                        </>
                      )
                  }
              </div>
                  
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-save-2-line", 
            type: "submit", 
            label: isCreateLoading ? "Création en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show:  (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const MatiereFiliereList = ({ef_list, fn_list, snf_list, item, matieres}:{ef_list: any[], fn_list: any[], item: any, snf_list: any[], matieres: any[]}) => {

  const [addModal, setAddModal] = useState<boolean>(false)
  const [unlinkModal, setUnLinkModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [selectItemModal, setSelectItemModal] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isDeleteLoading, isDeleteSuccess, isDeleteError, message } = useAppSelector((state) => state.matiere);

  useEffect(() => {
    if(selectItemModal && isDeleteSuccess && unlinkModal) window.location.reload();
  }, [selectItemModal, isDeleteSuccess , unlinkModal])

  return (
    <div className='kui-page-account-grid-item'>
      <div className='kui-page-account-grid-item__header'>
        <div className='kui-page-account-grid-item__illu'>
          <i className="pi-user-security-line"></i>
        </div>
        <h2>Matière</h2>
      </div>
      <div className='kui-page-account-grid-item__text'>
        <div>
          <Button label='Ajouter'  btype='button' bstyle='is--primary' onClick={() => setAddModal(true)} />
        </div>
      </div>
      <div className='kui-page-account-grid-item__container'>
        {
          matieres.filter((it: any) => ef_list
                                      .filter((el: any) => snf_list
                                                          .filter((ele: any) => fn_list
                                                                                .filter((elem: any) => elem.id === ele.niveau_filiere)
                                                                                .length > 0
                                                          )
                                                          .filter((elem: any) => elem.id === el.semestre_niveau_filiere)
                                                          .length > 0
                                      )
                                      .filter((itemx: any) => it.unite_enseignement_semestre_niveau === itemx.id)
                                      .length > 0
        )
          .length > 0 ? (
            <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={1}>
              {
                matieres.filter((it: any) => ef_list
                .filter((el: any) => snf_list
                                    .filter((ele: any) => fn_list
                                                          .filter((elem: any) => elem.id === ele.niveau_filiere)
                                                          .length > 0
                                    )
                                    .filter((elem: any) => elem.id === el.semestre_niveau_filiere)
                                    .length > 0
                )
                .filter((itemx: any) => it.unite_enseignement_semestre_niveau === itemx.id)
                .length > 0
                ).map((itemo: any, idx: number) => (
                  <div className='kui-page-account-grid-item__text' key={idx}>
                    <span>{itemo?.nom_court} </span>
                    <strong>{itemo?.intitule}</strong>
                    <Grid direction='fd-row' spacing='jc-start' align='start' wrap={true} gap={.4}>
                      <button type='button' className='kui-link' onClick={() => {
                        setSelectItemModal(itemo)
                        setEditModal(true)
                      }}>
                          <span>Modifier</span>
                      </button>

                      <button type='button' className='kui-link' onClick={() => {
                        if((selectItemModal?.id === itemo?.id) && isDeleteLoading) return
                        setSelectItemModal(itemo)
                        setUnLinkModal(true)
                        dispatch(removeMatiereToFiliere({
                          id: item.id,
                          matieres: [
                            itemo.id
                          ]
                        }))
                      }}>
                        {
                          (selectItemModal?.id === itemo?.id) && isDeleteLoading ? (
                            <span>Dissociation en cours...</span>
                          ) : (
                            <span>Dissocier</span>
                          )
                        }
                      </button>
                    </Grid>
                  </div>
                ))
              }
            </Grid>
          ) : (
            <div className='kui-page-account-grid-item__error kui-page-account-grid-item-error'>
              <div className='kui-page-account-grid-item-error__illustration'>
                <EmptyList loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-page-account-grid-item-error_details'>
                <strong>Aucune matière associée à cette filière</strong>
              </div>
            </div>
          )
        }
        
      </div>
      
      {addModal ? <AddMatiereToFiliere ef_list={ef_list
                .filter((el: any) => snf_list
                                    .filter((ele: any) => fn_list
                                                          .filter((elem: any) => elem.id === ele.niveau_filiere)
                                                          .length > 0
                                    )
                                    .filter((elem: any) => elem.id === el.semestre_niveau_filiere)
                                    .length > 0
                )} snf_list={snf_list} fn_list={fn_list} item={item} fnc={(val?: boolean) => setAddModal(val || false)}/> : null}

      {editModal && selectItemModal  ? <EditMatiereModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                            
      {
        isDeleteSuccess && unlinkModal ? (
          <Toast id={"avatar-success"} status={'st--success'} fnc={() => setUnLinkModal(false)} >
            <p>Dissociation reussie</p>
          </Toast>
        ) : null
      }
      {
        isDeleteError && unlinkModal ? (
          <Toast id={"avatar-danger"} status={'st--danger'} fnc={() => setUnLinkModal(false)} >
            <p>{message}</p>
          </Toast>
        ) : null
      }                
    </div>
  )
}


export const FilierePage = () => {
  const { token, info } = useContext(UserAuthContext);
  const [editModal, setEditModal] = useState<boolean>(false)
  let navigate = useNavigate()
  const {slug} = useParams()
  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.filiere}/${slug}`)
    }
  }, [token, navigate, slug]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, filiere, semester_filiere, message } = useAppSelector((state) => state.filiere);
  const { isLoading: isNLoading, isError: isNError, isSuccess: isNSuccess, niveaux, message: nMessage } = useAppSelector((state) => state.niveau);
  const { isLoading: isNFLoading, isError: isNFError, isSuccess: isNFSuccess, niveau_filieres, message: nfMessage } = useAppSelector((state) => state.filiere_niveau);
  const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, semestres, message: sMessage } = useAppSelector((state) => state.semestre);
  const { isLoading: isUELoading, isError: isUEError, isSuccess: isUESuccess, unites_enseignements, semester_unites_enseignements, message: ueMessage } = useAppSelector((state) => state.unite_enseignement);
  const { isLoading: isMLoading, isError: isMError, isSuccess: isMSuccess, matieres, message: mMessage } = useAppSelector((state) => state.matiere);
  const { isLoading: isCLoading, isSuccess: isCSuccess, isError: isCError, message: cMessage, cycles } = useAppSelector((state) => state.cycle);
  const { isLoading: isDLoading, isSuccess: isDSuccess, isError: isDError, message: dMessage, departments } = useAppSelector((state) => state.department);

  
  useEffect(() => {
    if(slug){
      dispatch(getFiliere(slug))
      dispatch(getLinkBetweenFiliereSemester({}))
      dispatch(getSemestres({}))
      dispatch(getNiveaux({}))
      dispatch(getNiveauFilieres({}))
      dispatch(getUniteEnseignements({}))
      dispatch(getLinkBetweenUniteEnseignementsSemester({}))
      dispatch(getMatieres({}))
      dispatch(getCycles({}))
      dispatch(getDepartments({}))
    }
    return () => {
      dispatch(reset())
      dispatch(nreset())
      dispatch(mreset())
      dispatch(ureset())
      dispatch(nfreset())
      dispatch(sreset())
      dispatch(dreset())
      dispatch(creset())
    }
  }, [dispatch, slug]);

  return (
    <MainLayout title='Filiere' isMin={false} here='filiere'>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <Link className='kui-link' to={links.filieres}>
            <span>Filières</span>
          </Link>
          <span> | </span>
          <p>Apercu</p>
        </div>
        {

          isLoading || isCLoading || isDLoading || isMLoading || isNLoading || isSLoading || isUELoading || isNFLoading  ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            (isError || isMError || isNError || isNFError || isSError || isUEError || isCError || isDError ) ? (
              <div className='kui-page-error'>
                <div className='kui-page-error__illustration'>
                  <NotFoundError loop={true} autoplay={true} size={{
                    height: 180,
                    width: 180
                  }}/>
                </div>
                <div className='kui-page-error_details'>
                  <p>Oupps! Une erreur est survenue</p>
                  <h1>{ message || mMessage || nMessage || ueMessage || sMessage || nfMessage || cMessage || dMessage  }</h1>
                </div>
              </div>
            ) : (
              isSuccess  && isCSuccess && isDSuccess && isMSuccess && isUESuccess && isNFSuccess && isSSuccess && isNSuccess  && cycles && departments && filiere && semester_filiere && semestres && unites_enseignements && semester_unites_enseignements && matieres && niveau_filieres && niveaux  ? (
                
                <div className='kui-page-account'>
        
                  <div className='kui-page-account__banner kui-page-account-banner'>
        
                    <div className='kui-page-account-banner__avatar'>
                      <i className="pi-edit-4-line"></i>
                    </div>
        
                    <div className='kui-page-account-banner__info'>
                      <div className='kui-page-account-banner__name'>
                        <h1>{filiere.intitule}</h1>
                        <span>{`Nom court: ${filiere?.nom_court}`}</span>
                      </div>
                      <div className='kui-page-account-banner__actions'>
                        <div className='kui-page-account-banner__action'>
                          <Button label={'Modifier les informations'} iconLeftClass='pi-edit-3-line' active={true} btype='button' bstyle='is--neutral' onClick={() => setEditModal(true)}/>
                        </div>
                      </div>
                    </div>
                    
                  </div>

        
                  <div className='kui-page-account__grid kui-page-account-grid'>
        
                    <div className='kui-page-account-grid-item'>
                      <div className='kui-page-account-grid-item__header'>
                        <div className='kui-page-account-grid-item__illu'>
                          <i className="pi-enter-door-line"></i>
                        </div>
                        <h2>Informations de la filière</h2>
                      </div>
                      <div className='kui-page-account-grid-item__container'>
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Statut: </span>
                            <strong>{filiere.statut?.toUpperCase()}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Departement: </span>
                            <strong>{departments.filter((el: any) => el.id === filiere.departement)[0]?.intitule || ""}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Cycle: </span>
                            <strong>{(cycles.filter((el: any) => el.id === filiere.cycle)[0]?.intitule + " " +cycles.filter((el: any) => el.id === filiere.cycle)[0]?.finalite) || ""}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Description: </span>
                            <strong>{filiere.description || "ND"}</strong>
                          </div>
                        </Grid>
                      </div>
                    </div>
        
                    <LinkFiliereToNiveauList fn_list={niveau_filieres.filter((el: any) => el.filiere === filiere.id)} item={filiere} niveaux={niveaux}/>
                    <LinkSemestreToFiliereList sf_list={semester_filiere} fn_list={niveau_filieres.filter((el: any) => el.filiere === filiere.id)} item={filiere} semestres={semestres}/>

                    <LinkUniteEnseignementFiliereList ues={unites_enseignements} ef_list={semester_unites_enseignements} fn_list={niveau_filieres.filter((el: any) => el.filiere === filiere.id)} item={filiere} snf_list={semester_filiere}/>

                    <MatiereFiliereList ef_list={semester_unites_enseignements} fn_list={niveau_filieres.filter((el: any) => el.filiere === filiere.id)} item={filiere} snf_list={semester_filiere} matieres={matieres}/>

                  </div>
                  {editModal  ? <EditModal item={filiere} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
       
                </div>
              ) : null
            )
          )
        }
      </div>
    </MainLayout>
  )
}
