import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { deleteDemandBourse, getDemandBourses, reset, setDemandBourse, traiterDemandBourse } from '../../../../../app/redux/features/bourse/demand_bourse.slice';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { API_URL2, calculateAge, capitalize, createInitial, creerInitiales, fetchFile, listDemandesBourses, optionifyAnnesScolaire, slugifyFileName } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale/fr';
import { setBourseEtudiant, reset as sbreset  } from '../../../../../app/redux/features/bourse/bours_etudiant.slice';
import { links } from '../../../../routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { getBourses, reset as breset  } from '../../../../../app/redux/features/bourse/bourse.slice';
import { getDemandInscriptions, reset as dreset  } from '../../../../../app/redux/features/inscription/demand.slice';
import { getStudents, reset as sreset  } from '../../../../../app/redux/features/student/student.slice';
import { getSchoolYears, reset as screset } from '../../../../../app/redux/features/school_year/school_year.slice';
import { MainLayout } from '../../../../layout';

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.demand_bourse);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une demande de bourse</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteDemandBourse(item.numero_demande))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const AddModal = ({students, demandes, bourses, annees, fnc}:{demandes: any[], bourses: any[], annees: any[], students: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    bourse: "",
    etudiant: "",
  })

  const [fichiers, setFichiers] = useState<any[]>([])
  const [student, setStudent] = useState<any>(null)
  const [search, setSearch] = useState<string>("")
  const [bourse, setBourse] = useState<any>(null)
  const [searchB, setSearchB] = useState<string>("")

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 4
      if(type < max){
        setImagesBase(file[0], {
          nom_fichier: slugifyFileName(file[0].name),
          type_fichier: `fichier-${fichiers.length + 1}`
        })
      }else{
        setError({
          field: "global",
          message: "Votre fichier ne doit pas dépasser 8Mo"
        })
        return
      }
    }
  }

  const setImagesBase = (file: any, info: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setFichiers(list => [...list.filter(el => el.image !== reader.result), {...info, fichier: reader.result}])
    }
  }

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.demand_bourse);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    

    if(!data.etudiant){
      setError({
        field: "global",
        message: "Veuillez choisir un etudiant"
      })
      return
    }
    if(!data.bourse){
      setError({
        field: "globale",
        message: "Veuillez choisir une bourse"
      })
      return
    }
    setCloseM(true)
    dispatch(setDemandBourse({...data, fichiers}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setData({
      bourse: "",
      etudiant: "",
    })
    setSearch("")
    setSearchB("")
    setFichiers([])
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une bourse</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Les informations ont été enregistrées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
            
            <div className='kui-modal-box-group'>
              <div className='kui-modal-box-group__header'> <h3>Bourse</h3></div>
              {
                bourse ? (
                  <div className={`kui-modal-box-list-item`}>
                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                      <i className="pi-coin-2-line"/>
                    </div>
                    <div className='kui-modal-box-list-item__container'>
                      <strong>{bourse?.intitule}</strong>
                      <span>{"Montant: "+bourse?.montant}</span>
                      <p>{`${optionifyAnnesScolaire(annees)?.filter((el: any)=> el.value === bourse.annee_universitaire)[0]?.name}`}</p>
                      <button type='button' className='kui-link' onClick={() => {setStudent(null); setData({...data, bourse: ""})}}>
                        <span>Changer</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='kui-modal-box-list'>
                    <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                      <div className='kui-modal-box-list-action-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={searchB} placeholder='Recherche...' onChange={(e) => setSearchB(e.target.value)}/>
                      </div>
                    </div>
                    {
                      searchB?.length > 1 ? (
                        bourses
                        .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()))
                        .length > 0 ? (
                          <div className='kui-modal-box-list__container'>
                            {
                              bourses
                              .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()))
                              .slice(0, 8)
                              .map((item: any, index: number) => (
                                <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                  setBourse(item)
                                  setData({...data, bourse: item?.id})
                                }}>
                                    {
                                      data?.etudiant === item?.id ? (
                                        <div className='kui-modal-box-list-item__check'>
                                          <i className="pi-minimize-line"></i>
                                        </div>
                                      ) : null
                                    }
                                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      <i className="pi-coin-2-line"/>
                                    </div>
                                    <div className='kui-modal-box-list-item__container'>
                                      <strong>{item.intitule}</strong>
                                      <span>{"Montant: "+item?.montant}</span>
                                      <p>{`${optionifyAnnesScolaire(annees)?.filter((el: any)=> el.value === item.annee_universitaire)[0]?.name}`}</p>
                                    </div>
                                </button>
                              ))      
                            }
                          
                          </div>
                        ) : (
                          <span>Aucun element trouvé</span>
                        )
                      ) : (
                        <span>Rechercher</span>
                      )
                      
                    }
                  </div>
                )
              }
            </div>

            <div className='kui-modal-box-group'>
              <div className='kui-modal-box-group__header'> <h3>Etudiant</h3></div>
              {
                student ? (
                  <div className={`kui-modal-box-list-item`}>
                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                      <span>{createInitial(student?.profile?.prenom + " " + student?.profile?.nom)}</span>
                    </div>
                    <div className='kui-modal-box-list-item__container'>
                      <strong>{student?.profile?.prenom + " " + student?.profile?.nom?.toUpperCase()}</strong>
                      <span>{`Sexe: ${student?.profile?.sexe} - Né(e) le ${student?.profile?.date_de_naissance ? capitalize(format(new Date(student?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${student?.profile?.lieu_de_naissance} - ${student?.profile?.pays_de_naissance}`}</span>
                      <button type='button' className='kui-link' onClick={() => {setStudent(null); setData({...data, etudiant: ""})}}>
                        <span>Changer</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='kui-modal-box-list'>
                    <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                      <div className='kui-modal-box-list-action-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                      </div>
                    </div>
                    {
                      search?.length > 2 ? (
                        students
                        .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                        .length > 0 ? (
                          <div className='kui-modal-box-list__container'>
                            {
                              students
                              .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                              .slice(0, 8)
                              .map((item: any, index: number) => (
                                <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                  setStudent( demandes.filter((el: any) => el.id ===  item.demande)[0])
                                  setData({...data, etudiant: item?.id})
                                }}>
                                    {
                                      data?.etudiant === item?.id ? (
                                        <div className='kui-modal-box-list-item__check'>
                                          <i className="pi-minimize-line"></i>
                                        </div>
                                      ) : null
                                    }
                                    <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      
                                      <span>{creerInitiales(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.nom)}</span>
                                    </div>
                                    <div className='kui-modal-box-list-item__container'>
                                      <span>Code: {item?.code_etudiant}</span>
                                      <strong>{demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0].profile?.nom?.toUpperCase()}</strong>
                                      <span>{`Sexe: ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.sexe} - Né(e) le ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance ? capitalize(format(new Date(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.lieu_de_naissance} - ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.pays_de_naissance}`}</span>
                                    </div>
                                </button>
                              ))      
                            }
                          
                          </div>
                        ) : (
                          <span>Aucun element trouvé</span>
                        )
                      ) : (
                        <span>Rechercher</span>
                      )
                      
                    }
                  </div>
                )
              }
            </div>

            <div className='kui-modal-fields'>
              <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                {
                  fichiers?.length > 0 ? (
                    fichiers.map((item: any, index: number) => (
                      <div className='kui-filefield-lil' key={index}>
                        <div className='kui-filefield-lil__container'>
                          <div className='kui-filefield-lil__icon'>
                            <i className="pi-file-Info-line"/>
                          </div>
                          <div className='kui-filefield-lil__info'>
                            <span>{item?.type_fichier}</span>
                            <strong>{item?.nom_fichier}</strong>
                          </div>
                          <div className='kui-filefield-lil__action'>
                            <button className='kui-link is--danger' type='button' onClick={() => {
                              setFichiers(fichiers.filter((el: any) => el !== item))
                            }}>
                              <span>Supprimer</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <strong>Aucun fichier enregistré</strong>
                  )
                }
                <div className='kui-filefield-lil'>
                  <input type="file" multiple={false} id='fichier' onChange={handleFileChangeFile} />
                    <label className='kui-filefield-lil__container' htmlFor="fichier">
                      <div className='kui-filefield-lil__icon'>
                        <i className="pi-file-Info-line"></i>
                      </div>
                      <div className='kui-filefield-lil__info'>
                        <strong>{`Ajouter un fichier`}</strong>
                      </div>
                    </label>
                </div>
              </Grid>
            </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}

const DecisionModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    statut: item.statut,
    numero_demande: item.numero_demande,
    decision: item.decision
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isEditLoading, isEditError, isEditSuccess, message } = useAppSelector((state) => state.demand_bourse);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data?.statut){
      setError({
        field: "global",
        message: "Veuillez choisir une decision"
      })
      return
    }else{
      if(data.statut === "en_attente"){
        setError({
          field: "global",
          message: "Veuillez choisir une decision"
        })
        return
      }
    }
    if(data.statut === "rejetee"){
      if(!data?.decision){
        setError({
          field: "decision",
          message: "Veuillez renseigner ce champs"
        })
        return
      }
    }
    
    setError(null)
    setCloseM(true)
    dispatch(traiterDemandBourse({
      ...data,
    }))
  }

  const closeModal = () => {
    setData(null)
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-decision'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Traitement de la demande</h2>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Traitement réussi avec succès</h1>
                <p>
                  La demande a été traitée.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }

            <div className='kui-modal-box__grid kui-modal-box-grid'>
              <button type='button' className={`kui-modal-box-grid-item ${data?.statut === "acceptee" ? "is--active as--success" : ""}`} onClick={() => {
                setData({...data, statut: "acceptee"})
              }}>
                <i className="pi-check-circle-line"></i>
                <span>Accepter la demande</span>
              </button>
              <button type='button' className={`kui-modal-box-grid-item ${data?.statut === "rejetee" ? "is--active as--danger" : ""}`} onClick={() => {
                setData({...data, statut: "rejetee"})
              }}>
                <i className="pi-forbid-circle-line"></i>
                <span>Rejeter la demande</span>
              </button>
            </div>

            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "textarea",
                field_require: true,
                value: data.decision,
                label: "Observation",
                id: "decision",
                onChange: (val: any) => setData({...data, decision: val}),
                error: error,
                placeholder: "Ecrire ici",
                isShow: data.statut === "rejetee"
              },
            ]}/>
                 
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-save-2-line", 
            type: "submit", 
            label: isEditLoading ? "Traitement en cours" : "Enregistrer la decision", 
            style:"is--primary", 
            active: !isEditLoading,
            show:  (closeM && isEditSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const CreateSutentModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isCreateSuccess, isCreateLoading, isCreateError, message } = useAppSelector((state) => state.bourse_etudiant);
  console.log(item.id)
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={!isCreateLoading} id={'create-student'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Attribution de la bourse à l'etudiant </h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Attribution de bourse réussie avec succès</h1>
                <p>
                  { "Cette bourse a bien été attribuée à cet etudiant" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isCreateError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-user-4-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isCreateError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous attribuer cette bourse à cet etudiant?</h1>
                      {/* <p>Confirm</p> */}
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isCreateLoading ? "pi-spinner5" : "pi-user-4-line", 
          type: "button", 
          label: isCreateLoading ? "Creation en cours" : (closeM && isCreateError ? "Réessayer" : "Attribuer"), 
          style:"is--primary", 
          active: !isCreateLoading,
          onClick: () => {
            setCloseM(true)
            let data = {
              bourse: item.bourse?.id,
              etudiant: item.etudiant
            }
            dispatch(setBourseEtudiant(data))
          },
          show: closeM && isCreateSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isCreateSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isCreateLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

export const DemandeBourse = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.demande_bourses}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, demand_bourses, message } = useAppSelector((state) => state.demand_bourse);
  const { isLoading: isBLoading, isError: isBError, isSuccess: isBSuccess, bourses, message: bMessage } = useAppSelector((state) => state.bourse);
  const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, students, message: sMessage } = useAppSelector((state) => state.student);
  const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demandes_inscription, message: dMessage } = useAppSelector((state) => state.demande_inscription);
  const { isLoading: isScLoading, isError: isScError, isSuccess: isScSuccess, school_years, message: scMessage } = useAppSelector((state) => state.school_year);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  
  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [addwModal, setAddwModal] = useState<boolean>(false)
  const [addModal, setAddModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.demande_bourses+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
  
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.demande_bourses+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getDemandBourses({}))
    dispatch(getBourses({}))
    dispatch(getDemandInscriptions({}))
    dispatch(getStudents({}))
    dispatch(getSchoolYears({}))
    return () => {
      dispatch(reset())
      dispatch(sreset())
      dispatch(breset())
      dispatch(screset())
      dispatch(dreset())
      dispatch(sbreset())
    }
  }, [dispatch])

  return (
    <MainLayout title="Demandes de bourse" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
                <i className="pi-home-6-line"></i>
                <span>Accueil</span>
            </Link>
            <span> | </span>
            <p>Demandes de bourse</p>
        </div>
        {
          isLoading || isBLoading || isSLoading || isDLoading || isScLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Demandes de bourse</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                    <div className='kui-page-list-actions-display'>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.demande_bourses+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-list-check-line"></i>
                        </button>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.demande_bourses+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-layout-grid-line"></i>
                        </button>
                    </div>
                    <div className='kui-page-list-actions-group'>
                        <div className='kui-page-list-actions-input'>
                        <span>Nombre à afficher: </span>
                        <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                        </div>
                        <div className='kui-page-list-actions-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                        </div>
                    </div>
                    <div className='kui-page-list-actions-group'>
                      <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddwModal(true)}/>
                    </div>
                </div>
              </div>
              {
                isError || isBError || isDError || isSError || isScError ? (
                  <div className='kui-page-error'>
                    <div className='kui-page-error__illustration'>
                      <NotFoundError loop={true} autoplay={true} size={{
                        height: 180,
                        width: 180
                      }}/>
                    </div>
                    <div className='kui-page-error_details'>
                      <p>Oupps! Une erreur est survenue</p>
                      <h1>{isError ? message : (isBError ? bMessage : isSError ? sMessage : isDError ? dMessage : isScError ? scMessage : "")}</h1>
                    </div>
                  </div> 
                ) : (
                  isSuccess && isSSuccess && isBSuccess && isScSuccess && isDSuccess  && demand_bourses  && students && bourses && school_years && demandes_inscription ? (
                    <div className='kui-page-list__container'>
                      <span className='kui-page-list__total'>
                        {
                          listDemandesBourses(demand_bourses, students, bourses, demandes_inscription, school_years)
                          .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                          .length > 0 ? `
                          ${listDemandesBourses(demand_bourses, students, bourses, demandes_inscription, school_years)
                            .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ).length > 1 ? listDemandesBourses(demand_bourses, students, bourses, demandes_inscription, school_years)
                              .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ).length + " elements trouvés" : listDemandesBourses(demand_bourses, students, bourses, demandes_inscription, school_years)
                              .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length +" element trouvé"} ` : "Aucun element trouvé"
                        }
                      </span>

                      {
                        listDemandesBourses(demand_bourses, students, bourses, demandes_inscription, school_years)
                        .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                        .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  listDemandesBourses(demand_bourses, students, bourses, demandes_inscription, school_years)
                                  .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-item is--md' key={index}>
                                        <div className='kui-page-list-item__min'>
                                          <div>
                                              <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "acceptee" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                              {item.statut?.toUpperCase()}
                                              </span>
                                          </div>
                                        </div>
                                        

                                        <div className='kui-page-list-item__container'>
                                            <div className='kui-page-list-item__details'>
                                              <strong>{item.numero_demande}</strong>
                                              <div style={{display: "flex", gap: ".3em"}}>
                                                <span className='kui-tag'>
                                                  {
                                                    `${item.bourse?.intitule?.toUpperCase()} - ${item.bourse?.annee?.toUpperCase()}`
                                                  }
                                                </span>
                                              </div>
                                              <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.photo_id ? API_URL2 + item?.student?.info?.photo_id : ""}/>
                                              
                                            </div>
                                        </div>
                                        <div className='kui-page-list-item__actions'>
                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setShowItemModal(true)
                                            }}>
                                                <i className={"pi-eye-line"}></i>
                                                <span>{"Apercu"}</span>
                                            </button>
                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setDeleteModal(true)
                                            }}>
                                                <i className={"pi-delete-2-line"}></i>
                                                <span>{"Supprimer"}</span>
                                            </button>
                                        </div>
                                    </div>
                                  ))        
                                }
                              </Grid>
                            ) : (
                            <Table>
                                <Row rtype='header'>
                                    <Column ctype={'small'}>
                                        <strong>#</strong>
                                    </Column>
                                    <Column ctype={'mid'}>
                                        <strong>Numero demande</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                        <strong>Etudiant</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                      <strong>Bourse</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                      <strong>Date de demande</strong>
                                    </Column>
                                    <Column ctype={'mid'}>
                                      <strong>Statut</strong>
                                    </Column>
                                    <Column ctype={'small'}>
                                      <strong>#</strong>
                                    </Column>
                                </Row>
                                {
                                  listDemandesBourses(demand_bourses, students, bourses, demandes_inscription, school_years)
                                  .filter((el: any) => el?.numero_demande?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                          <strong>{`#${index + 1}`}</strong>
                                      </Column>
                                      <Column ctype={'mid'}>
                                          <div>
                                          <span className='kui-tag'>{item.numero_demande}</span>
                                          </div>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.photo_id ? API_URL2 + item?.student?.info?.photo_id : ""}/>
                                      </Column>
                                      <Column ctype={'large'}>
                                          <div>
                                            <span className='kui-tag'>
                                              {
                                                `${item.bourse?.intitule?.toUpperCase()} - ${item.bourse?.annee?.toUpperCase()}`
                                              }
                                            </span>
                                          </div>
                                      </Column>
                                      <Column ctype={'large'}>
                                          <p>
                                            {``+(item?.date_demande ? capitalize(format(new Date(item.date_demande), "EEEE d MMMM yyyy", {locale: fr})) : "ND")}
                                          </p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                          <div>
                                            <span className={`kui-chip ${item?.statut?.toLocaleLowerCase() === "acceptee" ?  "is--success" : item?.statut?.toLocaleLowerCase() === "rejetee" ?  "is--danger" : "is--warning"}`}>
                                            {item.statut?.toUpperCase()}
                                            </span>
                                          </div>
                                      </Column>
                                        <Column ctype={'small'}>
                                            <TableOptions atype={"reduce"} tabs={[
                                              {
                                                icon: "pi-eye-line", 
                                                label: "Aperçu", 
                                                style:"is--neutral", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setShowItemModal(true)
                                                }
                                              },
                                              {
                                                icon: "pi-delete-2-line", 
                                                label: "Supprimer", 
                                                style:"is--danger", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setDeleteModal(true)
                                                }
                                              },
                                            ]}/>
                                        </Column>
                                    </Row>
                                  ))
                                }
                            </Table>
                            )
                        ) : (
                            <div className='kui-page-empty'>
                                <div className='kui-page-empty__illustration'>
                                  <EmptyList loop={true} autoplay={true} size={{
                                      height: (380 / 1.3),
                                      width: (420 / 1.3)
                                  }}/>
                                </div>
                                <div className='kui-page-empty__details'>
                                    <h1>Aucun element trouvé</h1>
                                </div>
                            </div>
                        )
                      }
                    </div>
                  ) : null
                )
              }
              {addwModal && bourses && students && demandes_inscription && school_years && <AddModal 
                demandes={demandes_inscription} 
                bourses={bourses} 
                annees={school_years} 
                students={students} 
                fnc={(val?: boolean) => setAddwModal(val || false)}
              />}
              {editModal && selectItemModal ? <DecisionModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)} /> : null}

              {addModal && selectItemModal ? <CreateSutentModal item={selectItemModal} fnc={(val?: boolean) => setAddModal(val || false)} /> : null}

              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

              {showItemModal && selectItemModal ? (
                <Modal size={'is--full-page'} withClose={true} id={'show-category'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                    <div className='kui-modal-box__header'>
                        <h2>Aperçue d'une demande</h2>
                    </div>
    
                    <div className='kui-modal-full-page'>
                      <div className='kui-modal-full-page__container'>
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__illustration kui-modal-full-page-illustration is--left'>
                              <div className='kui-modal-full-page-illustration__container is--square'>
                                {
                                  selectItemModal?.student?.info?.photo_id ? (
                                    ""
                                  ) : (
                                    <span>{creerInitiales(selectItemModal?.student?.info?.profile?.nom + " "+selectItemModal?.student?.info?.profile?.prenom)}</span>
                                  )
                                }
                              </div>
                            </div>
                            <div className='kui-modal-full-page-details'>
                              <h1>{selectItemModal?.student?.info?.profile?.nom?.toUpperCase() + " "+selectItemModal?.student?.info?.profile?.prenom}</h1>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Sexe: <strong>{selectItemModal?.student?.info?.profile?.sexe?.toUpperCase()}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Email: <strong>{selectItemModal?.email}</strong> </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Telephone: <strong>{selectItemModal?.student?.info?.profile?.telephone || "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Nationalité: <strong>{selectItemModal?.student?.info?.profile?.nationalite || "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Date de naissance: <strong>{selectItemModal?.student?.info?.profile?.date_de_naissance ? `${capitalize(format(new Date(selectItemModal?.student?.info.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr}))} (${calculateAge(selectItemModal?.student?.info?.profile?.date_de_naissance)} ans)` : "ND"}</strong></Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Lieu et Pays de naissance: <strong>{(selectItemModal?.student?.info?.profile?.lieu_de_naissance || "ND") + " - " + (selectItemModal?.student?.info?.profile?.pays_de_naissance || "ND")}</strong> </Grid>
                              <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Adresse et Pays de residence: <strong>{(selectItemModal?.student?.info?.profile?.adresse || "ND")+ " - " + (selectItemModal?.student?.info?.profile?.ville || "ND")+ " - " + (selectItemModal?.student?.info?.profile?.pays_de_residence || "ND")}</strong> </Grid>
                            </div>
                          </div>
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__list kui-modal-full-page-list'>
                              <h2>Informations de la demande de bourse</h2>

                              <div className='kui-modal-full-page-list__container'>
                                <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>

                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Numero de la demande: <strong>{selectItemModal?.numero_demande}</strong> 
                                  </Grid>
                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Date de la demande: <strong>{selectItemModal?.date_demande ? `${capitalize(format(new Date(selectItemModal?.date_demande), "EEEE d MMMM yyyy", {locale: fr}))}` : "ND"}</strong> 
                                  </Grid>
                                  <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>
                                    Bourse demandée: <strong>{selectItemModal?.bourse?.intitule?.toUpperCase() + " - " + selectItemModal?.bourse?.annee?.toUpperCase() }</strong> 
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                          <div className='kui-modal-full-page__section'>
                            <div className='kui-modal-full-page__list kui-modal-full-page-list'>
                              <h2>Documents</h2>
                              {
                                selectItemModal?.fichiers?.length > 0 ? (
                                  <div className='kui-modal-full-page-list__container'>
                                    <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                                      {
                                        selectItemModal?.fichiers?.map((item: any, index: number) => (
                                          <div className='kui-modal-full-page-list-item' key={index}>
                                            <div className='kui-modal-full-page-list-item__container'>
                                              <div className='kui-modal-full-page-list-item__illustration'>
                                                  <i className="pi-documents-line"></i>
                                              </div>
                                              <div className='kui-modal-full-page-list-item__text'>
                                                  <strong>{item?.nom_fichier}</strong>
                                                  <span>{`Type: ${item?.type_fichier?.toUpperCase()}`}</span>
                                              </div>
                                              <div>
                                                <Button iconLeftClass='pi-download-2-line' bstyle='is--primary' btype='button' btypo='is--icon-btn' onClick={() => {fetchFile(API_URL2 + item?.fichier)}} label={'Telecharger'}/>
                                              </div>
                                            </div>
                                        </div>
                                        ))
                                      }
                                    </Grid>
                                  </div>
                                ) : (
                                  <div className='kui-modal-empty'>
                                    <EmptyList loop={true} autoplay={true} size={{
                                        height: 190,
                                        width: 290
                                    }}/>
                                    <span>Aucun fichier</span>
                                </div>
                                )
                              }
                            </div>
                          </div>
                      </div>
                    </div>
                    
                    <ModalActions tabsActions={[
                        // {
                        //   icon: "pi-edit-3-line", 
                        //   type: "button", 
                        //   label:"Modifier", 
                        //   style:"is--primary", 
                        //   active: true,
                        //   onClick: () => {
                        //     navigate(links.edit_demande_inscription + "/" + selectItemModal.numero_demande)
                        //   },
                        //   show: true
                        // },
                        {
                          icon: "pi-check-circle-line", 
                          type: "button", 
                          label:"Decision", 
                          style:"is--success", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setEditModal(true)
                          },
                          show: selectItemModal.statut === "en_attente"
                        },
                        {
                          icon: "pi-user-4-line", 
                          type: "button", 
                          label:"Confirmer l'attribution de la bourse", 
                          style:"is--neutral", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setAddModal(true)
                          },
                          // show: selectItemModal.statut === "acceptee"
                          show: false
                        },
                        {
                          icon: "pi-delete-2-line", 
                          type: "button", 
                          label:"Supprimer", 
                          style:"is--danger", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setDeleteModal(true)
                          },
                          show: true
                        },
                        {
                          icon: "pi-close-line", 
                          type: "button", 
                          label:"Fermer", 
                          style:"is--neutral", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                          },
                          show: true
                        },
                    ]}/>
                </Modal>
              ) : null}  
            </>
          )
        }
        
      </div>
    </MainLayout>
  )
}
