import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../../layout'
import { Link, useNavigate } from 'react-router-dom'
import { links } from '../../../../../routes'
import { UserAuthContext } from '../../../../../../context/AuthContext'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store'
import { getCycles, reset } from '../../../../../../app/redux/features/cursus/cycle.slice'
import { getSchoolYears, getSubscriptionYears, reset as sreset  } from '../../../../../../app/redux/features/school_year/school_year.slice'
import { getFilieres, reset as freset  } from '../../../../../../app/redux/features/cursus/filiere.slice'
import { optionifyAnnesScolaire, optionifyCycle, optionifyFiliere, optionifyNiveauFilieres, optionifySession, paysOptions, sexeOptions, slugifyFileName } from '../../../../../../app/utils'
import { AddFileSuccess, LoaderAnimation, NotFoundError } from '../../../../../assets/illustration'
import { setDemandInscription } from '../../../../../../app/redux/features/inscription/demand.slice'
import { Button, Grid, KAlert, Selectfield, Textfield } from '../../../../../components'
import { getNiveauFilieres, reset as nreset } from '../../../../../../app/redux/features/cursus/filiere_niveau.slice'

export const DemandInscriptionCreate = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
        navigate(links.login + `?url=${links.create_demande_inscription}`)
    }
  }, [token, navigate]);

  const [data, setData] = useState<any>({
    email: "",
    information_financiere: "",
    information_supplementaire: "",
    information_pertinente: "",
    serie_du_bac: "",
    annee_du_bac: "",
    annee_universitaire: "",
    cycle: "",
    filiere: "",
    niveau: "",
    session_inscription: ""
  })
  
  const [dataProfile, setDataProfile] = useState<any>({
    nom: "",
    prenom: "",
    telephone: "",
    ville: "",
    date_de_naissance: "",
    lieu_de_naissance: "",
    pays_de_naissance: "",
    nationalite: "",
    sexe: "",
    pays_de_residence: "",
    adresse: ""
  })
  
  const [error, setError] = useState<any>({})
  const [fichiers, setFichiers] = useState<any[]>([])

  const [formation_anterieure, setFormationAnterieure] = useState<any>({
    intitule_diplome: "",
    etablissement: "",
    annee_obtention: ""
  })

  const [formations_anterieures, setFormationsAnterieures] = useState<any[]>([])
  
  const dispatch = useAppDispatch();
  const { isCreateError, isCreateLoading, isCreateSuccess, demande_inscription, message } = useAppSelector((state) => state.demande_inscription);
  const { isError, isSuccess, isLoading, cycles, message: cMessage } = useAppSelector((state) => state.cycle);
  const { isError: isSError, isSuccess: isSSuccess, isLoading: isSLoading, school_years, subscription_sessions, message: sMessage } = useAppSelector((state) => state.school_year);
  const { isError: isFError, isSuccess: isFSuccess, isLoading: isFLoading, filieres, message: fMessage } = useAppSelector((state) => state.filiere);
  const { isError: isNError, isSuccess: isNSuccess, isLoading: isNLoading, niveau_filieres, message: nMessage } = useAppSelector((state) => state.filiere_niveau);

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 4
      if(type < max){
        setImagesBase(file[0], {
          nom_fichier: slugifyFileName(file[0].name),
          type_fichier: e.target?.id
        })
      }else{
        setError({
          field: "global",
          message: "Votre fichier ne doit pas dépasser 8Mo"
        })
        return
      }
    }
  }

  const setImagesBase = (file: any, info: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setFichiers(list => [...list.filter(el => el.image !== reader.result), {...info, fichier: reader.result}])
    }
  }

  const addFormation = () => {
    if(!formation_anterieure.intitule_diplome){
      setError({
        field: "formation_anterieure_intitule_diplome",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!formation_anterieure.annee_obtention){
      setError({
        field: "formation_anterieure_annee_obtention",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!formation_anterieure.etablissement){
      setError({
        field: "formation_anterieure_etablissement",
        message: "Veuillez renseigner ce champs"
      })
      return
    }

    setFormationsAnterieures((list) => [...list.filter((el: any) => el != formation_anterieure), formation_anterieure])
    setFormationAnterieure({
      intitule_diplome: "",
      etablissement: "",
      annee_obtention: ""
    })
  }

  const removeFormation = (item: any) => {
    setFormationsAnterieures((list) => [...list.filter((el: any) => el != item)])
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.email){
      setError({
          field: "email",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.annee_universitaire){
      setError({
          field: "annee_universitaire",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.cycle){
      setError({
          field: "cycle",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.filiere){
      setError({
          field: "filiere",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.niveau){
      setError({
          field: "niveau",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.session_inscription){
      setError({
          field: "niveau",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.nom){
      setError({
          field: "profile_nom",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.prenom){
      setError({
          field: "profile_prenom",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.date_de_naissance){
      setError({
          field: "profile_date_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.telephone){
      setError({
          field: "profile_telephone",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.sexe){
      setError({
          field: "profile_sexe",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.nationalite){
      setError({
          field: "profile_nationalite",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.pays_de_naissance){
      setError({
          field: "profile_pays_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.lieu_de_naissance){
      setError({
          field: "profile_lieu_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(formations_anterieures?.length < 1){
        setError({
            field: "global",
            message: "Veuillez entrer une formation anterieure"
        })
        return
    }
    // setCloseM(true)
    dispatch(setDemandInscription({
      ...data, 
      profile: dataProfile, 
      formations_anterieures, 
      fichiers
    }))
  }

  useEffect(() => {
    dispatch(getCycles({}))
    dispatch(getSchoolYears({}))
    dispatch(getSubscriptionYears({}))
    dispatch(getFilieres({}))
    dispatch(getNiveauFilieres({}))

    return () => {
      dispatch(reset())
      dispatch(sreset())
      dispatch(freset())
      dispatch(nreset())
    }
  }, [dispatch])

  return (
    <MainLayout title="Demande d'inscription" isMin={false} here='demande_inscription'>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
                <i className="pi-home-6-line"></i>
                <span>Accueil</span>
            </Link>
            <span> | </span>
            <Link className='kui-link' to={links.demande_inscription}>
                <span>Demande d'inscription</span>
            </Link>
            <span> | </span>
            <p>Ajout d'une demande</p>
        </div>
        {
          isLoading || isSLoading || isFLoading || isNLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            isFError || isError || isNError || isSError ? (
              <div className='kui-page-error'>
                <div className='kui-page-error__illustration'>
                  <NotFoundError loop={true} autoplay={true} size={{
                      height: 180,
                      width: 180
                  }}/>
                </div>
                <div className='kui-page-error_details'>
                  <p>Oupps! Une erreur est survenue</p>
                  <h1>{isError ? cMessage : isFError ? fMessage : isNError ? nMessage : (isSError ? sMessage : "")}</h1>
                </div>
              </div> 
            ) : (
              isSuccess && isSSuccess && isFSuccess && isNSuccess && isFSuccess && cycles && filieres && niveau_filieres && school_years && subscription_sessions ? (
                <>
                  <div className='kui-page__header kui-page-header'>
                    <h2>Demande d'inscription</h2>
                  </div>

                  <div className='kui-page-forms'>
                    {
                      isCreateSuccess && demande_inscription ? (
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                          <div className={'kui-page-form'}>
                            <div className='kui-page-empty'>
                              <div className='kui-page-empty__illustration'>
                                <AddFileSuccess loop={true} autoplay={true} size={{
                                  height: (120),
                                  width: (120)
                                }}/>
                              </div>
                              <div className='kui-page-empty__details'>
                                <h1>La demande d'inscription a bien été ajoutée.</h1>
                              </div>
                            </div>
                          </div>
                          <div className={'kui-page-form'}>
                            <div className='kui-page-form__list kui-page-form-list'>
                              <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                                <div className='kui-page-form-action'>
                                  <Button label={'Revenir à la liste'} btype='button' onClick={() => navigate(links.demande_inscription)}/>
                                </div> 
                              </Grid>
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                          {
                            error?.field === "global" ? (
                              <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
                            ) : null
                          }
                          {
                            isCreateError ? (
                              <KAlert status={'st--danger'} title={'Oupss!! Une erreur est survenue'} message={message}/>
                            ) : null
                          }
                          <div className={'kui-page-form'}>
                            <div className='kui-page-form__header kui-page-form-header'>
                              <h3>Information de l'etudiant</h3>
                            </div>
                            <div className='kui-page-form__list kui-page-form-list'>
                              <div className='kui-page-form-fields'>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_nom'} 
                                    value={dataProfile.nom} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, nom: val})} 
                                    type={"text"}
                                    label='Nom de famille'
                                    placeholder={'Ex: Kabore'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_prenom'} 
                                    value={dataProfile.prenom} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, prenom: val})} 
                                    type={"text"}
                                    label='Prenom(s)'
                                    placeholder={'Ex: Jean Victor'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'profile_sexe'} 
                                    value={dataProfile.sexe} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, sexe: val})} 
                                    tabs={sexeOptions}
                                    label='Sexe'
                                    placeholder={'Ex: Masculin ou Feminin'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_nationalite'} 
                                    value={dataProfile.nationalite} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, nationalite: val})} 
                                    type={"text"}
                                    label='Nationalité'
                                    placeholder={'Ex: Burkinabe'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_date_de_naissance'} 
                                    value={dataProfile.date_de_naissance} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, date_de_naissance: val})} 
                                    type={"date"}
                                    label='Date de naissance'
                                    placeholder={'Ex:...'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_lieu_de_naissance'} 
                                    value={dataProfile.lieu_de_naissance} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, lieu_de_naissance: val})} 
                                    type={"text"}
                                    label='Lieu de naissance'
                                    placeholder={'Ex: Bobo-Dioulasso'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'profile_pays_de_naissance'} 
                                    value={dataProfile.pays_de_naissance} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_naissance: val})} 
                                    tabs={paysOptions}
                                    label='Pays de naissance'
                                    placeholder={'Ex: Burkina Faso'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_adresse'} 
                                    value={dataProfile.adresse} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, adresse: val})} 
                                    type={"text"}
                                    label='Adresse'
                                    placeholder={'Ex: Secteur 30'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_ville'} 
                                    value={dataProfile.ville} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, ville: val})} 
                                    type={"text"}
                                    label='Ville de residence'
                                    placeholder={'Ex: Bobo-Dioulasso'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'profile_pays_de_residence'} 
                                    value={dataProfile.pays_de_residence} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_residence: val})} 
                                    tabs={paysOptions}
                                    label='Pays de residence'
                                    placeholder={'Ex: Burkina Faso'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'profile_telephone'} 
                                    value={dataProfile.telephone} 
                                    require={true} 
                                    changeValue={(val: any) => setDataProfile({...dataProfile, telephone: val})} 
                                    type={"phone"}
                                    label='Numero de telephone'
                                    placeholder={'Ex: 0022675346789'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'email'} 
                                    value={data.email} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, email: val})} 
                                    type={"email"}
                                    label='Email'
                                    placeholder={'Ex: email@exemple.com'}
                                    error={error}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={'kui-page-form'}>
                            <div className='kui-page-form__header kui-page-form-header'>
                              <h3>Information de la demande</h3>
                            </div>
                            <div className='kui-page-form__list kui-page-form-list'>
                              <div className='kui-page-form-fields'>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'annee_universitaire'} 
                                    value={data.annee_universitaire} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, annee_universitaire: val})} 
                                    tabs={optionifyAnnesScolaire(school_years)}
                                    label='Année universitaire'
                                    placeholder={'Ex: 2025-2026'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'session_inscription'} 
                                    value={data.session_inscription} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, session_inscription: val})} 
                                    tabs={data?.annee_universitaire ? optionifySession(subscription_sessions?.filter((el: any) => el.annee_scolaire === data?.annee_universitaire), school_years) : optionifySession(subscription_sessions, school_years) }
                                    label={'Session d\'inscription'}
                                    placeholder={'Ex: Session'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'cycle'} 
                                    value={data.cycle} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, cycle: val})} 
                                    tabs={optionifyCycle(cycles)}
                                    label='Cycle'
                                    placeholder={'Ex: ...'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'filiere'} 
                                    value={data.filiere} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, filiere: val})} 
                                    tabs={
                                      data.cycle ? (
                                        optionifyFiliere(filieres.filter((el: any) => el.cycle === data.cycle), cycles)
                                      ) : (
                                        data.annee_universitaire ? (
                                          optionifyFiliere(filieres.filter((el: any) => school_years?.filter((el: any) => el.id === data.annee_universitaire)[0]?.filieres?.includes(el.id)), cycles)
                                        ) : (
                                          optionifyFiliere(filieres, cycles)
                                        )
                                      )
                                    }
                                    label='Filière'
                                    placeholder={'Ex: ...'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Selectfield 
                                    id={'niveau'} 
                                    value={data.niveau} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, niveau: val})} 
                                    tabs={data.filiere ? optionifyNiveauFilieres(niveau_filieres.filter((el: any) => el.filiere === data.filiere), filieres) : optionifyNiveauFilieres(niveau_filieres, filieres)}
                                    label='Niveau'
                                    placeholder={'Ex: ...'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'annee_du_bac'} 
                                    value={data.annee_du_bac} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, annee_du_bac: val})} 
                                    type={"text"}
                                    label='Année du BAC'
                                    placeholder={'Ex: 2017'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'serie_du_bac'} 
                                    value={data.serie_du_bac} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, serie_du_bac: val})} 
                                    type={"text"}
                                    label='Serie du BAC'
                                    placeholder={'Ex: D'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'information_financiere'} 
                                    value={data.information_financiere} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, information_financiere: val})} 
                                    type={"text"}
                                    label='Information financiere'
                                    placeholder={'Ecrire ici...'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'information_pertinente'} 
                                    value={data.information_pertinente} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, information_pertinente: val})} 
                                    type={"text"}
                                    label='Information pertinente'
                                    placeholder={'Ecrire ici...'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'information_supplementaire'} 
                                    value={data.information_supplementaire} 
                                    require={true} 
                                    changeValue={(val: any) => setData({...data, information_supplementaire: val})} 
                                    type={"text"}
                                    label='Information supplimentaire'
                                    placeholder={'Ecrire ici...'}
                                    error={error}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={'kui-page-form'}>
                            <div className='kui-page-form__header kui-page-form-header'>
                              <h3>Formations anterieures</h3>
                            </div>
                            <div className='kui-page-form__list kui-page-form-list'>
                            {
                              formations_anterieures?.length > 0 ? (
                                <div className='kui-page-form-list__container'>
                                  <br />
                                  <h4>Formations enregistrées</h4>
                                  <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={0.5}>
                                    {
                                      formations_anterieures?.map((item: any, index: number) => (
                                        <div className='kui-page-form-list-item' key={index}>
                                          <div className='kui-page-form-list-item__container'>
                                            <strong>{item?.intitule_diplome}</strong>
                                            <span>{item.etablissement + " - Obtenu en" +item.annee_obtention}</span>
                                          </div>
                                          <div className='kui-page-form-list-item__action'>
                                            <button className='kui-link is--danger' type='button' onClick={() => removeFormation(item)}>
                                              <span>Supprimer</span>
                                            </button>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </Grid>
                                </div>
                              ) : null
                            }
                              <div className='kui-page-form-fields'>
                                <div className='kui-page-form-field is--full'>
                                  <Textfield 
                                    id={'formation_anterieure_intitule_diplome'} 
                                    value={formation_anterieure.intitule_diplome} 
                                    require={true} 
                                    changeValue={(val: any) => setFormationAnterieure({...formation_anterieure, intitule_diplome: val})} 
                                    type='text'
                                    label='Intitulé du diplôme'
                                    placeholder={'Ex: Formation en...'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'formation_anterieure_etablissement'} 
                                    value={formation_anterieure.etablissement} 
                                    require={true} 
                                    changeValue={(val: any) => setFormationAnterieure({...formation_anterieure, etablissement: val})} 
                                    type='text'
                                    label='Etablissement'
                                    placeholder={'Ex: Etablissement'}
                                    error={error}
                                  />
                                </div>
                                <div className='kui-page-form-field'>
                                  <Textfield 
                                    id={'formation_anterieure_annee_obtention'} 
                                    value={formation_anterieure.annee_obtention} 
                                    require={true} 
                                    changeValue={(val: any) => setFormationAnterieure({...formation_anterieure, annee_obtention: val})} 
                                    type='date'
                                    label={'Année d\'obtention'}
                                    placeholder={'Ex: 2023'}
                                    error={error}
                                  />
                                </div>
                              </div>
                              <div className='kui-page-form-action'>
                                <Button label={'Enregistrer la formation'} 
                                  btype='button' 
                                  bstyle='is--secondary' 
                                  active={true}
                                  iconLeftClass={"pi-add-circle-line"}
                                  onClick={() => addFormation()}
                                />
                              </div> 
                            </div>
                          </div>

                          <div className={'kui-page-form'}>
                            <div className='kui-page-form__header kui-page-form-header'>
                              <h3>Fichiers</h3>
                            </div>
                            <div className='kui-page-form__list kui-page-form-list'>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='cnib' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "cnib").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "cnib")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "cnib")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "cnib"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="cnib">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter le fichier d'une carte d'identité ou un passeport en cours de validité`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} accept='.png,.jpg,jpeg' id='photos' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "photos").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "photos")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "photos")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "photos"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="photos">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter une photo d'identité`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='recommandation' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "recommandation").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "recommandation")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "recommandation")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "recommandation"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="recommandation">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter une lettre de recommandation`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='assurance_maladie' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "assurance_maladie").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "assurance_maladie")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "assurance_maladie")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "assurance_maladie"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="assurance_maladie">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter une attestation d'assurance maladie`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='justificatif_domicile' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "justificatif_domicile").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "justificatif_domicile")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "justificatif_domicile")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "justificatif_domicile"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="justificatif_domicile">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter un justificatif de domicile`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='releve' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "releve").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "releve")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "releve")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "releve"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="releve">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter un relevé de notes du baccalauréat ou du diplôme équivalent`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='bac' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "bac").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "bac")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "bac")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "bac"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="bac">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter une copie du baccalauréat ou du diplôme équivalent`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='diploma' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "diploma").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "diploma")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "diploma")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "diploma"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="diploma">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter une copie des diplômes, certificats ou titres obtenus`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='motivation' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "motivation").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "motivation")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "motivation")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "motivation"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="motivation">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter une lettre de motivation`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='cv' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "cv").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "cv")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "cv")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "cv"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="cv">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter un CV`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                              <div className='kui-filefield-lil'>
                                <input type="file" multiple={false} id='engagement' onChange={handleFileChangeFile} />
                                {
                                  fichiers?.filter((el: any) => el.type_fichier === "engagement").length > 0 ? (
                                    <div className='kui-filefield-lil__container'>
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <span>{fichiers?.filter((el: any) => el.type_fichier === "engagement ")[0]?.type_fichier}</span>
                                        <strong>{fichiers?.filter((el: any) => el.type_fichier === "engagement ")[0]?.nom_fichier}</strong>
                                      </div>
                                      <div className='kui-filefield-lil__action'>
                                        <button className='kui-link is--danger' type='button' onClick={() => {
                                          setFichiers(fichiers.filter((el: any) => el.type_fichier !== "engagement"))
                                        }}>
                                          <span>Supprimer</span>
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label className='kui-filefield-lil__container' htmlFor="engagement">
                                      <div className='kui-filefield-lil__icon'>
                                        <i className="pi-file-Info-line"></i>
                                      </div>
                                      <div className='kui-filefield-lil__info'>
                                        <strong>{`Ajouter le fichier de l'engagement`}</strong>
                                      </div>
                                    </label>
                                  )
                                }
                              </div>
                            </div>
                          </div>

                          <div className={'kui-page-form'}>
                            <form className='kui-page-form__list kui-page-form-list' onSubmit={handleSubmit}>
                              <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                                <div className='kui-page-form-action'>
                                  <Button label={(isCreateLoading) ? "Creation en cours" :'Enregistrer et ajouter'} 
                                    btype='submit' 
                                    bstyle='is--primary' 
                                    active={!(isCreateLoading)}
                                    iconLeftClass={(isCreateLoading) ? "pi-spinner5" : "pi-add-circle-line"}
                                  />
                                </div> 
                              </Grid>
                            </form>
                          </div>
                          
                        </Grid>
                      )
                    }
                  </div>
                </>
              ) : null
            )
          )
        }
      </div>
    </MainLayout>
  )
}
