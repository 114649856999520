import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SeanceState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondesenseignements/sceances/"

const initialState: SeanceState = {
    seances: [],
    seance: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setSeance = createAsyncThunk<any, Object>(
    'seance/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getSeances = createAsyncThunk<any, Object>(
    'seance/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getSeance = createAsyncThunk<any, Object>(
    'seance/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateSeance = createAsyncThunk<any, Object>(
    'seance/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteSeance = createAsyncThunk<any, Object>(
    'seance/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            const item = await deleteProtecteItems(data)
            return {item, content}
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const seanceSlice = createSlice({
    name: 'seance',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSeances.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSeances.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.seances = action.payload
            })
            .addCase(getSeances.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getSeance.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSeance.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.seance = action.payload
            })
            .addCase(getSeance.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setSeance.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setSeance.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.seances = [action.payload, ...state.seances]
            })
            .addCase(setSeance.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateSeance.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateSeance.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.seances = [action.payload, ...state.seances.filter((seance: any) => seance?.id !== action.payload?.id)]
                state.seance = action.payload
            })
            .addCase(updateSeance.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteSeance.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteSeance.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.seances = state.seances.filter((seance: any) => seance?.id !== action.payload?.content)
            })
            .addCase(deleteSeance.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = seanceSlice.actions
export default seanceSlice.reducer