import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { MentorState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondesmentorats/mentors/"

const initialState: MentorState = {
    mentors: [],
    mentor: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setMentor = createAsyncThunk<any, Object>(
    'mentor/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getMentors = createAsyncThunk<any, Object>(
    'mentor/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getMentor = createAsyncThunk<any, Object>(
    'mentor/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateMentor = createAsyncThunk<any, Object>(
    'mentor/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteMentor = createAsyncThunk<any, Object>(
    'mentor/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            const item = await deleteProtecteItems(data)
            return {item, content}
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const mentorSlice = createSlice({
    name: 'mentor',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMentors.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMentors.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.mentors = action.payload
            })
            .addCase(getMentors.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getMentor.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMentor.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.mentor = action.payload
            })
            .addCase(getMentor.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setMentor.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setMentor.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.mentors = [action.payload, ...state.mentors]
                state.mentor = action.payload
            })
            .addCase(setMentor.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateMentor.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateMentor.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.mentors = [action.payload, ...state.mentors.filter((mentor: any) => mentor?.id !== action.payload?.id)]
                state.mentor = action.payload
            })
            .addCase(updateMentor.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteMentor.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteMentor.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.mentors = state.mentors.filter((mentor: any) => mentor?.id !== action.payload?.content)
            })
            .addCase(deleteMentor.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = mentorSlice.actions
export default mentorSlice.reducer