import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { reset, setDemandMentorat } from '../../../../../../app/redux/features/student/demand_mentorat.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { paysOptions, sexeOptions, slugifyFileName, typeMentoratOption } from '../../../../../../app/utils';
import { links } from '../../../../../routes';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../../../layout';
import { Button, Grid, KAlert, Selectfield, Textfield } from '../../../../../components';
import { AddFileSuccess } from '../../../../../assets/illustration';

export const CreateDemandeMentoratPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()
  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.create_demand_mentorats}`)
    }
  }, [token, navigate]);

  const [data, setData] = useState<any>({
    email: "",
    profession_actuelle: "",
    experience_professionnelle: "",
    competence_specifique: "",
    raison_devenir_mentor: "",
    objectif_mentorat: "",
    nombre_heure_mentorat: "",
    type_mentorat: "",
    duree_mentorat: "",
  })
  
  const [dataProfile, setDataProfile] = useState<any>({
    nom: "",
    prenom: "",
    telephone: "",
    ville: "",
    date_de_naissance: "",
    lieu_de_naissance: "",
    pays_de_naissance: "",
    nationalite: "",
    sexe: "",
    pays_de_residence: "",
    adresse: ""
  })

  const [error, setError] = useState<any>({})
  const [fichiers, setFichiers] = useState<any[]>([])

  const dispatch = useAppDispatch();
  const { isCreateError, isCreateLoading, isCreateSuccess, demand_mentorat, message } = useAppSelector((state) => state.demand_mentorat);

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 4
      if(type < max){
        setImagesBase(file[0], {
          nom_fichier: slugifyFileName(file[0].name),
          type_fichier: `Fichier-${fichiers.length + 1}`
        })
      }else{
        setError({
          field: "global",
          message: "Votre fichier ne doit pas dépasser 8Mo"
        })
        return
      }
    }
  }

  const setImagesBase = (file: any, info: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setFichiers(list => [...list.filter(el => el.image !== reader.result), {...info, fichier: reader.result}])
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.email){
      setError({
          field: "email",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.profession_actuelle){
      setError({
          field: "profession_actuelle",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.experience_professionnelle){
      setError({
          field: "experience_professionnelle",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.competence_specifique){
      setError({
          field: "competence_specifique",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.raison_devenir_mentor){
      setError({
          field: "raison_devenir_mentor",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nombre_heure_mentorat){
      setError({
          field: "nombre_heure_mentorat",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.type_mentorat){
      setError({
          field: "type_mentorat",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.duree_mentorat){
      setError({
          field: "duree_mentorat",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.nom){
      setError({
          field: "profile_nom",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.prenom){
      setError({
          field: "profile_prenom",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.date_de_naissance){
      setError({
          field: "profile_date_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.telephone){
      setError({
          field: "profile_telephone",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.sexe){
      setError({
          field: "profile_sexe",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.nationalite){
      setError({
          field: "profile_nationalite",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!dataProfile.pays_de_naissance){
      setError({
          field: "profile_pays_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    if(!dataProfile.lieu_de_naissance){
      setError({
          field: "profile_lieu_de_naissance",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    
    // setCloseM(true)
    dispatch(setDemandMentorat({
      ...data, 
      profile: dataProfile, 
      fichiers
    }))
  }

  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])
  
  return (
    <MainLayout title="Devenir mentor" isMin={false} here='demand_mentorat'>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
                <i className="pi-home-6-line"></i>
                <span>Accueil</span>
            </Link>
            <span> | </span>
            <Link className='kui-link' to={links.demand_mentorats}>
                <span>Demandes de mentorat</span>
            </Link>
            <span> | </span>
            <p>Ajout d'une demande</p>
        </div>
        
        <div className='kui-page__header kui-page-header'>
          <h2>Devenir mentor</h2>
        </div>

        <div className='kui-page-forms'>
          {
            isCreateSuccess && demand_mentorat ? (
              <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                <div className={'kui-page-form'}>
                  <div className='kui-page-empty'>
                    <div className='kui-page-empty__illustration'>
                      <AddFileSuccess loop={true} autoplay={true} size={{
                        height: (120),
                        width: (120)
                      }}/>
                    </div>
                    <div className='kui-page-empty__details'>
                      <h1>La demande du mentorat a bien été ajoutée.</h1>
                    </div>
                  </div>
                </div>
                <div className={'kui-page-form'}>
                  <div className='kui-page-form__list kui-page-form-list'>
                    <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                      <div className='kui-page-form-action'>
                        <Button label={'Revenir à la liste'} btype='button' onClick={() => navigate(links.demand_mentorats)}/>
                      </div> 
                    </Grid>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
                {
                  error?.field === "global" ? (
                    <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
                  ) : null
                }
                {
                  isCreateError ? (
                    <KAlert status={'st--danger'} title={'Oupss!! Une erreur est survenue'} message={message}/>
                  ) : null
                }
                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Information du demandeur</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                    <div className='kui-page-form-fields'>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_nom'} 
                          value={dataProfile.nom} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, nom: val})} 
                          type={"text"}
                          label='Nom de famille'
                          placeholder={'Ex: Kabore'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_prenom'} 
                          value={dataProfile.prenom} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, prenom: val})} 
                          type={"text"}
                          label='Prenom(s)'
                          placeholder={'Ex: Jean Victor'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Selectfield 
                          id={'profile_sexe'} 
                          value={dataProfile.sexe} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, sexe: val})} 
                          tabs={sexeOptions}
                          label='Sexe'
                          placeholder={'Ex: Masculin ou Feminin'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_nationalite'} 
                          value={dataProfile.nationalite} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, nationalite: val})} 
                          type={"text"}
                          label='Nationalité'
                          placeholder={'Ex: Burkinabe'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_date_de_naissance'} 
                          value={dataProfile.date_de_naissance} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, date_de_naissance: val})} 
                          type={"date"}
                          label='Date de naissance'
                          placeholder={'Ex:...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_lieu_de_naissance'} 
                          value={dataProfile.lieu_de_naissance} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, lieu_de_naissance: val})} 
                          type={"text"}
                          label='Lieu de naissance'
                          placeholder={'Ex: Bobo-Dioulasso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Selectfield 
                          id={'profile_pays_de_naissance'} 
                          value={dataProfile.pays_de_naissance} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_naissance: val})} 
                          tabs={paysOptions}
                          label='Pays de naissance'
                          placeholder={'Ex: Burkina Faso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_adresse'} 
                          value={dataProfile.adresse} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, adresse: val})} 
                          type={"text"}
                          label='Adresse'
                          placeholder={'Ex: Secteur 30'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_ville'} 
                          value={dataProfile.ville} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, ville: val})} 
                          type={"text"}
                          label='Ville de residence'
                          placeholder={'Ex: Bobo-Dioulasso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Selectfield 
                          id={'profile_pays_de_residence'} 
                          value={dataProfile.pays_de_residence} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, pays_de_residence: val})} 
                          tabs={paysOptions}
                          label='Pays de residence'
                          placeholder={'Ex: Burkina Faso'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profile_telephone'} 
                          value={dataProfile.telephone} 
                          require={true} 
                          changeValue={(val: any) => setDataProfile({...dataProfile, telephone: val})} 
                          type={"phone"}
                          label='Numero de telephone'
                          placeholder={'Ex: 0022675346789'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'email'} 
                          value={data.email} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, email: val})} 
                          type={"email"}
                          label='Email'
                          placeholder={'Ex: email@exemple.com'}
                          error={error}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Information de la demande</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                    <div className='kui-page-form-fields'>

                      <div className='kui-page-form-field'>
                        <Selectfield 
                          id={'type_mentorat'} 
                          value={data.type_mentorat} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, type_mentorat: val})} 
                          tabs={typeMentoratOption}
                          label='Type de mentorat'
                          placeholder={'Choisir...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'profession_actuelle'} 
                          value={data.profession_actuelle} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, profession_actuelle: val})} 
                          type={"text"}
                          label={'Profession actuelle'}
                          placeholder={'Ecrire ici..'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'experience_professionnelle'} 
                          value={data.experience_professionnelle} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, experience_professionnelle: val})} 
                          type={"text"}
                          label={'Experience professionnelle'}
                          placeholder={'Ecrire ici..'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'competence_specifique'} 
                          value={data.competence_specifique} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, competence_specifique: val})} 
                          type={"text"}
                          label={'Competénce specifique'}
                          placeholder={'Ecrire ici...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'raison_devenir_mentor'} 
                          value={data.raison_devenir_mentor} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, raison_devenir_mentor: val})} 
                          type={"text"}
                          label={'Raison pour devenir mentor'}
                          placeholder={'Ecrire ici..'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'methode_collaboration_etudiant'} 
                          value={data.objectif_mentorat} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, objectif_mentorat: val})} 
                          type={"text"}
                          label={'Objectif du mentorat'}
                          placeholder={'Ecrire ici...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'nombre_heure_mentorat'} 
                          value={data.nombre_heure_mentorat} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, nombre_heure_mentorat: val})} 
                          type={"number"}
                          label={'Nombre d\'heure mentorat'}
                          placeholder={'Ecrire ici...'}
                          error={error}
                        />
                      </div>
                      <div className='kui-page-form-field'>
                        <Textfield 
                          id={'duree_mentorat'} 
                          value={data.duree_mentorat} 
                          require={true} 
                          changeValue={(val: any) => setData({...data, duree_mentorat: val})} 
                          type={"number"}
                          min={0}
                          label={'Duree du mentorat en mois'}
                          placeholder={'Ex: 1'}
                          error={error}
                        />
                      </div>
                    </div>
                  </div>
                </div>


                <div className={'kui-page-form'}>
                  <div className='kui-page-form__header kui-page-form-header'>
                    <h3>Fichiers</h3>
                  </div>
                  <div className='kui-page-form__list kui-page-form-list'>
                    
                    {
                      fichiers?.length > 0 ? (
                        fichiers.map((item: any, index: number) => (
                          <div className='kui-filefield-lil' key={index}>
                            <div className='kui-filefield-lil__container'>
                              <div className='kui-filefield-lil__icon'>
                                <i className="pi-file-Info-line"></i>
                              </div>
                              <div className='kui-filefield-lil__info'>
                                <span>{item?.type_fichier}</span>
                                <strong>{item?.nom_fichier}</strong>
                              </div>
                              <div className='kui-filefield-lil__action'>
                                <button className='kui-link is--danger' type='button' onClick={() => {
                                  setFichiers(fichiers.filter((el: any) => el !== item))
                                }}>
                                  <span>Supprimer</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <strong>Aucun fichier</strong>
                      )
                    }
                    <div className='kui-filefield-lil'>
                      <input type="file" multiple={false} id='fichier' onChange={handleFileChangeFile} />
                      
                      <label className='kui-filefield-lil__container' htmlFor="fichier">
                        <div className='kui-filefield-lil__icon'>
                          <i className="pi-file-Info-line"></i>
                        </div>
                        <div className='kui-filefield-lil__info'>
                          <strong>{`Ajouter un fichier`}</strong>
                        </div>
                      </label>
                        
                    </div>
                  </div>
                </div>

                <div className={'kui-page-form'}>
                  <form className='kui-page-form__list kui-page-form-list' onSubmit={handleSubmit}>
                    <Grid direction={'fd-row'} spacing={'jc-end'} align={'start'} wrap={true} gap={1}>
                      <div className='kui-page-form-action'>
                        <Button label={(isCreateLoading) ? "Creation en cours" :'Enregistrer et ajouter'} 
                          btype='submit' 
                          bstyle='is--primary' 
                          active={!(isCreateLoading)}
                          iconLeftClass={(isCreateLoading) ? "pi-spinner5" : "pi-add-circle-line"}
                        />
                      </div> 
                    </Grid>
                  </form>
                </div>
                
              </Grid>
            )
          }
        </div>
              
      </div>
    </MainLayout>
  )
}
