import React, { useContext, useEffect, useState } from 'react'
import { UserAuthContext } from '../../../../../context/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { links } from '../../../../routes';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { getUser, reset } from '../../../../../app/redux/features/user/user.slice';
import { MainLayout } from '../../../../layout';
import { LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { Button, Grid } from '../../../../components';
import { API_URL2, capitalize, createInitial } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale/fr';

export const AdminPage = () => {
  const { token, info } = useContext(UserAuthContext);
  const [recoverModal, setRecoverModal] = useState<boolean>(false)
  const [roleModal, setRoleModal] = useState<boolean>(false)
  const [addModal, setAddModal] = useState<boolean>(false)
  let navigate = useNavigate()
  const {slug} = useParams()
  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.admin}/${slug}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, user, message } = useAppSelector((state) => state.user);
  
  useEffect(() => {
    if(slug){
      dispatch(getUser(slug))
    }
    return () => {
      dispatch(reset())
    }
  }, [dispatch, slug]);

  return (
    <MainLayout title='Administrateur' isMin={false} here='admins'>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <Link className='kui-link' to={links.admins}>
            <span>Administrateurs</span>
          </Link>
          <span> | </span>
          <p>Apercu</p>
        </div>
        {
          isLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            (isError) ? (
              <div className='kui-page-error'>
                <div className='kui-page-error__illustration'>
                  <NotFoundError loop={true} autoplay={true} size={{
                    height: 180,
                    width: 180
                  }}/>
                </div>
                <div className='kui-page-error_details'>
                  <p>Oupps! Une erreur est survenue</p>
                  <h1>{ message }</h1>
                </div>
              </div>
            ) : (
              isSuccess && user ? (
                <div className='kui-page-account'>
        
                  <div className='kui-page-account__banner kui-page-account-banner'>
        
                    <div className='kui-page-account-banner__avatar'>
                      {
                        user?.profile?.photo_id ? (
                          <img src={API_URL2 + user?.profile?.photo_id} alt={"avatar"} />
                        ) : (
                          <span>{createInitial(user?.profile?.nom + " " + user?.profile?.prenom)}</span>
                        )
                      }
                    </div>
        
                    <div className='kui-page-account-banner__info'>
                      <div className='kui-page-account-banner__name'>
                        <h1>{user?.profile?.nom?.toUpperCase() + " " + user?.profile?.prenom}</h1>
                        {/* <span>{`@${info?.username}`}</span> */}
                      </div>
                      <div className='kui-page-account-banner__actions'>
                        <div className='kui-page-account-banner__action'>
                          <Button label={'Reinitialiser le mot de passe'} iconLeftClass='pi-edit-3-line' active={true} btype='button' bstyle='is--neutral' onClick={() => setRecoverModal(true)}/>
                        </div>
                        {/* <div className='kui-page-account-banner__action'>
                          <Button label={'Gerer ses privilèges'} iconLeftClass='pi-user-security-line' active={true} btype='button' bstyle='is--secondary' onClick={() => setRoleModal(true)}/>
                        </div> */}
                      </div>
                    </div>
                    
                  </div>
        
                  <div className='kui-page-account__grid kui-page-account-grid'>
        
                    <div className='kui-page-account-grid-item'>
                      <div className='kui-page-account-grid-item__header'>
                        <div className='kui-page-account-grid-item__illu'>
                          <i className="pi-enter-door-line"></i>
                        </div>
                        <h2>Mes informations du compte</h2>
                      </div>
                      <div className='kui-page-account-grid-item__container'>
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Nom et prenom(s): </span>
                            <strong>{user?.profile?.prenom + " " + user?.profile?.nom?.toUpperCase()}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Email: </span>
                            <strong>{`${user?.profile?.prenom}`}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Telephone: </span>
                            <strong>{`${user?.profile?.telephone}`}</strong>
                          </div>
                        </Grid>
                      </div>
                    </div>
        
                    <div className='kui-page-account-grid-item'>
                      <div className='kui-page-account-grid-item__header'>
                        <div className='kui-page-account-grid-item__illu'>
                          <i className="pi-user-4-line"></i>
                        </div>
                        <h2>Mes informations personnelles</h2>
                      </div>
                      <div className='kui-page-account-grid-item__container'>
                        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Sexe: </span>
                            <strong>{user?.profile?.sexe || "ND"}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Nationalité: </span>
                            <strong>{user?.profile?.nationalite}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Date: </span>
                            <strong>{`${user?.profile?.date_de_naissance ? capitalize(format(new Date(user?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}`}</strong>
                          </div>
                          <div className='kui-page-account-grid-item__text'>
                            <span>Lieu de naissance: </span>
                            <strong>{(user?.profile?.pays_de_naissance?.toUpperCase() || "ND") + " - " + (user?.profile?.lieu_de_naissance || "ND")}</strong>
                          </div>
                        </Grid>
                      </div>
                    </div>
                    
                  </div>
                </div>
              ) : null
            )
          )
        }
      </div>
    </MainLayout>
  )
}
