import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { links } from '../../../../../routes'
import { MainLayout } from '../../../../../layout'

export const SettingPage = () => {
  let navigate = useNavigate()
  const settings = [
    {
      icon: "pi-card-pay-line",
      path: links.type_frais,
      label: "Type de frais",
      color: "#9400FF",
      background: "#9400FF45"
    },
    {
      icon: "pi-presentation-3-line",
      path: links.type_enseignement,
      label: "Type d'enseignement",
      color: "#2450FF",
      background: "#2450FF45"
    },
    {
      icon: "pi-bed-line",
      path: links.chambre,
      label: "Type de chambres",
      color: "#a350FF",
      background: "#a350FF45"
    },
    {
      icon: "pi-world-2-line",
      path: links.zone,
      label: "Zones",
      color: "#F94C10",
      background: "#F94C1045"
    },
    {
      icon: "pi-computer-line",
      path: links.custom,
      label: "Application",
      color: "#FF4949",
      background: "#ff494945"
    },
  ]
  return (
    <MainLayout title="Parametres" here='setting' isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line-2"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <p>Paramètres</p>
        </div>
        <div className='kui-page__header kui-page-header'>
          <h1>🧰</h1>
          <h2>Paramètres</h2>
          <p>Bienvenue dans les paramètres globaux de la plateforme</p>
        </div>
        <strong>Que souhaitez-vous faire</strong>
        <div className='kui-page__settings kui-page-settings'>
          {
            settings.map((item: any, index: number) => (
              <button type='button' key={index} className='kui-page-setting-item' onClick={() => navigate(item.path)}>
                <div className='kui-page-setting-item__icon' style={{
                  color: item.color,
                  background: item.background
                }}>
                  <i className={item.icon}></i>
                </div>
                <strong>{item.label}</strong>
              </button>
            ))
          }
        </div>
      </div>
    </MainLayout>
  )
}
