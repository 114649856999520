import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BourseState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondesbourses/bourses/"

const initialState: BourseState = {
    bourses: [],
    bourse: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setBourse = createAsyncThunk<any, Object>(
    'bourse/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getBourses = createAsyncThunk<any, Object>(
    'bourse/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getBourse = createAsyncThunk<any, Object>(
    'bourse/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateBourse = createAsyncThunk<any, Object>(
    'bourse/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteBourse = createAsyncThunk<any, Object>(
    'bourse/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            const item = await deleteProtecteItems(data)
            return {item, content}
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const bourseSlice = createSlice({
    name: 'bourse',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBourses.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBourses.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bourses = action.payload
            })
            .addCase(getBourses.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getBourse.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBourse.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bourse = action.payload
            })
            .addCase(getBourse.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setBourse.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setBourse.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.bourses = [action.payload, ...state.bourses]
                state.bourse = action.payload
            })
            .addCase(setBourse.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateBourse.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateBourse.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.bourses = [action.payload, ...state.bourses.filter((bourse: any) => bourse?.id !== action.payload?.id)]
                state.bourse = action.payload
            })
            .addCase(updateBourse.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteBourse.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteBourse.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.bourses = state.bourses.filter((bourse: any) => bourse?.id !== action.payload?.content)
            })
            .addCase(deleteBourse.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = bourseSlice.actions
export default bourseSlice.reducer