import React from 'react'
import { links } from '../../../../routes'
import { SubmenuMenu } from '../../../../layout'
import { truncate } from '../../../../../app/utils'

export const SettingSubmenu = ({here}:{here?: any}) => {
    const settings_submeu = [
        {
            icon: "pi-card-pay-line",
            path: links.type_frais,
            label: "Type de frais",
            here: "type_frais",
        },
        {
            icon: "pi-presentation-3-line",
            path: links.type_enseignement,
            label: truncate("Type d'enseignement", 15),
            here: "type_enseignement",
        },
        {
            icon: "pi-bed-line",
            path: links.chambre,
            label: truncate("Type de chambres", 15),
            here: "chambre",
        },
        {
            icon: "pi-world-2-line",
            path: links.zone,
            label: "Zones",
            here: "type_frais",
        },
        {
            icon: "pi-computer-line",
            path: links.custom,
            label: "Application",
            here: "custom",
        },
    ]

    return (
        <SubmenuMenu here={here} title={'Paramètres'} tabs={settings_submeu}/>
    )
}
