import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { deleteStage, getStages, reset, setStage } from '../../../../../app/redux/features/student/stage.slice';
import { API_URL2, calculateAge, capitalize, createInitial, creerInitiales, listStages, listTeacher, slugifyFileName } from '../../../../../app/utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale/fr';
import { links } from '../../../../routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { getTeachers, reset as treset } from '../../../../../app/redux/features/teacher/teacher.slice';
import { getDemandInscriptions, reset as dreset } from '../../../../../app/redux/features/inscription/demand.slice';
import { getStudents, reset as sreset } from '../../../../../app/redux/features/student/student.slice';
import { getDemandeEnseignements, reset as ereset } from '../../../../../app/redux/features/teacher/demande_enseignement.slice';
import { MainLayout } from '../../../../layout';


const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.stage);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-cycle'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'une bourse</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
            setCloseM(true)
            dispatch(deleteStage(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const AddModal = ({students, demandes, teachers, fnc}:{demandes: any[], students: any[],teachers: any[], fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    date_de_debut_stage: "",
    enseignant: "",
    etudiant: "",
    rapport_stage: "",
  })

  const [brochure, setBrochure] = useState<any>(null)

  const [student, setStudent] = useState<any>(null)
  const [search, setSearch] = useState<string>("")
  const [teacher, setTeacher] = useState<any>(null)
  const [searchB, setSearchB] = useState<string>("")

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 4
      if(type < max){
        setImagesBase(file[0], {
          nom_fichier: slugifyFileName(file[0].name),
          type_fichier: e.target?.id
        })
      }else{
        setError({
          field: "global",
          message: "Votre fichier ne doit pas dépasser 8Mo"
        })
        return
      }
    }
  }

  const setImagesBase = (file: any, info: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setBrochure({...info, fichier: reader.result})
      setData({...data, rapport_stage: reader.result})
    }
  }

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.stage);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.date_de_debut_stage){
      setError({
        field: "date_de_debut_stage",
        message: "Veuillez renseigner ce champs"
      })
      return
    }


    if(!data.etudiant){
      setError({
        field: "global",
        message: "Veuillez choisir un etudiant"
      })
      return
    }
    if(!data.enseignant){
      setError({
        field: "global",
        message: "Veuillez choisir un enseignant"
      })
      return
    }
    if(!data.rapport_stage){
      setError({
        field: "global",
        message: "Veuillez renseigner le rapport de stage"
      })
      return
    }
    setCloseM(true)
    dispatch(setStage({...data}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setData({
      date_de_debut_stage: "",
      etudiant: "",
      enseignant: "",
      rapport_stage: ""
    })
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-inscription'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'un stage</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Les informations ont été enregistrées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            {
              error?.field === "global" ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message} withClose={true}/>
              ) : null
            }
              <ModalForm tabsField={[
                
                {
                  field: "textfield",
                  field_type: "date",
                  field_require: true,
                  value: data.date_de_debut_stage,
                  label: "Date de debut de stage",
                  id: "date_de_debut_stage",
                  onChange: (val: any) => setData({...data, date_de_debut_stage: val}),
                  error: error,
                  placeholder: "Entrer la date de debut stage",
                  isShow: true
                },

              ]}/>

              <div className='kui-modal-box-group'>
                <div className='kui-modal-box-group__header'> <h3>Etudiant</h3></div>
                {
                  student ? (
                    <div className={`kui-modal-box-list-item`}>
                      <div className='kui-modal-box-list-item__avatar is--rounded'>
                        <span>{createInitial(student?.profile?.prenom + " " + student?.profile?.nom)}</span>
                      </div>
                      <div className='kui-modal-box-list-item__container'>
                        <strong>{student?.profile?.prenom + " " + student?.profile?.nom?.toUpperCase()}</strong>
                        <span>{`Sexe: ${student?.profile?.sexe} - Né(e) le ${student?.profile?.date_de_naissance ? capitalize(format(new Date(student?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${student?.profile?.lieu_de_naissance} - ${student?.profile?.pays_de_naissance}`}</span>
                        <button type='button' className='kui-link' onClick={() => {setStudent(null); setData({...data, etudiant: ""})}}>
                          <span>Changer</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className='kui-modal-box-list'>
                      <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                        <div className='kui-modal-box-list-action-search'>
                          <i className="pi-search-line"></i>
                          <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                      </div>
                      {
                        search?.length > 2 ? (
                          students
                          .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                          .length > 0 ? (
                            <div className='kui-modal-box-list__container'>
                              {
                                students
                                .filter((el: any) => el?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.code_etudiant?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                .slice(0, 8)
                                .map((item: any, index: number) => (
                                  <button key={index} type='button' className={`kui-modal-box-list-item ${data?.etudiant === item?._id ? "active" : ""}`} onClick={() => {
                                    setStudent( demandes.filter((el: any) => el.id ===  item.demande)[0])
                                    setData({...data, etudiant: item?.id})
                                  }}>
                                      {
                                        data?.etudiant === item?.id ? (
                                          <div className='kui-modal-box-list-item__check'>
                                            <i className="pi-minimize-line"></i>
                                          </div>
                                        ) : null
                                      }
                                      <div className='kui-modal-box-list-item__avatar is--rounded'>
                                        
                                        <span>{creerInitiales(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.nom)}</span>
                                      </div>
                                      <div className='kui-modal-box-list-item__container'>
                                        <span>Code: {item?.code_etudiant}</span>
                                        <strong>{demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.prenom + " " + demandes.filter((el: any) => el.id ===  item.demande)[0].profile?.nom?.toUpperCase()}</strong>
                                        <span>{`Sexe: ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.sexe} - Né(e) le ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance ? capitalize(format(new Date(demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.lieu_de_naissance} - ${demandes.filter((el: any) => el.id ===  item.demande)[0]?.profile?.pays_de_naissance}`}</span>
                                      </div>
                                  </button>
                                ))      
                              }
                            
                            </div>
                          ) : (
                            <span>Aucun element trouvé</span>
                          )
                        ) : (
                          <span>Rechercher</span>
                        )
                        
                      }
                    </div>
                  )
                }
              </div>
              
              <div className='kui-modal-box-group'>
                <div className='kui-modal-box-group__header'> <h3>Enseignant</h3></div>
                {
                  teacher ? (
                    <div className={`kui-modal-box-list-item`}>
                      <div className='kui-modal-box-list-item__avatar is--rounded'>
                        <span>{createInitial(teacher?.info?.profile?.prenom + " " + teacher?.info?.profile?.nom)}</span>
                      </div>
                      <div className='kui-modal-box-list-item__container'>
                        <strong>{teacher?.info?.profile?.prenom + " " + teacher?.info?.profile?.nom?.toUpperCase()}</strong>
                        <span>{`Sexe: ${teacher?.info?.profile?.sexe} - Né(e) le ${teacher?.info?.profile?.date_de_naissance ? capitalize(format(new Date(teacher?.info?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${teacher?.info?.profile?.lieu_de_naissance} - ${teacher?.info?.profile?.pays_de_naissance}`}</span>
                        <button type='button' className='kui-link' onClick={() => {setTeacher(null); setData({...data, enseignant: ""})}}>
                          <span>Changer</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className='kui-modal-box-list'>
                      <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                        <div className='kui-modal-box-list-action-search'>
                          <i className="pi-search-line"></i>
                          <input type="search" value={searchB} placeholder='Recherche...' onChange={(e) => setSearchB(e.target.value)}/>
                        </div>
                      </div>
                      {
                        searchB?.length > 2 ? (
                          teachers
                          .filter((el: any) => el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()) || el?.code_enseignant?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()))
                          .length > 0 ? (
                            <div className='kui-modal-box-list__container'>
                              {
                                teachers
                                .filter((el: any) => el?.info?.profile?.nom?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()) || el?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()) || el?.code_enseignant?.toLocaleLowerCase()?.includes(searchB?.toLocaleLowerCase()))
                                .slice(0, 8)
                                .map((item: any, index: number) => (
                                  <button key={index} type='button' className={`kui-modal-box-list-item ${data?.enseignant === item?.id ? "active" : ""}`} onClick={() => {
                                    setTeacher(item)
                                    setData({...data, enseignant: item?.id})
                                  }}>
                                      {
                                        data?.enseignant === item?.id ? (
                                          <div className='kui-modal-box-list-item__check'>
                                            <i className="pi-minimize-line"></i>
                                          </div>
                                        ) : null
                                      }
                                      <div className='kui-modal-box-list-item__avatar is--rounded'>
                                        
                                        <span>{creerInitiales(item?.info?.profile?.prenom + " " + item?.info?.profile?.nom)}</span>
                                      </div>
                                      <div className='kui-modal-box-list-item__container'>
                                        <span>Code: {item?.code_enseignant}</span>
                                        <strong>{item?.info?.profile?.prenom + " " + item?.info?.profile?.nom?.toUpperCase()}</strong>
                                        <span>{`Sexe: ${item?.info?.profile?.sexe} - Né(e) le ${item?.info?.profile?.date_de_naissance ? capitalize(format(new Date(item?.info?.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr})) : "ND"} à ${item?.info?.profile?.lieu_de_naissance} - ${item?.info?.profile?.pays_de_naissance}`}</span>
                                      </div>
                                  </button>
                                ))      
                              }
                            
                            </div>
                          ) : (
                            <span>Aucun element trouvé</span>
                          )
                        ) : (
                          <span>Rechercher</span>
                        )
                        
                      }
                    </div>
                  )
                }
              </div>

              <div className='kui-modal-fields'>
                <div className='kui-filefield-lil'>
                  <input type="file" multiple={false} id='brochure' onChange={handleFileChangeFile} />
                  {
                    brochure ? (
                      <div className='kui-filefield-lil__container'>
                        <div className='kui-filefield-lil__icon'>
                          <i className="pi-file-Info-line"></i>
                        </div>
                        <div className='kui-filefield-lil__info'>
                          <span>{brochure?.type_fichier}</span>
                          <strong>{brochure?.nom_fichier}</strong>
                        </div>
                        <div className='kui-filefield-lil__action'>
                          <button className='kui-link is--danger' type='button' onClick={() => {
                            setBrochure(null)
                            setData({...data, rapport_stage: ""})
                          }}>
                            <span>Supprimer</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <label className='kui-filefield-lil__container' htmlFor="brochure">
                        <div className='kui-filefield-lil__icon'>
                          <i className="pi-file-Info-line"></i>
                        </div>
                        <div className='kui-filefield-lil__info'>
                          <strong>{`Ajouter le rapport de stage`}</strong>
                        </div>
                      </label>
                    )
                  }
                </div>
              </div>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
      
    </Modal>
  )
}

export const StagesPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.stage}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, stages, message } = useAppSelector((state) => state.stage);
  const { isLoading: isBLoading, isError: isBError, isSuccess: isBSuccess, teachers, message: bMessage } = useAppSelector((state) => state.teacher);
  const { isLoading: isSLoading, isError: isSError, isSuccess: isSSuccess, students, message: sMessage } = useAppSelector((state) => state.student);
  const { isLoading: isDLoading, isError: isDError, isSuccess: isDSuccess, demandes_inscription, message: dMessage } = useAppSelector((state) => state.demande_inscription);
  const { isLoading: isScLoading, isError: isScError, isSuccess: isScSuccess, demande_enseignements, message: scMessage } = useAppSelector((state) => state.demande_enseignement);
    
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)
  const [showItemModal, setShowItemModal] = useState<boolean>(false)

  const [addModal, setAddModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.stage+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
  }
    
  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
        navigate(links.stage+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
    }
  }

  useEffect(() => {
    dispatch(getStages({}))
    dispatch(getTeachers({}))
    dispatch(getDemandInscriptions({}))
    dispatch(getStudents({}))
    dispatch(getDemandeEnseignements({}))
    return () => {
      dispatch(reset())
      dispatch(treset())
      dispatch(dreset())
      dispatch(sreset())
      dispatch(ereset())
    }
  }, [dispatch])
    
  return (
    <MainLayout title="Stages" isMin={false}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
              <i className="pi-home-6-line"></i>
              <span>Accueil</span>
            </Link>
            <span> | </span>
            <p>Stages</p>
        </div>
        {
          isLoading || isBLoading || isSLoading || isDLoading || isScLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Stages</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                    <div className='kui-page-list-actions-display'>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.stage+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-list-check-line"></i>
                        </button>
                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.stage+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                        <i className="pi-layout-grid-line"></i>
                        </button>
                    </div>
                    <div className='kui-page-list-actions-group'>
                        <div className='kui-page-list-actions-input'>
                        <span>Nombre à afficher: </span>
                        <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                        </div>
                        <div className='kui-page-list-actions-search'>
                        <i className="pi-search-line"></i>
                        <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                        </div>
                    </div>
                    <div className='kui-page-list-actions-group'>
                      <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                    </div>
                </div>
              </div>
              {
                isError || isBError || isDError || isSError || isScError ? (
                  <div className='kui-page-error'>
                    <div className='kui-page-error__illustration'>
                      <NotFoundError loop={true} autoplay={true} size={{
                        height: 180,
                        width: 180
                      }}/>
                    </div>
                    <div className='kui-page-error_details'>
                      <p>Oupps! Une erreur est survenue</p>
                      <h1>{isError ? message : (isBError ? bMessage : isSError ? sMessage : isDError ? dMessage : isScError ? scMessage : "")}</h1>
                    </div>
                  </div> 
                ) : (
                  isSuccess && isSSuccess && isBSuccess && isScSuccess && isDSuccess  && demande_enseignements  && students && teachers && stages && demandes_inscription ? (
                    <div className='kui-page-list__container'>
                      <span className='kui-page-list__total'>
                        {
                          listStages(stages, students, teachers, demandes_inscription, demande_enseignements)
                          .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                          .length > 0 ? `
                          ${listStages(stages, students, teachers, demandes_inscription, demande_enseignements)
                            .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length > 1 ? listStages(stages, students, teachers, demandes_inscription, demande_enseignements)
                            .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length + " elements trouvés" : listStages(stages, students, teachers, demandes_inscription, demande_enseignements)
                            .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).length +" element trouvé"} ` : "Aucun element trouvé"
                        }
                      </span>

                      {
                        listStages(stages, students, teachers, demandes_inscription, demande_enseignements)
                        .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                        .length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  listStages(stages, students, teachers, demandes_inscription, demande_enseignements)
                                  .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-item is--md' key={index}>
                                        <div className='kui-page-list-item__min'>
                                          <div>
                                              <span className={`kui-chip ${item?.statut_stage?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                              {item.statut_stage?.toUpperCase()}
                                              </span>
                                          </div>
                                        </div>
                                        

                                        <div className='kui-page-list-item__container'>
                                            <div className='kui-page-list-item__details'>
                                              <strong>{item.code_enseignant}</strong>
                                              <p>Date de debut: <strong>{item?.date_de_debut_stage ? `${capitalize(format(new Date(item?.date_de_debut_stage), "EEEE d MMMM yyyy", {locale: fr}))}` : "ND"}</strong></p>

                                              <span>Etudiant</span>

                                              <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.photo_id ? API_URL2 + item?.student?.info?.photo_id : ""}/>

                                              <span>Enseignant</span>
                                              <TableItem title={item?.teacher?.info?.profile?.nom + " "+item?.teacher?.info?.profile?.prenom} subtitle={item?.teacher?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.teacher?.info?.profile?.telephone} withImage={true} image={item?.teacher?.info?.photo_id ? API_URL2 + item?.teacher?.info?.photo_id : ""}/>
                                            </div>
                                        </div>
                                        <div className='kui-page-list-item__actions'>
                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setShowItemModal(true)
                                            }}>
                                                <i className={"pi-eye-line"}></i>
                                                <span>{"Apercu"}</span>
                                            </button>
                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                setSelectItemModal(null)
                                                setSelectItemModal(item)
                                                setDeleteModal(true)
                                            }}>
                                                <i className={"pi-delete-2-line"}></i>
                                                <span>{"Supprimer"}</span>
                                            </button>
                                        </div>
                                    </div>
                                  ))        
                                }
                              </Grid>
                            ) : (
                            <Table>
                                <Row rtype='header'>
                                    <Column ctype={'small'}>
                                        <strong>#</strong>
                                    </Column>
                                    <Column ctype={'mid'}>
                                        <strong>Code Stage</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                        <strong>Etudiant</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                      <strong>Enseignement</strong>
                                    </Column>
                                    <Column ctype={'large'}>
                                      <strong>Date de debut</strong>
                                    </Column>
                                    <Column ctype={'mid'}>
                                      <strong>Statut</strong>
                                    </Column>
                                    <Column ctype={'small'}>
                                      <strong>#</strong>
                                    </Column>
                                </Row>
                                {
                                  listStages(stages, students, teachers, demandes_inscription, demande_enseignements)
                                  .filter((el: any) =>  el?.student?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.student?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.nom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.teacher?.info?.profile?.prenom?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                          <strong>{`#${index + 1}`}</strong>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          {/* <span className='kui-tag'>{item.code_enseignant}</span> */}
                                        </div>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.student?.info?.profile?.nom + " "+item?.student?.info?.profile?.prenom} subtitle={item?.student?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.student?.info?.profile?.telephone} withImage={true} image={item?.student?.info?.photo_id ? API_URL2 + item?.student?.info?.photo_id : ""}/>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.teacher?.info?.profile?.nom + " "+item?.teacher?.info?.profile?.prenom} subtitle={item?.teacher?.info?.profile?.sexe?.toUpperCase() + " - Numero de telephone: " +item?.teacher?.info?.profile?.telephone} withImage={true} image={item?.teacher?.info?.photo_id ? API_URL2 + item?.teacher?.info?.photo_id : ""}/>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <p><strong>{item?.date_de_debut ? `${capitalize(format(new Date(item?.date_de_debut), "EEEE d MMMM yyyy", {locale: fr}))}` : "ND"}</strong></p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                          <div>
                                            <span className={`kui-chip ${item?.statut_stage?.toLocaleLowerCase() === "actif" ?  "is--success" : "is--warning"}`}>
                                            {item.statut_stage?.toUpperCase()}
                                            </span>
                                          </div>
                                      </Column>
                                        <Column ctype={'small'}>
                                            <TableOptions atype={"reduce"} tabs={[
                                              {
                                                icon: "pi-eye-line", 
                                                label: "Aperçu", 
                                                style:"is--neutral", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setShowItemModal(true)
                                                }
                                              },
                                              {
                                                icon: "pi-delete-2-line", 
                                                label: "Supprimer", 
                                                style:"is--danger", 
                                                onClick: () => {
                                                  setSelectItemModal(null)
                                                  setSelectItemModal(item)
                                                  setDeleteModal(true)
                                                }
                                              },
                                            ]}/>
                                        </Column>
                                    </Row>
                                  ))
                                }
                            </Table>
                            )
                        ) : (
                            <div className='kui-page-empty'>
                                <div className='kui-page-empty__illustration'>
                                  <EmptyList loop={true} autoplay={true} size={{
                                      height: (380 / 1.3),
                                      width: (420 / 1.3)
                                  }}/>
                                </div>
                                <div className='kui-page-empty__details'>
                                    <h1>Aucun element trouvé</h1>
                                </div>
                            </div>
                        )
                      }
                    </div>
                  ) : null
                )
              }
              {addModal && teachers && students && demandes_inscription && demande_enseignements && <AddModal 
                demandes={demandes_inscription} 
                teachers={listTeacher( demande_enseignements, teachers)} 
                students={students} 
                fnc={(val?: boolean) => setAddModal(val || false)}
              />}

              {/* {editModal && selectItemModal ? (
                <EditModal
                  itemo={selectItemModal} 
                  demandes={demandes_inscription} 
                  teachers={listTeacher( demande_enseignements, teachers)} 
                  students={students} 
                  fnc={(val?: boolean) => setEditModal(val || false)}
                />
              ): null} */}

              
              {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

              {showItemModal && selectItemModal ? (
                <Modal size={'is--xl'} withClose={true} id={'show-category'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                    <div className='kui-modal-box__header'>
                      <h2>Aperçue d'un stage</h2>
                      <p>Debut: <strong>{selectItemModal?.date_de_debut_stage ? `${capitalize(format(new Date(selectItemModal?.date_de_debut_stage), "EEEE d MMMM yyyy", {locale: fr}))}` : "ND"}</strong> 
                      </p>
                    </div>
    
                    <div className='kui-modal-full-page'>
                      <div className='kui-modal-full-page__container'>
                        <div className='kui-modal-full-page__section'>
                          <h2>Etudiant</h2>
                          <div className='kui-modal-full-page__illustration kui-modal-full-page-illustration is--left'>
                            <div className='kui-modal-full-page-illustration__container is--square'>
                              {
                                selectItemModal?.student?.info?.photo_id ? (
                                  ""
                                ) : (
                                  <span>{creerInitiales(selectItemModal?.student?.info?.profile?.nom + " "+selectItemModal?.student?.info?.profile?.prenom)}</span>
                                )
                              }
                            </div>
                          </div>
                          
                          <div className='kui-modal-full-page-details'>
                            <h1>{selectItemModal?.student?.info?.profile?.nom?.toUpperCase() + " "+selectItemModal?.student?.info?.profile?.prenom}</h1>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Sexe: <strong>{selectItemModal?.student?.info?.profile?.sexe?.toUpperCase()}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Email: <strong>{selectItemModal?.email}</strong> </Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Telephone: <strong>{selectItemModal?.student?.info?.profile?.telephone || "ND"}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Nationalité: <strong>{selectItemModal?.student?.info?.profile?.nationalite || "ND"}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Date de naissance: <strong>{selectItemModal?.student?.info?.profile?.date_de_naissance ? `${capitalize(format(new Date(selectItemModal?.student?.info.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr}))} (${calculateAge(selectItemModal?.student?.info.profile?.date_de_naissance)} ans)` : "ND"}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Lieu et Pays de naissance: <strong>{(selectItemModal?.student?.info?.profile?.lieu_de_naissance || "ND") + " - " + (selectItemModal?.student?.info?.profile?.pays_de_naissance || "ND")}</strong> </Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Adresse et Pays de residence: <strong>{(selectItemModal?.student?.info?.profile?.adresse || "ND")+ " - " + (selectItemModal?.student?.info?.profile?.ville || "ND")+ " - " + (selectItemModal?.student?.info?.profile?.pays_de_residence || "ND")}</strong> </Grid>
                          </div>
                        </div>

                        <div className='kui-modal-full-page__section'>
                          <h2>Enseignant</h2>
                          <div className='kui-modal-full-page__illustration kui-modal-full-page-illustration is--left'>
                            <div className='kui-modal-full-page-illustration__container is--square'>
                              {
                                selectItemModal?.teacher?.info?.photo_id ? (
                                  ""
                                ) : (
                                  <span>{creerInitiales(selectItemModal?.teacher?.info?.profile?.nom + " "+selectItemModal?.teacher?.info?.profile?.prenom)}</span>
                                )
                              }
                            </div>
                          </div>
                          
                          <div className='kui-modal-full-page-details'>
                            <h1>{selectItemModal?.teacher?.info?.profile?.nom?.toUpperCase() + " "+selectItemModal?.teacher?.info?.profile?.prenom}</h1>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Sexe: <strong>{selectItemModal?.teacher?.info?.profile?.sexe?.toUpperCase()}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Email: <strong>{selectItemModal?.email}</strong> </Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Telephone: <strong>{selectItemModal?.teacher?.info?.profile?.telephone || "ND"}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Nationalité: <strong>{selectItemModal?.teacher?.info?.profile?.nationalite || "ND"}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Date de naissance: <strong>{selectItemModal?.teacher?.info?.profile?.date_de_naissance ? `${capitalize(format(new Date(selectItemModal?.teacher?.info.profile?.date_de_naissance), "EEEE d MMMM yyyy", {locale: fr}))} (${calculateAge(selectItemModal?.teacher?.info.profile?.date_de_naissance)} ans)` : "ND"}</strong></Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Lieu et Pays de naissance: <strong>{(selectItemModal?.teacher?.info?.profile?.lieu_de_naissance || "ND") + " - " + (selectItemModal?.teacher?.info?.profile?.pays_de_naissance || "ND")}</strong> </Grid>
                            <Grid direction={'fd-row'} spacing={'jc-between'} align={'start'} wrap={true} gap={0.5}>Adresse et Pays de residence: <strong>{(selectItemModal?.teacher?.info?.profile?.adresse || "ND")+ " - " + (selectItemModal?.teacher?.info?.profile?.ville || "ND")+ " - " + (selectItemModal?.teacher?.info?.profile?.pays_de_residence || "ND")}</strong> </Grid>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <ModalActions tabsActions={[
                        {
                          icon: "pi-edit-3-line", 
                          type: "button", 
                          label:"Modifier", 
                          style:"is--success", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setEditModal(true)
                          },
                          show: true
                        },
                        {
                          icon: "pi-delete-2-line", 
                          type: "button", 
                          label:"Supprimer", 
                          style:"is--danger", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                            setDeleteModal(true)
                          },
                          show: true
                        },
                        {
                          icon: "pi-close-line", 
                          type: "button", 
                          label:"Fermer", 
                          style:"is--neutral", 
                          active: true,
                          onClick: () => {
                            setShowItemModal(false)
                          },
                          show: true
                        },
                    ]}/>
                </Modal>
              ) : null}  
            </>
          )
        }
      </div>
    </MainLayout>
  )
}
